import styled from 'styled-components'

import Field from 'components/Form/components/Field'

export const TranslationFieldStyled = styled(Field)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Language = styled.div`
  display: flex;
  padding: 5px;
  border-bottom: 1px dashed var(--color-softStroke);
  text-align: left;
`

export const Label = styled.span`
  flex: 1;
  display: flex;
  align-items: center;

  .ant-tag {
    margin-left: 1em;
  }
`

export const Value = styled.span`
  display: flex;
  align-items: center;
  flex: 3;
  min-height: 40px;
  width: 100%;

  span {
    width: 100%;
  }

  input {
    text-align: left !important;
  }
`
