import styled from "styled-components";
import React, { ComponentPropsWithoutRef } from "react";

// Needed because of the error component being rendered where GlobalStyles not being setup yet
import './error-page.css';

const ErrorPageOuterContainer = styled.div`
    font-family: poppins, sans-serif;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    /* Layout */
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;

    /* Text */
    font-size: .813rem;
    font-weight: 200;


    background: url(/resources/images/image.png);
    background-size: cover;
`;

const ErrorPageInnerContainer = styled.div`
    display: block;
    padding: 1.5rem;
    margin: 0;
    width: 100%;
    overflow: auto;

    @media only screen and (min-width: 768px) {
        min-width: 600px;
        width: 60%;
        font-size: 1rem;
        padding: 0;
    }
`;


const LogoContainer = styled.div`
    display: block;
    max-height: 40px;
    height: 40px;
    width: auto;
    flex-shrink: 0;

    a {
        height: 100%;
    }
    img {
        height: 100%;
    }
`

const Logo = () => (
    <LogoContainer>
        <a href="https://www.brightcanary.eu" target="_blank" rel="noopener noreferrer">
          <img
            src='/resources/images/logo.svg'
            alt="Canary - data-driven sustainability"
          />
        </a>
    </LogoContainer>
)

export const ErrorPageStyled = (props: ComponentPropsWithoutRef<'div'>) => (
    <ErrorPageOuterContainer >
            <div className="header">
                <Logo />
            </div>
            <div className="middle" >
                <div className="main">
                    <ErrorPageInnerContainer {...props} />
                </div>
            </div>

            <div className="footer">
                <div style={{height: '40px'}} />
            </div>
    </ErrorPageOuterContainer>
)
