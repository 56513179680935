import { Input } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

const { Search } = Input

export const AntInput = styled(Input)`
  &:focus,
  &:hover {
    border-color: var(--color-main);
    border-right-width: 1px !important;
  }
  ::selection {
    background: ${({ theme }) => transparentize(0.8, theme.color.main)};
  }
`

export const AntSearch = styled(Search)`
  &:hover {
    border-color: var(--color-main);
    border-right-width: 1px !important;
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: var(--color-main);
    border-right-width: 1px !important;
    -webkit-box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
    box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
  }
`
