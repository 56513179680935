import { transparentize } from 'polished'
import styled from 'styled-components'

export const ModeStyled = styled.div`
  display: flex;
  width: 100%;

  > i.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;

    height: 30px !important;
    min-width: 30px !important;
    border-right: 1px solid var(--color-softStroke);
    transition: background 0.2s ease, fill 0.2s ease;
    padding: 0.3rem;

    fill: var(--color-softText);

    &:last-child {
      border-right: none;
    }

    &:hover,
    &:focus,
    &.active {
      background: ${({ theme }) => transparentize(0.9, theme.color.main)};
      fill: var(--color-main);
    }
  }
`
