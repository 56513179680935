const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.xrange',
    category: 'widget.others',
    keywords: ['xrange', 'timeserie', 'time', 'history'],
    img: '/resources/images/widgets/xrange.png',
    info: {
      'fr-FR':
        '<h2>X Range</h2>\n' +
        "<p>Le widget X Range affiche les changements de valeurs de points de données distinctes sur une période de temps d'une autre manière.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données (LINK)</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '<li><strong>Intervalle</strong> Sélectionnez la granularité des valeurs des points de données</li>\n' +
        '<li><strong>Données brutes disponibles</strong> Désactiver ou non le basculement des données brutes</li>\n' +
        '<li><strong>Données brutes activées par défaut</strong> Afficher les données brutes par défaut ou non</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Étiquette</strong> Saisissez une étiquette pour le point de données</li>\n' +
        '</ul>\n' +
        '<h4>Lignes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Décalages</strong> Ajoutez une ou plusieurs lignes pour le point de données sélectionné en sélectionnant une couleur, une étiquette et une valeur</li>\n' +
        '</ul>',
      'en-US':
        '<h2>X Range</h2>\n' +
        '<p>The X Range widget shows the changes in distinct datapoint values over a period of time in an alternate way.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints (LINK)</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Interval</strong> Select the granularity of the datapoint values</li>\n' +
        '<li><strong>Raw Data Available</strong> Disable raw data toggle or not</li>\n' +
        '<li><strong>Raw Data Default On</strong> Show raw data by default or not</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '</ul>\n' +
        '<h4>Lines</h4>\n' +
        '<ul>\n' +
        '<li><strong>Offsets</strong> Add one or more lines for the selected datapoint by selecting a color, label and value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>X Range</h2>\n' +
        '<p>De X Range-widget toont de veranderingen in afzonderlijke gegevenspuntwaarden over een bepaalde periode op een alternatieve manier.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Widgets titel</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten (LINK)</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '<li><strong>Interval</strong> Selecteer de granulariteit van de gegevenspuntwaarden</li>\n' +
        '<li><strong>Onbewerkte gegevens beschikbaar</strong> Schakel onbewerkte gegevens uit of niet</li>\n' +
        '<li><strong>Standaard onbewerkte gegevens aan</strong> Standaard onbewerkte gegevens weergeven of niet</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '</ul>\n' +
        '<h4>Lijnen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Verschuivingen</strong> Voeg een of meer lijnen toe voor het geselecteerde datapunt door een kleur, label en waarde te selecteren</li>\n' +
        '</ul>',
    },
    default: {
      type: 'XRange',
      title: 'widget.xrange',
      icon: 'fas fa-info',
      url: null,
      datapoints: [],
    },
    preview: {
      icon: 'fas fa-tachometer-alt',
      xrange: {
        chart: {
          type: 'xrange',
        },
        title: {
          text: undefined,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          title: {
            text: '',
          },
          categories: ['Series 1', 'Series 2'],
          reversed: true,
        },
        series: [
          {
            name: 'Project 1',
            pointWidth: 20,
            data: [
              {
                x: Date.UTC(2014, 11, 2),
                x2: Date.UTC(2014, 11, 5),
                y: 1,
              },
              {
                x: Date.UTC(2014, 11, 8),
                x2: Date.UTC(2014, 11, 9),
                y: 0,
              },
              {
                x: Date.UTC(2014, 11, 9),
                x2: Date.UTC(2014, 11, 19),
                y: 1,
              },
              {
                x: Date.UTC(2014, 11, 10),
                x2: Date.UTC(2014, 11, 23),
                y: 0,
              },
            ],
            dataLabels: {
              enabled: true,
            },
          },
        ],
        tooltip: false,
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        componentProps: {
          dataKey: 'datapoints',
          hideMethod: true,
          canReselect: false,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'label',
                },
                {
                  category: 'widget.lines',
                  componentName: 'XRangeLineConfigurator',
                  label: { formatted: 'widget.offsets' },
                  dataKey: 'lines',
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        dataKey: '',
        componentProps: {
          dataKey: 'defaultStartTime',
          datapointsDataKey: 'datapoints',
          range: true,
          hideGranularity: false,
          hideRawData: false,
        },
      },
    ],
  },
}

export default config
