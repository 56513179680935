import { darken, transparentize } from 'polished'
import { createGlobalStyle, css } from 'styled-components'

import AntStyling from './styled.ant'

const cssVariables = css`
  /* Static colors  */
  --color-white: #fff;
  --color-backgroundBase: #c6e5f7; /* Not used? */
  --color-strongerText: #181818; /* Not used? */
  --color-theme: #fdbe10;
  --color-mainShadow: rgb(180, 180, 180); /* Not used? */
  --color-success: #44bc66;
  --color-error: #f14e3e;
  --color-warning: #fed33a;
  --color-info: #3e76e5; /* Used Once */
  --color-soft: #c6e5f7; /* Not used? */
  --color-color2: #c6d336; /* Not used? */
  --color-color3: #10d680;
  --color-color4: #1bd6b9; /* Not used? */
  --color-color5: #21b9f7; /* Not used? */
  --color-color6: #438de4;
  --color-color8: #fa075e;
  --color-color9: #ff2b2a; /* Not used? */
  --color-color10: #606060; /* Not used? */
  --color-color11: #a507fa; /* Not used? */
  --color-color12: #5537ff; /* Not used? */
  --color-critical: #f88c1d;
  --color-sidebarText: #fff;
  --color-mainTextColor: #fff;
  --color-highlightText: #353535;
  --color-shadowBaseColor: rgb(180, 180, 180); /* Not used? */
  --color-black: #000; /* Not used? */
  --color-disabled: #eee; /* Not used? */

  /* Dynamic colors */
  --color-action: ${(p) => p.theme.color.action};
  --color-sideBar: ${(p) => p.theme.color.sideBar};
  --color-highlight: ${(p) => p.theme.color.highlight};
  --color-backgroundFront: ${(p) => p.theme.color.backgroundFront};
  --color-backgroundBack: ${(p) => p.theme.color.backgroundBack};
  --color-softerText: ${(p) => p.theme.color.softerText};
  --color-softText: ${(p) => p.theme.color.softText};
  --color-mainStroke: ${(p) => p.theme.color.mainStroke};
  --color-mainText: ${(p) => p.theme.color.mainText};
  --color-strongText: ${(p) => p.theme.color.strongText};
  --color-softStroke: ${(p) => p.theme.color.softStroke};
  --color-softerStroke: ${(p) => p.theme.color.softerStroke};
  --color-backgroundHighlight: ${(p) => p.theme.color.backgroundHighlight};
  --color-highlightText: ${(p) => p.theme.color.highlightText};
  --color-main: ${(p) => p.theme.color.main};

  /* Static Shadows */
  --shadow-d3Elevation1: 0 0 3px rgba(180, 180, 180, 0.12), 0 0 2px rgba(180, 180, 180, 0.24); /* Not used? */
  --shadow-d3Elevation2: 0 0 6px rgba(180, 180, 180, 0.16), 0 0 6px rgba(180, 180, 180, 0.23); /* Not used? */
  --shadow-d3Elevation3: 0 0 20px rgba(180, 180, 180, 0.19), 0 0 6px rgba(180, 180, 180, 0.23); /* Not used? */
  --shadow-d3Elevation4: 0 0 28px rgba(180, 180, 180, 0.25), 0 0 10px rgba(180, 180, 180, 0.22); /* Not used? */
  --shadow-d3Elevation5: 0 0 38px rgba(180, 180, 180, 0.3), 0 0 12px rgba(180, 180, 180, 0.22); /* Not used? */

  /* Dynamic Shadows */
  --shadow-shadowElevation0: ${(p) => p.theme.shadowElevation0};
  --shadow-shadowElevation1: ${(p) => p.theme.shadowElevation1};
  --shadow-shadowElevation2: ${(p) => p.theme.shadowElevation2};
  --shadow-shadowElevation3: ${(p) => p.theme.shadowElevation3};
  --shadow-shadowElevation4: ${(p) => p.theme.shadowElevation4};
  --shadow-shadowElevation5: ${(p) => p.theme.shadowElevation5};
  --shadow-shadowElevation6: ${(p) => p.theme.shadowElevation6};
  --shadow-panelElevationBottom: ${(p) => p.theme.panelElevationBottom};
  --shadow-panelElevationLeft: ${(p) => p.theme.panelElevationLeft};
  --shadow-panelElevationLeft2: ${(p) => p.theme.panelElevationLeft2};
  --shadow-panelElevationRight: ${(p) => p.theme.panelElevationRight};

  /* Typography */
  --font-family-primary: poppins, sans-serif;
  --font-family-secondary: poppins, sans-serif;
  --font-size-xl: 1.6rem;
  --font-spacing-xl: 0.02;
  --font-weight-xl: 600;

  --font-size-l: 1.4rem;
  --font-spacing-l: 0.01rem;
  --font-weight-l: 600;

  --font-size-m: 1rem;
  --font-spacing-m: 0.02;
  --font-weight-m: 300;

  --font-size-s: 0.9rem;
  --font-spacing-s: 0.02;
  --font-weight-s: 300;

  /* Layout */
  --topBar-height: 4em;
  --sideBar-width: 100px;
`

const GlobalStyled = createGlobalStyle`
	svg:not(:root).svg-inline--fa,
	svg:not(:host).svg-inline--fa {
		overflow: visible;
		/* Overwrite fontawesome */
		box-sizing: border-box!important;
	}

	:root {
		${cssVariables}
	}

	html {
		font-size: 13px;
		@media (max-width: 767px) {
			font-size: 12px;
		}
	}

	body {
		font-family: var(--font-family-primary);
		line-height: 1.7;
		@media (max-width: 767px) {
			font-size: 12px;
		}
	}

	* {
		margin: 0;
		box-sizing: border-box;
	}

	ul {
		padding: 0;
    list-style-type: none;
	}

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
  }

	h1, h2, h5 {
		color: var(--color-strongText);
	}

	h1 {
		font-size: 2.5rem;
	}

	h3, .h3 {
		font-size: var(--font-size-l);
		font-weight: var(--font-weight-l);
	}

	h4, .h4 {
		font-size: 1.5rem;
	}

  	h6, .h6 {
		font-size: 1rem;
    	font-weight: 500;
    	line-height: 1.2;
	}

	a {
		text-decoration: none;
    	color: var(--color-softText);
	}

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track-piece {
		background: transparent;
	}

	::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		border: 1px solid ${({ theme }) => darken(0.05, theme.color.backgroundFront)};
		background: ${({ theme }) => transparentize(0.5, theme.color.softText)};

		&:hover {
			background: var(--color-softText);
		}
	}

	::-webkit-scrollbar-thumb:window-inactive {
		background: var(--color-softerStroke);
	}


  ${AntStyling}
`
export default GlobalStyled
