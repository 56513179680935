import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Button from 'components/Global/Button'

export const ModeControlStyled = styled.div`
  display: flex;
`

export const HvacButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px !important;
  width: 30px !important;
  border-right: 1px solid var(--color-softStroke);
  transition: background 0.2s ease, fill 0.2s ease;

  fill: var(--color-softText);

  &:last-child {
    border-right: none;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => transparentize(0.9, theme.color.main)};
    fill: var(--color-main);
  }

  ${({ active }) =>
    active === 1
      ? css`
          background: ${({ theme }) => transparentize(0.9, theme.color.main)};
          fill: var(--color-main);
          border-bottom: 2px solid var(--color-main);
        `
      : active === 'auto' &&
        css`
          fill: var(--color-main);
        `}
`
