import { useCallback, useMemo } from 'react'

import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import { useCurrentCustomer } from 'services/store'
import { useLocaleHook } from 'services/user'

const FRAGMENT_ORGANISATION_LINK_FIELDS = gql`
  fragment OrganisationLinkOverviewFields on OrganisationLinkOverview {
    id
    companyId
    entityTypeId
    entityTypeName
    entityTags
    organisationId
    organisationName
    rootLinks
    matrixStateId
  }
`

const QUERY_ORGANISATION_FORMS = gql`
  query organisationForms($companyId: Float!, $type: String, $typeId: ID) {
    organisationForms(companyId: $companyId, type: $type, typeId: $typeId) {
      id
      name
      organisationType {
        id
        name
      }
    }
  }
`

const MUTATION_UPDATE_COMPANY_LINK = gql`
  ${FRAGMENT_ORGANISATION_LINK_FIELDS}

  mutation updateCompanyLinks($matrixStateId: Float!, $data: CompanyLinksStatusInput!) {
    updateCompanyLinks(matrixStateId: $matrixStateId, data: $data) {
      ...OrganisationLinkOverviewFields
    }
  }
`

export const useUpdateCompanyLinks = () => {
  const customer = useCurrentCustomer()

  const [execute, info] = useMutation(MUTATION_UPDATE_COMPANY_LINK)
  const update = useCallback(
    (matrixStateId, organisationLinkStatusses) => {
      return execute({
        variables: { matrixStateId, data: { companyId: customer.id, organisationLinkStatusses } },
        refetchQueries: [
          {
            query: QUERY_ORGANISATION_LINKS,
            variables: { companyId: customer?.id, stateId: matrixStateId },
          },
        ],
      })
    },
    [execute, customer]
  )

  return [update, info]
}

const MUTATION_CREATE_COMPANY_LINK = gql`
  ${FRAGMENT_ORGANISATION_LINK_FIELDS}

  mutation createCompanyLinks($matrixStartDate: Float!, $data: CompanyLinksStatusInput!) {
    createCompanyLinks(matrixStartDate: $matrixStartDate, data: $data) {
      ...OrganisationLinkOverviewFields
    }
  }
`

export const useCreateCompanyLinks = () => {
  const customer = useCurrentCustomer()

  const [execute, info] = useMutation(MUTATION_CREATE_COMPANY_LINK, {
    refetchQueries: [
      {
        query: QUERY_ORGANISATIONS_LINK_STATES,
        variables: { companyId: customer?.id },
        skip: !customer?.id,
      },
    ],
    awaitRefetchQueries: true,
  })
  const update = useCallback(
    (matrixStartDate, organisationLinkStatusses) => {
      return execute({
        variables: { matrixStartDate, data: { companyId: customer.id, organisationLinkStatusses } },
      })
    },
    [execute, customer]
  )

  return [update, info]
}

const QUERY_ORGANISATION_TYPE = gql`
  query organisationTypes($companyId: Float!) {
    organisationTypes(companyId: $companyId) {
      id
      name
    }
  }
`

export const useOrganisationType = () => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ORGANISATION_TYPE, {
    variables: { companyId: customer?.id },
    skip: !customer?.id,
    fetchPolicy: 'network-only',
  })
  return { data: data?.organisationTypes, ...rest }
}

const QUERY_ORGANISATION_LINKS = gql`
  ${FRAGMENT_ORGANISATION_LINK_FIELDS}

  query organisationLinks($companyId: Float!, $stateId: Float) {
    organisationLinks(companyId: $companyId, matrixStateId: $stateId) {
      ...OrganisationLinkOverviewFields
    }
  }
`

export const useOrganisationForms = ({ type, typeId }) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(QUERY_ORGANISATION_FORMS, {
    variables: { companyId: customer?.id, type, typeId },
    skip: !customer?.id,
    fetchPolicy: 'network-only',
  })
  return { data: data?.organisationForms, ...rest }
}

export const QUERY_ORGANISATIONS = gql`
  query organisations($companyId: Float!, $isCore: Boolean) {
    organisations(companyId: $companyId, isCore: $isCore) {
      id
      name
      legalEntity {
        abbreviation
      }
    }
  }
`

export const useOrganisations = ({ isCore } = {}) => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ORGANISATIONS, {
    variables: { companyId: customer?.id, isCore },
    skip: !customer?.id,
    fetchPolicy: 'network-only',
  })
  return { data: data?.organisations, ...info }
}

export const useOrganisationLinks = (stateId) => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ORGANISATION_LINKS, {
    variables: { companyId: customer?.id, stateId: stateId ?? null },
    skip: !customer?.id,
  })

  return { data: data?.organisationLinks, ...info }
}

const QUERY_ORGANISTION_FORM_LINKS = gql`
  query organisationFormLinks($companyId: Float!, $stateId: Float) {
    organisationFormLinks(companyId: $companyId, matrixStateId: $stateId) {
      id
      startDate
      organisationFormLinks {
        id
        organisation {
          id
          name
        }
        rootLink
        organisationFormLinkPercentages {
          id
          organisationForm {
            id
            name
          }
          percentage
        }
      }
    }
  }
`

export const useOrganisationFormLinks = (stateId) => {
  const customer = useCurrentCustomer()

  const { data, ...info } = useQuery(QUERY_ORGANISTION_FORM_LINKS, {
    variables: { companyId: customer?.id, stateId },
    skip: !customer?.id,
  })

  return {
    data: data?.organisationFormLinks
      ? data?.organisationFormLinks?.[0] || { organisationFormLinks: [] }
      : undefined,
    ...info,
  }
}

const QUERY_ORGANISATION_REPORTING_YEAR = gql`
  query organisationReportingYear($id: ID!) {
    organisation(id: $id) {
      id
      reportingYear {
        id
        period
        periodParts {
          startDay
          startMonth
          endDay
          endMonth
        }
      }
    }
  }
`

const ENTITIES_FOR_ORGANISATION = gql`
  query entitiesForOrganisation($companyId: Float!, $organisationId: Float!, $language: String!) {
    entitiesForOrganisation(
      companyId: $companyId
      organisationId: $organisationId
      language: $language
    ) {
      id
      name
      type
      preset {
        id
        name
      }
      hierarchy
      countryInfo {
        countryName
        countryImgName
      }
    }
  }
`

const STATIONARY_COMBUSTIONMETERS_FOR_ORGANISATION = gql`
  query stationaryCombustionMetersForOrganisation(
    $meterInfo: MeterInfoInput!
    $companyId: Float!
    $organisationId: Float!
  ) {
    stationaryCombustionMetersForOrganisation(
      meterInfo: $meterInfo
      companyId: $companyId
      organisationId: $organisationId
    ) {
      id
      datapointName
      equipName
      datapointTags
      equipTags
    }
  }
`

const PURCHASED_ENERGY_METERS_FOR_ORGANISATION = gql`
  query purchasedEnergyMetersForOrganisation(
    $meterInfo: MeterInfoInput!
    $companyId: Float!
    $organisationId: Float!
  ) {
    purchasedEnergyMetersForOrganisation(
      meterInfo: $meterInfo
      companyId: $companyId
      organisationId: $organisationId
    ) {
      id
      datapointName
      equipName
      datapointTags
      equipTags
    }
  }
`

const REFRIGERANT_EQUIPMENTS_FOR_ORGANISATION = gql`
  query refrigerantEquipmentsForOrganisation(
    $tagFilter: String!
    $companyId: Float!
    $organisationId: Float!
    $type: GhgRefrigerantDatapointType
  ) {
    refrigerantEquipmentsForOrganisation(
      tagFilter: $tagFilter
      companyId: $companyId
      organisationId: $organisationId
      type: $type
    ) {
      id
      datapointName
      equipName
      datapointTags
      equipTags
    }
  }
`

const MOBILE_COMBUSTION_VEHICLES_FOR_ORGANISATION = gql`
  query mobileCombustionVehiclesForOrganisation(
    $companyId: Float!
    $organisationId: Float!
    $departmentId: Float
    $vehicleTypeId: Float
  ) {
    mobileCombustionVehiclesForOrganisation(
      companyId: $companyId
      organisationId: $organisationId
      departmentId: $departmentId
      vehicleTypeId: $vehicleTypeId
    ) {
      id
      equipName
      equipTags
      companyId
    }
  }
`

export const useOrganisationReportingYear = (id, skip) => {
  const { data, ...info } = useQuery(QUERY_ORGANISATION_REPORTING_YEAR, {
    variables: { id },
    skip: skip || !id,
  })

  const reportingYear = data?.organisation.reportingYear
  const createDateRange = useCallback(
    (year) => {
      if (!reportingYear?.periodParts) {
        return [undefined, undefined]
      }

      const { startMonth, startDay, endDay, endMonth } = reportingYear.periodParts

      const start = dayjs([year, startMonth - 1, startDay])

      const endSameYear = dayjs([year, endMonth - 1, endDay, 23, 59, 59])
      const endNextYear = dayjs([year + 1, endMonth - 1, endDay, 23, 59, 59])

      const end = endSameYear < start ? endNextYear : endSameYear

      return [start, end]
    },
    [reportingYear]
  )

  return { data: reportingYear, createDateRange, ...info }
}

const QUERY_ORGANISATIONS_LINK_STATES = gql`
  query organisationLinkMatrixStates($companyId: Float!) {
    organisationLinkMatrixStates(companyId: $companyId) {
      id
      startDate
      endDate
    }
  }
`

export const useOrganisationLinkStates = () => {
  const customer = useCurrentCustomer()
  const { data, ...info } = useQuery(QUERY_ORGANISATIONS_LINK_STATES, {
    variables: { companyId: customer?.id },
    skip: !customer,
  })

  return { data: data?.organisationLinkMatrixStates, ...info }
}

const QUERY_ORGANISATIONS_FORM_LINK_STATES = gql`
  query organisationFormLinkMatrixStates($companyId: Float!) {
    organisationFormLinkMatrixStates(companyId: $companyId) {
      id
      startDate
      endDate
    }
  }
`

export const useOrganisationFormLinkStates = () => {
  const customer = useCurrentCustomer()
  const { data, ...info } = useQuery(QUERY_ORGANISATIONS_FORM_LINK_STATES, {
    variables: { companyId: customer?.id },
    skip: !customer,
  })

  return { data: data?.organisationFormLinkMatrixStates, ...info }
}

export const useEntitiesForOrganisation = (options = {}, organisationId) => {
  const locale = useLocaleHook()

  const customer = useCurrentCustomer()

  const queryResult = useQuery(ENTITIES_FOR_ORGANISATION, {
    ...options,
    variables: { companyId: customer?.id, language: locale, organisationId },
    skip: options?.skip || !customer?.id || !organisationId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    return {
      ...queryResult,
      data: queryResult?.data?.entitiesForOrganisation,
      refetch: queryResult?.refetch,
    }
  }, [queryResult])
}

export const useStationaryCombustionMetersForOrganisation = (meterInfo, organisationId) => {
  const customer = useCurrentCustomer()
  return useLazyQuery(STATIONARY_COMBUSTIONMETERS_FOR_ORGANISATION, {
    variables: { meterInfo, companyId: customer?.id, organisationId },
    skip: !meterInfo,
  })
}

export const usePurchasedEnergyMetersForOrganisation = (meterInfo, organisationId) => {
  const customer = useCurrentCustomer()
  return useLazyQuery(PURCHASED_ENERGY_METERS_FOR_ORGANISATION, {
    variables: { meterInfo, companyId: customer?.id, organisationId },
    skip: !meterInfo,
  })
}

export const useRefrigerantEquipmentsForOrganisation = (tagFilter = {}, organisationId) => {
  const { equipment, datapoint } = tagFilter
  const customer = useCurrentCustomer()
  return useLazyQuery(REFRIGERANT_EQUIPMENTS_FOR_ORGANISATION, {
    variables: {
      equipTagFilter: equipment,
      dpTagFilter: datapoint,
      companyId: customer?.id,
      organisationId,
    },
    skip: !tagFilter,
  })
}

export const useMobileCombustionVehiclesForOrganisation = (
  organisationId,
  departmentId,
  vehicleTypeId
) => {
  const customer = useCurrentCustomer()
  return useLazyQuery(MOBILE_COMBUSTION_VEHICLES_FOR_ORGANISATION, {
    variables: { companyId: customer?.id, organisationId, departmentId, vehicleTypeId },
    skip: !customer?.id || !organisationId,
  })
}

const MUTATION_CREATE_ORGANISATION_FORM_LINKS = gql`
  mutation createOrganisationFormLinks(
    $matrixStartDate: Float!
    $data: OrganisationFormLinkInput!
  ) {
    createOrganisationFormLinks(matrixStartDate: $matrixStartDate, data: $data) {
      id
      startDate
      organisationFormLinks {
        id
        organisation {
          id
          name
        }
        rootLink
        organisationFormLinkPercentages {
          id
          organisationForm {
            id
            name
          }
          percentage
        }
      }
    }
  }
`

export const useCreateOrganisationFormLinks = () => {
  const customer = useCurrentCustomer()

  const [execute, info] = useMutation(MUTATION_CREATE_ORGANISATION_FORM_LINKS, {
    refetchQueries: [
      {
        query: QUERY_ORGANISATIONS_FORM_LINK_STATES,
        variables: { companyId: customer?.id },
        skip: !customer?.id,
      },
    ],
    awaitRefetchQueries: true,
  })
  const create = useCallback(
    (matrixStartDate, organisationFormLinkInfo) => {
      return execute({
        variables: {
          matrixStartDate,
          data: {
            companyId: customer.id,
            organisationFormLinkInfo,
          },
        },
      })
    },
    [execute, customer]
  )

  return [create, info]
}

const MUTATION_UPDATE_ORGANISATION_FORM_LINKS = gql`
  mutation updateOrganisationFormLinks($matrixStateId: Float!, $data: OrganisationFormLinkInput!) {
    updateOrganisationFormLinks(matrixStateId: $matrixStateId, data: $data) {
      id
      startDate
      organisationFormLinks {
        id
        organisation {
          id
          name
        }
        rootLink
        organisationFormLinkPercentages {
          id
          organisationForm {
            id
            name
          }
          percentage
        }
      }
    }
  }
`

export const useUpdateOrganisationFormLinks = (matrixStateId) => {
  const customer = useCurrentCustomer()

  const [execute, info] = useMutation(MUTATION_UPDATE_ORGANISATION_FORM_LINKS)
  const update = useCallback(
    (organisationFormLinkInfo) => {
      return execute({
        variables: {
          matrixStateId,
          data: {
            companyId: customer.id,
            organisationFormLinkInfo,
          },
        },
      })
    },
    [execute, customer, matrixStateId]
  )

  return [update, info]
}

const QUERY_ORGANISATION_ACTIVE_CALCULATION_METHOD = gql`
  query activeOrganisationCalculationMethodByOrganisation($organisationId: ID!, $date: DateTime) {
    organisation(id: $organisationId) {
      activeOrganisationCalculationMethod(date: $date) {
        id
        activationDate
        refrigerationCalculationMethodId
      }
    }
  }
`

export const useOrganisationActiveCalculationMethod = (organisationId, date, options = {}) => {
  const { data, ...rest } = useQuery(QUERY_ORGANISATION_ACTIVE_CALCULATION_METHOD, {
    variables: { organisationId, date },
    skip: !organisationId,
    ...options,
  })

  return { data: data?.organisation?.activeOrganisationCalculationMethod, ...rest }
}
