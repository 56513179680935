import { transparentize } from 'polished'
import styled from 'styled-components'

import { microWidgets } from 'components/Dashboard/components/FloorMap/styled'
import Tooltip from 'components/Global/Tooltip'

const MicroWidgetConfigurator = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }

  > .FlexContainer {
    padding-top: 0.2rem;
    .Button {
      margin-right: 0.5rem;
    }
  }

  .MicroWidgetConfigurator__fields {
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  ${microWidgets}
  .Highlight {
    min-width: 3rem;
    min-height: 3rem;
  }
`

export const WidgetSelector = styled(Tooltip)`
  > .Tooltip__value-element {
    transition: border 0.2s ease;
    position: relative;
    cursor: pointer;
    border: 1px solid var(--color-softStroke);
    border-radius: 0.2rem;
    margin: 0.5rem 0;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.selected {
      border-color: var(--color-main);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
`
export const WidgetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  border: 1px solid var(--color-softStroke);
  border-radius: 0.2rem;
  transform-origin: top;

  .ValueElement {
    background: ${({ theme }) => transparentize(0.5, theme.color.softerStroke)};
    margin: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 0.5rem;
    cursor: pointer;
    transition: border 0.2s ease;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.2rem;

    &:hover,
    &.selected {
      border-color: var(--color-theme);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    > div {
      flex: 1;
      display: flex;
      max-width: 100%;
    }
    .Gauge {
      width: 200px;
      height: 200px;
      max-height: 200px;
    }
    .LastValueWidgetContainer {
      width: 160px;
    }
  }
`
export const ValueElement = styled.div`
  > * {
    position: static !important;
  }
`

export const EquipmentConfiguratorStyled = styled.div`
  padding: 0 1rem 10rem;
  display: flex;
  flex-wrap: wrap;

  .EquipmentConfigurator__equipments {
    display: flex;
    flex-wrap: wrap;

    label {
      display: block;
      width: 100%;
    }
  }

  .FormField {
    width: 100%;

    &.col2 {
      width: 50%;
    }
  }

  .EquipmentGroup {
    max-width: 10rem;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;

    > .Button {
      opacity: 1;
      transform: scale(1);
    }

    > .ValueElement {
      transition: border 0.2s ease;
      position: relative;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 0.2rem;
      width: 100%;
      margin: 0.5rem 0;
      height: 3rem;

      &:hover,
      &.selected {
        border-color: var(--color-theme);
      }

      &:hover {
        .Button {
          transform: scale(1);
          opacity: 1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
      .Button {
        position: absolute !important;
        left: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .LastValueWidget {
      padding: 0;

      &__container {
        box-shadow: none;
        align-items: center;
      }

      &__caption {
        padding: 0 !important;
      }

      &Caption {
        padding: 0 !important;

        .Title {
          padding: 0 !important;
        }
      }

      .Icon {
        height: 2rem;
      }
    }
  }
  .DatapointConfigurator__buttons {
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const MicroWidgetEdgeConfiguratorStyled = styled.div`
  padding: 0 1rem 10rem;
  display: flex;
  flex-wrap: wrap;

  .MicroWidgetEdgeConfigurator__datapoints {
    width: 100%;
  }

  .col2 {
    width: 50%;
  }
`

export default MicroWidgetConfigurator
