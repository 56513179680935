import { message } from 'antd'
import set from 'lodash/fp/set'
import get from 'lodash/get'

export const getGraphDataKey = (path, dataKey) =>
  `${path.replace('root.elements', 'sections')}${dataKey ? `.${dataKey}` : ''}`

export const getColumnTotal = (columns, layout) =>
  columns[layout.screenSize.screen.all[layout.screenSize.screen.all.length - 1]]

export const getCurrentArrayValue = (data, path) => {
  const dataKey = path.substring(0, path.lastIndexOf('[')).replace('root.elements', 'sections')
  return {
    dataKey,
    array: get(data, dataKey),
  }
}

export const getCurrentValue = (data, path) => {
  const dataKey = getGraphDataKey(path)
  return get(data, dataKey)
}

export const getUpdatedDataValue = (data, dataKey, value, operation, itemIndex) => {
  switch (operation) {
    case 'edit':
      return set(dataKey, value, data)
    case 'delete': {
      const _value = [...value.slice(0, itemIndex), ...value.slice(itemIndex + 1)]
      return set(dataKey, _value, data)
    }
    default:
  }
}

export const reCalculateLayout = ({
  array,
  selectedItem,
  columns,
  direction,
  operation,
  itemHeight,
  itemPrevHeight,
  containerHeight,
  intl,
}) => {
  const { rows, lockedRowHeight, rowDiff } =
    direction === 'row' || direction === 'all'
      ? calculateRowHeight({
          array,
          columns,
          operation,
          selectedItem,
          itemHeight,
          itemPrevHeight,
          containerHeight,
        })
      : {}

  const getItem = (item, itemIndex, rowIndex) => {
    if (operation === 'edit') {
      return {
        ...item,
        w: item.w,
        h:
          direction === 'row' || direction === 'all'
            ? rows.length === 1
              ? 100
              : rows[rowIndex]
            : item.h,
      }
    }
    if (operation === 'delete') {
      return {
        ...item,
        w: (itemIndex === selectedItem.itemIndex && 0) || item.w,
        h:
          direction === 'row'
            ? rows.length === 1
              ? 100
              : rows[rowIndex]
            : (itemIndex === selectedItem.itemIndex && selectedItem.h) || item.h,
      }
    }
    if (direction === 'row') {
      return {
        ...item,
        h: rows.length === 1 ? 100 : rows[rowIndex],
      }
    }

    return item
  }
  const fillCurrentRow = (currentRow, item, itemIndex) => ({
    ...currentRow,
    items: currentRow.items.concat(item),
    columns:
      operation === 'add' && direction === 'col' && itemIndex === selectedItem.itemIndex
        ? currentRow.columns // inserted item will be calculated based on current items
        : {
            total: currentRow.columns.total + item.w,
            ratio: currentRow.columns.ratio.concat(item.w),
          },
    selectedItemIndex:
      itemIndex === selectedItem.itemIndex ? currentRow.items.length : currentRow.selectedItemIndex,
  })

  return array.reduce(
    (output, item, index) => {
      const _item = getItem(item, index, output.currentRow.rowIndex)
      output.currentRow = fillCurrentRow(output.currentRow, _item, index)

      if (Math.round(output.currentRow.columns.total) >= columns || index === array.length - 1) {
        // row is filled
        if (
          (direction === 'col' || direction === 'all') &&
          selectedItem.rowIndex === output.currentRow.rowIndex
        ) {
          // target item is in current row
          output.currentRow.items = calculateColumnWidth(
            output.currentRow.items,
            output.currentRow.columns,
            output.currentRow.selectedItemIndex,
            selectedItem.w - array[selectedItem.itemIndex].w,
            operation,
            intl
          )
        }
        // add row to layout
        output.layout = output.layout.concat(output.currentRow.items)
        // reset current row
        output.currentRow = {
          rowIndex: output.currentRow.rowIndex + 1,
          items: [],
          columns: { total: 0, ratio: [] },
          selectedItemIndex: -1,
        }
      } else if (array.length === index + 1) {
        // add unfilled row to layout
        output.layout = output.layout.concat(output.currentRow.items)
      }

      return output
    },
    {
      layout: [],
      lockedRowHeight,
      rowDiff,
      currentRow: {
        rowIndex: 0,
        items: [],
        columns: { total: 0, ratio: [] },
        selectedItemIndex: -1,
      },
    }
  )
}

const calculateColumnWidth = (array, columns, itemIndex, diff, operation, intl) => {
  const allItemsLocked = array.reduce((allItemsLocked, item, index) => {
    allItemsLocked = allItemsLocked ? itemIndex + 1 === index || item.isLocked : allItemsLocked
    return allItemsLocked
  }, true)
  if (operation === 'add' && allItemsLocked) {
    message.error(intl.formatMessage({ id: 'widget.lockedWidgetAddError' }))
    return array.reduce(
      (array, item, index) => (itemIndex + 1 === index ? array : array.concat(item)),
      []
    )
  }
  if (operation === 'delete' && allItemsLocked) {
    return array.reduce(
      (array, item, index) =>
        itemIndex === index
          ? array.concat([
              item,
              {
                type: 'Empty',
                w: item.w,
                h: item.h,
              },
            ])
          : array.concat(item),
      []
    )
  }
  const { space, numberOfItems } = array.reduce(
    (spaceToDevide, item) => {
      if (item.isLocked) {
        spaceToDevide.space = spaceToDevide.space - item.w
        spaceToDevide.numberOfItems = spaceToDevide.numberOfItems - 1
      }
      return spaceToDevide
    },
    { space: columns.total, numberOfItems: array.length }
  )
  let error = false
  const getItemWidth = (item, index) => {
    switch (operation) {
      case 'add': {
        const avgItemWidth = space / numberOfItems
        return item.isLocked
          ? item.w
          : itemIndex + 1 === index
          ? avgItemWidth
          : item.w - avgItemWidth / (numberOfItems - 1)
      }
      case 'edit':
        if (item.w === columns.total || item.w === space) {
          return item.w
        }
        const width =
          itemIndex === index
            ? Math.min(item.w + diff, space)
            : item.isLocked
            ? item.w
            : item.w - diff / (numberOfItems - 1)
        error = error || width <= 0
        return width
      case 'delete':
        return item.isLocked ? item.w : item.w + array[itemIndex].w / (numberOfItems - 1)
      default:
    }
  }
  const newArray = array.map((item, index) => ({
    ...item,
    w: getItemWidth(item, index),
  }))
  if (error) {
    message.error(intl.formatMessage({ id: 'widget.maxWidgetResizeError' }))
    return array
  }
  return newArray
}

const calculateRowHeight = ({
  array,
  columns,
  operation,
  selectedItem,
  itemHeight,
  itemPrevHeight,
  containerHeight,
}) => {
  const currentRowHeights = array.reduce(
    (rows, item, index) => {
      rows.currentRowColCount = rows.currentRowColCount + item.w
      rows.currentLockedHeight = item.isLocked
        ? Math.max(itemHeight, item.height)
        : rows.currentLockedHeight
      if (rows.currentRowColCount === columns || index === array.length - 1) {
        const rowHeights = rows.rowHeights.concat({
          height: index === selectedItem.itemIndex && operation === 'delete' ? 0 : item.h,
          lockedHeight: rows.currentLockedHeight,
        })
        return {
          rowCount: rows.rowCount + 1,
          currentRowColCount: 0,
          currentLockedHeight: 0,
          rowHeights,
        }
      }
      return rows
    },
    { rowCount: 0, currentRowColCount: 0, currentLockedHeight: 0, rowHeights: [] }
  )

  const rowDiff =
    operation === 'add'
      ? containerHeight / (currentRowHeights.rowCount - 1)
      : ((currentRowHeights.rowHeights[selectedItem.rowIndex] &&
          currentRowHeights.rowHeights[selectedItem.rowIndex].lockedHeight) ||
          itemHeight) - itemPrevHeight

  return currentRowHeights.rowHeights.reduce(
    (output, { height }, index) => {
      switch (operation) {
        case 'add':
          const avgRowHeight = 100 / currentRowHeights.rowCount
          output.rows.push(
            height ? avgRowHeight + (height - 100 / (currentRowHeights.rowCount - 1)) : avgRowHeight
          )
          break
        case 'delete':
          output.rows.push(
            height === 0
              ? 0
              : height + array[selectedItem.itemIndex].h / (currentRowHeights.rowCount - 1)
          )
          break
        default:
          const newRowHeight =
            index === selectedItem.rowIndex
              ? currentRowHeights.rowHeights[selectedItem.rowIndex].lockedHeight || itemHeight
              : (containerHeight * height) / 100

          const newContainerHeight = containerHeight + rowDiff

          const newHeightPercentage = (100 / newContainerHeight) * newRowHeight

          output.rows.push(newHeightPercentage)
          output.overflow = output.overflow - newHeightPercentage
          if (index === currentRowHeights.rowHeights.length - 1 && output.overflow !== 0) {
            output.rows[selectedItem.rowIndex] =
              output.rows[selectedItem.rowIndex] + output.overflow
          }
      }
      return output
    },
    { rows: [], rowDiff, overflow: 100 }
  )
}

export const calculateParentHeights = (path, currentValue, heightUpdateValue) => {
  const updatePath = path.replace('root.elements', 'sections').split('.')
  return updatePath.reduce(
    (output, substring, index) => {
      if (index === 0) {
        output.dataKey = `${output.dataKey}${index > 0 ? '.' : ''}${substring}`
        const newHeight = get(output.value, `${output.dataKey}.h`) + Math.ceil(heightUpdateValue)
        output.value = getUpdatedDataValue(
          output.value,
          `${output.dataKey}.h`,
          index > 0 && newHeight > 100 ? 100 : newHeight,
          'edit'
        )
      }
      return output
    },
    { dataKey: '', value: currentValue }
  ).value
}

export const getRowItemIndex = (array, rowIndex, rowItemIndex, columns) =>
  array.reduce(
    (result, item, index) => {
      if (result.currentRowIndex === rowIndex + 1 && result.itemIndex === -1) {
        result.itemIndex = index
      }
      result.currentRowColCount = result.currentRowColCount + item.w
      if (result.currentRowColCount === columns) {
        result.currentRowIndex = result.currentRowIndex + 1
        result.currentRowColCount = 0
      }
      if (index === array.length - 1 && result.itemIndex === -1) {
        result.itemIndex = array.length
      }
      return result
    },
    { currentRowIndex: 0, currentRowColCount: 0, itemIndex: -1 }
  ).itemIndex

export const getEquipmentsLayoutForTemplate = (equipments, elements) => {
  return equipments.map((equipment) => ({
    ...equipment,
    detail: {
      elements: elements.map(({ elements }) => ({
        //datapoints: [],
        ...(elements && { elements: elements.map(() => ({ datapoints: [] })) }),
      })),
    },
  }))
}
export const alignEquipmentLayoutWithTemplate = (equipments, { operation, path, itemIndex }) => {
  if (operation === 'delete') {
    const equipmentItemPath = path
      .substring(0, path.lastIndexOf('['))
      .replace('root.elements[0].', '')
    return equipments.map((equipment) => {
      return set(
        `detail.${equipmentItemPath}`,
        get(equipment, `detail.${equipmentItemPath}`).filter((item, index) => index !== itemIndex),
        equipment
      )
    })
  }
  if (operation === 'add') {
    const equipmentItemPath = path
      .substring(0, path.lastIndexOf('['))
      .replace('root.elements[0].', '')
    return equipments.map((equipment) => {
      const elements = get(equipment, `detail.${equipmentItemPath}`)
      return set(
        `detail.${equipmentItemPath}`,
        [...elements.slice(0, itemIndex + 1), { datapoints: [] }, ...elements.slice(itemIndex + 1)],
        equipment
      )
    })
  }

  return equipments
}

export const getAddedSubSectionElements = (elements, elementIndex) => {
  let shrinkedItem = false
  return elements.some((element) => element.type === 'Section')
    ? [
        ...elements.reduce((mergedElements, element, index) => {
          mergedElements.push({
            ...element,
            w: !shrinkedItem && element.w > 1 ? element.w - 1 : element.w,
          })
          if (element.w > 1 && !shrinkedItem) {
            shrinkedItem = true
          }
          if (index === elementIndex) {
            mergedElements.push({
              type: 'Section',
              h: 100,
              w: 1,
              elements: [],
            })
          }
          return mergedElements
        }, []),
      ]
    : [
        {
          type: 'Section',
          h: 100,
          w: 11,
          elements: elements.map((element) => element),
        },
        {
          type: 'Section',
          h: 100,
          w: 1,
          elements: [],
        },
      ]
}

export const addSubSection = (payload, elements, action, dataProps) => {
  action({
    type: 'UPDATE_ELEMENTS_ARRAY',
    payload: {
      graph: dataProps.graph,
      dataKey: 'elements',
      value: getAddedSubSectionElements(elements, payload.elementIndex),
    },
  })
}

export const updateMatrixElementsArray = ({ currentValue, path, dataKey, value }) => {
  return {
    detailTemplate: set(
      `${path ? `${path}.` : ''}${dataKey}`,
      value.map(({ elements, ...section }) => ({
        ...section,
        elements: elements.map(({ datapoints, ...element }) => element),
      })),
      currentValue.detailTemplate
    ),
    equipments: currentValue.equipments.map((equipment) => ({
      ...equipment,
      detail: set(
        `${path ? `${path}.` : ''}${dataKey}`,
        value.map(({ elements }) => ({
          elements: elements.map(({ datapoints }) => ({ datapoints })),
        })),
        equipment.detail
      ),
    })),
  }
}
