import { Modal } from 'antd'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const ModalText = styled.div`
  margin: 0 auto;
  width: 100%;
  border: none;
  overflow: auto;

  li {
    list-style-type: decimal;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-l);
    letter-spacing: ${({ theme }) => theme.text.l.spacing}px;
  }

  p {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-s);
    letter-spacing: var(--font-spacing-s);
  }

  i {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-s);
    letter-spacing: var(--font-spacing-s);
  }

  font-family: var(--font-family-primary)
`

export const CanaryModal = styled(Modal)`
  ${({ theme }) =>
    theme.screenSize.screen.current === 's' &&
    css`
      width: auto !important;
    `}
  .ant-modal-title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-xl);
    letter-spacing: var(--font-spacing-xl);
  }

  .ant-input {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }

  .ant-checkbox + span {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }

  span {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }

  .ant-card-head-title {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-l);
    letter-spacing: var(--font-spacing-l);
  }

  .ant-tree-title {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }

  .ant-typography {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }

  .ant-list-header {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-l);
    letter-spacing: var(--font-spacing-l);
  }

  .ant-btn {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
    padding: 0 5px 0 5px;
  }

  .ant-tag {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-s);
    letter-spacing: var(--font-spacing-s);
  }

  .ant-modal-header {
    padding: 24px 36px 16px 24px;
  }

  .ant-modal-footer {
    padding: 10px 22px;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-btn:not(.ant-btn-danger):not(.ant-btn-primary):hover,
  .ant-btn:not(.ant-btn-danger):focus {
    color: ${({ theme }) => lighten(0.1, theme.color.main)};
    border-color: ${({ theme }) => lighten(0.1, theme.color.main)};
  }

  .ant-modal-content {
    height: 100%;
  }

  font-family: var(--font-family-primary)
`
