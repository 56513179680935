import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'
import { TagListFieldStyled } from 'components/Form/fields/TagListField/styled'
import IconStyled from 'components/Global/Icon/styled'

export const RuleFieldStyled = styled.div`
  padding: 0.5rem;
  border: 1px solid
    ${({ theme, error, virtual }) =>
      error
        ? theme.color.error
        : virtual
        ? transparentize(0.5, theme.color.highlight)
        : 'var(--color-softStroke)'};
  background: ${({ theme, error, virtual }) =>
    transparentize(
      0.98,
      error ? theme.color.error : virtual ? theme.color.highlight : theme.color.softStroke
    )};
  border-radius: 0.5rem;
  margin: 0.5rem 0;

  ${TagListFieldStyled} {
    flex-direction: column;
  }

  .DatapointField__actions {
    margin-right: 0.5rem;
    display: flex;
    background: var(--color-highlight);
    padding: 0.3rem 0;
    border-radius: 2rem;
  }

  .DatapointField__action {
    padding: 0 0.4rem;
    font-size: 1.4rem !important;
    color: ${({ theme }) => theme.color.highlightColor};
  }

  .DatapointField__expand {
    margin-left: 0.5rem;
  }
`

export const DatapointFieldHeader = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;

  ${IconStyled} {
    &.type-fa:not(.disabled) {
      &:hover,
      &.active {
        color: var(--color-main);
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .TagField__label {
    flex: 1;
    overflow: hidden;
    text-align: left;

    .Label {
      font-weight: 600;
    }

    ${Value} {
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }
  }

  ${IconStyled} {
    transition: color 0.2s ease;
    font-size: 1.2rem;
    display: flex;

    &.type-fa {
      color: var(--color-backgroundFront);
    }
  }
`

export const DatapointFieldContent = styled(motion.div)`
  overflow: hidden;
  transform-origin: top;

  .DatapointFieldContent__description {
    display: flex;
    text-align: left;
    margin: 1rem 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: var(--color-softerStroke);
    font-weight: 100;
    align-items: center;

    .image {
      min-width: 60%;
      margin-left: 1rem;
    }
  }

  .Field {
    //flex-direction: column;
    //text-align: left;
  }

  .Label {
    text-align: left;
  }

  .Value {
    //text-align: left;
    justify-content: flex-start;
  }
`

export const RuleValueListFieldStyled = styled(Field)`
  .Field {
    padding: 0;
  }

  .Label {
    flex: 1;
  }

  .TextField {
    .Value {
      max-width: 60%;
      margin-bottom: 0.5rem;
    }
  }
`
