import React from 'react'

import LoginLayout from '../layouts/Login'
import MainLayout from '../layouts/Main'
import NoLayout from '../layouts/NoLayout'
import { lazyRetry } from './lazyRetry'

const Login = React.lazy(() => 
  lazyRetry(() => import('../pages/user/login'), 'login')
)
const ForgotPassword = React.lazy(() => 
  lazyRetry(() => import('../pages/user/forgot'), 'forgot')
)
const ResetPassword = React.lazy(() => 
  lazyRetry(() => import('../pages/user/reset'), 'reset')
)
const Invite = React.lazy(() => 
  lazyRetry(() => import('../pages/user/invite'), 'invite')
)
const SquealOverview = React.lazy(() => 
  lazyRetry(() => import('../pages/squeal_overview'), 'squealOverview')
)
const DashboardsOverview = React.lazy(() => 
  lazyRetry(() => import('../pages/dashboard/landing'), 'landing')
)
const DashboardsManagement = React.lazy(() => 
  lazyRetry(() => import('../pages/dashboard/Management'), 'Management')
)
const Settings = React.lazy(() => 
  lazyRetry(() => import('../pages/settings'), 'settings')
)
const Fluvius = React.lazy(() => 
  lazyRetry(() => import('../pages/fluvius'), 'fluvius')
)
const Dashboard = React.lazy(() => 
  lazyRetry(() => import('../pages/dashboard'), 'dashboard')
)
const FireAlertOverview = React.lazy(() => 
  lazyRetry(() => import('../pages/fire_alert_overview'), 'fireAlertOverview')
)
const Sustainability = React.lazy(() => 
  lazyRetry(() => import('../pages/sustainability'), 'sustainability')
)
const Downloading = React.lazy(() => 
  lazyRetry(() => import('../pages/downloading'), 'downloading')
)
const UserMarketingRegistration = React.lazy(() =>
  lazyRetry(() => import('components/LayoutComponents/User/UserMarketingRegistration'), 'UserMarketingRegistration')
)

export default [
  // System Pages
  {
    path: '/user/login',
    component: Login,
    public: true,
    layout: LoginLayout,
  },
  {
    path: '/user/forgot-password',
    component: ForgotPassword,
    public: true,
    exact: true,
    layout: LoginLayout,
  },
  {
    path: '/user/reset',
    public: true,
    signOut: true,
    component: ResetPassword,
    layout: LoginLayout,
  },
  {
    path: '/user/invite/:token',
    component: Invite,
    public: true,
    signOut: true,
    exact: true,
    layout: LoginLayout,
  },
  {
    path: '/dashboards',
    component: DashboardsOverview,
    layout: MainLayout,
  },
  {
    path: '/settings',
    component: Settings,
    layout: MainLayout,
  },
  {
    path: '/fluvius',
    component: Fluvius,
    layout: MainLayout,
  },
  {
    path: '/squeal-overview',
    component: SquealOverview,
    layout: MainLayout,
  },
  {
    path: '/dashboard/management',
    component: DashboardsManagement,
    layout: MainLayout,
  },
  {
    path: '/dashboard/:id',
    component: Dashboard,
    layout: MainLayout,
  },
  {
    path: '/fire-alert-overview',
    component: FireAlertOverview,
    layout: MainLayout,
  },
  {
    path: '/sustainability',
    component: Sustainability,
    layout: MainLayout,
  },
  {
    path: '/downloading',
    component: Downloading,
    layout: NoLayout,
  },
  {
    path: '/EnerdoGreenprint',
    component: UserMarketingRegistration,
    public: true,
    signOut: true,
    exact: true,
    layout: LoginLayout,
  },
]
