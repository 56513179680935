import { gql, useQuery } from '@apollo/client'

export const HEYLEN_FIRE_ALERTS = 1

const APPLICATION_FEATURE_ALLOWED = gql`
  query applicationFeatureAllowed($applicationFeatureId: Float!) {
    applicationFeatureAllowed(applicationFeatureId: $applicationFeatureId)
  }
`

export const useApplicationFeatureAllowed = (applicationFeatureId) => {
  const { data, ...rest } = useQuery(APPLICATION_FEATURE_ALLOWED, {
    variables: { applicationFeatureId },
    fetchPolicy: 'network-only',
  })
  return { data: data?.applicationFeatureAllowed, ...rest }
}
