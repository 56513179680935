import { useCallback, useState } from 'react'

const INITIAL_VALUE = {
  isActive: false,
  value: null,
  isConfirmed: false,
}

export const useActionConfirmation = () => {
  const [confirmation, setConfirmation] = useState(INITIAL_VALUE)
  const onConfirmationRequest = useCallback(({ value, isConfirmed, isCanceled }) => {
    if (isCanceled) {
      setConfirmation(INITIAL_VALUE)
    } else {
      setConfirmation({
        value,
        isActive: !(isConfirmed || isCanceled),
        isConfirmed: !!isConfirmed,
        isCanceled,
      })
    }
  }, [])
  return [confirmation, onConfirmationRequest]
}
