import { css } from 'styled-components'

export const MainMenu = css`
  display: flex;
  flex-direction: column;
  &:hover,
  &.active {
    .Button__label {
      opacity: 1;
      transform: scale(1);
      color: var(--color-sidebarText);
    }
    > .Icon {
      opacity: 1;
      transform: scale(1.3);
      background: var(--color-highlight);
      box-shadow: var(--shadow-shadowElevation6);
      .Icon {
        color: var(--color-highlightText);
      }
    }
  }
  &.active {
    > .Icon {
      opacity: 1;
      transform: scale(1.3);
      box-shadow: 0 -3px 24px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      margin: ${({ theme }) => theme.screenSize.screen.current === 's' && '-1.5rem 0 1.5rem'};
      .Icon {
        color: var(--color-highlightText);
      }
    }

    .Button__label {
      color: var(--color-sidebarText);
    }
  }

  &.settings {
    margin-top: auto;
    margin-bottom: 0;
  }

  .Button__label {
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 0.6rem;
    color: var(--color-sidebarText);
    opacity: ${({ theme }) => theme.screenSize.screen.current !== 's' && 0};
    transform: ${({ theme }) => theme.screenSize.screen.current !== 's' && 'scale(0)'};
  }

  > .Icon {
    transition: opacity 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease, background 0.2s ease,
      color 0.2s ease;
    border-radius: 1rem;
    font-size: ${({ theme }) => (theme.screenSize.screen.current === 's' ? '2rem' : '1.5rem')};
    margin: ${({ theme }) =>
      theme.screenSize.screen.current === 's' ? '0 0 0.2em' : '1em 0 .8rem'};
    opacity: 0.7;
    transform: scale(1);
    padding: 0.7rem;
    display: flex;
    .Icon {
      color: var(--color-sidebarText);
    }
  }
`
