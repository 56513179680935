import React from 'react'

import { Layout } from 'antd'
import { injectIntl } from 'react-intl'
import { withTheme } from 'styled-components'

import { Container, Content, Footer, Header, Links, LoginWindow, Logo } from './loginLayout.styled'

const year = new Date().getFullYear()

const LoginLayout = ({ children, theme, intl }) => {
  return (
    <LoginWindow>
      <Layout.Content>
        <Container>
          <Header>
            <Logo>
              <a href="https://www.brightcanary.eu" target="_blank" rel="noopener noreferrer">
                <img
                  src={theme.images?.logo || '/resources/images/logo.svg'}
                  alt="Canary - data-driven sustainability"
                />
              </a>
            </Logo>
          </Header>
          <Content>{children}</Content>
          <Footer>
            <Links>
              <span>
                <a
                  href="https://www.bycanary.be/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'title.cookies' })}
                </a>
              </span>
              <span>
                <a
                  href="https://www.bycanary.be/algemene-voorwaarden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'termsAndConditions.title' })}
                </a>
              </span>
              <span>
                <a
                  href="https://www.bycanary.be/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'title.confidential.informationShort' })}
                </a>
              </span>
              <span>
                <a
                  href="https://brightcanary.freshdesk.com/support/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'support.title' })}
                </a>
              </span>
              <span>
                <a
                  href="https://brightcanary.freshdesk.com/support/tickets/new"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'contact.title' })}
                </a>
              </span>
              <span>
                <a
                  href="https://status.iot.canary.expert"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'status' })}
                </a>
              </span>
            </Links>
            <p>&copy; 2019 - {year} Canary bv. All rights reserved.</p>
          </Footer>
        </Container>
      </Layout.Content>
    </LoginWindow>
  )
}

export default injectIntl(withTheme(LoginLayout))
