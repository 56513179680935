import styled, { css } from 'styled-components'

import { SubTitle } from 'components/Layout/Content'

const ListStyled = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ListHeader = styled.div`
  transition: padding 0.4s ease;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-xl);
  letter-spacing: var(--font-spacing-xl);
  line-height: 1.3;
  margin-bottom: -4px;
  color: var(--color-strongText);
  padding: 2rem 24px 8px;
  background: var(--color-backgroundHighlight);
  align-items: center;

  h2 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-xl);
    letter-spacing: var(--font-spacing-xl);
    line-height: 1;
    color: var(--color-strongText);
    margin: 0;
  }

  ${SubTitle} {
  }

  ${({ compactMode }) =>
    compactMode &&
    css`
      padding-right: 65.5%;
    `}
  .SearchField {
    margin-left: 1rem;
    margin-right: auto;
    min-width: 350px;
    flex: none !important;
    .ant-input-affix-wrapper {
      line-height: 1.2;
    }
  }

  .Icon {
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    cursor: pointer;
    font-size: 1.6rem !important;
    opacity: 0.8 !important;

    &:hover {
      opacity: 1 !important;

      svg {
        path {
          fill: var(--color-strongText);
        }
      }
    }

    svg {
      margin: 0 0.2rem;

      path {
        fill: var(--color-mainText);
      }
    }
  }

  .ant-btn-icon-only {
    border: none;
  }

  .ContentFlex {
    min-width: fit-content;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    .ant-btn {
      margin-left: 1rem;

      &:not(.ant-btn-primary) {
        &:not(:hover) {
          color: var(--color-softText);
          background: transparent;
        }
      }
    }

    .ant-select-single:not(:hover) .ant-select-selector {
      background: transparent;
    }
  }

  .BulkSelect {
    width: calc(100% + 48px);
    margin: 0 -24px;
    padding: 0 24px;
    background: rgba(0, 0, 0, 0.02);
    margin-top: 1rem;
  }

  .BulkSelect__content {
    font-size: 1rem;
    padding: 1rem 0 !important;
    align-items: flex-start !important;
  }

  .BulkSelect__label {
    margin-top: 0.2rem;
  }
`

export const ListSubHeader = styled(ListHeader)`
  margin-bottom: auto;
  padding: 1rem 24px;

  &:empty {
    padding: 0;
  }

  color: var(--color-softText);
`

// apply changes also to src/components/Layout/List/scenes/Table/styled.js

export const AgNestedTable = styled.div`
  margin-left: 5rem;
  height: 100%;

  .ag-popup {
    height: auto !important;
  }
`

export default ListStyled
