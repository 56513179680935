import styled from 'styled-components'

const EntityTree = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  > .Tooltip {
    z-index: 100;
    margin-bottom: 0.5rem;
  }
  > .Tree {
    flex: 1;
  }
`

export default EntityTree
