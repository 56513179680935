import { lineTypes, orientation } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.information',
    category: 'widget.gaugesIndicators',
    keywords: ['indicator', 'status', 'kpi', 'information'],
    img: '/resources/images/widgets/information.png',
    info: {
      'fr-FR':
        '<h2>Informations</h2>\n' +
        "<p>Le widget d'informations affiche toutes les valeurs et fichiers possibles pour certaines entités et points de données.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Afficher le séparateur</strong> Ajouter une ligne horizontale sous l'en-tête</li>\n" +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de l'en-tête</li>\n" +
        "<li><strong>Couleur de la police</strong> Couleur de la police de l'en-tête</li>\n" +
        '</ul>\n' +
        '<h4>Données</h4>\n' +
        '<ul>\n' +
        "<li><strong>Ajouter une ligne</strong> Ajouter une ligne d'information au widget</li>\n" +
        '</ul>\n' +
        '<h3>Configuration de la ligne sélectionnée</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Libellé de la ligne</li>\n' +
        '<li><strong>Icône</strong> Icône de la ligne</li>\n' +
        '<li><strong>Bordure</strong> Afficher la bordure de la ligne</li>\n' +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de la ligne</li>\n" +
        '<li><strong>Couleur de la police</strong> Couleur de la police de la ligne</li>\n' +
        '</ul>\n' +
        '<h4>Données</h4>\n' +
        '<ul>\n' +
        "<li><strong>Type de ligne</strong> Type de ligne d'information</li>\n" +
        '<li><strong>Points de données</strong> Sélectionnez le point de données pour la ligne</li>\n' +
        "<li><strong>Entités</strong> Sélectionnez l'entité pour la ligne</li>\n" +
        "<li><strong>Type</strong> Sélectionnez le type de point de données ou d'équipement</li>\n" +
        '<li><strong>Afficher la flèche de différence</strong> Afficher la flèche de différence en fonction de la période de comparaison</li>\n' +
        '<li><strong>Afficher le pourcentage de différence</strong> Afficher le pourcentage de différence en fonction de la période de comparaison</li>\n' +
        '<li><strong>Couleur de la flèche vers le haut</strong> Sélectionnez la couleur de la flèche pour une différence positive</li>\n' +
        '<li><strong>Couleur de la flèche vers le bas</strong> Sélectionnez la couleur de la flèche pour la différence négative</li>\n' +
        "<li><strong>Afficher l'unité</strong> Afficher l'unité pour la valeur sélectionnée</li>\n" +
        '<li><strong>Masquer la valeur</strong> Masquer la valeur de la valeur sélectionnée</li>\n' +
        '<li><strong>Afficher la description</strong> Afficher la description de la légende</li>\n' +
        '<li><strong>Image</strong>Sélectionnez les images à afficher</li>\n' +
        '<li><strong>Fichier</strong>Sélectionnez le(s) fichier(s) à afficher</li>\n' +
        "<li><strong>Lien</strong> Ajouter un lien vers la ligne d'information</li>\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h5>Période de comparaison</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Légendes</strong> Attribuez un texte, une couleur et une icône à une valeur</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Information</h2>\n' +
        '<p>The information widget displays all possible values and files for certain entities and datapoints.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Add a horizontal line under the header</li>\n' +
        "<li><strong>Background Color</strong> Header's background color</li>\n" +
        "<li><strong>Font Color</strong> Header's font color</li>\n" +
        '</ul>\n' +
        '<h4>Data</h4>\n' +
        '<ul>\n' +
        '<li><strong>Add Line</strong> Add an information line to the widget</li>\n' +
        '</ul>\n' +
        '<h3>Selected Line Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Line's label</li>\n" +
        "<li><strong>Icon</strong> Line's icon</li>\n" +
        "<li><strong>Border</strong> Show line's border</li>\n" +
        "<li><strong>Background Color</strong> Line's background color</li>\n" +
        "<li><strong>Font Color</strong> Line's font color</li>\n" +
        '</ul>\n' +
        '<h4>Data</h4>\n' +
        '<ul>\n' +
        '<li><strong>Line Type</strong> Type of information line</li>\n' +
        '<li><strong>Datapoints</strong> Select datapoint for line</li>\n' +
        '<li><strong>Entities</strong> Select entity for line</li>\n' +
        '<li><strong>Type</strong> Select type for datapoint or equipment</li>\n' +
        '<li><strong>Show Difference Arrow</strong> Show difference arrow based on comparison period</li>\n' +
        '<li><strong>Show Difference Percentage</strong> Show difference percentage based on comparison period</li>\n' +
        '<li><strong>Arrow Up Color</strong> Select arrow color for positive difference</li>\n' +
        '<li><strong>Arrow Down Color</strong> Select arrow color for negative difference</li>\n' +
        '<li><strong>Show Unit</strong> Show unit for selected value</li>\n' +
        '<li><strong>Hide Value</strong> Hide value for selected value</li>\n' +
        '<li><strong>Show Description</strong> Show description of caption</li>\n' +
        '<li><strong>Image</strong>Select image(s) to display</li>\n' +
        '<li><strong>File</strong>Select file(s) to display</li>\n' +
        '<li><strong>Link</strong> Add a link to the information line</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Captions</strong> Assign a text, color and icon to a value</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Informatie</h2>\n' +
        '<p>De informatiewidget toont alle mogelijke waarden en bestanden voor bepaalde entiteiten en datapunten.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Voeg een horizontale lijn toe onder de kop</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur kop</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van kop</li>\n' +
        '</ul>\n' +
        '<h4>Gegevens</h4>\n' +
        '<ul>\n' +
        '<li><strong>Regel toevoegen</strong> Voeg een informatieregel toe aan de widget</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde lijnconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Label van de lijn</li>\n' +
        '<li><strong>Icoon</strong> Icoon van de lijn</li>\n' +
        '<li><strong>Rand</strong> Rand van lijn weergeven</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur van lijn</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van de regel</li>\n' +
        '</ul>\n' +
        '<h4>Gegevens</h4>\n' +
        '<ul>\n' +
        '<li><strong>Lijntype</strong> Type informatieregel</li>\n' +
        '<li><strong>Datapunten</strong> Selecteer datapunt voor lijn</li>\n' +
        '<li><strong>Entiteiten</strong> Selecteer entiteit voor regel</li>\n' +
        '<li><strong>Type</strong> Selecteer type voor datapunt of apparatuur</li>\n' +
        '<li><strong>Toon verschilpijl</strong> Toon verschilpijl op basis van vergelijkingsperiode</li>\n' +
        '<li><strong>Verschilpercentage weergeven</strong> Verschilpercentage weergeven op basis van vergelijkingsperiode</li>\n' +
        '<li><strong>Pijl omhoog Kleur</strong> Selecteer de kleur van de pijl voor een positief verschil</li>\n' +
        '<li><strong>Kleur pijl-omlaag</strong> Selecteer de kleur van de pijl voor een negatief verschil</li>\n' +
        '<li><strong>Eenheid weergeven</strong> Eenheid voor geselecteerde waarde weergeven</li>\n' +
        '<li><strong>Waarde verbergen</strong> Waarde voor geselecteerde waarde verbergen</li>\n' +
        '<li><strong>Beschrijving weergeven</strong> Beschrijving van bijschrift weergeven</li>\n' +
        '<li><strong>Afbeelding</strong>Selecteer afbeelding(en) om weer te geven</li>\n' +
        '<li><strong>Bestand</strong>Selecteer bestand(en) om weer te geven</li>\n' +
        '<li><strong>Link</strong> Voeg een link toe aan de informatieregel</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h5>Vergelijkingsperiode</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Bijschriften</strong> Wijs een tekst, kleur en pictogram toe aan een waarde</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
    },
    default: {
      type: 'Information',
      title: 'widget.information',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      url: null,
      lines: [],
      method: 'sum',
      showUnit: true,
      hideValue: false,
      captions: {},
    },
    preview: {
      value: 20,
      unit: '°C',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      showUnit: true,
      hideValue: false,
      showDescription: false,
      showDiffArrow: true,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDivider' },
        col: 'col2',
        dataKey: 'line',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.data',
        componentName: 'InformationLines',
        label: { formatted: 'widget.informationLines' },
        componentProps: {
          dataKey: 'lines',
          section: {
            name: 'lines',
            componentName: 'InformationLineConfigurator',
            componentProps: {
              dataKey: 'lines',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'title',
                },
                {
                  category: 'widget.general',
                  componentName: 'IconPicker',
                  label: { formatted: 'widget.icon' },
                  col: 'col2 first',
                  dataKey: 'icon',
                  componentProps: {
                    bottomDrawer: true,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.border' },
                  col: 'col2',
                  dataKey: 'border',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  componentProps: {
                    clearIcon: true,
                  },
                  label: { formatted: 'widget.backgroundColor' },
                  col: 'col2 first',
                  dataKey: 'color',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  componentProps: {
                    clearIcon: true,
                  },
                  label: { formatted: 'widget.fontColor' },
                  col: 'col2',
                  dataKey: 'fontColor',
                },
                {
                  category: 'widget.data',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.lineType' },
                  dataKey: 'type',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    valueKey: 'value',
                    options: lineTypes,
                  },
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIsNot',
                      dependencyValue: 'Detail',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Datapoints',
                  label: { formatted: 'widget.datapoints' },
                  componentProps: {
                    dataKey: 'datapoints',
                    method: 'method',
                    fixedUnit: true,
                  },
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIs',
                      dependencyValue: 'Status',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Entities',
                  label: { formatted: 'widget.entities' },
                  componentProps: {
                    dataKey: 'entities',
                    singleDatapoint: true,
                    selectByType: true,
                  },
                },
                {
                  category: 'widget.data',
                  componentName: 'DatapointValueTypePicker',
                  componentProps: {},
                  label: { formatted: 'widget.type' },
                  dataKey: 'dataKey',
                  dependencies: [
                    {
                      dependentOn: 'widget.datapoints',
                      dependencyType: 'hasValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showDifferenceArrow' },
                  col: 'col2 first',
                  dataKey: 'showDiffArrow',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showDifferencePercentage' },
                  col: 'col2',
                  dataKey: 'diffPercentage',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.arrowUpColor' },
                  col: 'col2 first',
                  dataKey: 'arrowUpColor',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.arrowDownColor' },
                  col: 'col2',
                  dataKey: 'arrowDownColor',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'EntityValueTypePicker',
                  componentProps: {
                    dataKey: 'entities',
                  },
                  label: { formatted: 'widget.type' },
                  dataKey: 'dataKey',
                  dependencies: [
                    {
                      dependentOn: 'widget.entities',
                      dependencyType: 'hasValue',
                      dependencyResolver: 'show',
                    },
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIs',
                      dependencyValue: 'Status',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showUnit' },
                  dataKey: 'showUnit',
                  col: 'col2 first',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Switch',
                  label: { formatted: 'widget.hideValue' },
                  dataKey: 'hideValue',
                  col: 'col2',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showDescription' },
                  dataKey: 'showDescription',
                  col: 'col2',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'EntityFilePicker',
                  componentProps: {
                    dataKey: 'entities',
                  },
                  dataKey: 'files',
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIs',
                      dependencyValue: 'File',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  label: { formatted: 'widget.layout' },
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: orientation,
                    selectionKeys: {
                      value: 'value',
                    },
                  },
                  dataKey: 'layout',
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIs',
                      dependencyValue: 'Detail',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  label: { formatted: 'widget.fields' },
                  componentName: 'InformationDetailConfigurator',
                  componentProps: {},
                  dataKey: 'hiddenFields',
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIs',
                      dependencyValue: 'Detail',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.data',
                  componentName: 'LinkPicker',
                  label: { formatted: 'widget.link' },
                  componentProps: {
                    writeDatapointOption: true,
                  },
                },
                {
                  category: 'widget.interval',
                  componentName: 'DefaultStartTimeSelector',
                  componentProps: {
                    hideLabel: true,
                    dataKey: 'timeRange',
                  },
                  label: { formatted: 'widget.timeRange', tagName: 'h4' },
                  dataKey: '',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.interval',
                  componentName: 'DefaultStartTimeSelector',
                  componentProps: {
                    hideLabel: true,
                    dataKey: 'comparisonPeriod',
                  },
                  label: { formatted: 'widget.comparisonPeriod', tagName: 'h4' },
                  dataKey: '',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.fixedValues',
                  componentName: 'CaptionEditor',
                  label: { formatted: 'widget.captions' },
                  dataKey: 'captions',
                  componentProps: {
                    columns: ['value', 'title', 'icon', 'color'],
                  },
                  dependencies: [
                    {
                      dependentOn: 'widget.type',
                      dependencyType: 'valueIsNot',
                      dependencyValue: 'Detail',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapoints',
                  },
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.advanced',
                  componentName: 'CustomLogic',
                  label: { formatted: 'widget.customLogic' },
                  dataKey: 'customLogic',
                  dependencies: [
                    {
                      dependentOn: 'widget.dataKey',
                      dependencyType: 'valueIs',
                      dependencyValue: 'referenceValue',
                      dependencyResolver: 'show',
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ],
  },
}

export default config
