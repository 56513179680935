import { useCallback } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import { useCurrentCustomer } from './store'

/* const ENERGY_CONTRACTS_FOR_COMPANY = gql`
  query energyContracts($companyId: Float!) {
    energyContracts(companyId: $companyId) {
      id
      name
      unit {
        id
        name
        alias
        formatted
      }
      energyContractCompositionParts {
        id
        energyType {
          id
          renewable
          energySource
          tags
        }
        fuelType {
          id
          name
          tags
        }
        percentage
      }
    }
  }
` */

const UPDATE_CONTRACT_MUTATION = gql`
  mutation updateEnergyContract(
    $id: Float!
    $data: EnergyContractUpdateInput!
    $updateNameOnly: Boolean!
  ) {
    updateEnergyContract(id: $id, data: $data, updateNameOnly: $updateNameOnly) {
      id
    }
  }
`

export const useUpdateContract = () => {
  const customer = useCurrentCustomer()

  const [update, data] = useMutation(UPDATE_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: QUERY_CURRENT_ENERGY_CONTRACTS,
        variables: { companyId: customer?.id },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const updateFn = useCallback(
    (options = {}) => {
      return update({
        ...options,
        variables: {
          ...options.variables,
        },
      })
    },
    [update]
  )

  return [updateFn, data]
}

const CREATE_CONTRACT_MUTATION = gql`
  mutation createEnergyContract($data: EnergyContractCreateInput!) {
    createEnergyContract(data: $data) {
      id
    }
  }
`

export const useCreateContract = () => {
  const customer = useCurrentCustomer()

  const [create, data] = useMutation(CREATE_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: QUERY_CURRENT_ENERGY_CONTRACTS,
        variables: { companyId: customer?.id },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const createFn = useCallback(
    (options = {}) => {
      return create({
        ...options,
        variables: {
          ...options.variables,
        },
      })
    },
    [create]
  )

  return [createFn, data]
}

const ENERGY_CONTRACT_HISTORY = gql`
  query energyContractHistory($id: ID!) {
    energyContractHistory(id: $id) {
      id
      activationDate
      directMetering
      energyContractCompositionParts {
        percentage
        energyType {
          energySource
        }
        fuelType {
          name
        }
      }
    }
  }
`

export const useEnergyContractHistory = (id) => {
  const { data, ...rest } = useQuery(ENERGY_CONTRACT_HISTORY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  })
  return { data: data?.energyContractHistory, ...rest }
}

const QUERY_CURRENT_ENERGY_CONTRACTS = gql`
  query currentEnergyContracts($companyId: Float!, $isFuelType: Boolean) {
    currentEnergyContracts(companyId: $companyId, isFuelType: $isFuelType) {
      id
      internalId
      country {
        id
        name
      }
      activationDate
      name
      unit {
        name
      }
      directMetering
      energyContractCompositionParts {
        energyType {
          id
        }
        fuelType {
          id
        }
      }
    }
  }
`

export const useCurrentEnergyContracts = ({ isFuelType } = {}) => {
  const customer = useCurrentCustomer()
  const result = useQuery(QUERY_CURRENT_ENERGY_CONTRACTS, {
    variables: { companyId: customer?.id, isFuelType },
    skip: !customer?.id,
    fetchPolicy: 'network-only',
  })
  return { ...result, data: result.data?.currentEnergyContracts }
}
