import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { HeaderValuePicker } from 'components/Form/components/ValuePicker/HeaderValuePicker.styled'
import Tooltip from 'components/Global/Tooltip'

const variants = {
  HeaderValuePicker,
}

const ValuePickerStyled = styled(Tooltip)`
  position: relative;
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          min-width: 150px;
        `}
  ${({ error, theme }) =>
    error &&
    css`
      .ant-select-selector {
        border-color: var(--color-error) !important;
      }
    `}
  ${({ valueElement, clearIcon }) =>
    valueElement &&
    css`
      .ValuePicker__icon-value {
        position: absolute;
        width: 22px;
        height: 22px;
        z-index: 10;
        right: ${clearIcon ? 30 : 20}px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;

        img {
          height: auto;
          width: 100%;
          max-height: 100%;
          position: absolute;
          right: 0;
        }
      }

      .ant-input {
        padding-right: 4rem !important;
      }
    `}

  min-width: fit-content;

  > .Tooltip__clear {
    top: 0.7rem;
    right: 0.7rem;
    z-index: 10;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding: 0 1rem 0 0;
    text-overflow: ellipsis;
  }

  .ant-input-disabled {
    background: transparent !important;
    opacity: 0.5;
  }

  ${({ variant }) => variants[variant]}
`

export const OptionStyled = styled.div`
  padding: 0 1rem 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};

  button {
    color: inherit !important;
  }

  &:hover {
    background: ${({ theme }) => transparentize(0.9, theme.color.main)};
  }

  background: ${({ selected, theme }) => selected && transparentize(0.8, theme.color.main)};
  color: ${({ selected, disabled, theme }) => {
    if (disabled) {
      return transparentize(0.7, theme.color.main)
    }

    if (selected) {
      return theme.color.main
    }
  }};

  .Option__primary {
    font-weight: 600;
    width: 100%;
  }

  .Option__secondary {
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }
`

export const FilterDropdownStyled = styled.div`
  display: flex;
  max-width: 500px;
  height: 100%;

  .FilterDropdown__filters {
    border-right: 1px solid var(--color-softerStroke);
    min-width: 200px;
    width: 200px;
  }

  .FilterDropdown__filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0.5rem;

    > div {
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
  }

  .FilterDropdown__options {
    overflow: hidden auto;
    min-width: 300px;
    width: 300px;
    height: 100%;
  }
`
export default ValuePickerStyled
