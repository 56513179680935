import { lighten } from 'polished'
import styled from 'styled-components'

export const Anchor = styled.a`
  color: var(--color-info);
  text-decoration: underline;

  &:focus-visible {
    outline: 1px solid var(--color-action);
    outline-offset: 1px;
  }
`

const ButtonBase = styled.button`
  all: unset;
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;

  min-width: 150px;
  min-height: 36px;
  text-align: center;

  vertical-align: middle;

  overflow-x: visible;
  overflow-y: visible;

  border: 1px solid;
  margin: 4px;
  box-sizing: border-box;
  font-weight: 600;
  padding: 5px;
  border-radius: 8px;
  font-stretch: 100%;
  outline-offset: 1px;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid var(--color-mainStroke);
  }
`

export const ButtonPrimary = styled(ButtonBase)`
  color: var(--color-white);
  background-color: var(--color-action);
  border-color: var(--color-action);

  &:hover,
  &:focus {
    background-color: ${(p) => lighten(0.1, p.theme.color.action)};
  }
`

export const ButtonSecondary = styled(ButtonBase)`
  color: var(--color-mainText);
  background-color: var(--color-softerStroke);
  border-color: var(--color-mainText);

  &:hover,
  &:focus {
    border-color: ${(p) => lighten(0.1, p.theme.color.action)};
    color: ${(p) => lighten(0.1, p.theme.color.action)};
  }
`
