import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import type { Preferences } from './types'

export const CookieContext = createContext<{
  preferences: Preferences | null
  setPreferences: Dispatch<SetStateAction<Preferences>>
}>({
  preferences: null,
  setPreferences: () => {},
})

export const useCookiePreferences = () => useContext(CookieContext)
