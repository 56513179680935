import { css } from 'styled-components'

export const IconPicker = css`
  margin: 0.2rem 0;
  transform-origin: left top;
  overflow: auto auto;
  background: var(--color-backgroundFront);
  box-shadow: 0 10px 35px -5px rgb(0 0 0 / 15%);
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;

  .Button {
    justify-content: flex-start;
    padding: 0.5rem 1rem;

    .Icon {
      margin-right: 0.5rem;
      width: 25px;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .close-button {
    color: var(--color-backgroundFront);
  }

  .IconPicker__button-container {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-backgroundFront);
  }

  .IconPickerSelector {
  }
`
