const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.floorMap',
    category: 'widget.controls',
    keywords: ['controls', 'image', 'floor', 'map'],
    img: '/resources/images/widgets/floormap.png',
    info: {
      'fr-FR':
        '<h2>Floormap</h2>\n' +
        '<p>Le widget Floormap affiche une image sur laquelle les emplacements avec des points de mesure sont affichés.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        "<li><strong>Fichier </strong>Télécharger un fichier d'arrière-plan</li>\n" +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong>Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Point de données</strong> Sélectionnez un point de données de widget</li>\n' +
        '<li><strong>Ajouter une étiquette de navigation</strong> Sélectionnez une étiquette de navigation du widget</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<ul>\n' +
        '<li><strong>Point(s) de données</strong>Sélectionnez un point de données à configurer</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Micro widget</strong> Sélectionnez un micro widget pour représenter le point de données</li>\n' +
        '<li><strong>X</strong> Spécifiez la position horizontale du micro widget</li>\n' +
        '<li><strong>Y</strong> Spécifiez la position verticale du micro widget</li>\n' +
        '</ul>\n' +
        '<h4>Plage de temps</h4>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h3>Libellé</h3>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du micro widget</li>\n' +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '<li><strong>Agrandir le libellé au survol </strong>Agrandir le libellé au survol du libellé</li>\n' +
        "<li><strong>Boutons de contrôle : Aucun, Plus/Moins, Marche/Arrêt, Mode </strong>Transformer l'étiquette en un type de boutons</li>\n" +
        '</ul>\n' +
        '<h3>Activé/désactivé</h3>\n' +
        '<ul>\n' +
        "<li><strong>Icône </strong>Icône du micro widget lorsqu'il est activé</li>\n" +
        "<li><strong>Couleur </strong>couleur de l'icône du micro widget lorsqu'il est activé</li>\n" +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '</ul>\n' +
        '<h3>Icône</h3>\n' +
        '<ul>\n' +
        "<li><strong>Icône </strong>Icône du micro widget lorsqu'il est activé</li>\n" +
        "<li><strong>Couleur </strong>couleur de l'icône du micro widget lorsqu'il est activé</li>\n" +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '</ul>\n' +
        '<h3>Contrôle</h3>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du micro widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '<li><strong>Agrandir le libellé au survol </strong>Agrandir le libellé au survol du libellé</li>\n' +
        '<li><strong>Boutons de contrôle : Aucun, Plus/Moins, Marche/Arrêt, Mode </strong>Transformez le contrôle en un type de boutons</li>\n' +
        '</ul>\n' +
        '<h3>Étiquette de navigation 1</h3>\n' +
        '<ul>\n' +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '<li><strong>Taille</strong> Sélectionnez la taille du micro widget</li>\n' +
        "<li><strong>Lien</strong> Définissez un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le micro widget</li>\n" +
        '</ul>\n' +
        '<h3>Étiquette de navigation 2</h3>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du micro widget</li>\n' +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Afficher le séparateur</strong> Ajouter une ligne horizontale sous l'en-tête</li>\n" +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de la ligne</li>\n" +
        '<li><strong>Couleur de la police</strong> Couleur de la police de la ligne</li>\n' +
        '<li><strong>Taille</strong> Sélectionnez la taille du micro widget</li>\n' +
        "<li><strong>Lien</strong> Définissez un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le micro widget</li>\n" +
        '</ul>\n' +
        '<h3>Zone transparente</h3>\n' +
        '<ul>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du micro widget</li>\n" +
        '<li><strong>Cercle </strong>Afficher la zone sous forme de cercle</li>\n' +
        "<li><strong>Opacité</strong> Détermine l'opacité de la zone</li>\n" +
        '<li><strong>Légendes</strong> Attribuez un texte, une couleur et une icône à une valeur</li>\n' +
        '<li><strong>Points de données connectés (LINK)</strong> Sélectionnez les points de données à connecter à la zone</li>\n' +
        '<li><strong>Mettre en surbrillance au survol</strong> Mettre en surbrillance le point de données connecté au survol</li>\n' +
        '<li><strong>Hériter de la couleur</strong> Appliquer la couleur de la zone au point de données connecté</li>\n' +
        '</ul>\n' +
        '<h3>Statut</h3>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du micro widget</li>\n' +
        '<li><strong>Icône </strong>Icône du micro widget</li>\n' +
        "<li><strong>Afficher le séparateur</strong> Ajouter une ligne horizontale sous l'en-tête</li>\n" +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de la ligne</li>\n" +
        '<li><strong>Couleur de la police</strong> Couleur de la police de la ligne</li>\n' +
        '<li><strong>Taille</strong> Sélectionnez la taille du micro widget</li>\n' +
        '<li><strong>Afficher la flèche de différence</strong> Afficher la flèche de différence en fonction de la période de comparaison</li>\n' +
        '<li><strong>Afficher le pourcentage de différence</strong> Afficher le pourcentage de différence en fonction de la période de comparaison</li>\n' +
        '<li><strong>Couleur de la flèche vers le haut</strong> Sélectionnez la couleur de la flèche pour une différence positive</li>\n' +
        '<li><strong>Couleur de la flèche vers le bas</strong> Sélectionnez la couleur de la flèche pour une différence négative</li>\n' +
        "<li><strong>Afficher l'unité</strong> Afficher l'unité pour la valeur sélectionnée</li>\n" +
        '<li><strong>Masquer la valeur</strong> Masquer la valeur pour la valeur sélectionnée</li>\n' +
        '<li><strong>Afficher la description</strong> Afficher la description de la légende</li>\n' +
        '<li><strong>Lien</strong> Définissez un tableau de bord, une section ou une URL qui sera ouvert lorsque</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Floormap</h2>\n' +
        '<p>The Floormap widget displays an image on which locations with measuring points are displayed.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>File </strong>Upload a background file</li>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong>Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint </strong> Select a widget datapoint</li>\n' +
        '<li><strong>Add Navigation Label </strong> Select a widget navigation label</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint(s) </strong>Select a datapoint to be configured</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Micro widget</strong> Select a micro widget to represent the datapoint</li>\n' +
        "<li><strong>X</strong> Specify the micro widget's horizontal position</li>\n" +
        "<li><strong>Y</strong> Specify the micro widget's vertical position</li>\n" +
        '</ul>\n' +
        '<h4>Time Range</h4>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Label</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Enlarge label on hover </strong>Enlarge label on hover of label</li>\n' +
        '<li><strong>Control Buttons: None, Plus/Minus, On/Off, Mode </strong>Transform label into a type of buttons</li>\n' +
        '</ul>\n' +
        '<h3>On/off</h3>\n' +
        '<ul>\n' +
        "<li><strong>Icon </strong>Micro widget's icon when on</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color when on</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '</ul>\n' +
        '<h3>Icon</h3>\n' +
        '<ul>\n' +
        "<li><strong>Icon </strong>Micro widget's icon when on</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color when on</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '</ul>\n' +
        '<h3>Control</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Enlarge label on hover </strong>Enlarge label on hover of label</li>\n' +
        '<li><strong>Control Buttons: None, Plus/Minus, On/Off, Mode </strong>Transform control into a type of buttons</li>\n' +
        '</ul>\n' +
        '<h3>Navigation Label 1</h3>\n' +
        '<ul>\n' +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Size</strong> Select the size of the Micro widget</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the micro widget</li>\n' +
        '</ul>\n' +
        '<h3>Navigation Label 2</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Add a horizontal line under the header</li>\n' +
        "<li><strong>Background Color</strong> Line's background color</li>\n" +
        "<li><strong>Font Color</strong> Line's font color</li>\n" +
        '<li><strong>Size</strong> Select the size of the Micro widget</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the micro widget</li>\n' +
        '</ul>\n' +
        '<h3>Transparent Zone</h3>\n' +
        '<ul>\n' +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Circle </strong>Display the zone as a circle</li>\n' +
        '<li><strong>Opacity</strong> Determine the opacity of the zone</li>\n' +
        '<li><strong>Captions</strong> Assign a text, color and icon to a value</li>\n' +
        '<li><strong>Connected Datapoints (LINK)</strong> Select datapoints to connect to the zone</li>\n' +
        '<li><strong>Highlight on hover</strong> Highlight connectd datapoint on hover</li>\n' +
        '<li><strong>Inherit color</strong> Apply the zone color to the connected datapoint</li>\n' +
        '</ul>\n' +
        '<h3>Status</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Add a horizontal line under the header</li>\n' +
        "<li><strong>Background Color</strong> Line's background color</li>\n" +
        "<li><strong>Font Color</strong> Line's font color</li>\n" +
        '<li><strong>Size</strong> Select the size of the Micro widget</li>\n' +
        '<li><strong>Show Difference Arrow</strong> Show difference arrow based on comparison period</li>\n' +
        '<li><strong>Show Difference Percentage</strong> Show difference percentage based on comparison period</li>\n' +
        '<li><strong>Arrow Up Color</strong> Select arrow color for positive difference</li>\n' +
        '<li><strong>Arrow Down Color</strong> Select arrow color for negative difference</li>\n' +
        '<li><strong>Show Unit</strong> Show unit for selected value</li>\n' +
        '<li><strong>Hide Value</strong> Hide value for selected value</li>\n' +
        '<li><strong>Show Description</strong> Show description of caption</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the micro widget</li>\n' +
        '</ul>\n' +
        '<h5>Comparison period</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Captions</strong> Assign a text, color and icon to a value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>KPI</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the micro widget</li>\n' +
        '</ul>\n' +
        '<h3>Gauge</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Min </strong> Determine the minimum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Max </strong> Determine the maximum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Consumption</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period</h5>\n' +
        '<h5>Time Range for main datapoint</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>\n' +
        '<h3>Circle Gauge</h3>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Micro widget's title</li>\n" +
        "<li><strong>Icon </strong>Micro widget's icon</li>\n" +
        "<li><strong>Color </strong>Micro widget's icon color</li>\n" +
        '<li><strong>Show Percentage</strong> Display percentages or not</li>\n' +
        '<li><strong>Show Inverted Value</strong> Toggles between amount reached and amount left</li>\n' +
        '<li><strong>Comparison Point</strong> Compare the widget datapoint against a Fixed value, the main datapoint(s) or other datapoint(s)</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Floormap</h2>\n' +
        '<p>De Vloerkaart-widget geeft een afbeelding weer waarop locaties met meetpunten worden weergegeven.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Bestand </strong>Upload een achtergrondbestand</li>\n' +
        '<li><strong>Label </strong>Widgets titel</li>\n' +
        '<li><strong>Pictogram </strong>Widgetpictogram</li>\n' +
        '<li><strong>Kleur </strong>Widgets pictogramkleur</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunt </strong> Selecteer een widget-datapunt</li>\n' +
        '<li><strong>Navigatielabel toevoegen </strong> Selecteer een widget-navigatielabel</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapunt(en) </strong>Selecteer een te configureren datapunt</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Micro-widget</strong> Selecteer een micro-widget om het datapunt weer te geven</li>\n' +
        '<li><strong>X</strong> Specificeer de horizontale positie van de micro-widget</li>\n' +
        '<li><strong>Y</strong> Specificeer de verticale positie van de micro-widget</li>\n' +
        '</ul>\n' +
        '<h4>Tijdbereik</h4>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h3>Label</h3>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>De titel van de micro-widget</li>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '<li><strong>Label vergroten bij aanwijzen </strong>Label vergroten bij aanwijzen van label</li>\n' +
        '<li><strong>Bedieningsknoppen: geen, plus/min, aan/uit, modus </strong>Label omzetten in een soort knoppen</li>\n' +
        '</ul>\n' +
        '<h3>Aan/uit</h3>\n' +
        '<ul>\n' +
        '<li><strong>Icoon </strong>Pictogram van micro-widget wanneer aan</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget wanneer aan</li>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '</ul>\n' +
        '<h3>Icoon</h3>\n' +
        '<ul>\n' +
        '<li><strong>Icoon </strong>Pictogram van micro-widget wanneer aan</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget wanneer aan</li>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '</ul>\n' +
        '<h3>Besturing</h3>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>De titel van de micro-widget</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '<li><strong>Label vergroten bij aanwijzen </strong>Label vergroten bij aanwijzen van label</li>\n' +
        '<li><strong>Bedieningsknoppen: geen, plus/min, aan/uit, modus </strong>Verander de besturing in een soort knoppen</li>\n' +
        '</ul>\n' +
        '<h3>Navigatielabel 1</h3>\n' +
        '<ul>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de Micro-widget</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de micro-widget wordt geklikt</li>\n' +
        '</ul>\n' +
        '<h3>Navigatielabel 2</h3>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>De titel van de micro-widget</li>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Voeg een horizontale lijn toe onder de kop</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur van lijn</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van de regel</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de Micro-widget</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de micro-widget wordt geklikt</li>\n' +
        '</ul>\n' +
        '<h3>Transparante zone</h3>\n' +
        '<ul>\n' +
        '<li><strong>Kleur </strong>De pictogramkleur van de micro-widget</li>\n' +
        '<li><strong>Cirkel </strong>Geef de zone weer als een cirkel</li>\n' +
        '<li><strong>Dekking</strong> Bepaal de doorzichtigheid van de zone</li>\n' +
        '<li><strong>Bijschriften</strong> Wijs een tekst, kleur en pictogram toe aan een waarde</li>\n' +
        '<li><strong>Verbonden datapunten (LINK)</strong> Selecteer datapunten om verbinding te maken met de zone</li>\n' +
        '<li><strong>Markeren bij zweven</strong> Verbonden datapunt markeren bij zweven</li>\n' +
        '<li><strong>Kleur overnemen</strong> Pas de zonekleur toe op het verbonden datapunt</li>\n' +
        '</ul>\n' +
        '<h3>Status</h3>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>De titel van de micro-widget</li>\n' +
        '<li><strong>Icoon </strong>Pictogram van de micro-widget</li>\n' +
        '<li><strong>Toon scheidingslijn</strong> Voeg een horizontale lijn toe onder de kop</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur van lijn</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van de regel</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de Micro-widget</li>\n' +
        '<li><strong>Toon verschilpijl</strong> Toon verschilpijl op basis van vergelijkingsperiode</li>\n' +
        '<li><strong>Toon verschilpercentage</strong> Toon verschilpercentage op basis van vergelijkingsperiode</li>\n' +
        '<li><strong>Pijl omhoog Kleur</strong> Selecteer de kleur van de pijl voor een positief verschil</li>\n' +
        '<li><strong>Kleur pijl-omlaag</strong> Selecteer de kleur van de pijl voor een negatief verschil</li>\n' +
        '<li><strong>Eenheid weergeven</strong> Eenheid voor geselecteerde waarde weergeven</li>\n' +
        '<li><strong>Waarde verbergen</strong> Waarde voor geselecteerde waarde verbergen</li>\n' +
        '<li><strong>Beschrijving weergeven</strong> Beschrijving van bijschrift weergeven</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'Image',
      title: 'widget.floorMap',
      icon: 'fas fa-info',
      url: null,
      color: null,
      datapoints: [],
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-image',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints' },
        componentProps: {
          dataKey: 'datapoints',
          navigationLabelOption: true,
          hideMethod: true,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              navigationLabelOption: true,
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'MicroWidgetConfigurator',
                  label: { formatted: 'widget.microWidget' },
                  componentProps: {
                    widgets: [
                      {
                        type: 'Label',
                      },
                      {
                        type: 'PhaseToggle',
                      },
                      {
                        type: 'LightBulb',
                      },
                      {
                        type: 'DatapointLabel',
                      },
                      {
                        type: 'NavigationLabel',
                      },
                      {
                        type: 'Navigation',
                      },
                      {
                        type: 'Highlight',
                      },
                      {
                        type: 'LastValueWidget',
                      },
                      {
                        type: 'ChartCard',
                      },
                      {
                        type: 'Gauge',
                      },
                      {
                        type: 'Consumption',
                      },
                      {
                        type: 'CircleGauge',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    ],
  },
}

export default config
