import styled from 'styled-components'

import FieldGroup from '../FieldGroup'

const TableStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableBody = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 20rem;
`

export const TableRow = styled(FieldGroup)`
  display: flex;
  position: relative;

  &.table-head {
    border-bottom: 2px solid var(--color-softerText);

    label {
      margin-right: 0.5rem;
    }
  }

  > .ButtonList {
    position: absolute;
    right: 0;
    bottom: 1px;
    height: calc(100% - 1px);
    background: var(--color-backgroundFront);
    z-index: 100;

    .Icon {
      margin: 0 0.3rem;
    }
  }

  .FormField {
    margin-bottom: 0 !important;
    height: auto;
    flex: 1;
    &.centered {
      text-align: center;
      > * {
        justify-content: center;
      }
    }

    .Text,
    > .Icon {
      margin-bottom: 0;
      padding: 5px 0;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .Icon {
      color: var(--color-softText);
    }

    .Progress {
      border-bottom: 1px solid var(--color-softerText);
      height: 100%;
    }

    input,
    .NumberInput,
    .ant-input-affix-wrapper {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      height: 100%;
      padding: 5px;
      box-shadow: none;
      width: 100%;
    }

    img,
    svg {
      height: 80%;
    }

    .Tooltip {
      > .Button {
        box-shadow: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid var(--color-softerText);
        border-radius: 0;
        background: none;
        height: auto;
        padding: 0 0.3rem;

        &:hover,
        &.active {
          border-bottom: 1px solid var(--color-main);
          background: none;
        }
      }
    }

    .TooltipContent {
      min-width: 200px;
      right: 0;
      left: auto;

      .Button {
        border-bottom: none;
      }
    }

    .ValuePicker {
      height: 100%;

      &__value {
        min-height: 100%;
      }
    }

    .ColorPicker {
      height: 100%;
      width: 100%;

      > .Button {
        border-bottom: none;

        &:after {
          top: 15%;
          bottom: 15%;
        }
      }

      border-bottom: 1px solid var(--color-softerText);
    }

    .IconPicker {
      height: 100%;
      width: 100%;

      > .Button {
        width: 100%;
        &:hover {
          .Icon.close-button {
            opacity: 1;
          }
        }

        .Icon:not(.close-button),
        img {
          height: 100%;
          max-width: 100%;
          object-fit: contain;
        }

        img {
          width: auto;
        }

        .Icon.close-button {
          top: 0.1rem;
          right: 0.1rem;
          opacity: 0;

          .Icon {
            color: var(--color-backgroundFront);
          }
        }
      }

      > div {
        width: 100%;

        > .Button {
          width: 100%;
        }

        > .Icon {
          width: 0.8rem;
          height: 0.8rem;
          top: 0.2rem;
          right: 0.5rem;
        }
      }
    }

    &.CalculationMethod {
      position: relative;
      .Selector {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
  }
`
export default TableStyled
