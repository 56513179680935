export const initialState = {
  sideBar: {
    status: 'closed',
  },
  mainPanel: {
    offset: '100px',
    resizeToggle: 'INIT',
  },
  modal: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAIN_PANEL_OFFSET':
      return {
        ...state,
        mainPanel: {
          ...state.mainPanel,
          offset: action.payload.value,
          resizeToggle:
            action.payload.resizeToggle ||
            (state.mainPanel.resizeToggle === 'INIT' ? 'RESIZE' : 'INIT'),
        },
      }
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        darkMode: !state.darkMode,
      }
    case 'SET_MODAL':
      return {
        ...state,
        modal: action.payload,
      }
    default:
      return state
  }
}

export default reducer
