import { Image } from 'antd'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const DropzoneContainer = styled.div`
  padding: 10px;
  &:focus {
    outline: none;
  }

  ${({ isDragActive, theme }) =>
    isDragActive &&
    css`
      border: 1px dashed var(--color-softText);
    `}
`

export const PresenceWrapper = styled(motion.div)``

export const PreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
`
