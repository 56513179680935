import styled from 'styled-components'

import { ContentFlex, ContentLabel } from 'components/Layout/Content'
import { ContentFlexStyled } from 'components/Layout/Content/styled'

const DndMapperStyled = styled.div`
  position: relative;
  ${ContentLabel} {
    position: absolute;
    font-size: 0.9rem;
    top: -1.7rem;
    right: 1rem;
    background: var(--color-backgroundFront);
    padding: 0 0.3rem;
    width: auto;
    min-width: auto;
    max-width: fit-content;
  }
  > ${ContentFlexStyled} {
  }
  .DndMapper__column {
    position: relative;
    padding-top: 2rem;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .DndMapper__values {
    position: relative;
    padding-top: 2rem;
  }
`

export const IconContainer = styled(ContentFlex)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10%;
  width: 80%;
`

export default DndMapperStyled
