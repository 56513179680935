import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Button from '../../Button'
import Tooltip from '../../Tooltip'
import TooltipContent from '../../Tooltip/TooltipContent/styled'

const iconList = css`
  .Upload {
    position: relative;
    transition: transform 0.2s ease;
    width: 3em;
    min-width: 3em;
    height: 3em;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;

    input {
      display: block !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      max-width: 100%;
      cursor: pointer;
    }

    &:hover {
      transform: scale(1.2);
    }

    .Button {
      display: none;
    }

    > .Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 2px solid var(--color-main);
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      z-index: -1;
      position: relative;

      svg {
        transform: rotate(45deg);
        font-size: 1rem;
      }
    }

    .uploading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${({ theme }) => transparentize(0.5, theme.color.backgroundFront)};
    }
  }

  .Button {
    transition: color 0.2s ease, transform 0.2s ease;
    flex: 1;
    padding: 0.5rem 0;
    font-size: 1.5rem;
    color: var(--color-softText);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: var(--color-main);
      transform: scale(1.4);
    }

    &.active {
      color: var(--color-main);
    }

    img {
      width: 60%;
    }
  }
`
const iconButton = css`
  width: 3rem;
  height: 3rem;
  font-size: 1.3rem;
  transition: background 0.2s ease, border 0.2s ease;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-main);
  border-radius: 3px;
  border: 1px solid ${({ isOpen, theme }) => (isOpen ? theme.color.main : 'transparent')};
  box-shadow: var(--shadow-shadowElevation1);

  &:hover {
    background: ${({ theme }) => transparentize(0.9, theme.color.main)};
  }
`
export const IconButton = styled(Button)`
  ${iconButton}
  img {
    width: 100%;
    max-height: 100%;
    border-radius: 0.2rem;
  }
`
const IconPickerStyled = styled(Tooltip)`
  display: flex;
  position: relative;
  z-index: ${({ isOpen }) => isOpen && 10};

  > .Button {
    ${iconButton}
  }

  ${TooltipContent} {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 1rem;
    width: 100%;
    background: var(--color-backgroundFront);
    box-shadow: var(--shadow-shadowElevation2);
    display: flex;
    flex-wrap: wrap;
    transform-origin: left top;
    max-height: 300px;
    overflow: auto auto;

    ${iconList}
    .Button {
      min-width: 20%;
    }
  }
`

export const IconPickerTrigger = styled.div`
  position: relative;
  width: 3rem;
  height: 3rem;
`

export const IconListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${iconList}
  .Button {
    .Icon {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  .IconList__buttons {
    display: flex;
  }
`
export const IconListButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`

export const IconListDrawer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 45vh;

  .ant-input-affix-wrapper {
    margin: 0 1rem;
    width: auto;
  }

  .IconList {
    flex: 1;
    overflow: auto;
  }
`
export default IconPickerStyled
