import { lighten } from 'polished'
import styled, { css } from 'styled-components'

const ColorRangeSlider = styled.div`
  margin: 2rem 1.3rem;
  > div {
    position: relative;
    width: 100%;
  }
`

export const SliderRailStyled = styled.div`
  > div {
    &:first-child {
      position: absolute;
      width: 100%;
      height: 14px;
      transform: translate(0%, -50%);
      border-radius: 7px;
      cursor: pointer;
    }
    &:last-child {
      position: absolute;
      width: 100%;
      height: 4px;
      transform: translate(0%, -50%);
      border-radius: 7px;
      pointer-events: none;
      background-color: var(--color-softStroke);
    }
  }
`

export const HandleStyled = styled.div`
  position: relative;
  &:hover {
    z-index: 10;
  }
  > div {
    left: ${({ percent }) => percent}%;
    position: absolute;
    transform: translate(-50%, -50%);
    &:first-child {
      z-index: 5;
      width: 28px;
      height: 14px;
      cursor: pointer;
      background: none;
      &:hover {
        + div {
          > .Button {
            transform: scale(1);
            visibility: visible;
          }
        }
      }
    }
    &:hover {
      .Handle__value,
      .Handle__edit span {
        transform: scale(1);
      }
    }
  }
  .Handle__value {
    transition: transform 0.2s ease;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 10px;
    transform: scale(0);
  }
  .Handle__edit {
    width: 4rem;
    margin: 0 0 0 -0.9rem;
    display: flex;
    justify-content: space-between;
    span {
      color: var(--color-mainText);
      transition: transform 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
      border: 1px solid;
      border-radius: 100%;
      transform: scale(0);
    }
  }
  .Handle__handle {
    z-index: 2;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ disabled, color, theme }) =>
      disabled ? theme.color.softStroke : color || 'var(--color-main)'};
    box-shadow: var(--shadow-shadowElevation1);
  }
  .ColorPicker {
    z-index: 2;
    width: 14px;
    height: 40px;
    &:hover,
    &.open {
      > .Button {
        transform: scale(1);
        visibility: visible;
      }
    }
    > .Button {
      transition: transform 0.2s ease, visibility 0.2s ease;
      transform: scale(0);
      visibility: hidden;
      top: 1rem;
      margin: 2rem 0;
      background: ${({ disabled, color, theme }) =>
        disabled ? theme.color.softStroke : color || 'var(--color-main)'};
      box-shadow: var(--shadow-shadowElevation1);
      height: 1.5rem;
      &:after {
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        top: 0;
        left: 50%;
        background: ${({ disabled, color, theme }) =>
          disabled ? theme.color.softStroke : color || 'var(--color-main)'};
        transform: rotate(45deg) translate(-5px, -1px);
        border-radius: 0;
        box-shadow: none;
        z-index: -1;
      }
    }
    .TooltipContent {
      width: 240px;
      margin-top: 3rem;
      ${({ percent }) =>
        percent < 70
          ? css`
              left: ${percent > 30 ? '-110px' : 0};
            `
          : css`
              right: 0;
              left: auto;
            `}
    }
  }
`

export const TrackStyled = styled.div`
  position: absolute;
  transform: translate(0%, -50%);
  height: 4px;
  z-index: 1;
  background: ${({ disabled, theme, colors }) =>
    disabled
      ? theme.color.softStroke
      : colors.length
      ? `linear-gradient(90deg, ${lighten(0.3, colors[0])} 0%, ${lighten(0.3, colors[1])} 100%)`
      : lighten(0.3, theme.color.main)};
  border-radius: 7px;
  cursor: pointer;
  left: ${({ source }) => source.percent}%;
  width: ${({ target, source }) => target.percent - source.percent}%;
`

export const TickStyled = styled.div`
  > div {
    &:first-child {
      position: absolute;
      margin-top: 14px;
      width: 1px;
      height: 5px;
      background-color: rgb(200, 200, 200);
      left: ${({ tick }) => tick.percent}%;
    }
    &:last-child {
      position: absolute;
      margin-top: 22px;
      font-size: 10px;
      text-align: center;
      margin-left: ${({ count }) => -(100 / count) / 2}%;
      width: ${({ count }) => 100 / count}%;
      left: ${({ tick }) => tick.percent}%;
    }
  }
`

export default ColorRangeSlider
