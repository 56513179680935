import React, { Component, ComponentProps } from 'react'
import UnexpectedErrorPage from './UnexpectedErrorPage'

// TODO: add custom error log to the user when its a known issue + when a potential work around is known
export class ErrorBoundary extends Component<
  Pick<ComponentProps<typeof UnexpectedErrorPage>, 'children'>,
  { hasError: boolean; error?: any }
> {
  state: { hasError: boolean; error?: any } = { hasError: false }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    try {
      window.posthog.capture('error_occurred', {
        error,
        errorInfo,
      })
    } catch (e) {
      console.log(`Error was not send to Canary
Error:
${e}`)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <UnexpectedErrorPage
          error={this.state.hasError ? this.state.error : undefined}
          onButtonClicked={() => this.setState({ hasError: false })}
        />
      )
    }
    return this.props?.children
  }
}
