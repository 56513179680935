import { css } from 'styled-components'

export const iconActionButton = css`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: var(--color-softText);
  color: var(--color-backgroundFront);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  box-shadow: var(--shadow-shadowElevation1);
  transform: scale(0);
  &.right {
    position: absolute;
    right: 0;
    top: 50%;
    &.clone {
      margin: -27px -10px 0 0;
    }
    &.add {
      margin: 5px -10px 0 0;
    }
  }
  &.bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    &.clone {
      margin: 0 0 -10px -27px;
    }
    &.add {
      margin: 0 0 -10px 5px;
    }
  }
  &:hover {
    box-shadow: var(--shadow-shadowElevation2);
    background: var(--color-action);
  }
  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
