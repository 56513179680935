import { fileEndpoint } from 'config'

export const imageStyles = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

const imageStyleUrl = (key, style, cheatDevSettings = false) => {
  if (!key) {
    console.warn('Key for image is empty')
    return null
  }

  const imageStyle = imageStyles[style]

  if (!imageStyle) {
    throw new Error(`Image style "${style}" does not exist`)
  }

  // TODO: Replace with ENV variabels
  let endpoint = ''

  if (cheatDevSettings) {
    endpoint = 'https://api.iot.canary.expert'
  } else {
    endpoint = fileEndpoint
  }
  return `${endpoint}/image/${imageStyle}/${key}`
}

export default imageStyleUrl
