import styled from 'styled-components'

import Button from '../../Button/styled'
import Selector from './index'

const CheckboxSelector = styled(Selector)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Button} {
    transition: background 0.2s ease, border 0.2s ease, color 0.2s ease;
    border: 1px solid var(--color-softStroke);
    border-radius: 1rem;
    padding: 0 0.8rem 0 0.4rem;
    margin: 0 0.5rem 0.5rem 0;
    color: var(--color-softText);
    line-height: 1.5;
    &:before {
      transition: background 0.2s ease;
      content: '';
      width: 0.7rem;
      min-width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      margin-right: 0.5rem;
      background: var(--color-softStroke);
    }
    &:hover,
    &.active {
      background: var(--color-softerStroke);
      color: var(--color-mainText);
      &:before {
        background: var(--color-main);
      }
    }
  }
`
export default CheckboxSelector
