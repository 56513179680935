import { darken } from 'polished'
import { css } from 'styled-components'

export const legend = css`
  .highcharts-axis-title {
    font-size: 10px;
    fill: var(--color-softText) !important;
  }

  .highcharts-axis-labels text {
    font-size: 9px !important;
    fill: var(--color-softText) !important;
  }

  .highcharts-legend-item {
    font-weight: 100;

    text,
    span {
      font-size: 10px !important;
      fill: var(--color-mainText) !important;
    }

    rect {
      height: 0.5rem;
      margin-top: 0.2rem;
    }

    &:hover {
      span {
        color: var(--color-softText) !important;
      }
    }
  }

  .highcharts-plot-line-label {
    font-size: 10px;
    fill: var(--color-softText) !important;
    transform: translateY(20px);
  }
`
export const tooltip = css`
  position: relative;
  z-index: 1000;

  p:empty {
    display: none;
  }

  > span,
  .highcharts-label > span {
    background: var(--color-backgroundFront);
    box-shadow: var(--shadow-shadowElevation4);
    border-radius: 5px;
    color: var(--color-softText);
    padding: 0;
    font-weight: 100;
    display: grid;
    grid-template: auto / 1fr auto;
    overflow: hidden;

    * {
      white-space: normal;
    }

    > {
      padding: 0.5rem;
    }

    .tooltip__date {
      background: ${({ theme }) => darken(0.015, theme.color.softerStroke)};
      color: var(--color-softText);
      border-radius: 5px 5px 0 0;
      padding: 0.5rem;
      margin-bottom: 0;
      grid-column: 1 / span 2;
      display: block;
      white-space: nowrap;
      min-width: fit-content;
      text-transform: capitalize;

      svg {
        margin: 0 0.5rem;
        opacity: 0.8;
      }
    }

    .tooltip__color {
      display: inline-block;
      width: 0.6rem;
      min-width: 0.6rem;
      height: 0.6rem;
      margin: 0.2rem 0.2rem 0 0;
      border-radius: 100%;
      align-self: flex-start;
    }

    .tooltip__name {
      color: var(--color-mainText);
      padding: 0.5rem;
      width: 15rem;
    }

    .tooltip__comparison {
      color: var(--color-mainText);
      padding: 0.5rem;
      display: flex;
      align-items: flex-end;
    }

    .tooltip__value {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--color-strongText);
      margin-bottom: 0;
      white-space: nowrap;
      background: var(--color-softerStroke);

      span {
        font-size: 1rem;
        margin: 0.3rem 0.2rem 0;
      }

      &.range {
        font-size: 1rem;

        span {
          font-size: 0.7rem;
          margin: 0.3rem 0.2rem 0;
        }
      }
    }

    ${({ raw }) =>
      !raw &&
      css`
        .tooltip__value,
        .tooltip__name {
          border-bottom: 1px solid var(--color-softStroke);
        }

        .tooltip__name {
          //border-right: 1px solid var(--color-softStroke);
        }

        .tooltip__comparison {
          border-left: 1px solid var(--color-softStroke);
        }
      `}
  }
`

export const annotations = css`
  &:hover {
    .x-axis-label {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }
    .y-axis-label {
      opacity: 1;
      transform: scale(1);
    }
  }

  .x-axis-label,
  .y-axis-label {
    transition: opacity 0.2s ease, transform 0.2s ease;
    position: absolute;
    bottom: 100%;
    color: var(--color-softText);
    box-shadow: var(--shadow-shadowElevation4);
    background: var(--color-backgroundFront);
    border-radius: 1rem;
    font-weight: 100;

    span {
      display: inline-block;
      padding: 0.3rem;
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      top: 100%;
      border-style: solid;
      border-width: 6px 8px 0 8px;
      margin-left: -12px;
      border-color: var(--color-softerStroke) transparent transparent transparent;
    }

    .value,
    .title {
      font-weight: 600;
    }

    .title {
      padding-left: 1rem;
    }

    .value {
      background: var(--color-error);
      color: var(--color-backgroundFront);
      padding-right: 1rem;
      padding-left: 1rem;
      border-radius: 0 1rem 1rem 0;

      > span {
        padding: 0;
        font-size: 0.7rem;
      }
    }
  }

  .x-axis-label {
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
  }
  .y-axis-label {
    transform: scale(0);
  }
`
