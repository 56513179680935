import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { basic } from './styled.basic'
import { ColorPicker } from './styled.ColorPicker'
import { icon } from './styled.icon'
import { iconActionButton } from './styled.iconActionButton'
import { iconListButton } from './styled.iconListButton'
import { mainButton } from './styled.mainButton'
import { MainMenu } from './styled.MainMenu'
import { NavigationWidget } from './styled.NavigationWidget'
import { roundedButton } from './styled.roundedButton'
import { smallButton } from './styled.smallButton'
import { squeals } from './styled.squeals'
import { subMenu } from './styled.subMenu'
import { tag } from './styled.tag'
import { treeItem } from './styled.treeItem'

const variants = {
  iconActionButton,
  iconListButton,
  mainButton,
  roundedButton,
  MainMenu,
  subMenu,
  squeals,
  basic,
  tag,
  icon,
  smallButton,
  ColorPicker,
  treeItem,
  NavigationWidget,
}

const ButtonStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
  ${({ variant }) => variants[variant]}
  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--color-action);
    `}
`
export default ButtonStyled
