import { useCallback, useMemo } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'
import { pick } from 'lodash'

import { useCurrentCustomer } from 'services/store'

export const IMPORT_FF_DEFAULTS = gql`
  mutation importFixedFileDefaults($tableId: Int!, $companyId: Float!, $deleteFirst: Boolean!) {
    importFixedFileDefaults(tableId: $tableId, companyId: $companyId, deleteFirst: $deleteFirst) {
      id
    }
  }
`

export const QUERY_AVAILABLE_FIXED_FILES = gql`
  query availableFixedFiles($canaryOnly: Boolean!, $groupId: String!) {
    availableFixedFiles(canaryOnly: $canaryOnly, groupId: $groupId) {
      id
      tableCaption
    }
  }
`

const QUERY_ALL_FIXED_FILE_RECORDS = gql`
  query allFixedFileRecords($tableId: Int!, $canaryOnly: Boolean!, $companyId: Float) {
    allFixedFileRecords(tableId: $tableId, canaryOnly: $canaryOnly, companyId: $companyId) {
      id
      fields
    }
    fixedFile(tableId: $tableId, canaryOnly: $canaryOnly) {
      id
      allowDelete
      allowInsert
      allowDuplicate
      allowDefaultImport
      overwriteDataOnDefaultImport
      detailFormFieldSelection
    }
  }
`

const QUERY_ALL_FIXED_FILE_RECORDS_AS_SELECT_OPTIONS = gql`
  query allFixedFileRecordsAsSelectOptions(
    $tableId: Int!
    $labelField: String!
    $companyId: Float
    $filter: String
  ) {
    allFixedFileRecordsAsSelectOptions(
      tableId: $tableId
      labelField: $labelField
      companyId: $companyId
      filter: $filter
    ) {
      value
      label
    }
  }
`

const QUERY_ENERGY_CONTRACT = gql`
  query energyContract($contractId: Float!) {
    energyContract(contractId: $contractId) {
      id
      internalId
      country {
        id
        name
      }
      activationDate
      name
      unit {
        id
        name
      }
      directMetering
      energyContractCompositionParts {
        id
        energyType {
          id
          renewable
          energySource
          standardUnit {
            id
            name
          }
          tags
        }
        fuelType {
          id
          name
          tags
          standardUnit {
            id
            name
          }
        }
        percentage
      }
    }
  }
`

export const useEnergyContract = (contractId, { skip }) => {
  const result = useQuery(QUERY_ENERGY_CONTRACT, {
    variables: { contractId },
    skip: skip || !contractId,
  })
  return { ...result, data: result.data?.energyContract }
}

export const useAllFixedFileRecordsAsSelectOptions = (
  tableId,
  labelField,
  companyId,
  filter = null,
  skip
) => {
  const result = useQuery(QUERY_ALL_FIXED_FILE_RECORDS_AS_SELECT_OPTIONS, {
    variables: { tableId, labelField: labelField, companyId, filter },
    skip: skip || !tableId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  return { ...result, data: result.data?.allFixedFileRecordsAsSelectOptions }
}

export const useAllFixedFileRecords = (tableId, customerModus, companyId, skip) => {
  const result = useQuery(QUERY_ALL_FIXED_FILE_RECORDS, {
    variables: { tableId, canaryOnly: !customerModus, companyId },
    skip: skip || !tableId,
    notifyOnNetworkStatusChange: true,
  })

  return useMemo(() => {
    const data = result.data
      ? {
          records: result.data?.allFixedFileRecords,
          permissions: pick(result.data?.fixedFile, [
            'allowDelete',
            'allowInsert',
            'allowDuplicate',
            'allowDefaultImport',
            'overwriteDataOnDefaultImport',
          ]),
          detailFormFieldSelection: result?.data?.fixedFile?.detailFormFieldSelection,
        }
      : undefined

    return {
      ...result,
      data,
    }
  }, [result])
}

const QUERY_FIXED_FILE_RECORD_DETAIL = gql`
  query fixedFileRecordDetail($tableId: Int!, $id: Int!, $canaryOnly: Boolean!, $companyId: Float) {
    singleFixedFileRecord(
      tableId: $tableId
      recordId: $id
      canaryOnly: $canaryOnly
      companyId: $companyId
    ) {
      id
      fields
      detailTableInfoRecords {
        detailTableId
        detailTableRelationField
        detailRecords {
          id
          fields
        }
      }
    }
  }
`

export const useFixedFileRecordDetail = (tableId, id, canaryOnly, companyId) => {
  const result = useQuery(QUERY_FIXED_FILE_RECORD_DETAIL, {
    variables: { tableId, id, canaryOnly, companyId },
    skip: !id || !tableId,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    return {
      ...result,
      data: result.data?.singleFixedFileRecord,
    }
  }, [result])
}

const QUERY_FIXED_FILE_DETAIL = gql`
  query fixedFileDetail($tableId: Int!, $canaryOnly: Boolean!) {
    fixedFile(tableId: $tableId, canaryOnly: $canaryOnly) {
      id
      tableName
      tableCaption
      orderByField
      detailFormFieldSelection
      allowInsert
      allowUpdate
      allowDelete
      allowDuplicate
      uniqueRecordDefinition
    }
  }
`
export const useFixedFileDetail = (tableId, canaryOnly) => {
  const result = useQuery(QUERY_FIXED_FILE_DETAIL, {
    variables: { tableId, canaryOnly },
    skip: !tableId,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    const { detailFormFieldSelection, ...fixedFile } = result.data?.fixedFile || {}
    return {
      ...result,
      data: {
        permissions: pick(fixedFile, [
          'allowInsert',
          'allowUpdate',
          'allowDelete',
          'allowDuplicate',
        ]),
        fields: detailFormFieldSelection,
        tableName: fixedFile?.tableName,
        tableCaption: fixedFile?.tableCaption,
        orderByField: fixedFile?.orderByField,
        uniqueRecordDefinition: fixedFile?.uniqueRecordDefinition,
      },
    }
  }, [result])
}

const MUTATION_DELETE_FIXED_FILE_RECORD = gql`
  mutation deleteFixeFileRecord($tableId: Int!, $recordId: Int!) {
    deleteFixedFileRecord(tableId: $tableId, recordId: $recordId) {
      id
    }
  }
`

export const useDeleteFixedFileRecord = (options) => {
  return useMutation(MUTATION_DELETE_FIXED_FILE_RECORD, {
    ...options,
    update(cache, { data }) {
      cache.modify({
        fields: {
          allFixedFileRecords(existingRecords = [], { readField }) {
            return existingRecords.filter((recordRef) => {
              return readField('id', recordRef) !== data.deleteFixedFileRecord.id
            })
          },
        },
      })
    },
  })
}

const MUTATION_UPDATE_FIXED_FILE_RECORD = gql`
  mutation updateFixedFileRecord(
    $tableId: Int!
    $newValues: FixedFileRecordUpdate!
    $canaryOnly: Boolean!
    $companyId: Float
  ) {
    updateFixedFileRecord(
      tableId: $tableId
      newValues: $newValues
      canaryOnly: $canaryOnly
      companyId: $companyId
    ) {
      id
      fields
    }
  }
`

export const useUdateFixeFileRecord = (options) => {
  const customer = useCurrentCustomer()
  const [updateFn, info] = useMutation(MUTATION_UPDATE_FIXED_FILE_RECORD, options)

  const updateRecord = useCallback(
    ({ tableId, canaryOnly, recordId, fields }, fnOptions = {}) => {
      return updateFn({
        ...fnOptions,
        variables: {
          tableId,
          newValues: { id: recordId, fields: JSON.stringify(fields) },
          canaryOnly,
          companyId: customer.id,
        },
        refetchQueries: [
          {
            query: QUERY_ALL_FIXED_FILE_RECORDS,
            variables: { tableId, canaryOnly, companyId: customer.id },
          },
        ],
      })
    },
    [updateFn, customer]
  )

  return [updateRecord, info]
}

const QUERY_RELATED_DATA = gql`
  query relatedData($tableId: Int!, $companyId: Float!) {
    relatedData(tableId: $tableId, companyId: $companyId) {
      id
      tableName
      fieldCaption
      data
    }
  }
`

export const useRelatedData = (tableId) => {
  const customer = useCurrentCustomer()

  const result = useQuery(QUERY_RELATED_DATA, {
    variables: { tableId, companyId: customer?.id },
    skip: !tableId || !customer?.id,
    fetchPolicy: 'network-only',
  })

  return useMemo(() => {
    const data = result?.data?.relatedData?.reduce((map, item) => {
      map[item.tableName] = item
      return map
    }, {})

    return {
      ...result,
      data,
    }
  }, [result])
}

const MUTATION_INSERT_FIXED_FILE_RECORD = gql`
  mutation insertFixedFileRecord(
    $tableId: Int!
    $newValues: FixedFileRecordInsert!
    $canaryOnly: Boolean!
    $companyId: Float
  ) {
    insertFixedFileRecord(
      tableId: $tableId
      newValues: $newValues
      canaryOnly: $canaryOnly
      companyId: $companyId
    ) {
      id
      fields
    }
  }
`

export const useInsertFixeFileRecord = (options) => {
  const customer = useCurrentCustomer()
  const [insertFn, info] = useMutation(MUTATION_INSERT_FIXED_FILE_RECORD, options)

  const updateRecord = useCallback(
    ({ tableId, canaryOnly, fields }, fnOptions = {}) => {
      const fieldData = canaryOnly ? fields : { ...fields, companyId: customer.id }

      return insertFn({
        ...fnOptions,
        variables: {
          tableId,
          newValues: { fields: JSON.stringify(fieldData) },
          canaryOnly,
          companyId: customer.id,
        },
        refetchQueries: [
          {
            query: QUERY_ALL_FIXED_FILE_RECORDS,
            variables: { tableId, canaryOnly, companyId: customer.id },
          },
        ],
      })
    },
    [insertFn, customer]
  )

  return [updateRecord, info]
}
