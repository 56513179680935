import React, { useMemo } from 'react'

import { set } from 'lodash'

import { useFilteredData } from 'components/Layout/List'

import { Grid, Item } from './styled'

const DefaultGridLayout = ({
  data,
  searchText,
  TileComponent,
  onRowClicked,
  columns,
  gridTileExtraProps,
  searchOptions = {},
}) => {
  const options = useMemo(() => {
    const searchKeys = columns?.map((column) => column.props.field)
    return { searchKeys, ...searchOptions }
  }, [columns, searchOptions])

  const formattedData = useMemo(() => {
    return data?.map((row) => {
      return columns.reduce((formattedRow, column) => {
        if (typeof valueFormatter !== 'function') {
          return formattedRow
        }

        const field = column.props.field

        return set(formattedRow, field, column.props.valueFormatter({ data: row }))
      }, row)
    })
  }, [data, columns])

  const items = useFilteredData(formattedData, searchText, options)

  return (
    <Grid className="Grid">
      <div>
        {items?.map((item) => (
          <Item key={item.id}>
            <TileComponent
              data={item}
              columns={columns}
              {...gridTileExtraProps}
              onClick={() => {
                if (typeof onRowClicked === 'function') {
                  onRowClicked({ data: item })
                }
              }}
            />
          </Item>
        ))}
      </div>
    </Grid>
  )
}

export default DefaultGridLayout
