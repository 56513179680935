import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react'

import { injectIntl } from 'react-intl'
// import io from 'socket.io-client'
import { useTheme } from 'styled-components'

import { coreConfig } from 'services/store'
import { useUser } from 'services/user'

import {
  checkForNewMessages,
  convertNotifications,
} from 'components/Layout/Notifications/functions'
import { useUi } from 'components/Layout/UiProvider'

import { showMessage } from './actions'
import reducer, { initialState } from './reducer'

const NotificationContext = createContext(null)
export const useNotification = () => useContext(NotificationContext) || { state: {} }

const NotificationProvider = ({ children, intl, socket }) => {
  const theme = useTheme()
  const { action: uiAction } = useUi()
  const [me] = useUser()
  const [state, dispatch] = useReducer(reducer, { ...initialState })
  // const socket = io(process.env.REACT_APP_WS_ENDPOINT, { withCredentials: false })

  const coreconfig = coreConfig()

  useEffect(() => {
    if (me?.id) {
      socket.emit(
        'aviary_notification',
        JSON.stringify({ type: 'notification', user: me.id, token: coreconfig.token })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  const action = useCallback(
    async (action) => {
      switch (action.type) {
        case 'SET_MESSAGE':
          await showMessage(action, theme, intl)
          break
        case 'ADD_NOTIFICATION':
          const newNotification = convertNotifications([action.payload.value], {
            ui: { action: uiAction },
          })[0]
          await showMessage(
            {
              type: 'SET_MESSAGE',
              payload: { value: newNotification },
            },
            theme,
            intl
          )
          return dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { value: newNotification },
          })
        default:
      }
      dispatch(action)
    },
    [theme, dispatch, intl, uiAction]
  )

  socket.removeAllListeners('aviary_notification')
  socket.on('aviary_notification', async function (msg) {
    const newNotificationList = convertNotifications(msg, { ui: { action: uiAction } })

    const newNotifications = checkForNewMessages(
      newNotificationList,
      state.notifications,
      state.deleted
    )
    await newNotifications.forEach(async (notification) => {
      return await action({
        type: 'SET_MESSAGE',
        payload: { value: notification },
      })
    })

    await action({
      type: 'SET_NOTIFICATIONS',
      payload: {
        value: newNotificationList,
      },
    })
  })

  return (
    <NotificationContext.Provider value={{ state, action }}>
      {children}
    </NotificationContext.Provider>
  )
}

export default injectIntl(NotificationProvider)
