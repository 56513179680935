import { buttonSizes, emissionFactors } from 'components/Dashboard/Widget/config/selections'

const variables = require('theme/variables')

const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.status',
    category: 'widget.ghgGaugesIndicators',
    keywords: ['indicator', 'status', 'kpi', 'ghg'],
    img: '/resources/images/widgets/lastvalue.png',
    info: {
      'fr-FR':
        '<h4>Statut</h4>\n' +
        '<p>Le widget Statut affiche la dernière valeur pour un point de données donné.</p>\n' +
        '<ul>\n' +
        '<li><strong>Étiquette </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> icône du widget</li>\n' +
        '<li><strong>Afficher le diviseur </strong> Affiche ou non une ligne de séparation horizontale</li>\n' +
        '<li><strong>Couleur de fond </strong> Sélectionnez la couleur de fond du widget</li>\n' +
        '<li><strong>Couleur du texte </strong> sélectionnez la couleur du texte pour le widget</li>\n' +
        '<li><strong>Taille </strong> Sélectionnez la taille du widget</li>\n' +
        '<li><strong>Points de données </strong> Sélectionnez un point de données du widget</li>\n' +
        "<li><strong>Afficher l'unité </strong> Afficher l'unité ou non</li>\n" +
        '<li><strong>Masquer la valeur </strong> Masquer la valeur ou non</li>\n' +
        '<li><strong>Montrer la description </strong> Afficher la description ou non</li>\n' +
        '<li><strong>Valeur Delta </strong> Utilisez la valeur delta du point de données pour afficher la ligne de tendance</li>\n' +
        "<li><strong>Intervalle de temps </strong> Déterminer l'intervalle de temps du point de données</li>\n" +
        '<li><strong>Période de comparaison </strong> Sélectionnez la période pour la valeur de référence</li>\n' +
        '<li><strong>Afficher la flèche de différence </strong> Afficher ou non la flèche de différence</li>\n' +
        '<li><strong>Afficher le pourcentage de la différence </strong> Affiche ou non le pourcentage de la différence</li>\n' +
        '<li><strong>Couleur de la flèche vers le haut </strong> Sélectionnez la couleur de la flèche vers le haut</li>\n' +
        '<li><strong>Couleur de la flèche vers le bas </strong> Sélectionnez la couleur de la flèche vers le bas</li>\n' +
        "<li><strong>Unité </strong> Sélectionnez l'unité du point de données</li>\n" +
        '<li><strong>Logique personnalisée </strong> Appliquer un calcul personnalisé à la valeur du point de données</li>\n' +
        '<li><strong>Légendes </strong> Ajouter un label, une icône et un type pour les valeurs fixes</li>\n' +
        '<li><strong>Lien </strong> Définissez un lien qui sera ciblé lorsque vous cliquerez sur le marqueur/point de données</li>' +
        '</ul>',
      'en-US':
        '<h4>Status</h4>\n' +
        '<p>The Status widget displays the latest value for a given datapoint.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Show Divider</strong> Display a horizontal divider line or not</li>\n' +
        '<li><strong>Background Color</strong> Select the background color for the widget</li>\n' +
        '<li><strong>Font Color</strong> Select the font color for the widget</li>\n' +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '<li><strong>Datapoints</strong> Select a widget datapoint</li>\n' +
        '<li><strong>Show Unit</strong> Display unit or not</li>\n' +
        '<li><strong>Hide Value</strong> Hide value or not</li>\n' +
        '<li><strong>Show Description</strong> Display description or not</li>\n' +
        '<li><strong>Delta Value </strong> Use the datapoint delta value to display the trendline</li>\n' +
        '<li><strong>Time Range </strong> Determine the time range of the datapoint</li>\n' +
        '<li><strong>Comparison Period </strong> Select the period for the reference value</li>\n' +
        '<li><strong>Show Difference Arrow</strong> Display the difference arrow or not</li>\n' +
        '<li><strong>Show Difference Perecentage</strong> Display the difference percentage or not</li>\n' +
        '<li><strong>Arrow Up Color</strong> Select the color for the upwards arrow</li>\n' +
        '<li><strong>Arrow Down Color</strong> Select the color for the downwards arrow</li>\n' +
        '<li><strong>Unit </strong> Select the unit of the datapoint</li>\n' +
        '<li><strong>Custom logic </strong> Apply a custom calculation to the datapoint value</li>\n' +
        '<li><strong>Captions</strong> Add label, Icon and Type for fixed values</li>\n' +
        '<li><strong>Link </strong> Define a link that will be targeted when clicking on the marker/datapoint</li>' +
        '</ul>\n',
      'nl-NL':
        '<h4>Status</h4>\n' +
        '<p>De Status widget geeft de laatste waarde voor een bepaald datapunt weer.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's titel</li>\n" +
        "<li><strong>Pictogram</strong> Widget's pictogram</li>\n" +
        '<li><strong>Toon scheidingslijn</strong> Geef een horizontale scheidingslijn weer of niet</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Selecteer de achtergrondkleur voor de widget</li>\n' +
        '<li><strong>Tekstkleur</strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Maat</strong> Selecteer de grootte van de widget</li>\n' +
        '<li><strong>Datapunt</strong> Selecteer een widget datapunt</li>\n' +
        '<li><strong>Toon eenheid</strong> Weergave-eenheid of niet</li>\n' +
        '<li><strong>Verberg waarde</strong> Verberg waarde of niet</li>\n' +
        '<li><strong>Beschrijving weergeven</strong> Weergave beschrijving of niet</li>\n' +
        '<li><strong>Delta Waarde</strong> Gebruik de deltawaarde van het datapunt om de trendlijn weer te geven</li>\n' +
        '<li><strong>Tijdspanne</strong> Bepaal het tijdbereik van het datapunt</li>\n' +
        '<li><strong>Vergelijkingsperiode </strong> Selecteer de periode voor de referentiewaarde</li>\n' +
        '<li><strong>Toon pijl verschil</strong> Toon de verschilpijl of niet</li>\n' +
        '<li><strong>Toon verschil percentage</strong> Toon het verschilpercentage of niet</li>\n' +
        '<li><strong>Pijl naar boven kleur</strong> Selecteer de kleur voor de pijl omhoog</li>\n' +
        '<li><strong>Pijl naar beneden kleur</strong> Selecteer de kleur voor de pijl naar beneden</li>\n' +
        '<li><strong>Bijschriften</strong> Label, Pictogram en Type toevoegen voor vaste waarden</li>\n' +
        '<li><strong>Eenheid </strong> Selecteer de eenheid van het datapunt</li>\n' +
        '<li><strong>Aangepaste logica </strong> Pas een aangepaste berekening toe op de waarde van het datapunt</li>\n' +
        '<li><strong>Link </strong> Definieer een link die zal worden getarget bij het klikken op de marker/datapunt</li>' +
        '</ul>\n',
    },
    default: {
      type: 'GhgLastValueWidget',
      title: 'widget.status',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      url: null,
      datapoints: [],
      method: 'sum',
      showUnit: true,
      hideValue: false,
      captions: {},
    },
    preview: {
      value: 20,
      unit: 'Kg',
      icon: 'fas fa-info',
      color: variables.color.light.backgroundFront,
      fontColor: variables.color.color.aviary.main,
      arrowUpColor: variables.color.color.aviary.success,
      arrowDownColor: variables.color.color.aviary.error,
      showUnit: true,
      hideValue: false,
      showDescription: false,
      showDiffArrow: true,
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDivider' },
        col: 'col2',
        dataKey: 'line',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.backgroundColor' },
        col: 'col2 first',
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        componentProps: {
          clearIcon: true,
        },
        label: { formatted: 'widget.fontColor' },
        col: 'col2',
        dataKey: 'fontColor',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDifferenceArrow' },
        col: 'col2 first',
        dataKey: 'showDiffArrow',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDifferencePercentage' },
        col: 'col2',
        dataKey: 'diffPercentage',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.arrowUpColor' },
        col: 'col2 first',
        dataKey: 'arrowUpColor',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.arrowDownColor' },
        col: 'col2',
        dataKey: 'arrowDownColor',
      },
      {
        category: 'widget.data',
        componentName: 'DatapointGroups',
        label: { formatted: 'widget.data', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapoints',
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.showUnit' },
        dataKey: 'showUnit',
        col: 'col2 first',
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.hideValue' },
        dataKey: 'hideValue',
        col: 'col2',
      },
      {
        category: 'widget.data',
        componentName: 'Switch',
        label: { formatted: 'widget.showDescription' },
        dataKey: 'showDescription',
        col: 'col2',
      },
      {
        category: 'widget.data',
        componentName: 'LinkPicker',
        label: { formatted: 'widget.link' },
        componentProps: {
          writeDatapointOption: false,
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          hideLabel: true,
          range: true,
          reportingYear: true,
        },
        label: { formatted: 'widget.timeRange', tagName: 'h4' },
        dataKey: 'timeRange',
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          hideLabel: true,
          range: true,
          reportingYear: true,
        },
        label: { formatted: 'widget.comparisonPeriod', tagName: 'h4' },
        dataKey: 'comparisonPeriod',
      },
      {
        category: 'widget.emissionFactor',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.emissionFactor' },
        dataKey: 'emissionFactor',
        componentProps: {
          selectionKeys: { label: 'label', value: 'value' },
          valueKey: 'value',
          options: emissionFactors,
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
