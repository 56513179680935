import { InputNumber } from 'antd'
import styled from 'styled-components'

export const InputNumberStyled = styled(InputNumber)`
  width: 100%;
  background-color: transparent;
  border-color: var(--color-softStroke);

  .ant-input-number-handler-wrap {
    background: var(--color-backgroundHighlight);
    border-color: var(--color-softStroke);
  }

  .ant-input-number-handler {
    color: var(--color-mainText);
    border-color: var(--color-softStroke);

    path {
      fill: var(--color-mainText);
    }
  }

  .ant-input-number-input-wrap {
    padding-right: 14px;
    color: var(--color-mainText);
  }

  &.error {
    border: 1px solid var(--color-error);
  }
`

export const SuffixWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${InputNumberStyled} {
    padding-right: ${({ suffixWidth }) => suffixWidth + 10}px;
  }
`

export const Suffix = styled.span`
  position: absolute;
  right: 35px;
  z-index: 100;
`
