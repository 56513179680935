import React from 'react'

import { lazyRetry } from './lazyRetry'

const DatapointConfigurator = React.lazy(() =>
  lazyRetry(() => import('components/SettingsComponents/DatapointConfigurator'), 'DatapointConfigurator')
)
const EntityTypeManager = React.lazy(() => 
  lazyRetry(() => import('components/SettingsComponents/EntityTypeManager'), 'EntityTypeManager')
)
const EditUserModal = React.lazy(() => 
  lazyRetry(() => import('components/Modals/Settings/EditUserModal'), 'EditUserModal')
)
const EditCompanyModal = React.lazy(() => 
  lazyRetry(() => import('components/Modals/Settings/EditCompanyModal'), 'EditCompanyModal')
)
const RoleModal = React.lazy(() => 
  lazyRetry(() => import('components/SettingsComponents/RoleModal'), 'RoleModal')
)
const PermissionsModal = React.lazy(() => 
  lazyRetry(() => import('components/SettingsComponents/PermissionsModal'), 'PermissionsModal')
)
const EditCustomerModal = React.lazy(() => 
  lazyRetry(() => import('components/Modals/Settings/EditCustomerModal'), 'EditCustomerModal')
)
const NewCustomerModal = React.lazy(() => 
  lazyRetry(() => import('components/Modals/Settings/NewCustomerModal'), 'NewCustomerModal')
)
const ModulesCustomerModal = React.lazy(() => 
  lazyRetry(() => import('components/Modals/Settings/ModulesCustomerModal'), 'ModulesCustomerModal')
)

export default [
  {
    path: '/settings/datapoints/configurator',
    component: DatapointConfigurator,
  },
  {
    path: '/settings/entity-type-manager',
    component: EntityTypeManager,
  },
  {
    path: '/settings/users/edit',
    component: EditUserModal,
  },
  {
    path: '/settings/company/edit',
    component: EditCompanyModal,
  },
  {
    path: '/settings/roles/new',
    component: RoleModal,
    exact: true,
  },
  {
    path: '/settings/roles/:roleId/permission/new',
    component: PermissionsModal,
    exact: true,
  },
  {
    path: '/settings/roles/:roleId/permission/:permissionId',
    component: PermissionsModal,
  },
  {
    path: '/settings/roles/:roleId',
    component: RoleModal,
  },
  {
    path: '/settings/customers/edit',
    component: EditCustomerModal,
  },
  {
    path: '/settings/customers/new',
    component: NewCustomerModal,
    exact: true,
  },
  {
    path: '/settings/customers/modules',
    component: ModulesCustomerModal,
    exact: true,
  },
]
