import { StyledErrorPage } from "./styled";
import { ErrorPageStyled } from "./ErrorPageStyled";
import React, { ComponentPropsWithoutRef } from "react";
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button } from 'antd'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

const HomeButton = styled(Button)`
    padding: 4.5px 15px;
    font-family: poppins, sans-serif;
    font-weight: 400;

    background-color: rgb(232, 61, 115);
    color: rgb(255, 255, 255) !important;

    text-decoration: none;
    line-height: 21px;

    &:hover,
    &:focus {
        background-color: ${lighten(0.1, 'rgb(232, 61, 115)')} !important;
        border-color: ${lighten(0.1, 'rgb(232, 61, 115)')} !important;
        color: rgb(255, 255, 255);
    }

    &:active {
        background-color: ${darken(0.1, 'rgb(232, 61, 115)')} !important;
        border-color: ${darken(0.1, 'rgb(232, 61, 115)')} !important;
        color: rgb(255, 255, 255);
    }
`;

const SupportButton = styled(Button)`
    padding: 4.5px 15px;
    font-family: poppins, sans-serif;
    font-weight: 400;

    text-decoration: none;
    line-height: 21px;
    color: rgb(73, 73, 73)!important;
    outline-color: rgb(73, 73, 73);

    &:hover,
    &:focus {
        border-color: ${lighten(0.1, 'rgb(232, 61, 115)')} !important;
        color: rgb(120, 128, 194)!important;
    }

    &:active {
        border-color: ${darken(0.1, 'rgb(232, 61, 115)')} !important;
        color: rgb(120, 128, 194)!important;
    }
`;

const { ErrorPageTitle, Text } = StyledErrorPage;

type ErrorBoundaryContentProps = {
    error?: Error;
    onButtonClicked: () => void;
} & ComponentPropsWithoutRef<'div'>

const UnexpectedErrorPage = ({
    error,
    onButtonClicked,
    ...props
}: ErrorBoundaryContentProps) => {
    return (
        <ErrorPageStyled {...props}>
            <ErrorPageTitle>
                <FormattedMessage 
                    id='unexpectedError.title'
                    defaultMessage='Oops! Something went wrong.'
                />
            </ErrorPageTitle>
            <div style={{ height: '.5em' }} />
            <Text>
                <FormattedMessage 
                    id='unexpectedError.intro'
                    defaultMessage='We encountered an unexpected error and are unable to fulfill your request at this moment. We apologize for the inconvenience and appreciate your patience. Please try the following:'
                />
            </Text>

            <ul style={{padding: '1.2em'}}>
                <li>
                    <FormattedMessage 
                        id='unexpectedError.refreshPage'
                        defaultMessage='Refresh the page or try again later.'
                    />
                </li>
                <li>
                    <FormattedMessage 
                        id='unexpectedError.checkConnection'
                        defaultMessage='Check your internet connection and browser settings.'
                    />
                </li>
                <li>
                    <FormattedMessage 
                        id='unexpectedError.mistake'
                        defaultMessage='If you believe this is a mistake, please contact our support team using the link below.'
                    />
                </li>
            </ul>

            <Text>
                <FormattedMessage 
                    id='unexpectedError.thanksForUnderstanding'
                    defaultMessage='Thank you for your understanding.'
                />
            </Text>

            <div style={{ height: '.5em' }} />

            <div style={{ display: 'flex', gap: '.5rem'}}>
                <HomeButton
                    onClick={onButtonClicked}
                    href='/dashboards'
                    color='primary'
                >
                    <FormattedMessage 
                        id='unexpectedError.homeButton'
                        defaultMessage='Back to Overview'
                    />
                </HomeButton>
                <SupportButton
                    href='https://brightcanary.freshdesk.com/support/home'
                    target='_blank'
                    style={{margin: 0, color: 'color: rgba(0, 0, 0, 0.65)', }}
                >
                    <FormattedMessage 
                        id='unexpectedError.contactSupport'
                        defaultMessage='Contact Support'
                    />
                </SupportButton>
            </div>
        </ErrorPageStyled>
    )
}

export default injectIntl(UnexpectedErrorPage)
