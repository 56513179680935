import {
  configFieldConvertor,
  ghgTrendFieldConvertors,
} from '../../../Dashboard/utils/common/helpers'
import { config as historicalBarChart } from './../HistoricalBarChart/config'

const config = {
  ...historicalBarChart,
  editProps: {
    ...historicalBarChart.editProps,
    category: 'widget.ghgCharts',
    keywords: [...historicalBarChart.editProps.keywords, 'ghg'],
    default: {
      ...historicalBarChart.editProps.default,
      dataType: 'ghg',
      type: 'GhgHistoricalBarChart',
    },
    fields: configFieldConvertor(historicalBarChart.editProps.fields, ghgTrendFieldConvertors),
  },
}

export default config
