import { config as circleGauge } from './../CircleGauge/config'

const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    ...circleGauge.editProps,
    name: 'widget.efficiencyGauge',
    category: 'widget.gaugesIndicators',
    keywords: ['gauge', 'indicator', 'efficiency'],
    img: '/resources/images/widgets/efficiencygauge.png',
    info: {
      'fr-FR':
        "<h4>Jauge d'efficacité</h4>\n" +
        "<p>Le widget Efficacité affiche la valeur d'une mesure particulière sur une échelle déterminée. La valeur est représentée par une barre de couleur progressant autour d'un cercle, alors que l'échelle est déterminée par une autre valeur. Le widget affichera à la fois la valeur numérique de la mesure et la différence avec la valeur cible sous forme de pourcentage.</p>\n" +
        '<ul>\n' +
        '<li><strong>Label </strong> titre du widget</li>\n' +
        '<li><strong>Légende </strong> texte qui sera affiché dans la jauge</li>\n' +
        '<li><strong>Icône </strong> icône du widget</li>\n' +
        '<li><strong>Couleur </strong> sélectionnez une couleur globale pour le widget</li>\n' +
        '\n' +
        '<li><strong>Valeur de référence </strong> montant précédent qui sera utilisé pour comparer le montant actuel</li>\n' +
        '<li><strong>Unité </strong> Unité de la valeur de référence</li>\n' +
        '<li><strong>Unité de temps </strong> heure unitaire du widget</li>\n' +
        "<li><strong>Heure de début par défaut </strong> définissez l'heure de début par défaut du widget</li>\n" +
        '<li><strong>Points de données </strong> sélectionnez le widget de points de données</li>\n' +
        '</ul>',
      'en-US':
        '<h4>Efficiency Widget</h4>\n' +
        '<p>The Efficiency widget displays the value of a particular metric on a set scale. The value is represented by a color bar progressing around a circle, whereas the scale is determined by another value. The widget will display both the numeric value of the metric and the difference with the target value as a percentage.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's title</li>\n" +
        '<li><strong>Caption </strong> Text that will be displayed within the gauge</li>\n' +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        '<li><strong>Color </strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Reference Value </strong> Previous amount that will be used to compare againts the current amount</li>\n' +
        '<li><strong>Unit </strong> Unit of the reference value</li>\n' +
        '<li><strong>Time unit </strong> Unit time for the widget</li>\n' +
        '<li><strong>Default Start Time </strong> Define the default start time for the widget</li>\n' +
        '<li><strong>Datapoints </strong> Select widget datapoints</li>\n' +
        '</ul>',
      'nl-NL':
        '<h4>Efficiëntie Meter</h4>\n' +
        '<p>De Efficiëntie-widget geeft de waarde van een bepaalde metriek op een vaste schaal weer. De waarde wordt weergegeven door een kleurenbalk die rond een cirkel loopt, terwijl de schaal wordt bepaald door een andere waarde. De widget geeft zowel de numerieke waarde van de metrische waarde als het verschil met de doelwaarde als percentage weer.</p>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong> titel van widget</li>\n' +
        '<li><strong>Bijschrift </strong> tekst die in de meter wordt weergegeven</li>\n' +
        '<li><strong>Icoon </strong> het pictogram van de widget</li>\n' +
        '<li><strong>Kleur </strong> selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Referentiewaarde </strong> vorig bedrag dat zal worden gebruikt om het huidige bedrag te vergelijken</li>\n' +
        '<li><strong>Eenheid </strong> Eenheid van de referentiewaarde</li>\n' +
        '<li><strong>Tijdseenheid </strong> Eenheidstijd voor de widget</li>\n' +
        '<li><strong>Standaard starttijd </strong> definieer de standaard starttijd voor de widget</li>\n' +
        '<li><strong>Datapunten </strong> selecteer de datapuntenwidget</li>\n' +
        '</ul>',
    },
    default: {
      ...circleGauge.editProps.default,
      type: 'EfficiencyGauge',
      title: 'widget.efficiencyGauge',
      icon: 'fas fa-leaf',
    },
    fields: circleGauge.editProps.fields,
  },
}

export default config
