import { Tree } from 'antd'
import styled from 'styled-components'

const { DirectoryTree } = Tree

export const AntDirectoryTree = styled(DirectoryTree)`
  .ant-tree-treenode-selected:hover::before,
  .ant-tree-treenode-selected::before {
    background: var(--color-main) !important;
  }
`
