import { css } from 'styled-components'

export const smallButton = css`
  font-size: 0.9em;
  color: var(--color-mainText);
  background: var(--color-softStroke);
  border: 1px solid var(--color-softText);
  border-radius: 1rem;
  padding: 0 0.5rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  padding: 0 0.5rem;
  &:hover {
    background: var(--color-softerStroke);
    border: 1px solid var(--color-mainText);
  }
`
