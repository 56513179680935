import { transparentize } from 'polished'
import { css } from 'styled-components'

export const DashboardMenu = css`
  ${({ theme, isOpen, level, dropdownIsOpen }) => css`
    font-size: ${theme.screenSize.screen.current === 's' ? '1.2rem' : '1rem'};
    background: none;

    > .DropdownArrow {
      transform: rotate(${dropdownIsOpen ? '180deg' : '0deg'}) !important;
    }
    > .TreeItemWrapper {
      transition: background 0.2s ease;
    }

    ${dropdownIsOpen &&
    level === 0 &&
    css`
      background: ${transparentize(0.92, theme.color.sidebarText)};

      > .TreeItemWrapper {
        box-shadow: ${level === 0 && '0 0 20px rgba(0, 0, 0, 0.15)'};
        background: ${({ theme }) => transparentize(0.95, theme.color.sidebarText)};
      }
    `}
    .DropdownArrow {
      position: absolute;
      right: 0;
      left: auto;
      top: ${theme.screenSize.screen.current === 's' ? '1rem' : '.2rem'};
      width: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      height: ${theme.screenSize.screen.current === 's' ? '3rem' : '2rem'};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;
      z-index: 20;

      &:after {
        transition: opacity 0.2s ease;
        left: auto;
        top: auto;
        width: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
        height: ${theme.screenSize.screen.current === 's' ? '0.8rem' : '0.5rem'};
        border-color: var(--color-sidebarText) !important;
        opacity: 0.8;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    > .Button {
      padding: ${(isOpen || level === 0) &&
      (theme.screenSize.screen.current === 's'
        ? '1.8rem 1rem 1.8rem 2.5rem'
        : '1rem 1rem 1rem 2.5rem')};
      line-height: ${(isOpen || level === 0) && 1.2};
      z-index: 10;
    }
  `}
`
