import { useCallback, useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'

import { DATAPOINT_DETAIL_QUERY } from 'services/datapoints'

export const useDatapointHover = (props) => {
  const { delay = 2000 } = props || {}
  const timer = useRef()
  const [hoveredElement, setHoveredElement] = useState(null)
  const [datapointName, setDatapointName] = useState(null)
  const [getDetails, { data: detail }] = useLazyQuery(DATAPOINT_DETAIL_QUERY)
  const [errorData, setErrorData] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  const onDatapointHover = useCallback(
    (e, datapoint) => {
      if (datapoint) {
        if (datapoint.id === 'Error') {
          setErrorData(datapoint)
        } else {
          getDetails({
            variables: { datapoint: { id: datapoint?.id } },
            skip: !datapoint?.id,
          })
        }
      }
      setHoveredElement(datapoint?.id ? e.target : null)
      setDatapointName(datapoint?.name)

      if (!datapoint) {
        clearTimeout(timer.current)
        setErrorData(null)
      } else {
        if (delay > 0 && datapoint?.id) {
          timer.current = setTimeout(() => {
            setHoveredElement(null)
            setErrorData(null)
          }, delay)
        }
      }
    },
    [delay, getDetails]
  )
  return {
    detail: detail?.datapoint ?? errorData ?? { namedReferences: {}, name: datapointName },
    hoveredElement,
    onDatapointHover,
  }
}
