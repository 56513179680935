import React, { ComponentPropsWithoutRef, ElementType, useMemo, useState } from 'react'

import { COOKIE_NAMES_MAP, getCookie } from 'util/cookies'

import { CookieBanner } from './CookieBanner'
import { CookieContext } from './CookieContext'
import { Preferences } from 'contexts/Cookies/types'

const getCookiePreferences = () => {
  const cookieString = getCookie(COOKIE_NAMES_MAP.preferences)
  if (cookieString) {
    try {
      const cookieEntries = cookieString.split('-').map((v) => {
        const [key, val] = v.split(':')
        return [key?.toLowerCase(), val === '1']
      })
      return Object.fromEntries(cookieEntries)
    } catch (err) {}
  }
  return null
}

export const CookieProvider = <T extends ElementType>({
  children,
  props,
}: ComponentPropsWithoutRef<T>) => {
  const [preferences, setPreferences] = useState<Preferences>(getCookiePreferences())

  const value = useMemo(() => ({ preferences, setPreferences }), [preferences, setPreferences])

  return (
    <CookieContext.Provider value={value}>
      {children}
      <CookieBanner />
    </CookieContext.Provider>
  )
}
