import { css } from 'styled-components'

export const TimeSelection = css`
  background: var(--color-backgroundFront);
  box-shadow: var(--shadow-shadowElevation2);
  padding: 0 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  transform-origin: top right;
  z-index: 250;

  ${({ theme, width }) =>
    theme.screenSize.screen.current === 's'
      ? css`
          width: auto;
          left: 1rem;
          right: 1rem;
        `
      : css`
          > * {
            width: ${width > 300 ? '50%' : '100%'};
            min-width: ${width > 300 ? '50%' : '100%'};
            padding-right: ${width > 300 && '1rem'};

            &:last-child {
              padding-right: 0;
            }
          }
        `}
  .FormField {
    &.DatePicker {
      padding-right: 0;
    }

    &.DateRangeSelector {
      .Button {
        border: 1px solid var(--color-softStroke);
        border-radius: 2rem;
        &.active,
        &:hover {
          border-color: var(--color-main);
        }
      }
    }
  }
  .TimeSelection__field-container {
    > * {
      margin-bottom: 0.5rem;
    }
  }
  .HeatmapIntervalSelector {
    flex-wrap: wrap;
    padding: 0;
    > label {
      margin: auto;
      min-width: 100%;
      padding: 0.5rem;
      border-bottom: 1px solid var(--color-softStroke);
      border-right: none !important;
      text-align: center;
    }
    > .MultiFieldContainer {
      border-right: none !important;
    }
  }
  .OrganisationReportingDateRange {
    flex-direction: column;
    .ant-radio-button-wrapper {
      height: auto;
      line-height: unset;
      margin-bottom: 0.5rem;
    }
    .RangePicker {
      margin: 0;
    }
  }
  .TimeSelection__button-container {
    width: calc(100% + 2rem);
    padding: 1rem 1rem 0;
    margin: 1rem -1rem 0;
    border-top: 1px solid var(--color-softStroke);
    display: flex;
    justify-content: flex-end;
  }
`
