import styled from 'styled-components'

const AgGridProgressCellStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  .ant-progress-text {
    color: var(--color-softText) !important;
  }
`

export default AgGridProgressCellStyled
