import { css } from 'styled-components'

export const iconListButton = css`
  font-size: 0.9em;
  color: var(--color-mainText);
  transition: color 0.2s ease, transform 0.2s ease;
  padding: 0 0.5rem;
  &:hover {
    color: var(--color-action);
    transform: scale(1.2);
  }
`
