import styled from 'styled-components'

import DndMapperStyled from 'components/Form/components/DndMapper/styled'
import { Label, Value } from 'components/Form/components/Field/styled'

import Field from '../../components/Field'
// import CsvMapper from 'components/Form/components/CsvMapper/CsvMapper'

const CsvMappingFieldStyled = styled(Field)`
  padding: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;

  ${Value} {
    display: flex;
    align-items: center;

    > div {
      border-radius: 0.3rem !important;

      span {
        font-size: var(--font-size-m);
        letter-spacing: var(--font-spacing-m);
        line-height: 1;
        color: var(--color-softText);
      }
    }
    ${DndMapperStyled} {
      margin: 3rem 0 0 -51%;
      width: auto;
    }
  }

  ${Label} {
    font-size: 1rem;
    margin: 0 0.5rem auto 0;
  }
`
export default CsvMappingFieldStyled
