import React, { useEffect, useMemo, useRef, useState } from 'react'

import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Card, Col, Input, message, Row, Select, Tabs, Tooltip, Tree } from 'antd'
import { injectIntl, intlShape } from 'react-intl'
import styled from 'styled-components'

import { DASHBOARDS_ID_QUERY } from 'services/dashboards'
import { ACCOUNT_BY_EMAIL_QUERY } from 'services/user'

import { handleError } from 'components/Dashboard/functions'
import FormComponent from 'components/Global/Form'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'

const { TabPane } = Tabs
const { Search } = Input

const disableChildren = (db) => {
  return {
    ...db,
    children: db.children.map((child) => {
      return disableChildren({ ...child, disabled: true })
    }),
  }
}

const StyledRow = styled(Row)`
  height: 100%;
  flex-wrap: nowrap;

  .ant-tabs {
    padding-top: 1rem;
    height: 100%;

    &-nav {
      margin-bottom: 0 !important;

      &:before {
        content: none !important;
      }
    }

    &-nav-wrap {
      overflow: visible !important;
      z-index: 1;
      margin-bottom: -1px;
    }

    &-tab {
      background: none !important;
      opacity: 0.5;
    }

    &-tab-active {
      box-shadow: 2px 10px 17px rgb(0 0 0 / 16%);
      opacity: 1;
    }

    &-content {
      height: 100%;
      overflow: hidden;
      margin-top: 0;
      .ant-tabs-tabpane {
        height: 100%;
        overflow: hidden;
      }
    }
  }
`

const StyledCol = styled(Col)`
  height: 100%;
  flex-grow: 1;

  &:first-child {
    padding: 0 1rem;
  }

  &:nth-child(2) {
    padding-top: 4rem;
  }
`

const StyledCard = styled(Card)`
  height: 100%;

  .StyledCard__title {
    font-weight: 600;
  }

  .ant-card-body {
    position: relative;
    z-index: 10;
    background: #ffffff;

    > div,
    > span,
    > form {
      margin-bottom: 1rem;
    }
  }

  .ant-form {
    height: auto;
  }
`

const ALLOWED_RECIPIENTS = gql`
  query allowedRecipients {
    allowedRecipients {
      id
      email
      name
    }
  }
`

const ShareDashboard = ({ intl, form }) => {
  const [checkMailAddress, { data: manualRecipient }] = useLazyQuery(ACCOUNT_BY_EMAIL_QUERY)
  const [currentTab, setCurrentTab] = useState('1')
  const [manualRecipients, setManualRecipients] = useState([])

  const { data, loading, error } = useQuery(DASHBOARDS_ID_QUERY, {
    variables: { omitMasterDashboardsFromOthers: true }, // Was true - sc-12942
    fetchPolicy: 'network-only',
  })

  const { data: userData, loading: usersLoading, error: usersError } = useQuery(ALLOWED_RECIPIENTS)
  const [checkedKeys, setCheckedKeys] = useState({ checked: [], halfChecked: [] })
  const treeRef = useRef()
  const permissionListRef = useRef()
  const [permissionList, setPermissionList] = useState()

  const onCheckMailAddress = (value) => {
    if (value !== '') {
      checkMailAddress({ variables: { email: value }, fetchPolicy: 'network-only' })
    }
  }

  useEffect(() => {
    if (manualRecipient) {
      if (manualRecipient.accountByEmail) {
        setManualRecipients([manualRecipient.accountByEmail, ...manualRecipients])
        const temp = form.getFieldsValue().recipients || []
        temp.push(manualRecipient.accountByEmail.id)
        form.setFieldsValue({ recipients: temp })
        message.success({
          content: intl.formatMessage({ id: 'settings.dashboards.mailAdded' }),
        })
      } else {
        message.error({
          content: intl.formatMessage({ id: 'settings.dashboards.unknownMail' }),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualRecipient])

  const containsAllAndOnly = (dashboards, rights) => {
    if (dashboards.findIndex((db) => !rights.includes(db.masterDashboardRights)) > -1) {
      return false
    }
    for (let i = 0; i < rights.length; i++) {
      if (dashboards.findIndex((db) => db.masterDashboardRights === rights[i]) === -1) {
        return false
      }
    }
    return true
  }

  const contains = (dashboards, rights) => {
    for (let i = 0; i < rights.length; i++) {
      if (dashboards.findIndex((db) => db.masterDashboardRights === rights[i]) > -1) {
        return true
      }
    }
    return false
  }

  const containsCombo = (dashboards, rights) => {
    for (let i = 0; i < rights.length; i++) {
      if (dashboards.findIndex((db) => db.masterDashboardRights === rights[i]) === -1) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (data?.sharableDashboards) {
      const selectedDashboards = data.sharableDashboards.filter(
        (db) => checkedKeys.checked.includes(db.id) || checkedKeys.halfChecked.includes(db.id)
      )
      if (contains(selectedDashboards, [0])) {
        permissionListRef.current = [
          { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
        ]
      } else {
        if (
          containsAllAndOnly(selectedDashboards, [4]) ||
          containsAllAndOnly(selectedDashboards, [2, 4])
        ) {
          permissionListRef.current = [
            { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
            { value: 4, label: intl.formatMessage({ id: 'settings.dashboards.visitorWithShare' }) },
          ]
        } else {
          if (containsCombo(selectedDashboards, [1, 4])) {
            permissionListRef.current = [
              { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
            ]
          } else {
            if (
              containsAllAndOnly(selectedDashboards, [1]) ||
              containsAllAndOnly(selectedDashboards, [1, 2])
            ) {
              permissionListRef.current = [
                { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
                { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
              ]
            } else {
              permissionListRef.current = [
                { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
                {
                  value: 4,
                  label: intl.formatMessage({ id: 'settings.dashboards.visitorWithShare' }),
                },
                { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
                { value: 2, label: intl.formatMessage({ id: 'settings.dashboards.owner' }) },
              ]
            }
          }
        }
      } /* let forbiddenDashboards = data.sharableDashboards.filter(
        (db) =>
          (checkedKeys.checked.includes(db.id) || checkedKeys.halfChecked.includes(db.id)) &&
          db.masterDashboardRights < 1
      )
      if (forbiddenDashboards.length > 0) {
        permissionListRef.current = [
          { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
        ]
      } else {
        forbiddenDashboards = data.sharableDashboards.filter(
          (db) =>
            (checkedKeys.checked.includes(db.id) || checkedKeys.halfChecked.includes(db.id)) &&
            db.masterDashboardRights < 2
        )
        if (forbiddenDashboards.length > 0) {
          permissionListRef.current = [
            { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
            { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
          ]
        } else {
          permissionListRef.current = [
            { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
            { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
            { value: 2, label: intl.formatMessage({ id: 'settings.dashboards.owner' }) },
          ]
        }
      } */ // Allow visitors & editors to share MDBs - sc-12942

      /* const forbiddenDashboards = data.sharableDashboards.filter(
        (db) =>
          (checkedKeys.checked.includes(db.id) || checkedKeys.halfChecked.includes(db.id)) &&
          ![2, 4].includes(db.masterDashboardRights) // < 2
      )
      if (forbiddenDashboards.length > 0) {
        permissionListRef.current = [
          { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
          { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
        ]
      } else {
        permissionListRef.current = [
          { value: 0, label: intl.formatMessage({ id: 'settings.dashboards.visitor' }) },
          { value: 4, label: intl.formatMessage({ id: 'settings.dashboards.visitorWithShare' }) },
          { value: 1, label: intl.formatMessage({ id: 'settings.dashboards.editor' }) },
          { value: 2, label: intl.formatMessage({ id: 'settings.dashboards.owner' }) },
        ]
      } */ setPermissionList(
        permissionListRef.current
      )
    }
  }, [checkedKeys, data, intl])

  const changeTab = (key) => {
    setCurrentTab(key)
    setCheckedKeys({ checked: [], halfChecked: [] })
  }

  const userList = useMemo(() => {
    if (!userData) {
      return []
    }
    return userData.allowedRecipients
      .map(({ id, name, email }) => ({
        value: id,
        label: email ? `${name} (${email})` : name,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  }, [userData])

  const dashboardList = useMemo(() => {
    if (!data) {
      return []
    }

    return data?.sharableDashboards?.map((db) => ({
      value: db.id,
      label: db.id,
      ...db,
    }))
  }, [data])

  const masterDashboardList = dashboardList
    // ?.filter((dashboard) => dashboard.readOnly && dashboard.masterDashboardRights > 0) -- sc-12942
    ?.filter((dashboard) => dashboard.readOnly)
    .map((db) => {
      return disableChildren(db)
    })

  const conventionalDashboardList = dashboardList
    ?.filter((dashboard) => !dashboard.readOnly)
    .map((db) => db)

  if (!data || !userData || error || usersError || loading || usersLoading) {
    const loaderProps =
      loading || usersLoading
        ? { spinner: { type: 'lineScale', size: 20 } }
        : handleError(error || usersError, !data || !userData, intl)
    return <WidgetLoader {...loaderProps} loading={loading || usersLoading} />
  }

  const getAllChildKeys = (node) => {
    if (node.children) {
      return node.children.flatMap((child) => {
        if (child.children) {
          return [child.key, ...getAllChildKeys(child)]
        }
        return [child.key]
      })
    }
    return []
  }

  const getAllParentKeys = (node) => {
    const parent = treeRef.current.state?.keyEntities[node.key]?.parent
    if (parent) {
      return [parent.key, ...getAllParentKeys(parent)]
    }
    return []
  }

  const getParentsToCheck = (key) => {
    const parent = treeRef.current.state.keyEntities[key]?.parent
    if (parent) {
      const uncheckedChildren = parent.children.filter(
        (child) => !checkedKeys.checked.includes(child.key) && key !== child.key
      )
      if (!uncheckedChildren.length) {
        return [parent.key, ...getParentsToCheck(parent.key)]
      }
    }
    return []
  }

  const handleCheck = (_, { node, node: { key, checked, halfChecked, children } }) => {
    const childKeys = getAllChildKeys(node)
    const parentKeys = getAllParentKeys(node)

    // if not checked nor half checked, check the node and check all its children
    // make parents above fully checked when all children below are checked
    if (!checked && !halfChecked) {
      const halfCheckedParentKeys = checkedKeys.halfChecked.filter((key) =>
        parentKeys.includes(key)
      )

      if (halfCheckedParentKeys.length) {
        const parentKeysToCheck = getParentsToCheck(node.key).filter((parent) =>
          halfCheckedParentKeys.includes(parent)
        )
        const checked = [key, ...parentKeysToCheck, ...childKeys, ...checkedKeys.checked]
        const halfChecked = [
          ...checkedKeys.halfChecked.filter((key) => !parentKeysToCheck.includes(key)),
        ]

        setCheckedKeys({ checked, halfChecked })
        form.setFieldsValue({ dashboards: [...checked, ...halfChecked] })
      } else {
        const checked = [key, ...childKeys, ...checkedKeys.checked]
        const halfChecked = checkedKeys.halfChecked

        setCheckedKeys({ checked, halfChecked })
        form.setFieldsValue({ dashboards: [...checked, ...halfChecked] })
      }
    }
    // if already checked (and so not halfChecked), halfCheck the node and uncheck all of its children.
    // if any parent nodes are checked, make halfchecked to show not all children are checked
    // OR uncheck fully if node doesn't have children
    else if (checked && !halfChecked) {
      const checkedParentKeys = checkedKeys.checked.filter((key) => parentKeys.includes(key))

      if (children.length) {
        const checked = checkedKeys.checked.filter(
          (checkedKey) =>
            !childKeys.includes(checkedKey) &&
            checkedKey !== key &&
            !checkedParentKeys.includes(checkedKey)
        )
        const halfChecked = [
          // key,
          ...checkedParentKeys,
          ...checkedKeys.halfChecked.filter(
            (checkedKey) => !childKeys.includes(checkedKey) && checkedKey !== key
          ),
        ]

        setCheckedKeys({ checked, halfChecked })
        form.setFieldsValue({ dashboards: [...checked, ...halfChecked] })
      } else {
        const checked = checkedKeys.checked.filter(
          (checkedKey) =>
            !childKeys.includes(checkedKey) &&
            checkedKey !== key &&
            !checkedParentKeys.includes(checkedKey)
        )
        const halfChecked = [
          ...checkedParentKeys,
          ...checkedKeys.halfChecked.filter(
            (checkedKey) => !childKeys.includes(checkedKey) && checkedKey !== key
          ),
        ]

        setCheckedKeys({ checked, halfChecked })
        form.setFieldsValue({ dashboards: [...checked, ...halfChecked] })
      }
    }
    // if not checked but halfChecked, uncheck the node
    else if (!checked && halfChecked) {
      const checked = checkedKeys.checked
      const halfChecked = checkedKeys.halfChecked.filter((checkedKey) => checkedKey !== key)

      setCheckedKeys({ checked, halfChecked })
      form.setFieldsValue({ dashboards: [...checked, ...halfChecked] })
    }
  }

  return (
    <StyledRow>
      <StyledCol span={10}>
        <Tabs onChange={changeTab} type="card">
          <TabPane
            tab={
              <Tooltip title={intl.formatMessage({ id: 'settings.dashboards.conventionalHint' })}>
                {intl.formatMessage({ id: 'settings.dashboards.conventional' })}
              </Tooltip>
            }
            key="1"
          >
            <StyledCard
              bodyStyle={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <p className="StyledCard__title">
                {intl.formatMessage({ id: 'shareDashboard.selectDashboards' })}
              </p>
              <Tree
                ref={treeRef}
                checkable
                treeData={conventionalDashboardList || []}
                checkStrictly
                onCheck={handleCheck}
                checkedKeys={checkedKeys}
              />
            </StyledCard>
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={intl.formatMessage({ id: 'settings.dashboards.masterHint' })}>
                {intl.formatMessage({ id: 'settings.dashboards.master' })}
              </Tooltip>
            }
            key="2"
          >
            <StyledCard
              bodyStyle={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <p className="StyledCard__title">
                {intl.formatMessage({ id: 'shareDashboard.selectDashboards' })}
              </p>
              <Tree
                ref={treeRef}
                checkable
                treeData={masterDashboardList || []}
                onCheck={handleCheck}
                checkedKeys={checkedKeys}
              />
            </StyledCard>
          </TabPane>
        </Tabs>
      </StyledCol>
      <StyledCol span={14}>
        <StyledCard
          bodyStyle={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <p className="StyledCard__title">
            {intl.formatMessage({ id: 'shareDashboard.shareWith' })}
          </p>
          <div className="ant-row ant-form-item" style={{ width: '100%' }}>
            <div className="ant-col ant-col-10 ant-form-item-label">
              <label
                title="Manual addresses"
                style={{
                  color: '#8a8a8a',
                  fontSize: '0.8rem',
                  fontWeight: 400,
                  textTransform: 'uppercase',
                }}
              >
                {intl.formatMessage({ id: 'shareDashboard.usersByMailAddress' })}
              </label>
            </div>
            <div className="ant-col ant-col-14 ant-form-item-control">
              <Search
                placeholder={intl.formatMessage({ id: 'settings.dashboards.addManually' })}
                enterButton=" + " // {intl.formatMessage({ id: 'settings.dashboards.addButton' })}
                allowClear
                onSearch={onCheckMailAddress}
              />
            </div>
          </div>
          <Select
            mode="tags"
            placeholder={intl.formatMessage({ id: 'settings.dashboards.pleaseSelect' })}
            disabled
            allowClear
            value={manualRecipients.map((rec) => rec.email)}
            style={{ width: '100%' }}
          />
          <FormComponent
            form={form}
            layout="horizontal"
            initialValues={{
              allowEdit: true,
            }}
            fields={[
              {
                type: 'select',
                name: 'recipients',
                mode: 'multiple',
                required: true,
                label: intl.formatMessage({
                  id: 'shareDashboard.knownUsers' /* 'shareDashboard.shareWith' */,
                }),
                options: userList,
              },
              {
                type: 'select',
                name: 'permissionLevel',
                required: currentTab === '2',
                label: intl.formatMessage({ id: 'shareDashboard.permissionLevel' }),
                options: permissionList,
                hidden: currentTab === '1',
              },
              {
                type: 'select',
                name: 'dashboards',
                mode: 'multiple',
                hidden: true,
                options: dashboardList,
              },
            ]}
          />
        </StyledCard>
      </StyledCol>
    </StyledRow>
  )
}

ShareDashboard.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ShareDashboard)
