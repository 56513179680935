const getDashboardMenuTreeItem = (
  { title, key, id, isFavourite, children, ...dashboard },
  dashboardsMenu,
  favourites,
  location
) => {
  if (isFavourite) {
    favourites.push({
      ...dashboard,
      name: title,
      icon: 'lnr lnr-star',
      url: `/dashboard/${key || id}`,
      isActive: location.pathname === `/dashboard/${key || id}`,
    })
  }
  dashboardsMenu.push({
    ...dashboard,
    title,
    key,
    id,
    url: `/dashboard/${key || id}`,
    children: children?.length
      ? getDashboardMenu(children, location, [], favourites).dashboards
      : [],
  })
}

export const getDashboardMenu = (dashboards, location, dashboardsMenu = [], favourites = []) => {
  return dashboards.reduce(
    (menu, dashboard) => {
      getDashboardMenuTreeItem(dashboard, dashboardsMenu, menu.favourites, location)
      return menu
    },
    { dashboards: dashboardsMenu, favourites }
  )
}
