import styled from 'styled-components'

import Ui from 'components/Widgets/Gauge/Ui'

export const CircleGaugeUi = styled(Ui)`
  .Caption {
    position: relative;
    margin-bottom: 0;
  }

  &.EfficiencyGauge {
    .data-label-container {
      &:nth-child(2) {
        .CircleGauge__caption-text {
          color: var(--color-strongText);
          font-weight: 600;
        }
      }
      .data-label {
        &:nth-child(1) {
          font-size: 0.7rem;

          .value {
            font-size: 1rem;
          }
        }

        &:nth-child(2) {
          font-size: 1.2rem;

          .CircleGauge__caption-text {
            font-weight: 600;
          }

          .value {
            font-size: 2rem;
          }
        }

        svg {
          font-size: 1.5rem;
        }
      }

      svg {
        path {
          fill: var(--color-success);
        }
      }
    }
  }

  .data-labels {
    top: -50px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .CircleGauge__caption-text {
      text-transform: uppercase;
      color: var(--color-softText);
      font-weight: 300;
    }
  }

  .data-label-container {
    text-align: center;

    &:nth-child(2) {
      border-top: 1px solid var(--color-softStroke);
    }

    svg {
      color: ${({ color }) => color || 'var(--color-main)'};

      path {
        fill: ${({ color }) => color || 'var(--color-main)'};
      }
    }
  }
`
