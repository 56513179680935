import React from 'react'

import { AgGridReact } from '@ag-grid-community/react'
import { PropTypes } from 'prop-types'

import { sortAlphabetically } from 'util/agGrid'

import { ContentLoader, ContentNoData } from 'components/Layout/Content'

import CompactCell from './components/CompactCell'
import { AgGridMaterial } from './styled'

export const Table = ({
  children,
  compactMode,
  frameworkComponents = {},
  width,
  verticalHeaders = false,
  headerHeight,
  tableHeight,
  defaultColDef,
  rowHeight = 50,
  ...agGridProps
}) => {
  return (
    <AgGridMaterial
      className="ag-theme-material"
      compactMode={compactMode}
      width={width}
      verticalHeaders={verticalHeaders}
      headerHeight={headerHeight}
      tableHeight={tableHeight}
    >
      <AgGridReact
        getRowNodeId={(row) => row.id}
        frameworkComponents={{
          customLoadingOverlay: ContentLoader,
          customNoRowsOverlay: ContentNoData,
          compact: CompactCell,
          ...frameworkComponents,
        }}
        loadingOverlayComponent={'customLoadingOverlay'}
        noRowsOverlayComponent={'customNoRowsOverlay'}
        rowHeight={rowHeight}
        rowSelection="single"
        animateRows
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: 'agSetColumnFilter',
          floatingFilter: true,
          flex: 2,
          comparator: sortAlphabetically,
          ...defaultColDef,
        }}
        {...agGridProps}
      >
        {children}
      </AgGridReact>
    </AgGridMaterial>
  )
}

Table.propTypes = {
  compactMode: PropTypes.bool,
}

Table.defaultProps = {
  compactMode: false,
}

export default Table
