import { css } from 'styled-components'

export const Navigation = css`
  transition: box-shadow 0.2s ease, color 0.2s ease, border-color 0.2s ease, background 0.2s ease;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  .Icon {
    margin: 0 0.5rem;
  }
  ${({ isActive }) =>
    isActive
      ? css`
          background: var(--color-main);
          border: 1px solid var(--color-main);
          color: var(--color-white);
          &:hover {
            box-shadow: var(--shadow-shadowElevation3);
            background: var(--color-white);
            color: var(--color-main);
          }
        `
      : css`
          border: 1px solid var(--color-main);
          color: var(--color-main);
          &:hover {
            box-shadow: var(--shadow-shadowElevation3);
            border-color: var(--color-main);
          }
        `}
`
