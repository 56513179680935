import { gql, useLazyQuery, useQuery } from '@apollo/client'

import { systemTags } from './entities'

export const ENTITY_PRESET_LIST = gql`
  query entityPresets($type: String) {
    entityPresets(type: $type) {
      id
      entityType {
        id
        name
      }
      translations {
        language {
          code
        }
        translation
      }
    }
  }
`

export const ENTITY_PRESET_LIST_LANGUAGE = gql`
  query entityPresets($type: String, $language: String!) {
    entityPresets(type: $type) {
      id
      entityType {
        id
        name
      }
      name(language: $language)
    }
  }
`

export const ENTITY_PRESET = gql`
  query entityPreset($id: Float!) {
    entityPreset(id: $id) {
      id
      entityType {
        id
        name
      }
      tags {
        id
        tagType
        tagName
        required
      }
      translations {
        id
        language {
          name
          code
        }
        translation
      }
      childPresets {
        id
        translations {
          id
          language {
            name
            code
          }
          translation
        }
        tags {
          id
          tagType
          tagName
          required
        }
      }
    }
  }
`

const ENTITY_CREATE_ENTITY_PRESET = gql`
  query entityPreset($id: Float!, $language: String!, $parentEntityId: ID) {
    entityPreset(id: $id) {
      id
      entityType {
        id
        name
      }
      tags(parentEntityId: $parentEntityId) {
        id
        tagName
        defaultValue
        required
        type: featureType {
          id
          name
        }
      }
      name(language: $language)
      childPresets {
        id
        name(language: $language)
        required
        defaultEnabled
        unit {
          id
          name
        }
        unitDisabled
        tags {
          id
          tagName
          defaultValue
          required
          type: featureType {
            id
            name
          }
        }
      }
    }
  }
`

export const PRESET_DATAPOINTS = gql`
  query entityPreset($id: Float!) {
    entityPreset(id: $id) {
      id
      childPresets {
        id
        translations {
          id
          language {
            name
            code
          }
          translation
        }
        tags {
          id
          tagType
          tagName
          required
        }
      }
    }
  }
`

export const UPSERT_ENTITY_PRESET_MUTATION = gql`
  mutation upsertEntityPreset($data: EntityPresetInput!) {
    upsertEntityPreset(data: $data) {
      success
      message
      object {
        id
      }
    }
  }
`

export const DELETE_ENTITY_PRESET = gql`
  mutation deleteEntityPreset($id: Float!) {
    deleteEntityPreset(id: $id) {
      success
      message
    }
  }
`
export const useEntityPresetList = (variables = {}, skip = false) => {
  const { data = {}, error, loading, refetch } = useQuery(ENTITY_PRESET_LIST, { variables, skip })
  if (error || loading) {
    return { error, loading }
  }
  return { data: data.entityPresets, refetch }
}

export const useEntityPreset = (id, skip = false) => {
  const { data, error, loading, refetch } = useQuery(ENTITY_PRESET, {
    variables: { id },
    skip,
    fetchPolicy: 'network-only',
  })
  if (error || loading) {
    return { error, loading }
  }
  return { data: data.entityPreset, refetch }
}

const extractTagsAndMarkers = (tags, childTags) => {
  return tags.reduce(
    (lists, tag) => {
      const isSystem = tag.tagName.substr(-3) === 'Ref' || systemTags.includes(tag.tagName)

      if (tag.type.name === 'marker') {
        lists.markers.push({
          ...tag,
          name: tag.tagName,
          enabled: !childTags && tag.required,
          isSystem,
        })
      } else {
        lists.tags.push({
          ...tag,
          name: tag.tagName,
          enabled: !childTags && tag.required,
          isSystem,
        })
      }
      return lists
    },
    { tags: [], markers: [] }
  )
}

export const useEntityCreateEntityPreset = () => {
  const [getPreset, { data, error, loading }] = useLazyQuery(ENTITY_CREATE_ENTITY_PRESET, {
    fetchPolicy: 'network-only',
  })
  if (error || loading || !data) {
    return [getPreset, { error, loading }]
  }

  const entityTags = extractTagsAndMarkers(data.entityPreset.tags)
  return [
    getPreset,
    {
      data: {
        ...data.entityPreset,
        ...entityTags,
        preset: {
          id: data.entityPreset.id,
        },
        datapoints: data.entityPreset.childPresets.map((datapoint) => {
          const datapointTags = extractTagsAndMarkers(datapoint.tags, true)
          return {
            ...datapoint,
            enabled: datapoint.required || datapoint.defaultEnabled,
            required: datapoint.required,
            preset: {
              id: datapoint.id,
            },
            ...datapointTags,
          }
        }),
      },
    },
  ]
}
