import React, { useCallback, useState } from 'react'

import { AnimatePresence } from 'framer-motion'
import { injectIntl } from 'react-intl'

import { FieldGroupTitle } from 'components/Form/components/FieldGroup/styled'
import FileClassificationField from 'components/Form/fields/FileClassificationField/FileClassificationField'
import TextAreaField from 'components/Form/fields/TextAreaField/TextAreaField'
import Icon from 'components/Global/Icon'

import {
  UploadAndRemarksFieldContent,
  UploadAndRemarksFieldHeader,
  UploadAndRemarksFieldStyled,
} from './styled'

const variants = {
  visible: { height: 'auto', opacity: 1 },
  hidden: { height: 0, opacity: 0, },
}

const UploadAndRemarksField = ({ value, name, filter, intl, ...fieldProps }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleHeaderClick = useCallback(() => setIsOpen((state) => !state), [setIsOpen])

  return (
    <UploadAndRemarksFieldStyled {...fieldProps}>
      <UploadAndRemarksFieldHeader
        onClick={handleHeaderClick}
        className={`${isOpen ? 'active' : 'inactive'}`}
      >
        <FieldGroupTitle>{intl.formatMessage({ id: 'settings.filesRemarks' })}</FieldGroupTitle>
        <Icon icon={isOpen ? 'lnr lnr-circle-minus' : 'lnr lnr-plus-circle'} />
      </UploadAndRemarksFieldHeader>

      <AnimatePresence>
        {isOpen && (
          <UploadAndRemarksFieldContent
            key='wrapper'
            initial='hidden'
            animate='visible'
            exit='hidden'
            large
            transition={{type: 'ease'}}
            variants={variants}
          >
            <div>
              <FileClassificationField name="files" />
              <TextAreaField name="remark" label={intl.formatMessage({ id: 'remarks' })} />
            </div>
          </UploadAndRemarksFieldContent>
        )}
      </AnimatePresence>      
    </UploadAndRemarksFieldStyled>
  )
}

export default injectIntl(UploadAndRemarksField)
