import styled from 'styled-components'

const ProgressStyled = styled.div`
  display: flex;
  position: relative;
  padding-top: 0.5rem;
  width: ${({ size }) =>
    ({
      small: '100px',
      large: '200px',
      full: 'auto',
    }[size])};

  .ant-progress-outer {
    margin-right: 0;
    padding-right: 5px;
  }

  .ant-progress-text {
    position: absolute;
    font-size: 0.7rem;
    top: -7px;
    color: var(--color-mainText);
    left: ${({ percentage }) => percentage}%;
    margin-left: ${({ percentage }) => (percentage > 10 ? '-15px' : 0)};
  }
  .Progress__value {
    margin-left: 0.5rem;
  }
`
export default ProgressStyled
