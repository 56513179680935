import styled from 'styled-components'

const ColumnsStyled = styled.div`
  overflow: auto;

  > h4 {
    margin-bottom: 0.5rem;
  }

  .Datapoints__buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .smallButton {
    margin: 0.5rem 0.5rem 0 0;
  }
`

export default ColumnsStyled
