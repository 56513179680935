import React, { useCallback, useMemo, useReducer, useState } from 'react'

import { ConfigProvider, message } from 'antd'
import { ThemeProvider } from 'styled-components'
import { getTheme } from 'theme/hooks'

import { CookieProvider } from 'contexts/Cookies/CookieProvider'

import Button from 'components/Global/Button'
import { useWindowSize } from 'components/Global/hooks'
import Icon from 'components/Global/Icon'
import Modal from 'components/Layout/UiProvider/Modal'

import reducer, { initialState } from './reducer'
import { UiContext } from './UiContext'

export { UiContext, useUi } from './UiContext'

const UiProvider = ({ children, theme }) => {
  const [mode, setMode] = useState(localStorage.getItem('mode') === 'dark')
  const aa = useMemo(() => getTheme(mode), [mode])

  const breakpoints = {
    s: 0,
    m: 600,
    l: 960,
  }
  const screenSize = useWindowSize(breakpoints)
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    darkMode: mode,
  })

  const action = useCallback(
    (action) => {
      switch (action.type) {
        case 'SET_NOTIFICATION':
          const { type = 'info', text, ...messageProps } = action.payload.message
          message[type]({
            ...messageProps,
            icon: <Icon icon="fas fa-info" variant="notification" color={theme.color[type]} />,
            content: (
              <>
                {text}
                <Button label="test" variant="smallButton" />
              </>
            ),
          })
          break
        case 'TOGGLE_DARK_MODE':
          setMode((v) => !v)
          break
        default:
      }
      dispatch(action)
    },
    [theme]
  )

  const antDTheme = useMemo(() => {
    return {
      token: {
        colorPrimary: aa.color.main,
        fontFamily: aa.font.primary,
        colorText: aa.color.mainText,
        colorLink: aa.color.softText,
        colorLinkHover: aa.color.main,
        fontSize: 13,
      },
    }
  }, [aa])

  const toggleMode = useCallback(() => {
    setMode((v) => {
      const isDarkMode = !v
      localStorage.setItem('mode', isDarkMode ? 'dark' : 'light')
      return isDarkMode
    })
  }, [])

  return (
    <UiContext.Provider value={{ state, action, mode, toggleMode }}>
      <ThemeProvider
        theme={{
          ...aa,
          screenSize,
        }}
      >
        <CookieProvider>
          <ConfigProvider theme={antDTheme}>{children}</ConfigProvider>
          <Modal />
        </CookieProvider>
      </ThemeProvider>
    </UiContext.Provider>
  )
}

export default UiProvider
