import React, { useEffect, useRef, useState } from 'react'

import { faCookieBite } from '@fortawesome/pro-solid-svg-icons/faCookieBite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { COOKIE_NAMES_MAP, setCookie } from 'util/cookies'

import { useCookiePreferences } from './CookieContext'
import { CookieDialog } from './Dialog'
import { Anchor, ButtonPrimary, ButtonSecondary } from './styledComponents'
import { isPosthogEnabled } from 'contexts/Cookies/postHogConfig'

declare global {
  interface Window {
    posthog: any
  }
}

const Container = styled.div`
  /* Flow */

  /* Position */
  z-index: 1000;
  position: fixed;
  bottom: 0;

  /* Typography */
  font-size: 16px;
  line-height: 1.2;
  color: var(--color-mainText, black);
  box-shadow: var(--shadow-panelElevationBottom);

  /* Size */
  width: 100%;

  /* Styling */
  background-color: var(--color-backgroundFront, white);
  box-shadow: '0px -2px 10px rgba(0, 0, 0, 0.3)';
`

const ManageCookiesFlag = styled.div`
  /* Flow */

  /* Position */
  z-index: 1000;
  position: fixed;
  bottom: 0;
  right: 5%;
  transform: translateY(60%);
  transition: all 0.3s;

  &:hover,
  &:focus-visible,
  &:focus-within,
  &.open {
    transform: translateY(0);
  }

  /* Typography */
  font-size: 1rem;
  line-height: 1.2;
  color: var(--color-mainText, black);
  box-shadow: var(--shadow-panelElevationBottom);

  /* Size */
  width: fit-content;

  /* Styling */
  cursor: pointer;
  padding: 6px 12px 8px 12px;
  border-radius: 16px 16px 0 0;
  background-color: var(--color-backgroundFront, white);
  box-shadow: '0px -2px 10px rgba(0, 0, 0, 0.3)';
`

const Text = styled.div`
  line-height: 1.2;
  font-size: 0.923rem;
  overflow-wrap: break-word;
`

const ButtonsGroup = styled.div`
  display: block;
  margin: 0 5%;
  position: relative;
  align-self: center;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  overflow-wrap: break-word;
  unicode-bidi: isolate;

  @media only screen and (min-width: 960px) {
    display: inline-block;
    min-width: fit-content;
  }
`

const Flex = styled.div`
  display: flex;
`

const Block = styled.div`
  display: block;
  padding-left: 5%;
  padding-top: 8px;
  padding-bottom: 8px;
`

const IconContainer = styled.div`
  padding: 10px 12px;
  font-size: 16px;
`

const ContentContainer = styled.div`
  line-height: 1;
  font-size: 13px;
`

const P = styled.p`
  line-height: 1.2;
  font-size: 0.923rem;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-style: 0;
`

const ManageCookieButton = styled.button`
  all: unset;

  &:focus-visible {
    outline: 1px solid var(--color-action);
  }
`

export const CookieBanner = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isRecording = useRef(false)
  const { preferences, setPreferences } = useCookiePreferences()

  useEffect(() => {
    if (window.posthog && isPosthogEnabled) {
      if (!isRecording.current) {
        window.posthog.init('phc_Unh4tuzt68aKZq4Okl1one03I4tkfkDLMQ7LiVFN6pi', {
          api_host: 'https://eu.i.posthog.com',
          // api_host: 'https://doe3m8irvsj5d.cloudfront.net',
          // ui_host: 'https://eu.posthog.com',
          person_profiles: 'identified_only',
          persistence: preferences?.analytics ? 'localStorage+cookie' : 'memory',
        })
        isRecording.current = true
      } else {
        window.posthog.set_config({
          persistence: preferences?.analytics ? 'localStorage+cookie' : 'memory',
        })
      }
      if (preferences?.analytics) {
        window.posthog.opt_in_capturing()
      } else {
        window.posthog.opt_out_capturing()
      }
    }
  }, [preferences])

  const handleOpenManageCookies = () => {
    setIsOpen(true)
  }

  const handleCloseManageCookies = () => {
    setIsOpen(false)
  }

  const handleSavePreferences = (settings: { required: boolean; analytics?: boolean }) => {
    setCookie(
      COOKIE_NAMES_MAP.preferences,
      `Required:${settings.required ? 1 : 0}-Analytics:${settings.analytics ? 1 : 0}`,
      180
    )
    setPreferences(settings)
    handleCloseManageCookies()
  }

  return (
    <>
      {isOpen && (
        <CookieDialog
          onSaveClicked={handleSavePreferences}
          onCloseClicked={handleCloseManageCookies}
        />
      )}
      {!preferences ? (
        <Container id="cookie-banner">
          <Flex>
            <Block>
              <Flex>
                <IconContainer>
                  <FontAwesomeIcon size="xl" icon={faCookieBite} />
                </IconContainer>

                <ContentContainer>
                  <P>
                    We use optional cookies to improve your experience on our websites. If you
                    reject optional cookies, only cookies necessary to provide you the services
                    listed above will be used. You may change your selection on which cookies to
                    accept by clicking "Manage Cookies" at the bottom-right of the page to change
                    your selection. This selection is maintained for 180 days. Please review your
                    selections regularly.
                  </P>
                  <br />
                  <Text className="">
                    <Anchor
                      href="https://www.bycanary.be/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Anchor>
                    {' | '}
                    <Anchor
                      href="https://www.bycanary.be/cookie-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie Policy
                    </Anchor>
                    {' | '}
                    <Anchor
                      href="https://www.bycanary.be/algemene-voorwaarden"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </Anchor>
                  </Text>
                </ContentContainer>
              </Flex>
            </Block>

            <ButtonsGroup>
              <ButtonSecondary onClick={handleOpenManageCookies}>Manage Cookies</ButtonSecondary>
              <ButtonPrimary
                onClick={() => {
                  handleSavePreferences({ required: true, analytics: false })
                }}
              >
                Necessary Only
              </ButtonPrimary>
              <ButtonPrimary
                onClick={() => {
                  handleSavePreferences({ required: true, analytics: true })
                }}
              >
                Allow All
              </ButtonPrimary>
            </ButtonsGroup>
          </Flex>
        </Container>
      ) : (
        <ManageCookiesFlag onClick={handleOpenManageCookies} className={`${isOpen ? 'open' : ''}`}>
          <ManageCookieButton>Manage Cookies</ManageCookieButton>
        </ManageCookiesFlag>
      )}
    </>
  )
}
