import { css } from 'styled-components'

export const WidgetEditor = css`
  background: var(--color-backgroundFront);
  opacity: 0 !important;
  box-shadow: var(--shadow-shadowElevation2);
  transform: scale(0);
  padding: 0.5em;
  border-radius: 1em;
`
