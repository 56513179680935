import { Checkbox } from 'antd'
import styled from 'styled-components'

export const AntCheckbox = styled(Checkbox)`
  &:not(.ant-checkbox-wrapper-disabled) {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color-main);
      border-color: var(--color-main);
    }

    &:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--color-main);
    }

    .ant-checkbox-checked::after {
      border: 1px solid var(--color-main);
    }
  }
`
