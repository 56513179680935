import { transparentize } from 'polished'
import styled from 'styled-components'

const DatePickerStyled = styled.div`
  .ant-picker {
    width: 100%;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: var(--color-main);
  }

  .ant-picker-focused {
    -webkit-box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
    box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
  }

  .ant-picker-range .ant-picker-active-bar {
    background: var(--color-main);
  }
`
export default DatePickerStyled
