import styled from 'styled-components'

const DashboardPickerStyled = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .Input {
    width: 100%;
  }

  .SearchableTree {
    height: 100%;
    overflow: hidden;

    .Tree {
      flex: 1;
      height: auto;
    }
  }
`

export const DashboardPickerButtonStyled = styled.span`
  display: flex;
  align-items: center;
  max-width: 100%;

  &:hover {
    > .Icon {
      &:first-child {
        color: var(--color-main);
      }

      &:not(:first-child) {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  &.active {
    > .Icon {
      &:first-child {
        background: var(--color-main);
        color: var(--color-mainText);
      }
    }
  }

  &.selected,
  &.child-selected {
    > .Icon {
      &:first-child {
        background: var(--color-action);
        color: var(--color-mainText);
      }
    }
  }

  &.child-selected {
    opacity: 0.5;
  }

  > .Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      transition: color 0.2s ease, background 0.2s ease;
      width: 1.8rem;
      min-width: 1.8rem;
      height: 1.8rem;
      color: var(--color-softStroke);
      margin-right: 1rem;
      background: transparent;
      font-size: 1rem;
      border-radius: 0.2rem;
      img {
        height: 90%;
        width: 90%;
        object-fit: contain;
      }
    }

    &:not(:first-child) {
      color: var(--color-softText);
      transition: opacity 0.2s ease, transform 0.2s ease, color 0.2s ease;
      width: 1.2rem;
      min-width: 1.2rem;
      height: 1.2rem;
      margin-left: 0.5rem;
      border: 1px solid;
      border-radius: 100%;
      cursor: pointer;
      padding: 0.2rem;
      font-size: 0.6rem;
      opacity: 0;
      transform: scale(0);

      &:hover {
        color: var(--color-action);
      }
    }
  }
  .Button__label {
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`
export default DashboardPickerStyled
