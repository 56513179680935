import { Tag } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

const { CheckableTag } = Tag

const CheckableTagStyled = styled(CheckableTag)`
  margin: 0.5rem 0.5rem 0 0;
  font-size: 1rem;

  &.unchecked {
    color: ${({ color }) => color || 'var(--color-main)'};
    border-color: ${({ color }) => color || 'var(--color-main)'};

    &:hover {
      color: ${({ color }) => color || 'var(--color-main)'};
    }
    &:active,
    &:focus {
      background-color: ${({ theme }) => transparentize(0.9, theme.color.main)};
    }
  }

  &.checked {
    background-color: ${({ color }) => color || 'var(--color-main)'};
  }
`
export default CheckableTagStyled
