import { Menu } from 'antd'
import styled from 'styled-components'

export const MultiIconMenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item-active {
    background: none !important;
  }

  .MenuItem {
    justify-content: space-around;
    color: var(--color-softText);
  }

  .Icon {
    margin: 0;
  }
`
