import { css } from 'styled-components'

import Icon from '../Icon/styled'

export const basic = css`
  transition: color 0.2s ease, transform 0.1s ease, border 0.2s ease;
  border-radius: 2rem;
  border: 1px solid transparent;
  box-shadow: var(--shadow-shadowElevation1);
  padding: 0.2rem 0.5rem;
  color: var(--color-softText);
  align-self: flex-start;

  &:hover {
    transform: scale(1.1);
    box-shadow: var(--shadow-shadowElevation2);
    color: var(--color-action);
  }
  &.active {
    border-color: var(--color-action);
    color: var(--color-action);
  }
  ${Icon} {
    margin-right: 0.5rem;
  }
`
