const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.kpiWidget',
    category: 'widget.gaugesIndicators',
    keywords: ['chart', 'trend', 'kpi', 'gauge', 'indicator'],
    img: '/resources/images/widgets/chartcard.png',
    info: {
      'fr-FR':
        '<h2>KPI</h2>\n' +
        '<p>Le widget KPI affiche les valeurs des indicateurs de performance clés pour un point de données donné sur un certain temps.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        '<li><strong>Couleur</strong> Sélectionnez une couleur globale pour le widget</li>\n' +
        "<li><strong>Lien</strong> Définir un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Modifiez l'unité de la valeur du widget</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur du widget</li>\n' +
        '</ul>',
      'en-US':
        '<h2>KPI</h2>\n' +
        '<p>The KPI widget displays Key Performance Indicator values for a given datapoint over a certain time.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        '<li><strong>Color</strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the widget</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the widget value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the widget value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>KPI</h2>\n' +
        '<p>De KPI-widget toont Key Performance Indicator-waarden voor een bepaald datapunt gedurende een bepaalde tijd.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de widget wordt geklikt</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de widgetwaarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de widgetwaarde</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'ChartCard',
      title: 'widget.kpiWidget',
      icon: 'fas fa-info',
      url: null,
      datapoints: [],
      method: 'sum',
      timeRange: {
        preset: 'past7Days',
      },
      customLogic: '',
      conversionUnit: null,
      defaultGranularity: 'd',
      defaultGranularityValue: 1,
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-chart-area',
      caption: {
        kpiValue: 21.9,
        kpiUnit: '°C',
        href: '',
        icon: 'fas fa-droplet',
      },
      chart: {
        lines: [
          {
            values: [789, 880, 876, 800, 890, 877, 900],
          },
        ],
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
        validation: {
          type: 'maxLength',
          value: 50,
        },
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'LinkPicker',
        label: { formatted: 'widget.link' },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.tooltip' },
        dataKey: 'hasTooltip',
      },
      {
        category: 'widget.general',
        componentName: 'TextArea',
        label: { formatted: 'widget.tooltipText' },
        dataKey: 'tooltip',
        componentProps: {
          maxLength: 250,
        },
        validation: {
          type: 'maxLength',
          value: 250,
        },
        dependencies: [
          {
            dependentOn: 'widget.hasTooltip',
            dependencyType: 'hasValue',
            dependencyResolver: 'show',
          },
        ],
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints' },
        componentProps: {
          dataKey: 'datapoints',
          method: 'method',
          fixedUnit: true,
          canReselect: false,
          //linkedMode: true,
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          hideLabel: true,
          hideGranularity: false,
          addRangeMethod: false,
        },
        label: { formatted: 'widget.timeRange', tagName: 'h4' },
        dataKey: '',
      },
      {
        category: 'widget.dashboardLink',
        componentName: 'DashboardLink',
        dataKey: 'dashboardLink',
        componentProps: {
          dataKey: 'dashboardLink',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'UnitPicker',
        label: { formatted: 'widget.unit' },
        dataKey: 'conversionUnit',
        componentProps: {
          dataKey: 'datapoints',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
