import styled, { css } from 'styled-components'

import { Table } from 'components/Global/FormField/styled.Table'

const variants = {
  Table,
}

const FormFieldStyled = styled.div`
  display: flex;

  ::placeholder,
  input {
    font-weight: 100;
    color: var(--color-softText);

    &.ant-input-disabled {
      background-color: var(--color-softerStroke);
    }
  }

  input {
    color: var(--color-mainText);
  }

  ::placeholder {
    color: var(--color-softText);
  }

  ${({ validation, theme }) => {
    const color = {
      error: theme.color.error,
    }[validation]
    return css`
      .FormField__message {
        color: ${color};
      }

      input {
        border-color: ${color} !important;
      }
    `
  }}

  ${({ width, componentName }) =>
    width &&
    css`
      flex: ${width} !important;
      overflow: ${componentName === 'Input' ? 'hidden' : 'visible'};
    `}

  ${({ labelStyle }) =>
    ({
      top: css`
        flex-direction: column;

        &.Switch {
          align-items: flex-start;

          button {
            margin-top: 0.5rem;
          }
        }
      `,
      left: css`
        flex-direction: row;
        align-items: center;

        &.Switch {
          .FormFieldLabel {
            margin: 0.5rem 0.5rem 0.5rem 0;
            flex: 1;
          }
        }
      `,
      right: css`
        flex-direction: row-reverse;
      `,
    }[labelStyle.position || 'top'])};

  > h4.FormFieldLabel {
    margin-bottom: 1rem;
  }

  &.ColorPicker {
    align-items: flex-start;

    &.align-right {
      .TooltipContent {
        right: 0;
        left: auto;
      }
    }
  }

  &.IconPicker {
    &.align-right {
      .TooltipContent {
        right: 0;
        left: auto;
      }
    }
  }

  .WidgetError {
    position: relative;
    margin: 1rem 0 0;
    flex-direction: row;
    text-align: left;
    > .Icon {
      margin: 0.7rem 1rem auto 0;
    }
  }

  ${({ variant }) => variants[variant]}
`

export const FormFieldLabel = styled.label`
  color: ${({ theme, as }) => (as === 'h4' ? 'var(--color-mainText)' : 'var(--color-softText)')};
  font-size: ${({ as }) => (as === 'h4' ? '1.1rem' : '0.8rem')};
  font-weight: 400;
  text-transform: uppercase;
  margin: 1rem ${({ margin }) => margin || 0} ${({ as }) => (as === 'h4' ? '-0.3rem' : '0.3rem')};
`
export default FormFieldStyled
