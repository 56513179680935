import styled, { css } from 'styled-components'

import { FieldStyled, Value } from 'components/Form/components/Field/styled'
import SearchStyled from 'components/Form/components/Search/styled'
import IconButtonFieldStyled from 'components/Form/fields/IconButtonField/styled'
import SearchFieldStyled from 'components/Form/fields/SearchField/styled'

export const FieldGroup = styled.div`
  display: ${({ flex, block }) => (flex ? 'flex' : block ? 'block' : 'grid')};
  grid-template: ${({ template = 'auto / auto' }) => template};
  grid-column: ${({ col, colSpan }) => `${col} / span ${colSpan}`};
  grid-gap: ${({ gap = '0 1rem', flex }) => !flex && gap};
  align-content: start;
  padding: ${({ boxed, padding = true }) => padding && (boxed ? '1rem' : '1rem 1rem 1rem 2rem')};
  margin: ${({ boxed }) => (boxed ? '1rem' : 0)};
  border-radius: ${({ boxed }) => (boxed ? '1rem' : 0)};
  box-shadow: ${({ boxed, theme }) => (boxed ? 'var(--shadow-shadowElevation2)' : 'none')};
  min-width: 0;

  ${({ boxed }) =>
    boxed
      ? css`
          ${FieldGroupTitle} {
            margin: 0 -1rem;
            padding: 1rem;
            border-bottom: 1px solid var(--color-softStroke);
            width: calc(100% + 2rem);
            overflow: hidden;

            &:not(:first-child) {
              margin-top: 1rem;
            }

            &:first-child {
              margin-top: -1rem;
              border-radius: 1rem 1rem 0 0;
            }
          }

          ${FieldGroupContent} {
            width: calc(100% + 2rem);
          }
        `
      : css`
          ${FieldGroupTitle} {
            background: var(--color-backgroundFront);
            margin: -1rem -1.2rem 0 0;
            padding: 3rem 0 1rem 0;
          }
        `}
  ${({ stroke, theme }) =>
    stroke &&
    css`
      border-bottom: 1px solid var(--color-softStroke);

      &:not(:last-of-type) {
        ${({ boxed }) => !boxed && `border-right: 1px solid var(--color-softStroke);`}
      }
    `}
	.ant-tabs {
    //overflow: visible;
  }

  .ant-tabs-nav {
    &:before {
      left: -1rem;
      right: -1rem;
      border-bottom-color: var(--color-softStroke);
    }
  }
`

export const FieldGroupTitle = styled.h3`
  grid-column: 1 / span 2;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-l)rem;
  letter-spacing: var(--font-spacing-l);
  color: var(--color-mainText);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${FieldStyled} {
    width: auto;
  }

  ${SearchFieldStyled} {
    margin: -0.5rem auto -0.5rem 0.5rem;
    flex: 1;

    ${Value} {
      width: 100%;
    }

    ${SearchStyled} {
      width: 100%;
    }

    svg {
      font-size: 1.2rem;
    }
  }

  ${IconButtonFieldStyled} {
    font-size: 1.2rem;
    margin-left: auto;

    &:last-child {
      margin-left: 0;
    }

    &:not(:last-child) {
      margin-right: 0.3rem;
    }
  }
`

export const FieldGroupContent = styled.div`
  grid-column: 1 / span 2;
  padding: 0.5rem;
  margin: 0 -1rem;
  width: calc(100% + 1rem) !important;
  flex: 1;
  overflow: hidden;
  > * {
    margin: 0.5rem;
  }
  &:first-child {
    .Value {
      flex: 2;
      justify-content: flex-end;
      text-align: right;
      input {
        text-align: right;
      }
    }

    > ${FieldStyled} {
      padding: 0.5rem;
      align-items: center;
    }
  }

  .Label {
    flex: 1;
  }

  > ${FieldStyled} {
    margin: 0;
    > .Value {
      flex: 2;
      text-align: right;

      > * {
        width: 100%;
      }

      input {
        text-align: right;
      }

      > button {
        margin-right: 0;
        margin-left: auto;
      }

      > span {
        display: block;
      }

      .ValuePickerValue {
        input {
          text-align: left;
        }
      }
    }
  }
`

export const ClickableIdField = styled.div`
  cursor: pointer;
`
