import styled from 'styled-components'

import Ui from 'components/Widgets/Gauge/Ui'

export const SolidGauge = styled(Ui)`
  .highcharts-series-0 {
    .highcharts-label {
      > span {
        top: 0 !important;
      }
    }
  }

  .highcharts-series-1 {
    margin-top: -40px !important;

    .data-labels {
      color: var(--color-mainText) !important;
      font-weight: 300;
    }

    .value {
      font-size: 1.1rem;
    }
  }
`
