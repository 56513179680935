import React, { useRef, useState } from 'react'

import styled, { css } from 'styled-components'

import { Anchor, ButtonPrimary, ButtonSecondary } from './styledComponents'
import { useCookiePreferences } from 'contexts/Cookies/CookieContext'

const Overlay = styled.div`
  display: block;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.8);
  /* background-color: rgba(0, 0, 0, 0.45); */
  overflow: auto;
  text-align: left;
`

const DialogContainer = styled.div`
  background-color: var(--color-backgroundFront, white);
  border: 1px solid var(--color-backgroundHighlight, black) !important;
  position: relative;
  top: 8%;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 640px;

  border-radius: 8px;

  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px;
`

const CloseButton = styled.button`
  float: right;
  z-index: 1;
  margin: 2px;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: inherit;
  color: var(--color-mainText) !important;
`

const ContentContainer = styled.div`
  position: static;
  margin-top: 36px;
  margin-left: 36px;
  margin-right: 36px;
`

const Form = styled.form`
  height: 446px;
  overflow: auto;
`

const textCss = css`
  color: var(--color-mainText);
  font-family: var(--font-family-primary);
`

const P = styled.p`
  ${textCss}

  margin-top: 0;
  font-size: 1.154rem;
  font-weight: 300;
  letter-spacing: 0.02rem;
  line-height: 1.3;

  Form > & {
    margin: 0;
  }
`

const H1 = styled.h1`
  ${textCss}

  font-size: 1.538rem;
  margin-top: 0;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
`

const H2 = styled.h2`
  ${textCss}
  font-size: 1.385rem;
`

const Input = styled.input`
  all: unset !important;
  display: inline-block;
  position: relative;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  height: 0;
  width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

const Label = styled.label`
  ${textCss}

  display: block;
  position: static;
  float: right;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 19px;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
  padding-right: 0;
  width: 80%;
  width: calc(100% - 19px);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-transform: none;
  cursor: pointer;
  box-sizing: border-box;

  &::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: 5px;
    left: 3px;
    height: 19px;
    width: 19px;
    content: '';
    border-radius: 50%;
    border: 1px solid var(--color-mainText);
    background-color: inherit;
  }

  ${Input}:checked + &::after {
    display: block;
    position: absolute;
    top: 10px;
    left: 8px;
    height: 9px;
    width: 9px;
    content: '';
    border-radius: 50%;
    background-color: var(--color-action);
  }

  ${Input}:hover + &::after {
    display: block;
    position: absolute;
    top: 10px;
    left: 8px;
    height: 9px;
    width: 9px;
    content: '';
    border-radius: 50%;
    background-color: var(--color-softText);
  }
`

const ButtonsGroup = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;
  display: flex;
  justify-content: flex-end;
`

const Dl = styled.dl`
  margin-top: 36px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  text-transform: none;
`

const Dt = styled.dt`
  margin-top: 20px;
  float: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  list-style: none;
`

const RadioInputContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 5px;
  margin-right: 34px;
  padding: 3px;
`

const ANALYTICS = 'Analytics'
const ACCEPT = 'accept'
const REJECT = 'reject'

type CookiePreferences = {
  required: boolean
  analytics?: boolean
}

export const CookieDialog = ({
  onSaveClicked,
  onCloseClicked,
}: {
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>
  onSaveClicked?: (settings: { required: boolean; analytics?: boolean }) => void
}) => {
  const { preferences } = useCookiePreferences()
  const [localPreferences, setLocalPreferences] = useState<CookiePreferences>(
    preferences ?? {
      required: true,
      analytics: false,
    }
  )
  const formRef = useRef<HTMLFormElement>(null)

  const handlePreferenceChange = (target: 'analytics', value: boolean) => {
    setLocalPreferences((v) => ({
      ...v,
      [target]: value,
    }))
  }

  const handleSave = () => {
    onSaveClicked?.(localPreferences)
  }

  const handleReset = () => {
    setLocalPreferences({ required: true })
    formRef.current?.reset()
  }

  return (
    <>
      <Overlay>
        <DialogContainer role="dialog">
          <CloseButton onClick={onCloseClicked}>✕</CloseButton>
          <ContentContainer>
            <H1>Manage cookie preferences</H1>
            <Form ref={formRef}>
              <P>
                Most Canary websites use cookies. Cookies are small text files placed on your device
                to store data so web servers can use it later. Canary and our third-party partners
                use cookies to remember your preferences and settings, help you sign in, and analyze
                how well our websites are working. For more info, see the Cookies and similar
                technologies section of the{' '}
                <Anchor href="https://www.bycanary.be/privacy-policy">Privacy Policy</Anchor>.
              </P>
              <Dl>
                <Dt>
                  <H2>Required</H2>
                  <P>
                    Canary uses required cookies to perform essential website functions and to
                    provide the services. For example, cookies are used to log you in, save your
                    language preferences, improve performance, and route traffic between web
                    servers. These cookies are necessary for our websites to work.
                  </P>
                </Dt>
                <Dt aria-label="Analytics">
                  <div role="radiogroup" aria-labelledby="analytics-title">
                    <H2 id="analytics-title">Analytics</H2>
                    <P>
                      We employ first party cookies from Posthog to understand how you use our
                      websites so we can make them better. For example, cookies are used to gather
                      information about the pages you visit and how many clicks you need to
                      accomplish a task. These cookies are optional.
                    </P>
                    <div
                      onChange={(event) => {
                        handlePreferenceChange(
                          'analytics',
                          (event.target as HTMLInputElement)?.value === ACCEPT
                        )
                      }}
                    >
                      <RadioInputContainer>
                        <Input
                          type="radio"
                          name={ANALYTICS}
                          id="Analytics-accept"
                          value={ACCEPT}
                          defaultChecked={
                            localPreferences.analytics !== undefined && localPreferences.analytics
                          }
                        />
                        <Label htmlFor="Analytics-accept">Accept</Label>
                      </RadioInputContainer>
                      <RadioInputContainer>
                        <Input
                          type="radio"
                          name={ANALYTICS}
                          id="Analytics-reject"
                          value={REJECT}
                          defaultChecked={
                            localPreferences.analytics !== undefined && !localPreferences.analytics
                          }
                        />
                        <Label htmlFor="Analytics-reject">Reject</Label>
                      </RadioInputContainer>
                    </div>
                  </div>
                </Dt>
              </Dl>
            </Form>
            <ButtonsGroup>
              <ButtonSecondary type="button" onClick={handleReset}>
                Reset all
              </ButtonSecondary>
              <ButtonPrimary type="button" onClick={handleSave}>
                Save changes
              </ButtonPrimary>
            </ButtonsGroup>
          </ContentContainer>
        </DialogContainer>
      </Overlay>
    </>
  )
}
