import {
  calculationMethods,
  datapointValueTypes,
} from 'components/Dashboard/Widget/config/selections'

const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.grid',
    category: 'widget.grids',
    keywords: ['grid', 'table'],
    img: '/resources/images/widgets/grid.png',
    info: {
      'fr-FR':
        '<h2>Grille</h2>\n' +
        '<p>Le widget Grille affiche les données dans un aperçu de tableau personnalisable.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong>Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données (LIEN)</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong>Sélectionnez les points de données du widget</li>\n' +
        '</ul>\n' +
        '<h4>Colonnes (LIEN)</h4>\n' +
        '<ul>\n' +
        '<li><strong>Colonne(s) </strong>Sélectionnez une colonne à configurer</li>\n' +
        '</ul>\n' +
        '<h4>Lignes (LIEN)</h4>\n' +
        '<ul>\n' +
        '<li><strong>Ligne(s) </strong>Sélectionnez une ligne à configurer</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<ul>\n' +
        '<li><strong>Point(s) de données </strong>Sélectionnez un point de données à configurer</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '</ul>\n' +
        '<h3>Configuration de la colonne sélectionnée</h3>\n' +
        '<ul>\n' +
        '<li><strong>Colonne(s) </strong>Sélectionnez une colonne à configurer</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '<li><strong>Type</strong> Sélectionnez le type de la colonne</li>\n' +
        '<li><strong>Largeur</strong> Définit la largeur du </li>\n' +
        '<li><strong>Décimal</strong> Sélectionnez la couleur de la flèche pour la différence négative</li>\n' +
        "<li><strong>Afficher l'unité</strong> Afficher l'unité pour la valeur sélectionnée</li>\n" +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '</ul>\n' +
        '<h3>Configuration de ligne sélectionnée</h3>\n' +
        '<ul>\n' +
        '<li><strong>Ligne(s) </strong>Sélectionnez une ligne à configurer</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Méthode</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '<li><strong>Colonnes </strong>Sélectionnez les colonnes pour lesquelles les calculs seront appliqués</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Grid</h2>\n' +
        '<p>The Grid widget displays data in a customizable table overview.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong>Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong>Select widget datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Columns</h4>\n' +
        '<ul>\n' +
        '<li><strong>Column(s) </strong>Select a column to be configured</li>\n' +
        '</ul>\n' +
        '<h4>Rows</h4>\n' +
        '<ul>\n' +
        '<li><strong>Row(s) </strong>Select a row to be configured</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint(s) </strong>Select a datapoint to be configured</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '</ul>\n' +
        '<h3>Selected Column Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Column(s) </strong>Select a column to be configured</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '<li><strong>Type</strong> Select the type for the column</li>\n' +
        '<li><strong>Width</strong> Define the width of the </li>\n' +
        '<li><strong>Decimals</strong> Select arrow color for negative difference</li>\n' +
        '<li><strong>Show Unit</strong> Show unit for selected value</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '</ul>\n' +
        '<h3>Selected Row Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Row(s) </strong>Select a row to be configured</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Columns </strong>Select the columns for which the calculations will be applied</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Raster</h2>\n' +
        '<p>De rasterwidget geeft gegevens weer in een aanpasbaar tabeloverzicht.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Icoon </strong>Widget-icoon</li>\n' +
        '<li><strong>Kleur </strong>Widgetpictogramkleur</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten </strong>Selecteer widgetgegevenspunten</li>\n' +
        '</ul>\n' +
        '<h4>Kolommen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Kolom(en) </strong>Selecteer een kolom die moet worden geconfigureerd</li>\n' +
        '</ul>\n' +
        '<h4>Rijen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Rij(en) </strong>Selecteer een rij die moet worden geconfigureerd</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapunt(en) </strong>Selecteer een te configureren datapunt</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde kolomconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Kolom(en) </strong>Selecteer een kolom die moet worden geconfigureerd</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '<li><strong>Type</strong> Selecteer het type voor de kolom</li>\n' +
        '<li><strong>Breedte</strong> Definieer de breedte van de </li>\n' +
        '<li><strong>Decimalen</strong> Selecteer de kleur van de pijl voor een negatief verschil</li>\n' +
        '<li><strong>Eenheid weergeven</strong> Eenheid voor geselecteerde waarde weergeven</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde rijconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Rij(en) </strong>Selecteer een rij die moet worden geconfigureerd</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Methode</strong> Selecteer de berekeningsmethode bij meerdere datapunten</li>\n' +
        '<li><strong>Kolommen </strong>Selecteer de kolommen waarop de berekeningen worden toegepast</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'Grid',
      title: 'widget.grid',
      icon: 'fas fa-info',
      color: null,
      datapoints: [],
      gridColumns: [],
    },
    unUsedDatapointProperties: [
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-table',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              hideMethod: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
              ],
            },
          },
          groupSection: {
            name: 'datapointsGroups',
            componentName: 'DatapointGroupConfigurator',
            componentProps: {
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'name',
                },
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  label: { formatted: 'widget.calculationMethod' },
                  dataKey: 'method',
                  componentProps: {
                    selectionKeys: { label: 'label', value: 'value' },
                    options: calculationMethods,
                  },
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.columns',
        componentName: 'Columns',
        label: { formatted: 'widget.columns' },
        componentProps: {
          dataKey: 'gridColumns',
          section: {
            name: 'columns',
            componentName: 'ColumnConfigurator',
            componentProps: {
              dataKey: 'gridColumns',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'DatapointValueTypePicker',
                  componentProps: {
                    types: [
                      {
                        value: 'referenceValue',
                        label: { formatted: 'widget.referenceValue' },
                        valueType: 'referenceValue',
                      },
                      {
                        value: 'comparisonValue',
                        label: { formatted: 'widget.comparisonValue' },
                        valueType: 'value',
                      },
                      {
                        value: 'absoluteValue',
                        label: { formatted: 'widget.absoluteValue' },
                        valueType: 'absoluteValue',
                      },
                      ...datapointValueTypes.filter(({ valueType }) => valueType === 'dataKey'),
                    ],
                  },
                  label: { formatted: 'widget.type' },
                  placeholder: { formatted: 'widget.type' },
                  dataKey: 'dataKey',
                },
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'headerName',
                },
                {
                  category: 'widget.general',
                  componentName: 'RangeSlider',
                  componentProps: {
                    min: 100,
                    max: 500,
                    step: 10,
                  },
                  label: { formatted: 'widget.width' },
                  dataKey: 'width',
                },
                {
                  category: 'widget.general',
                  componentName: 'NumberInput',
                  label: { formatted: 'widget.decimals' },
                  dataKey: 'decimals',
                  dependencies: [
                    {
                      dependentOn: 'dataKey',
                      dependencyType: 'valueIsIn',
                      dependencyValue: ['comparisonValue', 'referenceValue'],
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showUnit' },
                  dataKey: 'showUnit',
                  col: 'col2 first',
                  dependencies: [
                    {
                      dependentOn: 'dataKey',
                      dependencyType: 'valueIsIn',
                      dependencyValue: ['comparisonValue', 'referenceValue'],
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.interval',
                  componentName: 'DefaultStartTimeSelector',
                  label: { formatted: 'widget.decimals' },
                  dataKey: '',
                  componentProps: {
                    dataKey: 'defaultStartTime',
                    datapointsDataKey: 'datapoints',
                    range: true,
                    hideCalculationMethod: true,
                  },
                  dependencies: [
                    {
                      dependentOn: 'dataKey',
                      dependencyType: 'valueIsIn',
                      dependencyValue: ['comparisonValue', 'referenceValue', 'absoluteValue'],
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.interval',
                  componentName: 'ValueTypeSelector',
                  dataKey: 'method',
                  dependencies: [
                    {
                      dependentOn: 'dataKey',
                      dependencyType: 'valueIsIn',
                      dependencyValue: ['comparisonValue', 'referenceValue'],
                      dependencyResolver: 'show',
                    },
                  ],
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.rows',
        componentName: 'Rows',
        label: { formatted: 'widget.rows' },
        componentProps: {
          dataKey: 'gridRows',
          section: {
            name: 'rows',
            componentName: 'RowConfigurator',
            componentProps: {
              dataKey: 'gridRows',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'OptionPicker',
                  componentProps: {
                    options: calculationMethods,
                    selectionKeys: {
                      label: 'label',
                      value: 'value',
                    },
                  },
                  label: { formatted: 'widget.calculationMethod' },
                  dataKey: 'method',
                },
                {
                  category: 'widget.general',
                  componentName: 'ColumnPicker',
                  componentProps: {},
                  label: { formatted: 'widget.columns' },
                  dataKey: 'gridColumns',
                },
              ],
            },
          },
        },
      },
    ],
  },
}

export default config
