import { motion } from 'framer-motion'
import styled from 'styled-components'

const TopBarStyled = styled(motion.div)`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.helpdeskMode ? '#C6E5F7' : 'var(--color-backgroundFront)'};
  z-index: 600;
  right: 0;
  box-shadow: var(--shadow-shadowElevation2);
  height: var(--topBar-height);
  padding: 0 1.5rem;
  justify-content: ${({ theme }) => theme.screenSize.screen.current === 's' && 'space-between'};
  .ant-dropdown-trigger {
    margin-left: 0.5rem;
  }
`
export default TopBarStyled
