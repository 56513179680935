import dayjs from 'dayjs'

import { CALCULATIONS } from 'util/constants'
import customLogicRunner from 'util/customLogicRunner'
import round from 'util/round'

import { getCalculatedHistory } from '../components/Dashboard/utils/common/helpers'

export const getOverUsePercentageString = (validData, max, enabled) => {
  if (enabled && validData && max) {
    const relativeUsagePercentage = Math.round((validData * 100) / max) - 100
    return relativeUsagePercentage > 0
      ? `<span class="">+${relativeUsagePercentage.toLocaleString()}%</span>`
      : ''
  }
  return ''
}

const conversionCalculations = {
  divide: (value, factor) => value / factor,
  multiply: (value, factor) => value * factor,
  subtraction: (value, factor) => value - factor,
  addition: (value, factor) => value + factor,
}

export const getConversionFn = (operation) => {
  if (conversionCalculations[operation]) {
    return conversionCalculations[operation]
  }

  throw new Error(`Conversion "${operation}" does not exists`)
}

export const getDatapointCalculatedValue = (
  {
    average,
    min,
    max,
    last,
    first,
    sum,
    unitObject: { difference } = {},
    conversionFactor,
    calculationMethod,
  },
  { decimals, customLogic } = {}
) => {
  const { operation, multiplyFactor } = conversionFactor || {}
  let value =
    sum ??
    (difference && first?.value
      ? last?.value - first.value
      : {
          sum: last?.value,
          default: last?.value,
          last: last?.value,
          mean: average?.value ?? last?.value,
          min: min?.value ?? last?.value,
          max: max?.value ?? last?.value,
        }[calculationMethod || 'default'])

  value = operation ? getConversionFn(operation)(value, multiplyFactor) : value
  return customLogic
    ? round(
        customLogicRunner(customLogic, {
          value,
          data: { last, first },
        }),
        decimals
      )
    : value ?? null
}

const getDataValue = (
  { value, first, last, average, min, max, sum, tags },
  unitObject,
  calculationMethod
) => {
  if (sum) {
    return sum
  }
  if (last || sum) {
    if (unitObject?.difference && first?.value) {
      return last.value - first.value
    }

    return {
      sum: value || last.value,
      default: value || last.value,
      last: value || last.value,
      mean: value || (average?.value ?? last.value),
      min: value || (min?.value ?? last.value),
      max: value || (max?.value ?? last.value),
    }[calculationMethod || 'default']
  }
}

const getRoundedValue = ({ value, customLogic, decimals }) => {
  let roundedValue = value
  roundedValue = customLogic
    ? round(customLogicRunner(customLogic, { value: roundedValue }), decimals)
    : round(roundedValue, decimals)
  return roundedValue
}

export const getDatapointsCalculatedValue = (
  data,
  { decimals, customLogic, method, calculationMethod = 'mean' }
) => {
  if (!data?.length) {
    return null
  }
  const { unitObject, conversionFactor } = data[0] || {}
  const { operation, multiplyFactor } = conversionFactor || {}
  const calculation = CALCULATIONS[method || 'sum']
  let calculatedValue = calculation(
    data.reduce((values, item) => {
      const value = getDataValue(item, unitObject, calculationMethod)
      if (value) {
        values.push(value)
      }
      return values
    }, [])
  )

  calculatedValue = operation
    ? getConversionFn(operation)(calculatedValue, multiplyFactor)
    : calculatedValue

  return getRoundedValue({ value: calculatedValue, customLogic, decimals })
}

export const getComparatorValue = ({
  comparators,
  comparatorValues,
  comparatorsData,
  method,
  calculationMethod,
  customLogic,
  decimals,
  defaultValue,
}) => {
  if (comparators === null) {
    return defaultValue
  }
  if (comparatorValues[0] !== undefined) {
    return comparatorValues[0]
  }
  if (comparatorsData?.data?.datapoints?.length) {
    return getComparatorCalculatedValue(comparatorsData.data.datapoints, {
      method,
      customLogic,
      decimals,
      calculationMethod,
    })
  }
  return defaultValue
}

export const getComparatorCalculatedValue = (
  data,
  { decimals, customLogic, method, calculationMethod }
) => {
  if (data && typeof data[0] === 'number') {
    return data[0]
  }
  return getDatapointsCalculatedValue(data, { decimals, customLogic, method, calculationMethod })
}

export const calculatePlotBands = (colors = [], min, max) => {
  const distance = Math.abs(max - min)

  return colors
    .map((color, index) => {
      const next = colors[index + 1] || [1] // last value = 1 = 100%

      const limit = (val) => round(Math.min(val, max), 2)

      // calcuate the percentage and add it to the begin value, can not be more then the max value
      return {
        from: limit(min + distance * color[0]),
        to: limit(min + distance * next[0]),
        color: color[1],
      }
    })
    .filter((color) => color.from !== color.to)
}

export const getGhgGroupProperties = (groups = []) => {
  return groups.reduce(
    (
      groupProperties,
      { datapoints, conversionUnit, defaultCalculationMethod, emissionFactors, offsets }
    ) => {
      datapoints.forEach((datapoint) => {
        groupProperties[datapoint] = {
          emissionFactors,
          returnUnitId: conversionUnit,
          offsets,
          defaultCalculationMethod,
        }
      })
      return groupProperties
    },
    {}
  )
}

export const getEmissionUnit = (emission) =>
  ({
    co2: 'kg',
    ch4Co2e: 'kg',
    n2oCo2e: 'kg',
    hfcCo2e: 'kg',
    pfcCo2e: 'kg',
    sf6Co2e: 'kg',
    nf3Co2e: 'kg',
    total: 'kg',
    biogenicCo2: 'kgCO2e',
    ar4: 'kgCO2e',
    ar5: 'kgCO2e',
    tonCO2e: 'tonCO2e',
    'Total CO2e': 'kgCO2e',
  }[emission])

export const getDataForEmissionFactor = (data, emissionFactor) => {
  return data.reduce((filteredData, d) => {
    const validData = d.emissions ? d.emissions.find(({ type }) => type === emissionFactor) : d
    if (validData !== undefined) {
      filteredData.push(validData)
    }
    return filteredData
  }, [])
}

export const getOffsetsForGranularity = ({
  startTime,
  endTime,
  defaultGranularity,
  defaultGranularityValue = 1,
}) => {
  const _endTime = endTime || dayjs()
  const amount = Math.abs(_endTime.diff(startTime, defaultGranularity)) / defaultGranularityValue
  const base = _endTime.startOf(defaultGranularity)
  let offsets = []
  if (base) {
    for (let i = 0; i < amount; i++) {
      const start = base.subtract(i + 1, defaultGranularity).valueOf()
      const end = base.subtract(i, defaultGranularity).valueOf()
      offsets.push({ startTime: start, endTime: end, dashStyle: 'Solid', color: 'red' })
    }
  }
  return offsets
}

export const getDatapointsOffsetsCalculatedValues = ({ data = [], decimals }) => {
  const { history, min, max, mean, sum } = data.reduce(
    (
      calculatedDatapoints,
      { history, compareHistory, unitObject, conversionFactor, customLogic },
      index
    ) => {
      calculatedDatapoints.list.push([
        ...(compareHistory
          ? compareHistory.map((history) =>
              getCalculatedHistory({ ...unitObject, ...conversionFactor, customLogic }, history)
            )
          : []),
        getCalculatedHistory({ ...conversionFactor, customLogic }, history),
      ])

      if (index === data.length - 1) {
        calculatedDatapoints.history = calculatedDatapoints.list[0].map((value, index) =>
          round(
            CALCULATIONS.sum(calculatedDatapoints.list.map((element) => element[index])),
            decimals
          )
        )
        calculatedDatapoints.sum = round(CALCULATIONS.sum(calculatedDatapoints.history), decimals)
        calculatedDatapoints.min = round(CALCULATIONS.min(calculatedDatapoints.history), decimals)
        calculatedDatapoints.max = round(CALCULATIONS.max(calculatedDatapoints.history), decimals)
        calculatedDatapoints.mean = round(CALCULATIONS.mean(calculatedDatapoints.history), decimals)
      }

      return calculatedDatapoints
    },
    { list: [], history: [] }
  )
  return { history, min, max, mean, sum }
}
