import { css } from 'styled-components'

export const smallButton = css`
  font-size: 0.9em;
  color: ${({ isPrimary, disabled }) =>
    isPrimary && !disabled ? 'var(--color-white)' : 'var(--color-softText)'};
  background: ${({ theme, isPrimary, isActive, disabled }) =>
    (isPrimary || isActive) && !disabled ? theme.color.action : 'none'};
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? 'var(--color-softText)' : theme.color.action)};
  border-radius: 1rem;
  padding: 0 0.5rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  max-width: fit-content;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  &:hover {
    ${({ disabled }) => !disabled && 'background: var(--color-softerStroke);'}

    span {
      color: ${({ isActive, isPrimary, disabled }) =>
        !disabled && (isActive || isPrimary ? 'var(--color-action)' : 'var(--color-mainText)')};
    }
    .Icon {
      color: ${({ disabled }) => !disabled && 'var(--color-action)'};
    }
  }

  span {
    color: ${({ isActive, isPrimary, disabled }) =>
      (isActive || isPrimary) && !disabled ? 'var(--color-white)' : 'var(--color-mainText)'};
  }
  .Icon {
    margin-right: 0.2rem;
  }
`
