import { transparentize } from 'polished'
import styled from 'styled-components'

const DashboardMenuStyled = styled.div`
  height: 100%;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;

  .SearchableTree {
    flex: 1;
    overflow: hidden;
    padding-right: 0.3rem;

    > .Tree {
      padding-right: 0.3rem;

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: none;
        background: ${({ theme }) => transparentize(0.7, theme.color.mainTextColor)};

        &:hover {
          background: ${({ theme }) => transparentize(0.3, theme.color.mainTextColor)};
        }
      }
    }
  }

  .DropdownArrow {
    top: 7px;
  }

  .ant-input-affix-wrapper {
    border-color: ${({ theme }) => transparentize(0.7, theme.color.mainTextColor)};
    margin: 0 1rem 0 2rem;
    width: auto;
    border-radius: 2rem;

    .ant-input-suffix .anticon,
    .ant-input-prefix {
      color: ${({ theme }) => transparentize(0.5, theme.color.mainTextColor)};
    }

    input {
      color: var(--color-mainTextColor);

      ::placeholder {
        color: ${({ theme }) => transparentize(0.8, theme.color.mainTextColor)};
      }
    }
  }
`

export const MenuItemButtonStyled = styled.div`
  &:hover {
    .Icon,
    .Button__label {
      opacity: 1;
    }
  }

  .Icon {
    transition: opacity 0.2s ease;
    color: var(--color-mainTextColor);
    opacity: 0.6;
    width: 1rem;

    &.type-img {
      img {
        width: 100%;
      }
    }
  }

  .Button__label {
    transition: opacity 0.2s ease;
    color: var(--color-mainTextColor);
    opacity: 0.6;
  }

  .Button {
    &.active {
      .Button__label,
      .Icon {
        opacity: 1;
      }
    }
  }
`
export default DashboardMenuStyled
