const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.grid',
    category: 'widget.grids',
    keywords: ['grid', 'table'],
    img: '/resources/images/widgets/grid.png',
    info: {
      'fr-FR':
        '<h4>Grid</h4>\n' +
        '<p>Le widget Grid affiche les données dans un tableau personnalisable.</p>\n' +
        '<ul>\n' +
        '<li><strong>Étiquette </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> icône du widget</li>\n' +
        '<li><strong>Couleur </strong> sélectionnez une couleur globale pour le widget</li>\n' +
        '<li><strong>Décimales </strong> Configurez le nombre de décimales à afficher. La valeur sera arrondie.</li>\n' +
        '<li><strong>Points de données </strong> Sélectionnez les points de données du widget</li>\n' +
        '<li><strong>Légendes </strong> Ajouter un label, une icône et un type pour les valeurs fixes</li>' +
        '</ul>',
      'en-US':
        '<h4>Grid</h4>\n' +
        '<p>The Grid widget displays data in a customizable table overview.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's title</li>\n" +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        '<li><strong>Color </strong> Select an overall color for the widget</li>\n' +
        '<li><strong>Decimals </strong> Configure number of decimals to be shown. Value will be rounded.</li>\n' +
        '<li><strong>Datapoints </strong> Select widget datapoints</li>\n' +
        '<li><strong>Captions </strong> Add label, Icon and Type for fixed values</li>\n' +
        '</ul>',
      'nl-NL':
        '<h4>Grid</h4>\n' +
        '<p>De Grid-widget geeft de gegevens weer in een aanpasbaar tabeloverzicht.</p>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's titel</li>\n" +
        "<li><strong>Icoon </strong> Widget's icoon</li>\n" +
        '<li><strong>Kleur </strong> Selecteer een algemene kleur voor de widget</li>\n' +
        '<li><strong>Decimalen </strong> Configureer het aantal te tonen decimalen. De waarde wordt afgerond.</li>\n' +
        '<li><strong>Datapunten </strong> Selecteer widget datapunten</li>\n' +
        '<li><strong>Bijschriften </strong> Label, Icoon en Type toevoegen voor vaste waarden</li>\n' +
        '</ul>',
    },
    default: {
      type: 'AgGridCard',
      title: 'widget.grid',
      icon: 'fas fa-info',
      datapoints: [],
      decimals: 2,
    },
    preview: {
      icon: 'fas fa-table',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints' },
        componentProps: {
          dataKey: 'datapoints',
          method: 'method',
          fixedUnit: true,
          canReselect: false,
        },
      },
      {
        category: 'widget.fixedValues',
        componentName: 'NumberInput',
        label: { formatted: 'widget.decimals' },
        dataKey: 'decimals',
      },
      {
        category: 'widget.captions',
        componentName: 'CaptionEditor',
        label: { formatted: 'widget.captions' },
        dataKey: 'captions',
        componentProps: {
          columns: ['value', 'title', 'icon', 'errorLevel'],
        },
      },
    ],
  },
}

export default config
