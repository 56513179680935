import { css } from 'styled-components'

export const getStylePropertiesByColumnRatio = ({ widthRatio, columns, screen }) => {
  let currentScreenColumnRatio = widthRatio
  const largestScreenColumns = columns[screen.all[screen.all.length - 1]]
  const factor = largestScreenColumns / columns[screen.current]

  switch (screen.current) {
    case 's':
      currentScreenColumnRatio = 2
      break
    case 'm':
      if (widthRatio < 3) {
        currentScreenColumnRatio = Math.ceil(widthRatio)
      } else if (widthRatio > 9) {
        currentScreenColumnRatio = largestScreenColumns / factor
      } else {
        currentScreenColumnRatio = widthRatio / factor
      }

      break
    default:
  }

  const columnWidth = widthRatio
    ? `${(100 / columns[screen.current]) * currentScreenColumnRatio}%`
    : 'auto'

  const flex = (currentScreenColumnRatio && currentScreenColumnRatio) || 1
  return css`
    flex: ${flex} ${flex} ${columnWidth};
    max-width: ${widthRatio ? columnWidth : 'none'};
    min-width: ${columnWidth};
  `
}

export const getRowPosition = (
  element,
  columns,
  screenSize,
  columnRenderCount,
  elementTotal,
  elementIndex
) => {
  const columnTotal = columns[screenSize.screen.current]
  const newColumnRenderCount = columnRenderCount.column + element.w
  const roundedNewColumnRenderCount = Math.round(newColumnRenderCount)
  const result = {
    isFirst: columnRenderCount.rowItemIndex === 0,
    isLast: roundedNewColumnRenderCount % columnTotal === 0,
    rowIndex: columnRenderCount.rowIndex,
    rowItemIndex: columnRenderCount.rowItemIndex,
  }
  const newRow =
    roundedNewColumnRenderCount % columnTotal === 0 || elementIndex === elementTotal - 1

  columnRenderCount.column = roundedNewColumnRenderCount === columnTotal ? 0 : newColumnRenderCount
  columnRenderCount.rowIndex = newRow ? columnRenderCount.rowIndex + 1 : columnRenderCount.rowIndex
  columnRenderCount.rows = newRow
    ? columnRenderCount.rows.concat(columnRenderCount.rowItemCount)
    : columnRenderCount.rows
  columnRenderCount.rowItemCount = newRow ? 1 : columnRenderCount.rowItemCount + 1
  columnRenderCount.rowItemIndex =
    newColumnRenderCount === columnTotal ? 0 : columnRenderCount.rowItemIndex + 1
  return result
}

export const handleError = (error, noData, intl) => {
  const errorMessage = error
    ? error.graphQLErrors?.[0]
      ? 'configurationError'
      : error?.networkError?.message
    : noData && 'No data found for datapoint(s)'

  return (
    {
      'Account has insufficient permission': {
        icon: 'fas fa-circle-xmark',
        label:
          intl &&
          intl.formatMessage({
            id: 'widget.insufficientRights',
          }),
        error: true,
        message: intl
          ? intl.formatMessage({
              id: 'widget.insufficientRights',
            })
          : errorMessage,
        level: 'error',
      },
      'No data found for datapoint(s)': {
        icon: 'fas fa-database',
        label:
          intl &&
          intl.formatMessage({
            id: 'widget.noDataForDataPoint',
          }),
        error: true,
        message: intl
          ? intl.formatMessage({
              id: 'widget.noDataForDataPoint',
            })
          : errorMessage,
        level: 'info',
      },
      'Failed to fetch': {
        icon: 'fas fa-globe',
        label:
          intl &&
          intl.formatMessage({
            id: 'widget.noInternetConnection',
          }),
        error: true,
        message: intl
          ? intl.formatMessage({
              id: 'widget.noInternetConnection',
            })
          : errorMessage,
        level: 'error',
      },
      configurationError: {
        icon: 'fas fa-circle-xmark',
        label:
          intl &&
          intl.formatMessage({
            id: 'widget.configurationError',
          }),
        error: true,
        message: intl
          ? intl.formatMessage({
              id: 'widget.configurationError',
            })
          : errorMessage,
        level: 'error',
      },
    }[errorMessage] ||
    (errorMessage && {
      icon: 'fas fa-circle-xmark',
      label:
        intl &&
        intl.formatMessage({
          id: 'widget.noDataForDataPoint',
        }),
      error: true,
      message: errorMessage,
      level: 'error',
    })
  )
}

export const capitalizeString = (string) => string.charAt(0).toUpperCase() + string.slice(1)
