import styled from 'styled-components'

const Skeleton = styled.span`
  display: inline-block;
  background: var(--color-softerStroke);
  width: ${({ width }) => width};
  max-width: 100%;
  height: 100%;
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
  @keyframes shine-lines {
    0% {
      background-position: 0%;
    }
    99% {
      background-position: 320%;
    }
    100% {
      background-position: 0%;
    }
  }
`
export default Skeleton
