import { Layout } from 'antd'
import styled from 'styled-components'

import { getRemValue, mediaQuery } from 'util/styledHelpers'

export const LoginWindow = styled(Layout)`
  overflow: auto;
  height: auto;
  min-height: 100vh;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: ${({ theme }) =>
      `url(${theme.images?.background || '/resources/images/BACKGROUND.jpg'})`};
    background-size: cover;
  }
  .ant-layout-content {
    position: relative;
    z-index: 1;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  height: 100%;
  transition: background-image 0.4s linear;

  input {
    width: 100%;
  }
`

export const Header = styled.div`
  padding: ${getRemValue(20)} ${getRemValue(20)} 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 0;
  align-items: center;
  width: 100%;
  ${mediaQuery.laptop`
    padding: ${getRemValue(40)};
    justify-content: flex-start;
    flex-wrap: wrap;
  `}
`

export const Logo = styled.div`
  max-height: ${getRemValue(40)};
  flex-shrink: 0;

  img {
    height: ${getRemValue(40)};
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Footer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--color-mainText);
  ${mediaQuery.laptop`
    padding: ${getRemValue(40)};
  `}
`
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  span {
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: ${getRemValue(10)};
      margin-right: 0;
      ${mediaQuery.laptop`
        margin-bottom: 0;
        margin-right: ${getRemValue(10)};
    `}
    }
  }

  ${mediaQuery.laptop`
  flex-direction: row;
`}
`
