import { useMemo } from 'react'

import dayjs from 'dayjs'

const getPresetObject = (preset) =>
  ({
    thisDay: {
      amount: 0,
      unit: 'day',
      startOfUnit: 'day',
    },
    thisWeek: {
      amount: 0,
      unit: 'week',
      startOfUnit: 'week',
    },
    thisMonth: {
      amount: 0,
      unit: 'month',
      startOfUnit: 'month',
    },
    pastDay: {
      amount: 1,
      unit: 'day',
      startOfUnit: null,
    },
    past7Days: {
      amount: 7,
      unit: 'day',
      startOfUnit: null,
    },
    past21Days: {
      amount: 21,
      unit: 'day',
      startOfUnit: null,
    },
    past28Days: {
      amount: 28,
      unit: 'day',
      startOfUnit: null,
    },
    yesterday: {
      amount: 1,
      unit: 'day',
      startOfUnit: 'day',
    },
    dayBefore: {
      amount: 2,
      unit: 'day',
      startOfUnit: 'day',
    },
    thisDayLastWeek: {
      amount: 1,
      unit: 'day',
      startOfUnit: 'day',
    },
    previousWeek: {
      amount: 1,
      unit: 'week',
      startOfUnit: 'week',
    },
    previousMonth: {
      amount: 1,
      unit: 'month',
      startOfUnit: 'month',
    },
  }[preset])

export const parseDefaultTime = (config, type, referenceTimeRange) => {
  let time = referenceTimeRange?.startTime ? dayjs(referenceTimeRange.startTime) : dayjs()

  if (!config) {
    return time
  }

  const { amount, unit, startOfUnit, duration, durationUnit, preset, startTime, endTime } = config
  if (type === 'startTime' && startTime) {
    return dayjs(startTime)
  }
  if (type === 'endTime' && endTime) {
    return dayjs(endTime)
  }

  if (preset) {
    switch (preset) {
      case 'none':
        return null
      case 'thisDay':
        return type === 'startTime' ? time.startOf('day') : null
      case 'thisWeek':
        return type === 'startTime' ? time.startOf('week') : null
      case 'thisMonth':
        return type === 'startTime' ? time.startOf('month') : null
      case 'thisYear':
        return type === 'startTime' ? time.startOf('year') : null
      case 'pastDay':
        return type === 'startTime' ? time.subtract(1, 'days') : null
      case 'past7Days':
        return type === 'startTime' ? time.subtract(7, 'days') : null
      case 'past21Days':
        return type === 'startTime' ? time.subtract(21, 'days') : null
      case 'past28Days':
        return type === 'startTime' ? time.subtract(28, 'days') : null
      case 'yesterday':
        return type === 'startTime' ? time.startOf('day').subtract(1, 'day') : time.startOf('day')
      case 'dayBefore':
        return type === 'startTime'
          ? time.startOf('day').subtract(2, 'day')
          : time.startOf('day').subtract(1, 'day')
      case 'thisDayLastWeek':
        return type === 'startTime'
          ? time.startOf('day').subtract(1, 'week')
          : time.startOf('day').subtract(6, 'days')
      case 'previousWeek':
        return type === 'startTime'
          ? time.startOf('week').subtract(1, 'week')
          : time.startOf('week')
      case 'previousMonth':
        return type === 'startTime'
          ? time.startOf('month').subtract(1, 'month')
          : time.startOf('month')
      case 'previousYear':
        return type === 'startTime'
          ? time.startOf('year').subtract(1, 'year')
          : time.startOf('year')
      default:
        console.warn('Unknown preset')
        break
    }
  }

  if (amount > 0 && unit) {
    time = time.subtract(amount, unit)
    if (type === 'endTime') {
      if (startOfUnit) {
        time.startOf(startOfUnit)
      }
      return duration ? time.add(duration, durationUnit || unit) : null
    }
  }

  if (startOfUnit) {
    return type === 'startTime' ? time.startOf(startOfUnit) : null
  }

  return time
}

export const getDefaultTimeRange = (timeRange, referenceTimeRange) => {
  if (!timeRange || timeRange.preset === 'none') {
    return {}
  }
  const parsedStartTime = parseDefaultTime(timeRange, 'startTime', referenceTimeRange)
  const parsedEndTime = parseDefaultTime(timeRange, 'endTime', referenceTimeRange)
  return {
    startTime: parsedStartTime?.valueOf() || null,
    endTime: parsedEndTime?.valueOf() || null,
  }
}

export const getGranularityForTimeRange = (value, heatMapIntervals = false, calculationMethod) => {
  if (calculationMethod === 'raw' || !value) {
    return {}
  }

  if (value.unit) {
    return {
      defaultGranularity: {
        hour: 'm',
        day: 'h',
        week: 'h',
        month: 'd',
        year: 'MS',
      }[value.unit],
      defaultGranularityValue: heatMapIntervals
        ? {
            hour: 1,
            day: value.amount > 3 ? 15 : 1,
            week: 1,
            month: 1,
            year: 1,
          }[value.unit]
        : {
            hour: value.amount < 4 ? 1 : 15,
            day: value.amount < 7 ? 1 : 4,
            week: value.amount > 1 ? 4 : 1,
            month: 1,
            year: 1,
          }[value.unit],
      defaultRawData: false,
      hideRawData: !(value.unit === 'hour' && value.amount < 24),
      allowedIntervals: {
        hour: ['m', 'h'],
        day: heatMapIntervals ? ['d', 'h'] : ['d', 'h', 'm'],
        week: heatMapIntervals || value.amount > 1 ? ['w', 'd', 'h'] : ['w', 'd', 'h', 'm'],
        month: heatMapIntervals ? ['MS', 'w', 'd'] : ['MS', 'w', 'd', 'h'],
        year: heatMapIntervals ? ['Y', 'MS', 'w'] : ['Y', 'MS', 'w', 'd'],
      }[value.unit],
    }
  }
  if (
    value.preset === 'thisDay' ||
    value.preset === 'yesterday' ||
    value.preset === 'dayBefore' ||
    value.preset === 'thisDayLastWeek' ||
    value.preset === 'pastDay'
  ) {
    return {
      defaultGranularity: heatMapIntervals ? 'h' : 'm',
      defaultGranularityValue: 1,
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['d', 'h'] : ['d', 'h', 'm'],
    }
  }
  if (
    value.preset === 'thisWeek' ||
    value.preset === 'previousWeek' ||
    value.preset === 'past7Days'
  ) {
    return {
      defaultGranularity: 'd',
      defaultGranularityValue: 1,
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['w', 'd', 'h'] : ['w', 'd', 'h', 'm'],
    }
  }
  if (
    value.preset === 'thisMonth' ||
    value.preset === 'previousMonth' ||
    value.preset === 'past28Days'
  ) {
    return {
      defaultGranularity: 'd',
      defaultGranularityValue: 1,
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['MS', 'w', 'd'] : ['MS', 'w', 'd', 'h'],
    }
  }
  if (value.preset === 'thisYear' || value.preset === 'previousYear') {
    return {
      defaultGranularity: 'MS',
      defaultGranularityValue: 1,
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['Y', 'MS', 'w'] : ['Y', 'MS', 'w', 'd'],
    }
  }
  const { startTime, endTime } = getDefaultTimeRange(value)
  const duration = (endTime || dayjs().valueOf()) - startTime

  if (duration < 14400000) {
    // 4 hours
    return {
      defaultGranularityValue: 1,
      defaultGranularity: 'm',
      allowedIntervals: ['h', 'm'],
    }
  }
  if (duration < 86400000) {
    // 1 day
    return {
      defaultGranularityValue: 1,
      defaultGranularity: heatMapIntervals ? 'h' : 'm',
      allowedIntervals: heatMapIntervals ? ['d', 'h'] : ['d', 'h', 'm'],
    }
  }
  if (duration < 604800000) {
    // 1 week
    return {
      defaultGranularityValue: 30,
      defaultGranularity: 'm',
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['w', 'd', 'h'] : ['w', 'd', 'h', 'm'],
    }
  }
  if (duration < 2419200000) {
    // 28days
    return {
      defaultGranularityValue: 1,
      defaultGranularity: 'h',
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['w', 'd'] : ['w', 'd', 'h'],
    }
  }
  if (duration < 31535999000) {
    return {
      defaultGranularityValue: 1,
      defaultGranularity: 'h',
      defaultRawData: false,
      hideRawData: true,
      allowedIntervals: heatMapIntervals ? ['MS', 'w', 'd'] : ['MS', 'w', 'd', 'h'],
    }
  }
  return {
    defaultGranularityValue: 1,
    defaultGranularity: 'd',
    defaultRawData: false,
    hideRawData: true,
    allowedIntervals: heatMapIntervals ? ['Y', 'MS', 'w'] : ['Y', 'MS', 'w', 'd'],
  }
}

export const getGranularityMomentUnit = (granularityUnit) => {
  if (granularityUnit === 'MS') {
    return 'month'
  }
  if (granularityUnit === 'Y') {
    return 'year'
  }
  return granularityUnit
}
export const getStartEndTime = ({
  startTime,
  endTime,
  granularity,
  defaultStartTime,
  extendPeriod,
}) => {
  const dayjsUnit = getGranularityMomentUnit(granularity)

  const start = startTime ? startTime.startOf(dayjsUnit).valueOf() : undefined

  const end = endTime
    ? endTime.endOf(dayjsUnit).valueOf()
    : extendPeriod && defaultStartTime.preset
    ? dayjs(start).add(1, defaultStartTime.preset.replace('this', '').toLowerCase()).valueOf()
    : undefined

  return { end, start, dayjsUnit }
}

export const granularityCompare = (g1, g2) => {
  const order = ['MS', 'w', 'd', 'h', 'm', 's']
  const g1Index = order.findIndex((i) => i === g1)
  const g2Index = order.findIndex((i) => i === g2)
  return g1Index < g2Index ? 1 : -1
}

export const useDefaultTimeRange = (timeRange, defaultTimeRange, skip) => {
  const startTimeDefault = useMemo(
    () =>
      skip
        ? {}
        : {
            startTime: getDefaultTimeRange(defaultTimeRange).startTime,
            endTime: dayjs().valueOf(),
            preset: timeRange?.preset,
            presetObject: timeRange?.preset && getPresetObject(timeRange?.preset),
          },
    // eslint-disable-next-line
    [skip]
  )

  const { startTime, endTime } = useMemo(() => (skip ? {} : getDefaultTimeRange(timeRange)), [
    timeRange,
    skip,
  ])

  return skip
    ? {}
    : {
        startTime: (startTime || startTimeDefault.startTime).valueOf(),
        endTime: (endTime || startTimeDefault.endTime).valueOf(),
        preset: timeRange?.preset,
        presetObject: timeRange?.preset && getPresetObject(timeRange?.preset),
      }
}
