import { transparentize } from 'polished'
import styled from 'styled-components'

const TextAreaStyled = styled.div`
  position: relative;

  textarea {
    transition: all 0.3s;
    border: 1px solid var(--color-softStroke);
    padding: 5px 13px 2rem;
    border-radius: 2px;
    background: transparent;
    width: 100%;

    &:focus,
    &:hover {
      border-color: var(--color-main);
      border-right-width: 1px !important;
      outline: var(--color-main);
    }

    ::selection {
      background: ${({ theme }) => transparentize(0.8, theme.color.main)};
    }
  }
`

export const CharacterCountStyled = styled.span`
  color: ${({ status, theme }) => theme.color[status]};
  border: 1px solid ${({ status, theme }) => theme.color[status]};
  border-radius: 1rem;
  padding: 0 0.5rem;
  position: absolute;
  bottom: 1rem;
  left: 13px;
  background: ${({ theme }) => transparentize(0.1, theme.color.backgroundFront)};
  line-height: 1.4;
  box-shadow: var(--shadow-shadowElevation1);
`
export default TextAreaStyled
