import { Button } from 'antd'
import styled, { css } from 'styled-components'

export const NavButtonStyled = styled(Button)`
  margin-right: 1em;
  border-radius: 5px;
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? theme.color.softStroke : theme.color.highlight)} !important;
  background-color: ${({ theme, type, disabled }) => {
    if (disabled) {
      return theme.color.softer
    }

    return type === 'primary' ? theme.color.highlight : 'transparent'
  }} !important;

  svg path {
    fill: ${({ theme, type, disabled }) =>
      type === 'primary' && !disabled
        ? 'var(--color-highlight)'
        : 'var(--color-mainText)'} !important;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--color-highlight);
        background-color: ${({ theme, type }) =>
          type === 'primary' ? theme.color.backgroundFront : 'var(--color-highlight)'} !important;

        svg path {
          fill: ${({ theme, type }) =>
            type === 'primary'
              ? 'var(--color-highlight)'
              : 'var(--color-highlightText)'} !important;
        }
      }
    `}
`
