import { format } from 'date-fns'
import { enUS, fr, nl } from 'date-fns/locale'

const locales = {
  'nl-NL': nl,
  en: enUS,
  'fr-FR': fr,
}

export const getLocalizedFormattedDate = (date, displayFormat, locale) =>
  format(new Date(date), displayFormat, { locale: locales[locale] })
