import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Caption, { Title } from 'components/Global/Caption/styled'
import Icon from 'components/Global/Icon/styled'
import TextExtended from 'components/Global/TextExtended/styled'

const SparkleStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => (theme.screenSize.screen.current === 's' ? '12rem' : '100%')};
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-shadowElevation1);
  background: var(--color-backgroundFront);

  .CaptionTooltip {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${({ to, href }) =>
    (to || href) &&
    css`
      transition: border 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid ${({ theme, color }) => transparentize(0.5, color || theme.color.main)};
        transform: scale(1.02);
        box-shadow: var(--shadow-shadowElevation3);
      }
    `}
  .ChartCard__container {
    flex: 1;
    overflow: hidden;

    > div {
      height: 100%;
    }
  }

  ${Caption} {
    padding: 0.7rem;
    display: grid;
    grid-template: auto / 1fr 3rem;
    align-items: center;
    margin: 0;

    ${Icon} {
      grid-column: 2;
      grid-row: 1 / span2;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem !important;
      height: 2.5rem;
      font-size: 1.3rem;
      color: var(--color-white);
      border-radius: 0.3rem;
      align-self: start;
      justify-self: end;

      &:not(.type-img) {
        padding: 0.5rem;
        background: ${({ color }) => color || 'var(--color-main)'};
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    ${Title} {
      text-align: left;
      font-size: 1.1rem;
      line-height: 1.3;
      font-weight: 300;
      color: var(--color-softText);
      min-height: 3rem;
    }
  }

  ${TextExtended} {
    color: var(--color-strongText);
    text-align: left;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 600;
    grid-column: 1;

    label {
      font-size: 1rem;
      margin-left: 0.2rem;
    }
  }
`
export default SparkleStyled
