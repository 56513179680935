import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Label, Value } from 'components/Form/components/Field/styled'

export const LocationSearchFieldStyled = styled(Field)`
  ${Value} {
    display: flex;
    align-items: center;
  }

  ${Label} {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`
