const config = {
  displayProps: {},
  editProps: {
    name: 'widget.statusGrid',
    category: 'widget.statusGrid',
    keywords: ['other', 'data entry'],
    img: '/resources/images/widgets/sdg.png',
    info: {
      'fr-FR': '',
      'en-US': '',
      'nl-NL': '',
    },
    default: {
      type: 'DataTable',
      datapoints: [],
    },
    preview: {
      icon: 'fas fa-calendar',
    },
    fields: [
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapoints',
          hideMethod: true,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              dataKey: 'datapoints',
              hideMethod: true,
              fields: [
                {
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
                {
                  componentName: 'UnitPicker',
                  label: { formatted: 'widget.unit' },
                  dataKey: 'conversionUnit',
                  componentProps: {
                    dataKey: 'datapoints',
                  },
                },
              ],
            },
          },
        },
      },
    ],
  },
}

export default config
