import { transparentize } from 'polished'
import styled from 'styled-components'

const HighlightStyled = styled.div`
  transition: background 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${({ theme, color, hoveredEdge, inheritedColor }) =>
    `1px solid ${
      (hoveredEdge && hoveredEdge.color) || inheritedColor || color || theme.color.main
    }`};
  background: ${({ theme, color, opacity, hoveredEdge, inheritedColor }) =>
    transparentize(
      1 - (opacity || 1),
      (hoveredEdge && hoveredEdge.color) || color || inheritedColor || theme.color.main
    )};
  border-radius: ${({ circle }) => (circle ? '100%' : 0)};
  transform: rotate(${({ rotation }) => rotation}deg);

  .Loader {
    border-radius: ${({ circle }) => (circle ? '100%' : 0)};
  }

  .ResizeHandler {
    display: ${({ isSelected, isPreviewMode }) => (isSelected && isPreviewMode ? 'block' : 'none')};
  }
`

export const ResizeHandler = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  background: ${({ theme, color }) => color || 'var(--color-main)'};
  z-index: 10;

  &#resize {
    right: -4px;
    bottom: -4px;
  }

  &#rotate {
    left: 50%;
    top: -30px;
    margin-left: -4px;
    border-radius: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 4px;
      height: 22px;
      width: 1px;
      background: ${({ theme, color }) => color || 'var(--color-mainText)'};
    }
  }
`
export default HighlightStyled
