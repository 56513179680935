import { lighten } from 'polished'
import styled from 'styled-components'

const RangeSliderStyled = styled.div`
  .ant-slider-handle.ant-tooltip-open {
    border-color: var(--color-main);
  }

  .ant-slider-handle,
  .ant-slider-dot {
    border-color: ${({ theme }) => lighten(0.3, theme.color.main)};
    background-color: var(--color-backgroundFront);
  }

  .ant-slider-track {
    background-color: ${({ theme }) => lighten(0.3, theme.color.main)};
  }

  .ant-slider-mark-text {
    color: var(--color-softText);
  }

  .ant-slider {
    &:hover {
      .ant-slider-rail {
        background-color: var(--color-softStroke);
      }
    }
  }

  .ant-slider-rail {
    background-color: var(--color-softerStroke);
  }

  margin: 0 0 1rem;
`
export default RangeSliderStyled
