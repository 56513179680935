const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.yearOverview',
    category: 'widget.grids',
    keywords: ['grid', 'table'],
    img: '/resources/images/widgets/grid.png',
    info: {
      'fr-FR':
        "<h2>Grille d'aperçu de l'année</h2>\n" +
        "<p>Le widget Aperçu de l'année affiche les données de mesure pour une année donnée sur une base mensuelle.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong>Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données (LIEN)</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez les points de données du widget</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Decimals </strong> Configurez le nombre de décimales à afficher. La valeur sera arrondie.</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<ul>\n' +
        '<li><strong>Point(s) de données </strong>Sélectionnez un point de données à configurer</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '</ul>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Saisissez un libellé pour le point de données</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Year Overview Grid</h2>\n' +
        '<p>The Year Overview widget displays metering data for a given year on a monthly basis.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong>Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select widget datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Decimals </strong> Configure number of decimals to be shown. Value will be rounded.</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapoint(s) </strong>Select a datapoint to be configured</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '</ul>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Enter a label for the datapoint</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Jaaroverzichtsraster</h2>\n' +
        '<p>De widget Jaaroverzicht geeft maandelijks meetgegevens voor een bepaald jaar weer.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Pictogram </strong>Widgetpictogram</li>\n' +
        '<li><strong>Kleur </strong>Widgetpictogramkleur</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten </strong> Widgetgegevenspunten selecteren</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Decimalen </strong> Configureer het aantal decimalen dat moet worden weergegeven. Waarde wordt afgerond.</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<ul>\n' +
        '<li><strong>Datapunt(en) </strong>Selecteer een te configureren datapunt</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Voer een label in voor het datapunt</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'YearOverview',
      title: 'widget.yearOverview',
      icon: 'fas fa-info',
      color: null,
      datapoints: [],
      decimals: 2,
    },
    unUsedDatapointProperties: [
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-table',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.percentage' },
        dataKey: 'percentage',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapoints',
          method: 'method',
          fixedUnit: true,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              dataKey: 'datapoints',
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'caption',
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.fixedValues',
        componentName: 'NumberInput',
        label: { formatted: 'widget.decimals' },
        dataKey: 'decimals',
      },
    ],
  },
}

export default config
