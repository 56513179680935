import { transparentize } from 'polished'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  top: 1em;
  transition: transform 0.2s ease;
  min-width: 5rem;
  height: 5rem;
  padding: 0 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  img,
  .ant-image {
    height: 100%;
  }

  .Upload {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 100%;
      object-fit: contain;
      width: auto;
      margin-right: 1rem;
    }
  }
  .Button {
    z-index: 100;
    transition: background-color 0.2s ease;
    border: 1px solid var(--color-softText);
    border-radius: 1rem;
    padding: 0 1rem;
    background-color: ${({ theme }) => transparentize(0.2, theme.color.backgroundFront)};
    font-size: 0.9rem;
    margin: 1rem;

    &:hover {
      background-color: var(--color-backgroundFront);
    }

    .Icon {
      margin-right: 0.5rem;
    }
  }
`
