import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      message
      argreementRequired
      switchAllowed
      homeDashboardId
    }
  }
`

export const LOGIN_AS_MUTATION = gql`
  mutation aliasLogin($email: String!, $aliasId: Float!, $password: String!) {
    aliasLogin(email: $email, aliasId: $aliasId, password: $password) {
      success
      message
      argreementRequired
      switchAllowed
    }
  }
`

export const AGREE_TO_TERMS_MUTATION = gql`
  mutation agreeToTerms {
    agreeToTerms
  }
`

export const ME_QUERY = gql`
  query me {
    me {
      id
      language {
        code
      }
      admin
      company {
        id
        name
        dealer {
          id
        }
        entity {
          id
        }
        isActive
      }
      email
      firstname
      surname
      avatar
      advanced
    }
  }
`

export const ACCOUNT_QUERY = gql`
  query account($id: Float!) {
    account(id: $id) {
      id
      inActive
      email
      firstname
      surname
      admin
      phone
      mobile
      language {
        code
      }
      roles {
        id
        company {
          id
          name
        }
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const ACCOUNT_BY_EMAIL_QUERY = gql`
  query accountByEmail($email: String!) {
    accountByEmail(email: $email) {
      id
      email
      firstname
      surname
    }
  }
`

const PROFILE_QUERY = gql`
  query account($id: Float!) {
    account(id: $id) {
      id
      email
      firstname
      surname
      phone
      mobile
      avatar
    }
  }
`

export const COMPANY_ACCOUNTS_QUERY = gql`
  query companyAccounts($companyId: Float!, $onlyActive: Boolean) {
    companyAccounts(companyId: $companyId, onlyActive: $onlyActive) {
      id
      email
      firstname
      surname
      phone
      mobile
      createdAt
      updatedAt
    }
  }
`

export const useCompanyAccounts = (onlyActive) => {
  const customer = useCurrentCustomer()

  const { data, ...rest } = useQuery(COMPANY_ACCOUNTS_QUERY, {
    variables: {
      companyId: customer?.id,
      onlyActive,
    },
    skip: !customer?.id,
    notifyOnNetworkStatusChange: true,
    // ...options,
  })

  return { data: data?.companyAccounts, ...rest }
}

export const LANGUAGE_QUERY = gql`
  query language {
    language
  }
`

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount($data: AccountUpdateInput!) {
    updateAccount(data: $data) {
      success
      message
    }
  }
`
export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($data: AccountUpdateInput!) {
    updateProfile(data: $data) {
      success
      message
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation forgot($email: String!) {
    forgot(email: $email)
  }
`

export const RESET_TOKEN = gql`
  query token($token: String!) {
    token(token: $token) {
      success
      message
      token
    }
  }
`

export const SET_PASS = gql`
  mutation resetpassword($token: String!, $password: String!) {
    resetpassword(token: $token, password: $password) {
      success
      message
    }
  }
`
export const CREATE_SUPPORT_TICKET = gql`
  mutation createSupportTicket($data: SupportTicketInput!) {
    createSupportTicket(data: $data)
  }
`

export const useAccountDetails = (id) => {
  const { data, error, loading } = useQuery(ACCOUNT_QUERY, {
    variables: { id },
    skip: !id,
  })
  return { data, error, loading }
}

export const useProfileDetails = (id) => {
  const { data, error, loading } = useQuery(PROFILE_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  })
  return { data, error, loading }
}

const DEFAULT_LOCALE = 'en-US'
export const useLocaleHook = () => {
  const { data, error, loading } = useQuery(ME_QUERY)
  if (error) {
    console.error('Error occured fetching user locale, returning default en-US')
    return DEFAULT_LOCALE
  }
  if (loading) {
    return DEFAULT_LOCALE
  }
  return (data?.me.language && data?.me.language.code) || DEFAULT_LOCALE
}

export const useUser = () => {
  const { data, ...options } = useQuery(ME_QUERY)

  return [data?.me, options]
}

const QUERY_CURRENT_ORGANISATION = gql`
  query me {
    me {
      id
      company {
        id
        organisation {
          id
          name
        }
      }
    }
  }
`

export const QUERY_USERS = gql`
  query accounts($aliasId: String) {
    accounts(aliasId: $aliasId) {
      id
      email
    }
  }
`

export const useCurrentOrganisation = () => {
  const { data, ...rest } = useQuery(QUERY_CURRENT_ORGANISATION)

  return useMemo(
    () => ({
      data: data?.me?.company?.organisation,
      ...rest,
    }),
    [data, rest]
  )
}
