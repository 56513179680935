const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.energyClassification',
    category: 'widget.gaugesIndicators',
    keywords: ['bulletgraph', 'indicator'],
    img: '/resources/images/widgets/bulletgraph.png',
    info: {
      'fr-FR':
        '<h2>Classement énergétique</h2>\n' +
        "<p>Le widget Classification énergétique affiche le score énergétique d'un équipement donné pour une zone donnée sur une période donnée.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur d'arrière-plan</strong> Couleur d'arrière-plan de l'en-tête</li>\n" +
        "<li><strong>Couleur de la police</strong> Couleur de la police de l'en-tête</li>\n" +
        '<li><strong>Taille</strong> Sélectionnez la taille du widget</li>\n' +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Classification énergétique</strong> Sélectionnez un élément de classification énergétique</li>\n' +
        '<h5>Période de comparaison</h5>\n' +
        '<li><strong>Points de données (LINK)</strong> Sélectionnez un ou plusieurs points de données de widget ou effacez tous les points de données sélectionnés</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date personnalisée</strong> Sélectionnez une date personnalisée</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<li><strong>Point(s) de données</strong>Sélectionnez un point de données à configurer</li>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Surface</strong> Sélectionnez une entité dont la propriété de surface sera utilisée pour le calcul</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Energy Classification</h2>\n' +
        '<p>The Energy Classification widget shows the energy score of a given equipment for a given area over a given period.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Background Color</strong> Header's background color</li>\n" +
        "<li><strong>Font Color</strong> Header's font color</li>\n" +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Energy Classification</strong> Select an Energy Classification item</li>\n' +
        '<h5>Comparison period</h5>\n' +
        '<li><strong>Datapoints (LINK)</strong> Select one or more widget datapoints or clear all selected datapoints</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Datapoints</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Custom Date</strong> Select a custom date</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<li><strong>Datapoint(s) </strong>Select a datapoint to be configured</li>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Area</strong> Select an entity whose area property will be used for the caluclation</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Energieclassificatie</h2>\n' +
        '<p>De widget Energieclassificatie toont de energiescore van een bepaald apparaat voor een bepaald gebied gedurende een bepaalde periode.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Titel van widget</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Achtergrondkleur</strong> Achtergrondkleur kop</li>\n' +
        '<li><strong>Letterkleur</strong> Letterkleur van kop</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Energieclassificatie</strong> Selecteer een item voor energieclassificatie</li>\n' +
        '<h5>Vergelijkingsperiode</h5>\n' +
        '<li><strong>Datapunten (LINK)</strong> Selecteer een of meer widget-datapunten of wis alle geselecteerde datapunten</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Gegevenspunten</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum</strong> Selecteer een aangepaste datum</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<li><strong>Datapunt(en) </strong>Selecteer een te configureren datapunt</li>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gebied</strong> Selecteer een entiteit waarvan de gebiedseigenschap zal worden gebruikt voor de berekening</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'assets',
      type: 'EnergyClassification',
      title: 'widget.energyClassification',
      icon: 'fas fa-info',
      url: null,
      datapoints: [],
      color: null,
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
    ],
    preview: {
      icon: 'fas fa-gauge',
      gauge: {},
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'ClassificationPicker',
        label: { formatted: 'widget.energyClassification' },
        dataKey: 'energyClassification',
      },
      {
        category: 'widget.datapoints',
        componentName: 'Datapoints',
        label: { formatted: 'widget.datapoints', tagName: 'h4' },
        componentProps: {
          dataKey: 'datapoints',
          singleDatapoint: true,
          tags: {
            meter: '1',
          },
          fixedUnit: 'energyClassification.unit',
        },
      },
      {
        category: 'widget.general',
        componentName: 'EntityCascader',
        dataKey: 'area',
        label: { formatted: 'widget.area' },
      },
      {
        category: 'widget.interval',
        dataKey: '',
        componentName: 'DefaultStartTimeSelector',
        label: { formatted: 'widget.comparisonPeriod', tagName: 'h4' },
        componentProps: {
          dataKey: 'comparisonPeriod',
          period: true,
          hideGranularity: true,
          hideCalculationMethod: true,
          hideLabel: true,
          defaultPeriod: 'energyClassification.interval',
        },
      },
    ],
  },
}

export default config
