import React, { useRef } from 'react'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'
import Icon from 'components/Global/Icon'

import { OnOffControlStyled } from './styled'

const OnOffControl = ({
  writeDatapoint = {},
  datapoint = {},
  iconOn,
  iconOff,
  colorOn,
  colorOff,
  value,
  confirmation,
  onConfirmationRequest,
  readOnly,
}) => {
  const element = useRef()
  const toggleState = Number(value)

  const handleToggle = () => {
    const newToggleState = !toggleState ? 1 : 0
    onConfirmationRequest({ value: newToggleState })
  }

  return (
    <OnOffControlStyled
      classname="OnOffControl"
      active={toggleState === 1}
      onClick={!readOnly && handleToggle}
      color={toggleState ? colorOn || 'var(--color-theme)' : colorOff || 'var(--color-softText)'}
      readOnly={readOnly}
      ref={element}
    >
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        id={writeDatapoint.id || datapoint.id}
        onConfirmationRequest={onConfirmationRequest}
        parentRef={element.current}
      >
        <Icon
          icon={confirmation.value ? iconOn || 'fas fa-lightbulb' : iconOff || 'far fa-lightbulb'}
        />
      </ActionConfirmationWithAdjustableValue>
      <Icon icon={toggleState ? iconOn || 'fas fa-lightbulb' : iconOff || 'far fa-lightbulb'} />
    </OnOffControlStyled>
  )
}

export default OnOffControl
