import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import Skeleton from 'components/Form/components/Skeleton/styled'

export const FieldStyled = styled.div`
  grid-row: ${({ row }) => row};
  grid-column: ${({ col }) => col};
  display: ${({ fullWidth = false }) => (fullWidth ? 'block' : 'flex')};
  justify-content: space-between;
  position: relative;
  ${props => !props.noPadding && 'padding: 0.3rem 0.5rem;'}
  margin-bottom: 2px;
  width: ${({ width }) => width};

  ${({ color }) =>
    color &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: -7px;
        width: 3px;
        height: 60%;
        background: ${({ color }) => color};
      }
    `}

  ${Skeleton} {
    width: 140px;
  }

  &.Full-width > * {
    width: 100%;
  }
`
export const Label = styled.div`
  color: var(--color-mainText);
  white-space: nowrap;
  grid-row: ${({ row }) => row};
  font-weight: 100;
  margin-right: 0.5rem;
  flex: 1;

  .Icon {
    margin-left: 0.4em;
  }
`

export const InfoText = styled.div`
  font-size: 0.9em;
  color: var(--color-softText);
`

export const WarningText = styled(InfoText)`
  color: var(--color-warning);
`

export const Value = styled.div`
  /* flex: 1; */
  color: var(--color-strongText);
`

export const FieldMessage = styled(motion.span)`
  color: var(--color-error);
  font-size: 0.8rem !important;
  font-weight: 100;
  overflow: hidden;
`

export const FieldComponent = styled.div`
  line-height: 1.3;
  color: var(--color-strongText);
  grid-row: ${({ row }) => row};
  grid-column: ${({ col }) => col};
  justify-self: start;
  margin: 0.3rem 0;
  font-weight: 500;
`

export const LinkField = styled.div`
  display: flex;
  border-radius: 3rem;
  color: var(--color-softText);
  padding-right: 0.5rem;
  align-items: center;

  strong {
    color: var(--color-strongText);
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    height: 100%;
    align-items: center;
    display: flex;
    font-weight: 600;

    &.separator {
      border-right: 1px solid var(--color-softText);
    }
  }
`
