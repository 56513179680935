import { css } from 'styled-components'

export const iconActionButton = css`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: var(--color-main);
  color: var(--color-white);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-shadowElevation1);
  transform: scale(0);
  &.right {
    position: absolute;
    right: 0;
    top: 50%;
    margin: -10px -10px 0 0;
  }
  &.bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin: 0 0 -10px -10px;
  }
  &:hover {
    box-shadow: var(--shadow-shadowElevation2);
  }
`
