import styled from 'styled-components'

const DropdownArrow = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-right: 1px solid var(--color-softText);
    border-bottom: 1px solid var(--color-softText);
    transform: rotate(45deg) translate(-3px, -3px);
  }
`

export default DropdownArrow
