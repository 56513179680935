import styled, { css } from 'styled-components'

const BaseLayoutStyled = styled.div`
  font-family: var(--font-family-primary);
  ${p => !p.theme?.darkMode ? 'background: var(--color-backgroundBack);' : 'background-image: var(--color-backgroundBack);'}
  color: var(--color-mainText);
  ${({ theme }) =>
    theme.screenSize.screen.current === 's' &&
    css`
      display: flex;
      flex-direction: column-reverse;
      height: 100vh;
    `};
`
export default BaseLayoutStyled
