import { css } from 'styled-components'

export const Widget = css`
  &.title {
    font-size: 1.2rem;
    color: var(--color-mainText);
  }
  color: var(--color-strongText);
  margin: 0;
`
