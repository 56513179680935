import { configFieldConvertor, removeObjectKeys } from '../../../../Dashboard/utils/common/helpers'
import { config as gauge } from './../../SolidGauge/Gauge/config'

const config = {
  ...gauge,
  editProps: {
    ...gauge.editProps,
    name: 'widget.relativeGauge',
    category: 'widget.gaugesIndicators',
    keywords: [...gauge.editProps.keywords, 'relative'],
    img: '/resources/images/widgets/relativegauge.png',
    info: {
      'en-US':
        '<h2>Relative Gauge</h2>\n' +
        '<p>The Relative Gauge displays the actual value of a datapoint in comparison to a reference value for the same datapoint with a certain positive and negative deviation.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong> Widget's title</li>\n" +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select a widget datapoint</li>\n' +
        '<li><strong>Calculation Method</strong> Select the calculation method in case of multiple datapoints</li>\n' +
        '<li><strong>Show reference value </strong> Display the reference value or not</li>\n' +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Date/Period</strong> Select a custom date/period for the selected value</li>\n' +
        '</ul>\n' +
        '<h5>Comparison Period - Time Range for Main Datapoint(s)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Date/Period</strong> Select a custom date/period for the selected value</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Max deviation in % </strong> Determine the maximum deviation from the reference value (applies to both min and max)</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Relatieve meter</h2>\n' +
        '<p>De relatieve meter geeft de werkelijke waarde van een datapunt weer in vergelijking met een referentiewaarde voor hetzelfde datapunt met een bepaalde positieve en negatieve afwijking.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong> Widgets titel</li>\n' +
        '<li><strong>Pictogram </strong> Widgetpictogram</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Gegevenspunten </strong> Selecteer een widgetgegevenspunt</li>\n' +
        '<li><strong>Berekeningsmethode</strong> Selecteer de berekeningsmethode in geval van meerdere datapunten</li>\n' +
        '<li><strong>Referentiewaarde weergeven </strong> Referentiewaarde weergeven of niet</li>\n' +
        '<li><strong>Kleuren </strong> Definieer kleuren voor de overgang tussen waarden</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum/periode</strong> Selecteer een aangepaste datum/periode voor de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h5>Vergelijkingsperiode - Tijdsbereik voor belangrijkste datapunt(en)</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste datum/periode</strong> Selecteer een aangepaste datum/periode voor de geselecteerde waarde</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Max. afwijking in % </strong> Bepaal de maximale afwijking van de referentiewaarde (geldt voor zowel min als max)</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
      'fr-FR':
        '<h2>Jauge relative</h2>\n' +
        "<p>La jauge relative affiche la valeur réelle d'un point de données par rapport à une valeur de référence pour le même point de données avec un certain écart positif et négatif.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong> Titre du widget</li>\n' +
        '<li><strong>Icône </strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez un point de données de widget</li>\n' +
        '<li><strong>Méthode de calcul</strong> Sélectionnez la méthode de calcul en cas de points de données multiples</li>\n' +
        '<li><strong>Afficher la valeur de référence </strong> Afficher ou non la valeur de référence</li>\n' +
        '<li><strong>Couleurs </strong> Définir les couleurs pour la transition entre les valeurs</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps pour les points de données principaux</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date/période personnalisée</strong> Sélectionnez une date/période personnalisée pour la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h5>Période de comparaison - Plage de temps pour les principaux points de données</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Date/période personnalisée</strong> Sélectionnez une date/période personnalisée pour la valeur sélectionnée</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        "<li><strong>Écart maximum en % </strong> Déterminer l'écart maximum par rapport à la valeur de référence (s'applique à la fois au minimum et au maximum)</li>\n" +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
    },
    default: {
      ...removeObjectKeys(gauge.editProps.default, [
        'minComparatorsMethod',
        'maxComparatorsMethod',
        'minComparators',
        'maxComparators',
      ]),
      type: 'RelativeGauge',
      title: 'widget.relativeGauge',
      comparators: [],
      comparatorsMethod: 'mean',
    },
    preview: {
      icon: 'fas fa-gauge',
      gauge: {
        ...gauge.editProps.preview.gauge,
        series: [
          {
            ...gauge.editProps.preview.gauge.series[0],
            type: 'gauge',
          },
        ],
      },
    },
    fields: configFieldConvertor(gauge.editProps.fields, [
      {
        selector: (field) => field.componentProps?.dataKey === 'minComparators',
        resolver: () => 'exclude',
      },
      {
        selector: (field) => field.componentProps?.dataKey === 'maxComparators',
        resolver: (field) => [
          {
            ...field,
            label: { formatted: 'widget.comparisonPoint', tagName: 'h4' },
            componentProps: {
              ...field.componentProps,
              dataKey: 'comparators',
              method: 'comparatorMethod',
            },
          },
          {
            category: 'widget.datapoints',
            componentName: 'Switch',
            label: { formatted: 'widget.showComparator' },
            dataKey: 'showComparator',
            col: 'col2',
          },
          {
            category: 'widget.datapoints',
            componentName: 'NumberInput',
            label: { formatted: 'widget.deviation' },
            dataKey: 'deviation',
          },
        ],
      },
      {
        selector: (field) => field.componentProps?.dataKey === 'comparisonPeriod',
        resolver: (field) => ({
          ...field,
          componentProps: {
            ...field.componentProps,
            datapointsDataKey: 'comparators',
          },
        }),
      },
    ]),
  },
}

export default config
