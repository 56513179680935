import { gql, useQuery } from '@apollo/client'

export const GET_COMPANY_BY_ID = gql`
  query company($companyId: Float!) {
    company(companyId: $companyId) {
      id
      name
      dataLimit
      smsLimit
      isDealer
      superCompanyRoles {
        id
        company {
          id
        }
        name
      }
      admin {
        surname
        firstname
        id
        roles {
          name
          id
        }
      }
    }
  }
`

export const GET_HANDLED_COMPANIES_BY_ID = gql`
  query company($companyId: Float!) {
    company(companyId: $companyId) {
      id
      name
      isDealer
      handledCompanies {
        id
        name
      }
    }
  }
`
export const UPDATE_COMPANY_RELATION = gql`
  mutation updateCompanyRelation($data: CompanyRelationUpdateInput!) {
    updateCompanyRelation(data: $data) {
      message
      success
    }
  }
`
export const MANAGED_COMPANY_QUERY = gql`
  query managedCompany($companyId: Float) {
    managedCompany(companyId: $companyId) {
      id
      name
      accounts {
        id
        email
        firstname
        surname
        phone
        createdAt
        updatedAt
        inActive
      }
      subCompanies {
        id
        subCompany {
          id
          name
          admin {
            id
            firstname
            surname
            email
          }
        }
        roles {
          id
          name
        }
      }
    }
  }
`

const GET_CUSTOMER_BY_ID = gql`
  query customer($customerId: Float!) {
    customer(customerId: $customerId) {
      id
      dataLimit
      name
      smsLimit
      street
      streetNumber
      postalCode
      city
      country {
        id
        name
      }
      street2
      streetNumber2
      postalCode2
      city2
      country2 {
        id
        name
      }
      internalId
      energyClassification {
        id
        name
      }
      reportingYear {
        id
        period
      }
      legalEntity {
        id
        description
      }
      vatNumber
      website
      createdAt
      updatedAt
      entity {
        id
      }
      admin {
        email
      }
      isActive
      isDealer
      logo
      dealer {
        id
        name
      }
      customerMail
      customerCode
      modules {
        id
        key
        group {
          id
          key
        }
      }
      license {
        id
        key
      }
    }
  }
`

const CHECK_FOR_CLIENT_QUERY = gql`
  query hasClients($customerId: Float!) {
    hasClients(customerId: $customerId)
  }
`

export const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCustomer($data: CompanyUpdateInput!, $companyId: Float!) {
    updateCustomer(data: $data, companyId: $companyId) {
      success
      message
    }
  }
`

export const INSERT_COMPANY_MUTATION = gql`
  mutation insertCustomer($data: CompanyInsertInput!) {
    insertCustomer(data: $data)
  }
`

export const CUSTOMER_OVERVIEW_QUERY = gql`
  query customers {
    customers {
      id
      isActive
      name
      street
      streetNumber
      postalCode
      city
      country {
        id
        name
      }
      vatNumber
      logo
      website
      dealer {
        id
        name
      }
      isDealer
      entity {
        id
      }
      admin {
        email
      }
      dataLimit
      smsLimit
      license {
        id
        key
      }
    }
  }
`

export const DEALER_OVERVIEW_QUERY = gql`
  query dealers($dealerId: Float!) {
    dealers(dealerId: $dealerId) {
      id
      name
    }
  }
`

const COUNTRY_LIST_QUERY = gql`
  query countries($addAllOption: Boolean) {
    countries(addAllOption: $addAllOption) {
      id
      name
      alpha2
    }
  }
`

export const useCustomerOverviewQuery = () => {
  const { data, loading, error, refetch } = useQuery(CUSTOMER_OVERVIEW_QUERY, {
    notifyOnNetworkStatusChange: true,
  })
  return { data, loading, error, refetch }
}

export const useCustomerDetails = (customerId) => {
  const { data, error, loading } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { customerId },
    skip: !customerId,
    fetchPolicy: 'no-cache',
  })
  return { data, error, loading }
}

export const useDealerOverview = (dealerId) => {
  const { data, error, loading } = useQuery(DEALER_OVERVIEW_QUERY, {
    variables: { dealerId },
    skip: !dealerId,
    fetchPolicy: 'no-cache',
  })
  return { data, error, loading }
}

export const useCountryList = (addAllOption = false) => {
  return useQuery(COUNTRY_LIST_QUERY, { variables: { addAllOption } })
}

export const useCheckIfDealerHasClients = (customerId) => {
  const { data, error, loading } = useQuery(CHECK_FOR_CLIENT_QUERY, {
    variables: { customerId },
    skip: !customerId,
    fetchPolicy: 'no-cache',
  })
  return { data, error, loading }
}

export const GET_COMPANY_INFRA_INFO = gql`
  query infraInfo($companyId: Float!) {
    infraInfo(companyId: $companyId) {
      hierarchy
      numberOfUsers
      numberOfCompanies
      numberOfOrganisations
      numberOfSites
      numberOfBuildings
      numberOfPersona
      numberOfVehicles
      numberOfUpstreamDownstreamActivities
      numberOfAssets
      numberOfDatapoints
      numberOfCatches
    }
  }
`
