import antdData from 'antd/locale/nl_BE'
import localeData from 'react-intl/locale-data/nl'

import error from './error'
import errorPage from './errorPage'
import form from './form'
import global from './global'
import license from './license'
import notification from './notification'
import settings from './settings'
import sustainability from './sustainability'
import widget from './widget'

export default {
  locale: 'nl-NL',
  antdData,
  localeData,
  messages: {
    ...global,
    ...form,
    ...settings,
    ...widget,
    ...sustainability,
    ...error,
    ...errorPage,
    ...notification,
    ...license,
  },
}
