import { buttonSizes } from 'components/Dashboard/Widget/config/selections'

const config = {
  displayProps: {},
  editProps: {
    name: 'widget.clock',
    category: 'widget.others',
    keywords: ['gauge', 'indicator', 'clock'],
    img: '/resources/images/widgets/clock.png',
    info: {
      'fr-FR':
        '<h2>Horloge</h2>\n' +
        "<p>Le widget horloge affiche l'heure et la date actuelles.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé</strong> Titre du widget</li>\n' +
        '<li><strong>Icône</strong> Icône du widget</li>\n' +
        "<li><strong>Couleur</strong> Couleur de l'icône du widget</li>\n" +
        '<li><strong>Taille</strong> Sélectionnez la taille du widget</li>\n' +
        '<li><strong>Afficher la date</strong> Afficher la date ou non</li>\n' +
        '<li><strong>Format de date</strong> Sélectionnez le format de date</li>\n' +
        "<li><strong>Format de l'heure</strong> Sélectionnez le format de l'heure</li>\n" +
        '</ul>',
      'en-US':
        '<h2>Clock</h2>\n' +
        '<p>The clock widget displays the current time and date.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label</strong> Widget's title</li>\n" +
        "<li><strong>Icon</strong> Widget's icon</li>\n" +
        "<li><strong>Color</strong> Widget icon's color</li>\n" +
        '<li><strong>Size</strong> Select the size of the widget</li>\n' +
        '<li><strong>Show Date</strong> Display the date or not</li>\n' +
        '<li><strong>Date Format</strong> Select the date format</li>\n' +
        '<li><strong>Time Format</strong> Select the time format</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Klok</h2>\n' +
        '<p>De klokwidget geeft de huidige tijd en datum weer.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label</strong> Widgets titel</li>\n' +
        '<li><strong>Icoon</strong> Widget-icoon</li>\n' +
        '<li><strong>Kleur</strong> Kleur van widgetpictogram</li>\n' +
        '<li><strong>Grootte</strong> Selecteer de grootte van de widget</li>\n' +
        '<li><strong>Datum weergeven</strong> Datum weergeven of niet</li>\n' +
        '<li><strong>Datumformaat</strong> Selecteer het datumformaat</li>\n' +
        '<li><strong>Tijdnotatie</strong> Selecteer de tijdnotatie</li>\n' +
        '</ul>',
    },
    preview: {},
    default: {
      type: 'Clock',
      size: 's',
      showDate: true,
      showSeconds: true,
      dateFormat: 'PP',
      timeFormat: 'kk:mm:ss',
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.general',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.size' },
        dataKey: 'size',
        componentProps: {
          options: buttonSizes,
          selectionKeys: {
            value: 'value',
          },
        },
      },
      {
        category: 'widget.general',
        componentName: 'Switch',
        label: { formatted: 'widget.showDate' },
        dataKey: 'showDate',
        col: 'col2 first',
      },
      {
        category: 'widget.general',
        componentName: 'DateTimeFormatPicker',
        label: { formatted: 'widget.dateFormat' },
        dataKey: 'dateFormat',
        componentProps: {
          type: 'date',
        },
      },
      {
        category: 'widget.general',
        componentName: 'DateTimeFormatPicker',
        label: { formatted: 'widget.timeFormat' },
        dataKey: 'timeFormat',
        componentProps: {
          type: 'time',
        },
      },
    ],
  },
}

export default config
