import styled from 'styled-components';

const Text = styled.p<{ $color?: string; $stretch?: boolean; }>`
    color: ${({ $color }) => $color ?? 'inherit'};
    ${({$stretch}) => $stretch && 'white-space: pre;'}
    margin: 0;
`;

const ErrorPageTitle = styled.h1`
    margin: 0;
    font-size: large;
    text-transform: capitalize;

    @media only screen and (min-width: 768px) {
        font-size: xx-large;
    }
`;

export const StyledErrorPage = {
    ErrorPageTitle,
    Text,
};
