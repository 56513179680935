import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
import * as userIcons from 'theme/icons/user'

const icons = Object.values(userIcons).map(({ iconName, prefix }) => `${prefix} ${iconName}`)

const ICONS_QUERY = gql`
  query icons {
    files(companyWide: true) {
      id
      code: key
    }
  }
`

export const useIconsQuery = () => {
  const { data, loading, error, refetch } = useQuery(ICONS_QUERY)
  const combined = useMemo(() => {
    const files = data?.files?.map((icon) => icon.code) || []
    return [...files, ...icons]
  }, [data])

  return { data: combined, loading, error, refetch }
}
