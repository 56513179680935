import { Button } from 'antd'
import { darken, lighten } from 'polished'
import styled, { css } from 'styled-components'

export const AntButton = styled(Button)`
  ${({ type, color = 'main', disabled, theme }) => {
    if (type === 'link') {
      return css`
        color: ${theme.color[color]} !important;
        border: none;

        &:hover,
        &:focus {
          color: ${lighten(0.1, theme.color[color])} !important;
        }
      `
    }

    if (color) {
      switch (type) {
        case 'primary':
          return css`
            background: ${disabled ? theme.color.softerStroke : theme.color[color]} !important;
            border-color: ${disabled ? theme.color.softStroke : theme.color[color]} !important;
            color: ${disabled ? theme.color.softText : 'var(--color-white)'} !important;

            ${!disabled &&
            css`
              &:hover,
              :focus {
                background: ${lighten(0.1, theme.color[color])} !important;
                border-color: ${lighten(0.1, theme.color[color])} !important;
              }

              &:active {
                background: ${darken(0.1, theme.color[color])} !important;
                border-color: ${darken(0.1, theme.color[color])} !important;
              }
            `}
          `

        default:
          return css`
            border-color: var(--color-softStroke) !important;
            background: transparent !important;
            &:hover,
            &:focus {
              border-color: ${lighten(0.1, theme.color[color])} !important;
            }

            &:active {
              border-color: ${darken(0.1, theme.color[color])} !important;
            }
          `
      }
    }
  }}
`
