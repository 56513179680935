import { useEffect } from 'react'

import { useSocket } from 'contexts/WebsocketContext'

import { widgetConfig } from 'components/Dashboard/Widget/config'

export const useDatapointSocket = (datapoints = [], widgetType, subscription = true) => {
  const { subscribe, incomingData } = useSocket()

  useEffect(() => {
    if (subscription) {
      const dpList = datapoints.reduce((list, dp) => (dp.id ? list.concat(dp.id) : list), [])
      if (dpList.length) {
        subscribe(dpList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!(widgetConfig({ type: widgetType })?.subscribeToSocket || datapoints.length)) {
    return null
  }

  if (datapoints.some((dp) => dp.id === incomingData?.tags.uid)) {
    return { id: incomingData?.tags.uid, value: incomingData?.fields.value }
  }

  return null
}

export const useWidgetDataRefetch = (
  refetch,
  newSocketValue,
  loading,
  isPreviewMode,
  isEditMode
) => {
  useEffect(() => {
    if (newSocketValue && !loading && !isPreviewMode && !isEditMode) {
      refetch(false, newSocketValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSocketValue, /* loading, */ isPreviewMode, isEditMode])
}
