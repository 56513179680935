import { motion } from 'framer-motion'
import styled from 'styled-components'

const ActionConfirmationStyled = styled(motion.div)`
  position: ${({ location }) => (location ? 'fixed' : 'absolute')};
  left: ${({ location }) => (location ? `${location.left}px` : 0)};
  top: ${({ location, offset }) =>
    location ? `${location.top - (offset ? location.height : -location.height) - 30}px` : '100%'};
  font-size: 0.9rem;
  font-weight: 100;
  background-color: var(--color-backgroundFront);
  border: 1px solid
    ${({ theme, status }) => (status ? theme.color[status] : theme.color.softStroke)};
  box-shadow: var(--shadow-shadowElevation2);
  border-radius: 3px;
  padding: 5px;
  z-index: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  max-width: 15rem;

  p {
    width: 100%;
    margin: 0;
    strong {
      font-weight: 600;
    }
  }

  .value {
    color: var(--color-strongText);
    font-size: 0.7rem;
    font-weight: 300;

    strong {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .Icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .PhaseToggle {
    margin: 0.2rem;
  }

  .ActionConfirmation__container {
    display: flex;
    width: 100%;
    padding-top: 0.5rem;
  }

  .smallButton {
    margin: auto 0 0 0.5rem;
    height: 1.3rem;

    &.secondary {
      margin-left: auto;
    }
  }
`

export default ActionConfirmationStyled
