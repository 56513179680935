import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { gql } from '@apollo/client'
import io from 'socket.io-client'

// Temp fix to eliminate circular dependency user => store
const ME_QUERY = gql`
  query me {
    me {
      id
      language {
        code
      }
      admin
      company {
        id
        name
        dealer {
          id
        }
        entity {
          id
        }
        isActive
      }
      email
      firstname
      surname
      avatar
      advanced
    }
  }
`

const initialState = (apolloClient, logoutUser) => ({
  resetApolloCache: () => apolloClient.resetStore(),
  logoutUser,
  customer: JSON.parse(localStorage.getItem('customer')) || null,
  globalTime: {
    startTime: null,
    endTime: null,
  },
})

function reducer(state, { type, payload }) {
  switch (type) {
    case 'setCustomer':
      localStorage.setItem('customer', JSON.stringify(payload))
      return { ...state, customer: payload }
    case 'setGlobalTime':
      return { ...state, globalTime: payload }

    default:
      throw new Error(`Invalid action '${type}'.`)
  }
}

export const setCustomer = (payload) => ({ type: 'setCustomer', payload })
export const setGlobalTime = (payload) => ({ type: 'setGlobalTime', payload })

export const SocketProvider = io(process.env.REACT_APP_WS_ENDPOINT, { withCredentials: false })

const appStateContext = createContext()
export const useAppState = () => useContext(appStateContext)

export const useCurrentCustomer = () => {
  const {
    appState: { customer },
  } = useAppState()

  return customer
}

export const etlConfig = () => {
  return {
    url: process.env.REACT_APP_ETL_URL,
    local: process.env.REACT_APP_ETL_URL.indexOf('localhost') > 0,
    token: localStorage.getItem('token'),
  }
}

export const coreConfig = () => {
  return {
    url: process.env.REACT_APP_CORE_URL,
    local: process.env.REACT_APP_CORE_URL.indexOf('localhost') > 0,
    token: localStorage.getItem('token'),
  }
}

export const reportConfig = () => {
  return {
    url: process.env.REACT_APP_REPORT_URL,
    local: process.env.REACT_APP_REPORT_URL.indexOf('localhost') > 0,
    token: localStorage.getItem('token'),
  }
}

export const Store = ({ children, apolloClient, logoutUser }) => {
  const [appState, appStateDispatch] = useReducer(reducer, initialState(apolloClient, logoutUser))

  useEffect(() => {
    if (!appState.customer) {
      apolloClient
        .query({ query: ME_QUERY })
        .then((res) => {
          appStateDispatch({
            type: 'setCustomer',
            payload: res.data.me.company,
          })
        })
        .catch(() => {})
    }
    // eslint-disable-next-line
  }, [])

  return (
    <appStateContext.Provider value={{ appState, appStateDispatch }}>
      {children}
    </appStateContext.Provider>
  )
}
