import Editor from 'react-simple-code-editor'
import styled from 'styled-components'

import Icon from 'components/Global/Icon'

export const Container = styled.div`
  position: relative;
`

export const EditorStyled = styled(Editor)`
  > * {
    outline: none;
  }

  pre {
    border: 1px solid var(--color-softStroke) !important;
  }

  textarea:focus + pre {
    border-color: var(--color-main) !important;
  }
`

export const ValidIcon = styled(Icon)`
  color: ${({ valid, theme }) => (valid ? 'var(--color-success)' : 'var(--color-error)')};
  position: absolute;
  right: 12px;
  top: 12px;
`
