import { gql, useQuery } from '@apollo/client'

import { useCurrentCustomer } from 'services/store'

const GET_NON_ASSET_ENTITY_TYPES = gql`
  query nonAssetEntityTypes {
    nonAssetEntityTypes {
      id
      label
      entityType {
        id
      }
      uploadDatapointsOnly
      hasInternalId
    }
  }
`

const VEHICLE_TYPES_FOR_COMPANY = gql`
  query vehicleTypesForCompany($companyId: Float!) {
    vehicleTypesForCompany(companyId: $companyId) {
      id
      description
    }
  }
`

export const useNonAssetEntityTypes = (isCreateMode) => {
  const { data, error, loading, refetch } = useQuery(GET_NON_ASSET_ENTITY_TYPES, {
    fetchPolicy: 'network-only',
    skip: !isCreateMode,
  })
  return { data, error, loading, refetch }
}

export const useVehicleTypesForCompany = () => {
  const customer = useCurrentCustomer()
  const { data, ...rest } = useQuery(VEHICLE_TYPES_FOR_COMPANY, {
    variables: { companyId: customer?.id },
    skip: !customer?.id,
  })
  return { data: data?.vehicleTypesForCompany, ...rest }
}
