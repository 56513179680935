import styled from 'styled-components'

import Icon from '../Icon/styled'
import Caption from './index'
import { Title } from './styled'

export const WidgetCaption = styled(Caption)`
  padding: 1rem 1.5rem 1rem;
  display: flex;
  align-items: center;
  ${Title} {
    font-size: 1.2rem;
    color: var(--color-mainText);
    display: flex;
    align-items: center;
  }
  p {
    color: var(--color-strongText);
    margin: 0;
  }

  ${Icon} {
    z-index: 1;
    color: ${({ color }) => color || 'var(--color-softText)'} !important;
    margin: 0 0.8rem 0 0;
    width: 1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
