import { darken, transparentize } from 'polished'
import { css } from 'styled-components'

export const mainButton = css`
  font-size: 1rem;
  color: ${({ isPrimary, disabled }) =>
    isPrimary && !disabled ? 'var(--color-white)' : 'var(--color-softText)'};
  background: ${({ isPrimary, disabled }) =>
    !disabled && isPrimary ? 'var(--color-action)' : 'var(--color-softerStroke)'};
  border: 1px solid
    ${({ isPrimary, disabled }) =>
      !disabled && (isPrimary ? 'var(--color-action)' : 'var(--color-softText)')};
  border-radius: 0.5rem;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  transition: background 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  max-width: fit-content;
  box-shadow: ${({ isPrimary, disabled }) =>
    isPrimary && !disabled && 'var(--shadow-shadowElevation3)'};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  &:hover {
    background: ${({ theme, isPrimary, disabled }) =>
      !disabled &&
      (isPrimary ? transparentize(0.9, theme.color.action) : theme.color.softerStroke)};
    border: 1px solid
      ${({ disabled }) => (disabled ? 'var(--color-mainText)' : 'var(--color-action)')};
    color: ${({ isPrimary, disabled }) =>
      !disabled && (isPrimary ? 'var(--color-action)' : 'var(--color-mainText)')};
  }
  &:focus {
    outline: 5px auto
      ${({ theme, isPrimary, disabled }) =>
        darken(0.2, !disabled && isPrimary ? theme.color.action : theme.color.softerStroke)};
  }
  &:last-of-type {
    margin-right: 0;
  }
  span {
    white-space: nowrap;
  }
`
