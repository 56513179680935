export const calculationMethods = [
  { value: 'sum', label: { formatted: 'widget.sum' } },
  { value: 'mean', label: { formatted: 'widget.mean' } },
  { value: 'min', label: { formatted: 'widget.min' } },
  { value: 'max', label: { formatted: 'widget.max' } },
  { value: 'range', label: { formatted: 'widget.range' } },
  { value: 'amount', label: { formatted: 'widget.amount' } },
]

export const minMax = [
  { value: 'min', label: { formatted: 'widget.min' } },
  { value: 'max', label: { formatted: 'widget.max' } },
  { value: 'fixedValue', label: { formatted: 'widget.fixedValue' } },
  { value: 'yAxisMin', label: { formatted: 'widget.yAxisMin' } },
  { value: 'yAxisMax', label: { formatted: 'widget.yAxisMax' } },
]

export const barChartTypes = [
  { value: 'column', label: { formatted: 'widget.column' } },
  { value: 'bar', label: { formatted: 'widget.bar' } },
]

export const detailTypes = [
  { value: 'none', label: { formatted: 'widget.none' } },
  { value: 'grid', label: { formatted: 'widget.fixedGrid' } },
  { value: 'list', label: { formatted: 'widget.fixedList' } },
  { value: 'floatingGrid', label: { formatted: 'widget.floatingGrid' } },
  { value: 'floatingList', label: { formatted: 'widget.floatingList' } },
]

export const emissionFactors = [
  { value: 'total', label: 'Total CO2e' },
  { value: 'co2', label: 'CO2' },
  { value: 'ch4Co2e', label: 'CH4' },
  { value: 'n2oCo2e', label: 'N2O' },
  { value: 'hfcCo2e', label: 'HFCs' },
  { value: 'pfcCo2e', label: 'PFCs' },
  { value: 'sf6Co2e', label: 'SF6' },
  { value: 'nf3Co2e', label: 'NF3' },
  { value: 'ar4', label: 'AR4' },
  { value: 'ar5', label: 'AR5' },
  { value: 'biogenicCo2', label: 'Biogenic CO2' },
]

export const lineTypes = [
  { value: 'Status', label: { formatted: 'widget.status' } },
  { value: 'File', label: { formatted: 'widget.file' } },
  /*{ value: 'Detail', label: { formatted: 'widget.detail' } },*/
]

export const detailFields = [
  { value: 'id', label: { formatted: 'widget.id' }, dataKey: 'id' },
  { value: 'type', label: { formatted: 'widget.type' }, dataKey: 'type' },
  { value: 'country', label: { formatted: 'widget.country' }, dataKey: 'country.alpha2' },
  { value: 'site', label: { formatted: 'widget.site' }, dataKey: 'site.name' },
  { value: 'building', label: { formatted: 'widget.building' }, dataKey: 'building.name' },
  { value: 'floor', label: { formatted: 'widget.floor' }, dataKey: 'floor.name' },
  { value: 'room', label: { formatted: 'widget.room' }, dataKey: 'room.name' },
  { value: 'remark', label: { formatted: 'widget.remark' }, dataKey: 'room.remark' },
  {
    value: 'degreeDayReference',
    label: { formatted: 'widget.degreeDayReference' },
    dataKey: 'degreeDayReference.name',
  },
  { value: 'varia', label: { formatted: 'widget.varia' }, dataKey: 'room.varia' },
]

export const granularityUnits = [
  { value: 's', label: { formatted: 'widget.seconds' } },
  { value: 'm', label: { formatted: 'widget.minutes' } },
  { value: 'h', label: { formatted: 'widget.hours' } },
  { value: 'd', label: { formatted: 'widget.days' } },
  { value: 'w', label: { formatted: 'widget.weeks' } },
  { value: 'MS', label: { formatted: 'widget.months' } },
  { value: 'Y', label: { formatted: 'widget.years' } },
]

export const buttonSizes = [
  { value: 's', label: { text: 'Small' } },
  { value: 'm', label: { text: 'Medium' } },
  { value: 'l', label: { text: 'Large' } },
  { value: 'auto', label: { text: 'AUTO' } },
]

export const orientation = [
  { value: 'portrait', label: { formatted: 'widget.portrait' } },
  { value: 'landscape', label: { formatted: 'widget.landscape' } },
]

export const datapointValueTypes = [
  {
    value: 'referenceValue',
    label: { formatted: 'widget.value' },
    valueType: 'referenceValue',
  },
  { value: 'name', label: { formatted: 'widget.name' }, valueType: 'dataKey' },
  { value: 'namedReferences.siteRef', label: { formatted: 'widget.site' }, valueType: 'dataKey' },
  {
    value: 'namedReferences.buildingRef',
    label: { formatted: 'widget.building' },
    valueType: 'dataKey',
  },
  { value: 'namedReferences.floorRef', label: { formatted: 'widget.floor' }, valueType: 'dataKey' },
  { value: 'namedReferences.roomRef', label: { formatted: 'widget.room' }, valueType: 'dataKey' },
  {
    value: 'namedReferences.equipRef',
    label: { formatted: 'widget.equipment' },
    valueType: 'dataKey',
  },
]

export const sdgLabels = [
  {
    value: '1',
    label: { formatted: 'widget.sdg1' },
    color: '#e5253b',
    icon: '/resources/images/sdg/sdg-01.png',
  },
  {
    value: '2',
    label: { formatted: 'widget.sdg2' },
    color: '#dea73a',
    icon: '/resources/images/sdg/sdg-02.png',
  },
  {
    value: '3',
    label: { formatted: 'widget.sdg3' },
    color: '#4c9f38',
    icon: '/resources/images/sdg/sdg-03.png',
  },
  {
    value: '4',
    label: { formatted: 'widget.sdg4' },
    color: '#c61a2d',
    icon: '/resources/images/sdg/sdg-04.png',
  },
  {
    value: '5',
    label: { formatted: 'widget.sdg5' },
    color: '#ff3a21',
    icon: '/resources/images/sdg/sdg-05.png',
  },
  {
    value: '6',
    label: { formatted: 'widget.sdg6' },
    color: '#27bde2',
    icon: '/resources/images/sdg/sdg-06.png',
  },
  {
    value: '7',
    label: { formatted: 'widget.sdg7' },
    color: '#fcc30b',
    icon: '/resources/images/sdg/sdg-07.png',
  },
  {
    value: '8',
    label: { formatted: 'widget.sdg8' },
    color: '#a31941',
    icon: '/resources/images/sdg/sdg-08.png',
  },
  {
    value: '9',
    label: { formatted: 'widget.sdg9' },
    color: '#fd6924',
    icon: '/resources/images/sdg/sdg-09.png',
  },
  {
    value: '10',
    label: { formatted: 'widget.sdg10' },
    color: '#de1466',
    icon: '/resources/images/sdg/sdg-10.png',
  },
  {
    value: '11',
    label: { formatted: 'widget.sdg11' },
    color: '#fe9c24',
    icon: '/resources/images/sdg/sdg-11.png',
  },
  {
    value: '12',
    label: { formatted: 'widget.sdg12' },
    color: '#bf8b2e',
    icon: '/resources/images/sdg/sdg-12.png',
  },
  {
    value: '13',
    label: { formatted: 'widget.sdg13' },
    color: '#3e7f44',
    icon: '/resources/images/sdg/sdg-13.png',
  },
  {
    value: '14',
    label: { formatted: 'widget.sdg14' },
    color: '#0f97d8',
    icon: '/resources/images/sdg/sdg-14.png',
  },
  {
    value: '15',
    label: { formatted: 'widget.sdg15' },
    color: '#58c02a',
    icon: '/resources/images/sdg/sdg-15.png',
  },
  {
    value: '16',
    label: { formatted: 'widget.sdg16' },
    color: '#01689d',
    icon: '/resources/images/sdg/sdg-16.png',
  },
  {
    value: '17',
    label: { formatted: 'widget.sdg17' },
    color: '#1a486a',
    icon: '/resources/images/sdg/sdg-17.png',
  },
]

export const timeUnits = [
  { value: 'hour', label: { formatted: 'widget.hour' } },
  { value: 'day', label: { formatted: 'widget.day' } },
  { value: 'week', label: { formatted: 'widget.week' } },
  { value: 'month', label: { formatted: 'widget.month' } },
  { value: 'year', label: { formatted: 'widget.year' } },
]
export const defaultStartTimeUnits = [
  { value: 'hour', label: { formatted: 'widget.hours' } },
  { value: 'day', label: { formatted: 'widget.days' } },
  { value: 'week', label: { formatted: 'widget.weeks' } },
  { value: 'month', label: { formatted: 'widget.months' } },
  { value: 'year', label: { formatted: 'widget.years' } },
]

export const calendarTypes = [
  { value: 'enum', label: { formatted: 'widget.default' } },
  { value: 'boolean', label: { formatted: 'widget.boolean' } },
]

const sortOrder = [
  { value: 'asc', label: { formatted: 'widget.ascending' } },
  { value: 'desc', label: { formatted: 'widget.descending' } },
]

export const optionalSortOrder = [
  { value: 'none', label: { formatted: 'widget.none' } },
  ...sortOrder,
]

export const dashStyles = [
  { value: 'Solid', label: 'default' },
  { value: 'ShortDash', label: '-------------' },
  { value: 'ShortDot', label: '.................' },
  { value: 'ShortDashDot', label: '_._._._._._._' },
  { value: 'ShortDashDotDot', label: '_.._.._.._.._' },
  { value: 'Dot', label: '. . . . . . . . . .' },
]

export const graphTypes = [
  { value: 'line', label: { formatted: 'widget.line' } },
  { value: 'area', label: { formatted: 'widget.area' } },
  { value: 'column', label: { formatted: 'widget.column' } },
]
