import { useEffect, useState } from 'react'

import { merge } from 'lodash';

import themes from 'config/themes/aviary.json'

const domainsWithHeylen = [
  'smart.heylenwarehouses.com',
  'buildingtechnologies21.be',
  'buildingtechnologies21.com',
  'buildingtechnologies21.nl',
  'www.buildingtechnologies21.be',
  'www.buildingtechnologies21.com',
  'www.buildingtechnologies21.nl',
  'whitelabel.canary.expert',
]

const themeDomain = domainsWithHeylen.includes(window.location.hostname) ? 'heylen' : 'aviary'

const baseTheme = merge({}, themes.common, {images: themes[themeDomain].images})
const lightTheme = merge({}, baseTheme, themes[themeDomain].light)
const darkTheme = merge({}, baseTheme, themes[themeDomain].dark)

export const useTheme = () => {
  const domainName = window.location.hostname

  const themeDomain = domainsWithHeylen.includes(domainName) ? 'heylen' : 'aviary'
  const storedMode = localStorage.getItem('mode') === 'dark' ? 'dark' : 'light'

  const [customTheme, setCustomTheme] = useState(storedMode === 'dark' ? darkTheme : lightTheme)
  useEffect(() => {
    const loadThemeConfig = () => {
      const themeObj = {
        ...themes.common,
      }

      merge(themeObj, themes[themeDomain][storedMode], {images: themes[themeDomain].images})
      setCustomTheme(themeObj)
    }
    loadThemeConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedMode, themeDomain])

  return customTheme
}

export function getTheme(mode) {
  if (typeof mode === 'string') {
    return mode === 'dark' ? darkTheme : lightTheme;
  }
  return localStorage.getItem('mode') === 'dark' ? darkTheme : lightTheme
}
