import { setLightness } from 'polished'
import styled, { css } from 'styled-components'

import Caption from 'components/Global/Caption'
import { Description, Title } from 'components/Global/Caption/styled'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import TextExtended from 'components/Global/TextExtended/styled'

export const DataPointLabelStyled = styled(Caption)`
  transition: border 0.2s ease;
  border-radius: 2px;
  flex-wrap: wrap;
  align-items: center;
  cursor: default;
  box-shadow: var(--shadow-shadowElevation1);
  width: min-content;

  ${({ buttons, modeControl, color, hoveredEdge }) =>
    buttons
      ? css`
          background-color: var(--color-softerStroke);
          display: grid !important;
          grid-template: auto / auto 1fr;
          text-align: left;
          border-right: ${((hoveredEdge && hoveredEdge.color) || color) &&
          `7px solid ${(hoveredEdge && hoveredEdge.color) || color}`};

          ${Title} {
            padding: 0 0.5rem;
            align-self: end;
          }

          ${TextExtended} {
            padding: 0 0.5rem;
            align-self: start;
          }

          ${modeControl &&
          css`
            display: flex;

            ${Title} {
              padding: 0.5rem;
              grid-row: -3;
            }
          `}
        `
      : css`
          padding: 4px;
          justify-content: center;
          background-color: ${({ theme, color }) =>
            setLightness(theme.darkMode ? 0.18 : 0.96, color || theme.color.main)};
        `}
  ${({ enlargeLabelOnHover }) =>
    enlargeLabelOnHover &&
    css`
      &:hover:not(.confirmation) {
        ${Description} {
          position: absolute;
          visibility: visible;
          opacity: 0.95;
          transform: scale(1);
        }
      }
    `}

  ${Title} {
    font-size: 0.8rem;
    font-weight: 100;
    color: var(--color-mainText);
    width: 100%;

    span {
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${Description} {
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    position: absolute;
    visibility: hidden;
    background-color: var(--color-backgroundFront);
    color: var(--color-strongText);
    border: 1px solid var(--color-softStroke);
    box-shadow: var(--shadow-shadowElevation2);
    opacity: 0;
    bottom: 100%;
    width: max-content;
    max-width: 300%;
    transform: scale(0);
    border-radius: 3px;
    padding: 5px;
    z-index: 1;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 0.3rem;
    text-align: left;
  }

  ${TextExtended} {
    margin-bottom: 0;
    color: var(--color-strongText);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;

    label {
      margin-left: 0.3rem;
      font-size: 0.7rem;
      font-weight: 300;
    }
  }

  ${WidgetLoader} {
    font-size: 0.6rem;

    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
`

export const Buttons = styled.div`
  grid-row: 1 / span2;
  display: flex;
  border-left: 1px solid var(--color-softStroke);
  background-color: var(--color-backgroundFront);
  width: 100%;
`
