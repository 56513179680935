import { css } from 'styled-components'

import Icon from '../Icon/styled'

export const roundedButton = css`
  transition: box-shadow 0.3s ease;
  background: var(--color-backgroundFront);
  border-radius: 4rem;
  box-shadow: var(--shadow-shadowElevation3);
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  &:hover {
    box-shadow: var(--shadow-shadowElevation5);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--color-highlight);
      box-shadow: var(--shadow-shadowElevation5);
      color: var(--color-highlightText);

      ${Icon} {
        color: var(--color-highlightText);
      }
    `}
  .Button__label {
  }
`
