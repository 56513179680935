import styled from 'styled-components'

import Selector from 'components/Global/FormField/Selector'
import Loader from 'components/Global/Loader'

const OptionPickerStyled = styled(Selector)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const OptionPickerLoader = styled(Loader)`
  color: var(--color-softText);
  text-transform: uppercase;
  .Icon {
    color: var(--color-softerText);
    font-size: 2rem;
  }
  label {
    font-size: 0.7rem;
  }
  .Spinner {
    order: 1;
    margin-top: 0.5rem;
  }
`

export default OptionPickerStyled
