import { motion } from 'framer-motion'
import { darken, lighten, setLightness, transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { legend, tooltip } from 'components/Dashboard/components/libraries/HighCharts/styled'
import { axisLabels, dataLabels, gauge } from 'components/Widgets/Gauge/styled'

import Tooltip from '../../../Global/Tooltip'

export const InlineErrorsStyled = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 100;
`

export const Error = styled(Tooltip)`
  position: relative;

  .Button {
    .Icon {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      color: ${({ theme, type = 'error' }) => theme.color[type]};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.1rem;
      margin: 0.1rem 0.5rem 0 0;
    }
  }

  .TooltipContent {
    position: absolute;
    left: 100%;
    bottom: 0;
    display: flex;
    padding: 0.3rem 0.5rem;
    background: ${({ theme, type = 'error' }) =>
      transparentize(
        0.3,
        theme.darkMode ? darken(0.5, theme.color[type]) : lighten(0.38, theme.color[type])
      )};
    border: 1px solid ${({ theme, type = 'error' }) => transparentize(0.5, theme.color[type])};
    border-radius: 0.3rem;
    color: var(--color-softText);
    font-size: 0.75rem;
    box-shadow: var(--shadow-shadowElevation2);
    width: 240px;
    backdrop-filter: blur(5px);
  }

  > .Icon {
    &.hide-button {
      color: var(--color-mainText);
      cursor: pointer;
      margin-right: -0.2rem;
    }

    svg {
      width: 90%;
      height: 90%;
    }
  }
`

export const UniversalChartStyled = styled.div`
  position: relative;

  ${legend}
  ${({ type }) =>
    type === 'solidgauge' &&
    css`
      ${gauge}
      ${axisLabels}
      ${dataLabels}
    `}
  .highcharts-tooltip {
    ${tooltip}
    .tooltip__value {
      min-width: 18rem;
      ${({ raw }) =>
        raw &&
        css`
          border-bottom: 1px solid var(--color-softStroke);
        `}
      &.value {
        ${({ raw }) =>
          raw &&
          css`
            width: auto;
            min-width: auto;
            white-space: nowrap;
          `}
      }

      .value {
        font-size: ${({ captions }) => (captions ? '1rem' : '1.4rem')} !important;
        white-space: nowrap;
        padding-left: 1rem;
      }
    }
  }

  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: var(--color-softStroke) !important;
  }

  .highcharts-legend-item {
    rect {
      width: 0.5rem;
    }
  }
`

export const ChartStyled = styled.div``
export const ChartDetailStyled = styled(motion.div)`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  margin: 1rem 0.5rem 0.5rem;
  max-height: 90%;
  z-index: 100;
  position: relative;

  &:hover {
    .FormField {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .FormField {
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    flex-direction: row-reverse;
    justify-content: flex-end;
    box-shadow: var(--shadow-shadowElevation2);
    background: var(--color-backgroundFront);
    border-radius: 2rem;
    padding: 0.3rem;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);

    label {
      margin: auto 0.5rem;
    }

    button {
      margin-top: auto;
    }
  }

  ${({ layout }) =>
    layout === 'list'
      ? css`
          //border: 1px solid var(--color-softStroke);
          border-radius: 0.5rem;
          box-shadow: var(--shadow-shadowElevation4);
          background: ${({ theme }) => transparentize(0.05, theme.color.backgroundFront)};
          right: 0;
          flex-direction: column;
          height: fit-content;

          .ChartDetail__content {
            max-height: 45vh;
            border-radius: 0 0 0.5rem 0.5rem;
          }

          .FormField.Switch {
            position: absolute;
            bottom: 100%;
            margin: 0 0 0.8rem;
          }
          .ChartDetail__labels {
            border-radius: 0.5rem 0.5rem 0 0;
          }
        `
      : css`
          .ChartDetail__content {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            > div {
              width: 144px;
              max-width: 144px;
              min-width: 144px;
              padding: 0.5rem;
            }
          }

          .FormField.Switch {
            margin: 0 auto 0 1rem;
          }
        `}
  ${({ floating }) =>
    floating
      ? css`
          position: absolute;
          right: 0;
          cursor: all-scroll;
          height: fit-content;
        `
      : css`
          transform: none !important;
          max-height: ${({ sectionHeight, height }) => (sectionHeight * height) / 100 - 100}px;
        `}
  .ChartDetail__labels {
    background: var(--color-softerStroke);
    color: var(--color-softText);
    display: flex;
    text-align: right;
    border-bottom: 1px solid var(--color-softStroke);
    font-weight: 400;

    .ChartDetail__cell {
      flex: 1;
      padding: 0.1rem 0.5rem;

      &:not(:last-child) {
        border-right: 1px solid var(--color-softStroke);
      }
    }

    strong {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  .ChartDetail__content {
    overflow: auto;
  }
`
export const ChartDetailCard = styled.div`
  overflow: hidden;
  color: ${({ color, theme }) =>
    setLightness(theme.darkMode ? 0.4 : 0.3, color || theme.color.main)};
  font-size: 0.9rem;

  ${({ layout }) =>
    layout === 'list'
      ? css`
          .ChartDetail__values {
            display: flex;
            text-align: right;
            background: ${({ color, theme }) =>
              setLightness(theme.darkMode ? 0.05 : 0.99, color || theme.color.main)};

            .ChartDetail__cell {
              flex: 1;
              padding: 0.3rem 0.5rem;

              &:not(:last-child) {
                border-right: 1px solid
                  ${({ color, theme }) =>
                    setLightness(theme.darkMode ? 0.2 : 0.9, color || theme.color.main)};
              }
            }
          }

          .ChartDetail__header {
            padding: 0.3rem 0.5rem 0.3rem 1rem;
          }
        `
      : css`
          background: ${({ color, theme }) => setLightness(0.99, color || theme.color.main)};
          box-shadow: var(--shadow-shadowElevation4);
          border-radius: 0.5rem;
          height: 100%;

          .ChartDetail__header {
            padding: 0.3rem 0.5rem;
          }

          .ChartDetail__values {
            padding: 0.3rem 1rem;

            .value {
              display: flex;
              align-items: flex-end;

              span {
                font-weight: 600;
                margin: auto auto 0.1rem 0.3rem;
                font-size: ${layout === 'totals' && '.7rem'};
              }
            }
          }

          .ChartDetail__cell {
            display: flex;

            span {
              margin-right: auto;
            }
          }
        `}
  .ChartDetail__header {
    font-weight: 600;
    background: ${({ color, theme }) =>
      setLightness(theme.darkMode ? 0.1 : 0.965, color || theme.color.main)};

    .name {
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      text-overflow: ellipsis;

      &:before {
        content: '';
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background: ${({ color }) => color || 'var(--color-mainText)'};
        margin: 0.5rem 0.5rem auto 0;
      }

      span {
        margin: auto 0 0.1rem auto;
        font-size: 0.7rem;
      }
    }
  }

  .ChartDetail__values {
    font-weight: 400;
    font-size: 0.9rem;

    strong {
      font-weight: 600;
    }
  }
`
export const ChartColor = styled.span``
