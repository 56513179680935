import { css } from 'styled-components'

export const subMenu = css`
  color: var(--color-sidebarText);

  &.active,
  &:hover {
    opacity: 1;
    color: var(--color-highlight);
  }

  ${({ theme }) =>
    theme.screenSize.screen.current === 's'
      ? css`
          display: flex;
          flex-direction: column;
          width: 7rem;
          max-width: 7rem;
          min-width: 7rem;
          opacity: 0.7;

          .Button__label {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            width: 100%;
            text-align: center;
            padding: 0 0.1rem;
          }

          .Icon {
            font-size: 2rem;
          }
        `
      : css`
          flex-direction: column;
          padding-top: 2rem;
          .Button__label {
            text-align: center;
            line-height: 1.3;
            font-weight: 100;
            font-size: 0.9rem;
            padding: 0.3rem;
          }
        `}
`
