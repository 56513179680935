import { Select } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

export const AntSelect = styled(Select)`
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-color: var(--color-main);
      border-right-width: 1px !important;
      font-size: var(--font-size-m);
      font-weight: var(--font-weight-m);
      letter-spacing: var(--font-spacing-m);
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--color-main);
      -webkit-box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
      box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
      font-size: var(--font-size-m);
      font-weight: var(--font-weight-m);
      letter-spacing: var(--font-spacing-m);
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
    border-color: var(--color-main);
    -webkit-box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
    box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.8, theme.color.main)};
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    letter-spacing: var(--font-spacing-m);
  }
`
