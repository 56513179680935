import styled from 'styled-components'

import Loader from 'components/Global/Loader'

const EntityTree = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > .Tree {
    margin-top: 1rem;
    flex: 1;
  }

  .TreeItem {
    &.level0 {
      > .TreeItemWrapper {
        > .Button {
          cursor: default;
          pointer-events: none;

          &:hover {
            .Button__label,
            .Icon {
              color: var(--color-mainText);
            }
          }
        }
      }
    }
  }
`

export const EntityTreeLoader = styled(Loader)`
  color: var(--color-softText);
  text-transform: uppercase;
  .Icon {
    color: var(--color-softerText);
    font-size: 2rem;
  }
  label {
    font-size: 0.7rem;
  }
  .Spinner {
    order: 1;
    margin-top: 0.5rem;
  }
`
export default EntityTree
