import React, { Suspense } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import NotFoundPage from 'pages/404'
import { matchPath } from 'react-router'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import { setSetting } from 'services/settings'
import { useAppState } from 'services/store'
import { ME_QUERY } from 'services/user'

import { ContentLoader } from 'components/Layout/Content'

import modalRoutes from './modalRoutes'
import pageRoutes from './pageRoutes'

const publicRender = (route, props, user, logoutUser) => {
  const token = localStorage.getItem('token')

  if (user && token) {
    if (route.signOut) {
      logoutUser(false, false)
      if (window.posthog.reset) {
        window.posthog.reset()
      }
    } else {
      // Login page does not render with valid token, so redirect to landing dashboard
      if (route.path === '/user/login') {
        return <Redirect to={{ pathname: '/dashboards' }} />
      } else {
        return <Redirect to={{ pathname: route.path }} />
      }
    }
  }

  const Page = React.createElement(route.component, props)
  return React.createElement(route.layout, { ...props, children: Page })
}

const privateRender = (route, props, user) => {
  const token = localStorage.getItem('token')
  if (!user || !token) {
    return (
      <Redirect
        to={{
          pathname: '/user/login',
          state: { redirect: { search: props.location.search, pathname: props.match.url } },
        }}
      />
    )
  }

  // Temp fix to avoid non-canary users to visit forbidden pages via URL
  if (
    (props.location.pathname.indexOf('/settings/customers') !== -1 ||
      props.location.pathname.indexOf('/settings/entity-type-manager') !== -1 ||
      props.location.pathname.indexOf('/settings/fixed-files') !== -1) &&
    user.company.id !== 2
  ) {
    return <Redirect to={{ pathname: '/dashboards' }} />
  }

  const Page = React.createElement(route.component, props)
  return React.createElement(route.layout, { ...props, children: Page })
}

const Routing = ({ history }) => {
  const client = useApolloClient()
  const { data, loading, error } = useQuery(ME_QUERY)
  const {
    appState: { logoutUser },
  } = useAppState()
  const token = localStorage.getItem('token')

  if (error && token) {
    // If an invalid token is present, we delete all of them
    localStorage.removeItem('token')
    localStorage.removeItem('customer')
    localStorage.removeItem('user')
    localStorage.removeItem('helpdeskInProgress')
  }
  const user = data?.me
  // If a token is present but we have no user data,
  // try to get the user data
  if (token && loading) {
    return <ContentLoader hideText hideSpinner icon="logo" />
  }

  const handleLocationChange = (location) => {
    const isModal = modalRoutes.some((route) => matchPath(location.pathname, route))
    setSetting(client, 'isModalOpen', isModal)
  }

  history.listen(handleLocationChange)
  handleLocationChange(history.location)

  return (
    <Router history={history}>
      <Suspense fallback={<ContentLoader hideText hideSpinner icon="logo" />}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboards" />} />
          {pageRoutes.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={(props) =>
                route.public
                  ? publicRender(route, props, user, logoutUser)
                  : privateRender(route, props, user)
              }
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
      <Suspense fallback={<ContentLoader hideText hideSpinner icon="logo" />}>
        <Switch>
          {modalRoutes.map((route) => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routing
