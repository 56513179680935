import React, { useCallback, useMemo } from 'react'

import { useFieldArray } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import FilesField from 'components/Form/fields/FilesField'
import ImagesField from 'components/Form/fields/ImagesField'

import { FileClassificationFieldStyled } from './styled'

const entityFeatureTypes = [{ __typename: 'EntityFeatureType', id: '1', name: 'Images', showPreview: true, description: null }, { __typename: 'EntityFeatureType', id: '2', name: 'Documents', showPreview: false, description: null }]

const FileClassificationField = ({ value, name, intl, ...fieldProps }) => {
  const { fields, append } = useFieldArray({
    name,
  })

  const handleFileUpload = useCallback(
    (payload, { type, ...file }) => {
      append({ id: null, file, type })
    },
    [append]
  )

  const classifiedGroups = useMemo(
    () =>
      entityFeatureTypes.map((type) => {
        const classifiedFiles = fields.reduce((classifiedFiles, file, itemIndex) => {
          if (file.type.id === type.id) {
            classifiedFiles.push({ ...file, itemIndex })
          }
          return classifiedFiles
        }, [])
        return type.showPreview ? (
          <ImagesField
            key={type.id}
            name={name}
            value={classifiedFiles}
            label={intl.formatMessage({
              id: `file.type.${type.name.toLowerCase()}`,
              defaultMessage: type.name,
            })}
            onUpload={(payload, file) => {
              handleFileUpload(payload, { ...file, type })
            }}
          />
        ) : (
          <FilesField
            key={type.id}
            name={name}
            value={classifiedFiles}
            label={intl.formatMessage({
              id: `file.type.${type.name.toLowerCase()}`,
              defaultMessage: type.name,
            })}
            onUpload={(payload, file) => {
              handleFileUpload(payload, { ...file, type })
            }}
          />
        )
      }),
    [fields, handleFileUpload, name, intl]
  )

  return (
    <FileClassificationFieldStyled {...fieldProps}>
      {classifiedGroups}
    </FileClassificationFieldStyled>
  )
}

export default injectIntl(FileClassificationField)
