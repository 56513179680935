import React from 'react'

import { Controller } from 'react-hook-form'

import TextArea from 'components/Form/components/TextArea/TextArea'
import TextAreaFieldStyled from 'components/Form/fields/TextAreaField/styled'
import Markdown from 'components/Global/Markdown'

const TextAreaField = ({ name, defaultValue = '', disabled, onChange, markdown, ...props }) => {
  return (
    <TextAreaFieldStyled {...props}>
      {({ control, isEditMode, watch }) => {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange: fromFieldOnChange, value: controllerValue }) => {
              const fieldValue = watch(name)
              const handleChange = (event) => {
                fromFieldOnChange(event.target.value)
                onChange && onChange(event.target.value)
              }
              if (isEditMode) {
                return (
                  <TextArea onChange={handleChange} value={controllerValue} disabled={disabled} />
                )
              }

              if (markdown) {
                return <Markdown>{fieldValue}</Markdown>
              }

              return <span style={{whiteSpace: 'break-spaces'}}>{fieldValue}</span>
            }}
          />
        )
      }}
    </TextAreaFieldStyled>
  )
}

export default TextAreaField
