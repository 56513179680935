import { calculationMethods } from 'components/Dashboard/Widget/config/selections'

const config = {
  displayProps: {},
  subscribeToSocket: false,
  editProps: {
    name: 'widget.gauge',
    category: 'widget.ghgGaugesIndicators',
    keywords: ['gauge', 'indicator', 'ghg'],
    img: '/resources/images/widgets/solidgauge.png',
    info: {
      'fr-FR':
        '<h2>Jauge</h2>\n' +
        "<p>Le widget Jauge affiche des données en temps réel qui varient entre un minimum et un maximum pour un seul point de données. La valeur numérique remplit un demi-cercle à l'approche de sa valeur maximale.</p>\n" +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong> Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<li><strong>Points de données </strong> Sélectionnez les points de données du widget</li>\n' +
        "<li><strong>Min </strong> Déterminez la valeur minimale de la jauge. Il peut s'agir d'aucune, d'une valeur fixe ou d'un point de données.</li>\n" +
        "<li><strong>Max </strong> Déterminez la valeur maximale de la jauge. Il peut s'agir d'aucune, d'une valeur fixe ou d'un point de données.</li>\n" +
        '<li><strong>Couleurs </strong> Définir les couleurs pour la transition entre les valeurs</li>\n' +
        '</ul>\n' +
        '<h4>Intervalle</h4>\n' +
        '<h5>Plage de temps</h5>\n' +
        '<ul>\n' +
        '<li><strong>Préréglage</strong> Sélectionnez un temps prédéfini pour la valeur sélectionnée</li>\n' +
        '<li><strong>Décalage</strong> Sélectionnez un décalage et une durée pour la valeur sélectionnée</li>\n' +
        '<li><strong>Période personnalisée</strong> Sélectionnez une période personnalisée</li>\n' +
        '</ul>\n' +
        '<h4>Avancé</h4>\n' +
        '<ul>\n' +
        "<li><strong>Unité</strong> Changer l'unité de la valeur sélectionnée</li>\n" +
        '<li><strong>Logique personnalisée</strong> Appliquer les modifications à la valeur sélectionnée</li>\n' +
        '</ul>',
      'en-US':
        '<h2>Gauge</h2>\n' +
        '<p>The Gauge widget shows real-time data which ranges between a minimum and a maximum for a single datapoint. The numeric value fills up a half-circle as it approaches its maximum value.</p>\n' +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong> Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapoints </strong> Select widget datapoints</li>\n' +
        '<li><strong>Min </strong> Determine the minimum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Max </strong> Determine the maximum value for the gauge. This can be either none, a fixed value or a datapoint.</li>\n' +
        '<li><strong>Colors </strong> Define colors for the transition between values</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Time Range</h5>\n' +
        '<ul>\n' +
        '<li><strong>Preset</strong> Select a time preset for the selected value</li>\n' +
        '<li><strong>Offset</strong> Select an offset and a duration for the selected value</li>\n' +
        '<li><strong>Custom Period</strong> Select a custom period</li>\n' +
        '</ul>\n' +
        '<h4>Advanced</h4>\n' +
        '<ul>\n' +
        '<li><strong>Unit</strong> Change the unit of the selected value</li>\n' +
        '<li><strong>Custom Logic</strong> Apply changes to the selected value</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Meter</h2>\n' +
        '<p>De Meter-widget toont realtime gegevens die variëren tussen een minimum en een maximum voor een enkel datapunt. De numerieke waarde vult een halve cirkel wanneer deze de maximale waarde nadert.</p>\n' +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Pictogram </strong> Widgetpictogram</li>\n' +
        '<li><strong>Kleur </strong>Widgetpictogramkleur</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<li><strong>Datapunten </strong> Widgetgegevenspunten selecteren</li>\n' +
        '<li><strong>Min </strong> Bepaal de minimumwaarde voor de meter. Dit kan geen, een vaste waarde of een datapunt zijn.</li>\n' +
        '<li><strong>Max </strong> Bepaal de maximale waarde voor de meter. Dit kan geen, een vaste waarde of een datapunt zijn.</li>\n' +
        '<li><strong>Kleuren </strong> Definieer kleuren voor de overgang tussen waarden</li>\n' +
        '</ul>\n' +
        '<h4>Interval</h4>\n' +
        '<h5>Tijdbereik</h5>\n' +
        '<ul>\n' +
        '<li><strong>Voorinstelling</strong> Selecteer een vooraf ingestelde tijd voor de geselecteerde waarde</li>\n' +
        '<li><strong>Offset</strong> Selecteer een offset en een duur voor de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste periode</strong> Selecteer een aangepaste periode</li>\n' +
        '</ul>\n' +
        '<h4>Geavanceerd</h4>\n' +
        '<ul>\n' +
        '<li><strong>Eenheid</strong> Verander de eenheid van de geselecteerde waarde</li>\n' +
        '<li><strong>Aangepaste logica</strong> Wijzigingen toepassen op de geselecteerde waarde</li>\n' +
        '</ul>',
    },
    default: {
      dataType: 'ghg',
      type: 'GhgGauge',
      title: 'widget.gauge',
      icon: 'fas fa-info',
      url: null,
      color: null,
      datapoints: [],
      minComparators: [],
      maxComparators: [],
      colors: [
        [0, '#44bc66'],
        [0.33, '#fed33a'],
        [0.66, '#f88c1d'],
        [1, '#f14e3e'],
      ],
      timeRange: {
        preset: 'past7Days',
      },
      comparisonPeriod: null,
      customLogic: '',
      returnUnitId: null,
      emissionFactor: 'total',
      method: 'mean',
      minComparatorsMethod: 'mean',
      maxComparatorsMethod: 'mean',
    },
    unUsedDatapointProperties: [
      'caption',
      'color',
      'calculationMethod',
      'conversionUnit',
      'customLogic',
      'groupId',
      'rangeId',
      'offsets',
      'emissionFactors',
    ],
    preview: {
      icon: 'fas fa-gauge',
      gauge: {
        series: [
          {
            type: 'solidgauge',
            data: [30],
            tooltip: { valueSuffix: 'm³' },
            dataLabels: {
              y: 10,
              formatter: function () {
                return `<div class="data-labels" style="text-align:center;">
          <span class="value">30</span>
          <span class="unit">m³</span><br/>
         </div>`
              },
            },
          },
        ],
        yAxis: {
          min: 0,
          max: 100,
        },
        unit: 'm³',
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        col: 'col2 first',
        componentProps: {
          bottomDrawer: true,
        },
        label: { formatted: 'widget.icon' },
        dataKey: 'icon',
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        col: 'col2',
        label: { formatted: 'widget.color' },
        dataKey: 'color',
      },
      {
        category: 'widget.data',
        componentName: 'DatapointGroups',
        componentProps: {
          dataKey: 'datapoints',
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.calculationMethod' },
        dataKey: 'method',
        componentProps: {
          selectionKeys: { label: 'label', value: 'value' },
          valueKey: 'value',
          options: calculationMethods,
        },
        dependencies: [
          {
            dependentOn: 'widget.datapoints',
            dependencyType: 'hasValue',
            dependencyResolver: 'show',
          },
        ],
      },
      {
        category: 'widget.data',
        componentName: 'Comparator',
        label: { formatted: 'widget.min', tagName: 'h4' },
        componentProps: {
          dataKey: 'minComparators',
          method: 'minComparatorMethod',
          disableProperty: true,
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.calculationMethod' },
        dataKey: 'minComparatorMethod',
        componentProps: {
          selectionKeys: { label: 'label', value: 'value' },
          valueKey: 'value',
          options: calculationMethods,
        },
        dependencies: [
          {
            dependentOn: 'widget.minComparators',
            dependencyType: 'hasValue',
            dependencyResolver: 'show',
          },
        ],
      },
      {
        category: 'widget.data',
        componentName: 'Comparator',
        label: { formatted: 'widget.max', tagName: 'h4' },
        componentProps: {
          dataKey: 'maxComparators',
          method: 'maxComparatorMethod',
          disableProperty: true,
          fixedUnit: true,
          bottomDrawer: 'GhgDataSelector',
          buttons: false,
          canReselect: false,
          label: { formatted: 'widget.countries' },
        },
      },
      {
        category: 'widget.data',
        componentName: 'OptionPicker',
        label: { formatted: 'widget.calculationMethod' },
        dataKey: 'maxComparatorMethod',
        componentProps: {
          selectionKeys: { label: 'label', value: 'value' },
          valueKey: 'value',
          options: calculationMethods,
        },
        dependencies: [
          {
            dependentOn: 'widget.maxComparators',
            dependencyType: 'hasValue',
            dependencyResolver: 'show',
          },
        ],
      },
      {
        category: 'widget.data',
        componentName: 'ColorRangeSlider',
        label: { formatted: 'widget.colors' },
        dataKey: 'colors',
        componentProps: {},
      },
      {
        category: 'widget.interval',
        dataKey: '',
        componentName: 'DefaultStartTimeSelector',
        componentProps: {
          dataKey: 'timeRange',
          datapointsDataKey: 'datapoints',
          range: true,
          reportingYear: true,
        },
      },
      {
        category: 'widget.interval',
        componentName: 'DefaultStartTimeSelector',
        dataKey: '',
        componentProps: {
          dataKey: 'comparisonPeriod',
          datapointsDataKey: 'maxComparators',
          range: true,
          reportingYear: true,
        },
      },
      {
        category: 'widget.emissionFactor',
        componentName: 'EmissionFactorSelector',
        label: { formatted: 'widget.emissionFactor' },
        dataKey: 'emissionFactor',
      },
      {
        category: 'widget.advanced',
        label: { formatted: 'widget.unit' },
        dataKey: 'returnUnitId',
        componentName: 'UnitPicker',
        componentProps: {
          startUnit: 'kgCO2e',
        },
      },
      {
        category: 'widget.advanced',
        componentName: 'CustomLogic',
        label: { formatted: 'widget.customLogic' },
        dataKey: 'customLogic',
      },
    ],
  },
}

export default config
