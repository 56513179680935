export const COOKIE_NAMES_MAP = {
  preferences: 'AVCC',
} as const

type CookieName = typeof COOKIE_NAMES_MAP[keyof typeof COOKIE_NAMES_MAP]
export function setCookie(cname: CookieName, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie(cname: CookieName) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function deleteCookie(cname: CookieName) {
  const cookieVal = getCookie(cname)
  if (cookieVal) {
    setCookie(cname, cookieVal, -1)
  }
}
