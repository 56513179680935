import styled from 'styled-components'

import Button from 'components/Global/Button'
import Icon from 'components/Global/Icon/styled'

export const PageTitleStyled = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 0.5rem;
  align-items: center;

  h1 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-xl);
    margin-bottom: 0;
    color: var(--color-strongText);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${Icon} {
    width: auto;
    min-width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.8rem;
    color: var(--color-strongText);
    display: flex;

    img,
    svg {
      position: relative;
      left: 0;
      top: 0;
      height: 100%;
      width: auto;
    }
  }
`

export const FavouriteButton = styled(Button)`
  margin-left: 0.8rem;

  ${Icon} {
    color: ${({ isActive }) =>
      isActive ? 'var(--color-highlight, var(--color-main))' : 'var(--color-softText)'};
  }
`
