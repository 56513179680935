import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router'

import { DASHBOARD_LAYOUT_QUERY, UPDATE_DASHBOARD_MUTATION } from 'services/dashboards'
import { useHasFixedPermissionAccess } from 'services/fixedpermissions'
import { useHasModule } from 'services/security/modules'
import { useUser } from 'services/user'

import { ContentLoader } from 'components/Layout/Content'
import { UiContext } from 'components/Layout/UiProvider'

import {
  onDeleteSection,
  onLayoutUpdate,
  onRestoreSection,
  onValueUpdate,
  onWidgetAdd,
  onWidgetDelete,
  onWidgetSave,
} from './actions'
import reducer, { initialState } from './reducer'

const DashboardContext = createContext(null)

export const useDashboard = () => useContext(DashboardContext) || { state: {} }

const DashboardProvider = ({
  rowHeight,
  breakpoints,
  screenSize,
  dashboardContainer,
  isDataEntryDashboard,
  columns,
  children,
  data,
}) => {
  const location = useLocation()
  const [updateDashboard] = useMutation(UPDATE_DASHBOARD_MUTATION, {
    refetchQueries: [{ query: DASHBOARD_LAYOUT_QUERY, variables: { id: data.id.toString() } }],
  })
  const [user] = useUser()
  const [hasPermission, { loading: fixedPermLoading }] = useHasFixedPermissionAccess()
  const [hasLicense, { loading: licenseLoading }] = useHasModule()

  const timezoneOffset = 'CET'

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    advanced: user?.advanced,
    timezoneOffset,
    permissions: null,
    isDataEntryDashboard,
    data: data.id ? { ...data, isDetailDashboard: false } : initialState.data,
    locationData: location.state,
    layout: {
      screenSize,
      rowHeight,
      breakpoints,
      columns,
      dashboardContainer,
    },
  })

  useEffect(() => {
    const permissionsAvailable = !fixedPermLoading && !licenseLoading
    if (!state.permissions && permissionsAvailable) {
      const isSmallOrLarger = !hasLicense('energyInsights')
      dispatch({
        type: 'SET_PERMISSIONS',
        payload: {
          permissions: {
            hasSustainabilityAccess:
              (hasPermission('SUSTAINABILITY_CONTRIBUTOR') ||
                hasPermission('SUSTAINABILITY_ADMINISTRATOR')) &&
              isSmallOrLarger,
            hasDataEntryAccess: hasPermission('SUSTAINABILITY_ADMINISTRATOR') && isSmallOrLarger,
            isSustainabilityContributor:
              hasPermission('SUSTAINABILITY_CONTRIBUTOR') && isSmallOrLarger,
            isSustainabilityAdministrator:
              hasPermission('SUSTAINABILITY_ADMINISTRATOR') && isSmallOrLarger,
            isSustainabilityVisitor: hasPermission('SUSTAINABILITY_VISITOR') && isSmallOrLarger,
          },
        },
      })
    }
  }, [hasPermission, state.permissions, fixedPermLoading, licenseLoading, hasLicense])

  useEffect(() => {
    dispatch({
      type: 'SET_ADVANCED_MODE',
      payload: {
        value: user?.advanced,
      },
    })
  }, [user?.advanced])

  const { action: uiAction } = useContext(UiContext)

  const action = (action) => {
    switch (action.type) {
      case 'ADD_WIDGET':
        onWidgetAdd(action, state, dispatch, updateDashboard)
        break
      case 'DELETE_WIDGET':
        onWidgetDelete(action, state, dispatch, updateDashboard)
        break
      case 'ON_LAYOUT_UPDATE':
        onLayoutUpdate(action, state, dispatch, updateDashboard)
        break
      case 'ON_VALUE_UPDATE':
        onValueUpdate(action, state, dispatch, updateDashboard)
        break
      case 'SAVE_WIDGET':
        onWidgetSave(action, state, dispatch, updateDashboard)
        break
      case 'RESTORE_SECTION':
        onRestoreSection(action, state, dispatch, updateDashboard)
        break
      case 'DELETE_SECTION':
        onDeleteSection(action, state, dispatch, updateDashboard)
        break
      case 'SET_WIDGET_EDIT_MODE':
        uiAction({ type: 'SET_MAIN_PANEL_OFFSET', payload: { value: '100px' } })
        break
      case 'SAVE_DASHBOARD':
        updateDashboard({
          variables: {
            id: parseInt(data.id),
            data: {
              layout: JSON.stringify(state.data.layout),
            },
          },
        })
        break

      default:
    }
    dispatch(action)
  }

  return state.permissions ? (
    <DashboardContext.Provider value={{ state, action }}>{children}</DashboardContext.Provider>
  ) : (
    <ContentLoader hideText icon="fas fa-chart-area" hideSpinner size="xl" />
  )
}

export default DashboardProvider
