import { css } from 'styled-components'

export const treeItem = css`
  justify-content: flex-start;
  transition: padding 0.2s ease, line-height 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--color-main);
    `}
  &:hover, &:focus {
    color: var(--color-main);
  }

  .Button__label {
    transition: color 0.2s ease;
    white-space: nowrap;
  }

  .Icon {
    margin-right: 0.5rem;
    path {
      transition: color 0.2s ease;
    }
  }
`
