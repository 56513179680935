import { InputNumber } from 'antd'
import { transparentize } from 'polished'
import styled from 'styled-components'

const NumberInputStyled = styled(InputNumber)`
  border-color: var(--color-softStroke);
  .ant-input-number-input {
    &::selection {
      background: ${({ theme }) => transparentize(0.8, theme.color.main)};
    }
  }
`
export default NumberInputStyled
