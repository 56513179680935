import { useCallback, useMemo } from 'react'

import { gql, useMutation, useQuery } from '@apollo/client'

import { useCurrentCustomer } from './store'
import { useUser } from './user'

export const GET_FIXED_PERMISSIONS = gql`
  query fixedPermissions {
    fixedPermissions {
      id
      name
    }
  }
`

export const GET_FIXED_PERMISSIONS_FOR_USER = gql`
  query fixedPermissionRelations($accountID: Float!, $companyID: Float!) {
    fixedPermissionRelations(accountID: $accountID, companyID: $companyID) {
      fixedPermission {
        id
        name
      }
    }
  }
`

const CREATE_FIXED_PERMISSION_RELATION = gql`
  mutation createFixedPermissionRelation($data: FixedPermissionRelationInput!) {
    createFixedPermissionRelation(data: $data) {
      success
    }
  }
`

const DELETE_FIXED_PERMISSION_RELATION = gql`
  mutation deleteFixedPermissionRelation($id: Float!) {
    deleteFixedPermissionRelation(id: $id) {
      success
    }
  }
`

const GET_ALL_FIXED_PERMISSIONS_FOR_COMPANY = gql`
  query getFixedPermissionsForCompany($companyId: Float!) {
    fixedPermissions {
      id
      name
    }
    companyPermissions: company(companyId: $companyId) {
      name
      superCompanies {
        superCompany {
          id
          name
        }
        fixedPermissions {
          id
        }
      }
    }
  }
`

const GET_ALL_FIXED_PERMISSIONS_FOR_COMPANY_WITH_USER_SELECTION = gql`
  query getFixedPermissionsForCompanyWithUserSelection($companyId: Float!, $accountId: Float!) {
    fixedPermissions {
      id
      name
    }
    companyPermissions: company(companyId: $companyId) {
      name
      fixedPermissionRelations(accountId: $accountId) {
        id
        fixedPermission {
          id
        }
      }
      superCompanies {
        superCompany {
          id
          name
          fixedPermissionRelations(accountId: $accountId) {
            id
            fixedPermission {
              id
            }
          }
        }
        fixedPermissions {
          id
        }
      }
    }
  }
`

export const useFixedPermissions = (companyId, accountId = null) => {
  const query = accountId
    ? GET_ALL_FIXED_PERMISSIONS_FOR_COMPANY_WITH_USER_SELECTION
    : GET_ALL_FIXED_PERMISSIONS_FOR_COMPANY
  const variables = accountId ? { companyId, accountId } : { companyId }
  const { data, refetch } = useQuery(query, {
    variables,
    skip: !companyId || (accountId === undefined && accountId !== null),
    fetchPolicy: 'network-only',
  })

  const [deleteFixedPermissionRelation] = useMutation(DELETE_FIXED_PERMISSION_RELATION)

  const [createFixedPermissionRelation] = useMutation(CREATE_FIXED_PERMISSION_RELATION)

  if (!data) {
    return {}
  }

  const { fixedPermissions, companyPermissions } = data
  const permissionTable = []

  const selectedPermissionsCurrentCompany =
    companyPermissions?.fixedPermissionRelations?.reduce((all, relation) => {
      all[relation.fixedPermission.id] = relation
      return all
    }, {}) || {}

  permissionTable.push({
    company: {
      id: companyId,
      name: companyPermissions.name,
    },
    permissions: fixedPermissions.map((fp) => {
      return {
        id: fp.id,
        name: fp.name,
        selected: !!selectedPermissionsCurrentCompany[fp.id],
        enabled: true,
        relationId: selectedPermissionsCurrentCompany[fp.id]?.id,
      }
    }),
  })

  companyPermissions.superCompanies.forEach((company) => {
    const allowedPermissions = company.fixedPermissions.map((permission) => permission.id)
    const selectedPermissions =
      company.superCompany?.fixedPermissionRelations?.reduce((all, relation) => {
        all[relation.fixedPermission.id] = relation
        return all
      }, {}) || {}

    permissionTable.push({
      company: company.superCompany,
      permissions: fixedPermissions.map((fp) => {
        return {
          id: fp.id,
          name: fp.name,
          selected: !!selectedPermissions[fp.id],
          enabled: allowedPermissions.includes(fp.id),
          relationId: selectedPermissions[fp.id]?.id,
        }
      }),
    })
  })

  const save = async (newPermissions, selectedCustomers = []) => {
    const originalPermissions = permissionTable
      .map((row) => ({
        companyId: row.company.id,
        value: row.permissions.filter((p) => p.selected),
      }))
      .filter(({ companyId }) => selectedCustomers.includes(companyId))
      .reduce((prev, row) => {
        row.value.forEach((permission) => {
          prev[`${row.companyId}_${permission.id}`] = {
            companyId: row.companyId,
            permissionId: permission.id,
            relationId: permission.relationId,
          }
        })

        return prev
      }, {})

    const removed = Object.keys(originalPermissions)
      .filter((key) =>
        newPermissions[key.split('_')[0]] !== undefined ? true : newPermissions[key] === undefined
      )
      .map((key) => originalPermissions[key])

    const added = Object.keys(newPermissions)
      .filter((key) => originalPermissions[key] === undefined)
      .map((key) => newPermissions[key])

    const removeCalls = removed.map((permission) =>
      deleteFixedPermissionRelation({ variables: { id: permission.relationId } })
    )

    const addCalls = added
      .filter((permission) => permission.permissionId)
      .map((permission) =>
        createFixedPermissionRelation({
          variables: {
            data: {
              companyID: permission.companyId,
              accountID: accountId,
              fixedPermissionID: permission.permissionId,
            },
          },
        })
      )

    await Promise.all([...removeCalls, ...addCalls]).catch(() => {
      throw new Error('Error updating the permissions')
    })

    await refetch()

    return true
  }

  return { permissionTable, save }
}

export const useHasFixedPermissionAccess = () => {
  const customer = useCurrentCustomer()

  const [user, { error: userError }] = useUser()

  const { data: userPermData, loading, error: permError } = useQuery(
    GET_FIXED_PERMISSIONS_FOR_USER,
    {
      skip: !user || !customer,
      variables: {
        accountID: user?.id,
        companyID: customer?.id,
      },
    }
  )

  const permissionKeys = useMemo(() => {
    const userPermissions = userPermData?.fixedPermissionRelations?.map(
      (relation) => relation.fixedPermission
    )
    return userPermissions?.map((perm) => perm.name)
  }, [userPermData])

  const isAdmin = user?.admin && user?.company.id === customer?.id

  const hasAccess = useCallback(
    (permissions) => {
      if (isAdmin) return true

      if (!permissionKeys) return false

      const permList = typeof permissions === 'string' ? [permissions] : permissions

      return permList.some((permission) => permissionKeys.includes(permission))
    },
    [isAdmin, permissionKeys]
  )

  return [
    hasAccess,
    {
      customer,
      user,
      permissions: userPermData?.fixedPermissionRelations?.fixedPermission,
      loading,
      error: userError || permError,
    },
  ]
}
