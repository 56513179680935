import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Form, message } from 'antd'

import { imageStyles } from 'util/imageStyleUrl'
import { ME_QUERY, UPDATE_PROFILE_MUTATION, useProfileDetails } from 'services/user'

import FormComponent from 'components/Global/Form'
import Upload from 'components/Global/FormField/Upload/index.js'
import { CanaryModal } from 'components/Global/Modal'

import Loader from '../../LayoutComponents/Loader'
import { ImageWrapper } from './styled'

export const EditProfileModal = ({ intl, userId, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { data } = useProfileDetails(userId)
  const [logo, setLogo] = useState(data?.account?.avatar)
  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION, {
    refetchQueries: [
      {
        query: ME_QUERY,
        fetchPolicy: 'no-cache',
      },
    ],
  })

  if (!data) {
    return <Loader />
  }

  const deleteFile = () => {
    setLogo(null)
    initialValues.avatar = null
  }

  const handleOk = async () => {
    setLoading(true)
    const values = await form.validateFields().catch((err) => {
      console.error('Validation failed: ', err)
    })

    if (!values) {
      setLoading(false)
      return
    }

    if ((values.password || values.oldPassword) && (!values.password || !values.oldPassword)) {
      message.error({
        content: `${intl.formatMessage({
          id: 'settings.upateProfile.UpdatePasswordIncomplete',
        })}`,
      })
      setLoading(false)
      return
    }

    try {
      updateProfile({
        variables: {
          data: {
            id: userId,
            firstname: values.firstname,
            surname: values.surname,
            phone: values.phone === '' ? null : values.phone,
            mobile: values.mobile === '' ? null : values.mobile,
            avatar: logo,
            password: values.password,
            oldPassword: values.oldPassword,
          },
        },
        update: (_, res) => {
          if (res?.data?.updateProfile?.success) {
            message.success({
              content: intl.formatMessage({ id: 'settings.updateProfile.success' }),
            })
            form.resetFields()

            handleClose()
          } else {
            message.error({
              content: `${intl.formatMessage({
                id: 'settings.updateProfile.failed',
              })}: ${res?.data?.updateProfile.message}`,
            })

            handleClose()
          }
        },
      })
    } catch (error) {
      message.error({
        content: `${intl.formatMessage({
          id: 'settings.updateProfile.failed',
        })}: ${error.message}`,
      })
      handleClose()
    }
  }

  const initialValues = {
    firstname: data.account.firstname,
    surname: data.account.surname,
    phone: data.account.phone,
    mobile: data.account.mobile,
    avatar: data.account.avatar,
    password: '',
  }

  return (
    <CanaryModal
      title={intl.formatMessage({
        id: 'settings.updateProfile.title',
      })}
      centered
      visible
      width="50%"
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={() => handleClose()}
      maskClosable={false}
    >
      <ImageWrapper>
        <Upload
          value={initialValues.avatar}
          label={intl.formatMessage({ id: 'form.label.avatar' })}
          imageStyle={imageStyles.small}
          onChange={(value) => {
            setLogo(value)
          }}
          icon="fas fa-upload"
          placeholderIcon="fas fa-xmark"
          loadingText={false}
          companyWide={false}
          deleteLabel={intl.formatMessage({ id: 'dataTable.delete' })}
          deleteFile={deleteFile}
        />
      </ImageWrapper>
      <FormComponent
        form={form}
        rows
        initialValues={initialValues}
        fields={[
          {
            type: 'input',
            name: 'firstname',
            row: 0,
            label: intl.formatMessage({ id: 'form.label.firstname' }),
            customRules: [],
          },
          {
            type: 'input',
            name: 'surname',
            row: 0,
            label: intl.formatMessage({ id: 'form.label.surname' }),
            customRules: [],
          },
          {
            type: 'input',
            name: 'phone',
            row: 1,
            label: intl.formatMessage({ id: 'form.label.phone' }),
            placeholder: intl.formatMessage({ id: 'form.label.noZeroesAfterPlus' }),
            customRules: [
              {
                message: intl.formatMessage({ id: 'form.label.noZeroesAfterPlus' }),
                pattern: new RegExp(/^\+?[1-9]\d{2,14}$/),
              },
            ],
          },
          {
            type: 'input',
            name: 'mobile',
            row: 2,
            label: intl.formatMessage({ id: 'form.label.mobile' }),
            placeholder: intl.formatMessage({ id: 'form.label.noZeroesAfterPlus' }),
            customRules: [
              {
                message: intl.formatMessage({ id: 'form.label.noZeroesAfterPlus' }),
                pattern: new RegExp(/^\+?[1-9]\d{2,14}$/),
              },
            ],
          },
          { type: 'divider', row: 3 },
          {
            type: 'label',
            disabled: true,
            row: 4,
            label: intl.formatMessage({ id: 'settings.updateProfile.changePassword' }),
          },
          { type: 'divider', row: 5 },
          {
            type: 'password',
            name: 'oldPassword',
            skipRequired: true,
            autoComplete: false,
            row: 6,
            label: intl.formatMessage({ id: 'settings.updateProfile.oldPassword' }),
            customRules: [],
          },
          {
            type: 'password',
            name: 'password',
            skipRequired: true,
            autoComplete: false,
            row: 7,
            label: intl.formatMessage({ id: 'settings.updateProfile.newPassword' }),
            customRules: [],
          },
          {
            type: 'confirmPassword',
            name: 'confirmNewPassword',
            skipRequired: true,
            autoComplete: false,
            row: 8,
            label: intl.formatMessage({ id: 'settings.updateProfile.confirmPassword' }),
            customRules: [],
          },
        ]}
      />
    </CanaryModal>
  )
}
