import styled from 'styled-components'

import { Table } from 'components/Global/FormField/FormFieldElement/Table.styled'

const variants = {
  Table,
}

const FormFieldElementStyled = styled.div`
  &.NumberInput {
    width: 100%;
  }
  ${(props) => variants[props.variant]}
`

export const MultiFieldContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid var(--color-softStroke);
  padding: 0 5px;
  border-radius: 2px;
  > label {
    margin: auto 1rem;
  }
  > .MultiFieldContainer {
    border: none;
    padding: 0;
    .NumberInput {
      min-width: 4rem;
    }
  }

  > * {
    flex: 1;
    border-right: 1px solid var(--color-softStroke) !important;
    &:last-child {
      border-right: none !important;
    }

    input {
      border: none;
    }
  }

  > span {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
  }

  .ValuePicker__value {
    border: none !important;
  }

  .Tooltip {
    min-width: 100px;
  }

  .NumberInput {
    min-width: 4rem;
  }

  .ant-input-number,
  .ant-input,
  .ant-input-affix-wrapper,
  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--color-softStroke);
    border-radius: 0;
    margin-bottom: -1px;
    margin-top: 1px;
  }
  .ant-input-number,
  .ant-input,
  .ant-input-affix-wrapper {
    &:not(.ant-input-number-disabled) {
      input {
        &.ant-input-affix-wrapper-focused,
        &.ant-input-number-focused,
        &:focus,
        &:hover {
          border-bottom-color: var(--color-main);
          box-shadow: none;
        }
      }
    }
  }
`
export default FormFieldElementStyled
