export default {
  'settings.menu.users': 'Gebruikers instellingen',
  'settings.menu.users.users': 'Gebruikers en bedrijven',
  'settings.menu.users.roles': 'Rollen',
  'settings.menu.data': 'Data instellingen',
  'settings.menu.data.datapoints': 'Datapunten',
  'settings.menu.data.environmentSetup': 'Omgevingsinstellingen',
  'settings.menu.data.rules': 'Regels',
  'settings.menu.data.flows': 'Flows',
  'settings.menu.data.import': 'Importeren',
  'settings.menu.data.importETL': 'Importeren (ETL)',
  'settings.menu.data.reportTemplates': 'Documentsjablonen',
  'settings.menu.data.organisation': 'Organisatie',
  'settings.menu.data.conversionFactors': 'Omrekeningsfactoren',
  'settings.menu.data.dataManagement': 'Gegevensbeheer',
  'settings.menu.admin': 'Canary admin',
  'settings.menu.admin.customers': 'Klanten',
  'settings.menu.admin.entityTypeManager': 'Entiteitstypen',
  'settings.menu.admin.fixedFiles': 'Vaste bestanden',
  'settings.menu.admin.fixedFileConfiguration': 'Fixed file configuration',
  'settings.menu.admin.menu': 'Menu',
  'settings.menu.admin.logging': 'Logging',

  // Datapoints
  'settings.trendline.title': 'Trendline',
  'settings.loading': 'Bezig met laden...',
  'settings.noData': 'Geen gegevens',
  'settings.id': 'id',
  'settings.showAll': 'Toon alles',
  'settings.actualValue': 'Werkelijke waarde',
  'settings.created': 'Aangemaakt',
  'settings.updated': 'Updated',
  'settings.timestamp': 'Tijdstempel',
  'settings.status': 'Status',
  'settings.equipment': 'Toestel',
  'settings.tagsFields': 'Tags en velden',
  'settings.markers': 'Markers',
  'settings.tags': 'Tags',
  'settings.actions': 'Acties',
  'settings.description': 'Omschrijving',
  'settings.datapoints': 'Datapunten',
  'settings.filesRemarks': 'Bestanden en opmerkingen',
  'settings.unit': 'Eenheid',
  'settings.entityPreset': 'Entiteit preset',
  'settings.entities.equipmentMoveFailed':
    'Toestellen kunnen enkel verplaatst worden naar een ruimte',
  'settings.entities.equipmentMoveSucces': ' is verplaatst naar ruimte ',
  'settings.entities.chooseLocation': 'Kies een locatie',
  'settings.entities.deleteEntity': 'Entiteit verwijderen',
  'settings.entities.duplicateEntity': 'Entiteit dupliceren',
  'settings.catches.deleteCatch': 'Catch verwijderen',
  'settings.entities.entity': 'Entiteit',
  'settings.entities.department': 'Departement',
  'settings.rules': 'Rules',
  'settings.rules.curErr.name': 'Actuele Fout',
  'settings.rules.heartbeat.name': 'Heartbeat',
  'settings.rules.minVal.name': 'Minimum Waarde detectie',
  'settings.rules.maxVal.name': 'Maximum Waarde detectie',
  'settings.rules.filter.name': 'Filteronderhoud Alarm',
  'settings.rules.fireAlarm.name': 'Brandalarm',
  'settings.rules.outDoorTempDiff.name': 'Verschil Buitentemperatuur',
  'settings.rules.switchActions.name': 'Maximale Schakelaaractiviteit',
  'settings.rules.co2LevelCheck.name': 'CO2 Niveau detectie',
  'settings.rules.ReportingPeriodControlYearly.name': 'ReportingPeriodControlYearly',
  'settings.rules.ReportingPeriodControlQuarterly.name': 'ReportingPeriodControlQuarterly',
  'settings.rules.ReportingPeriodControlMonthly.name': 'ReportingPeriodControlMonthly',
  'settings.rules.ReportingPeriodControlWeekly.name': 'ReportingPeriodControlWeekly',
  'settings.rules.ReportingPeriodControlDaily.name': 'ReportingPeriodControlDaily',
  'settings.rules.ReportingPeriodControlHourly.name': 'ReportingPeriodControlHourly',
  'settings.rules.leakage.name': 'Lekdetectie',
  'settings.rules.overflow.name': 'Overloopdetectie',
  'settings.rules.curErr.fileName': 'curErr.png',
  'settings.rules.heartbeat.fileName': 'heartbeat.png',
  'settings.rules.minVal.fileName': 'minVal.png',
  'settings.rules.maxVal.fileName': 'maxVal.png',
  'settings.rules.filter.fileName': 'filter.png',
  'settings.rules.fireAlarm.fileName': 'fireAlarm.png',
  'settings.rules.outDoorTempDiff.fileName': 'outDoorTempDiff.png',
  'settings.rules.switchActions.fileName': 'switchActions.png',
  'settings.rules.co2LevelCheck.fileName': 'co2LevelCheck.png',
  'settings.rules.ReportingPeriodControlYearly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlQuarterly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlMonthly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlWeekly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlDaily.fileName': 'ReportingPeriodControl.png',
  'settings.rules.ReportingPeriodControlHourly.fileName': 'ReportingPeriodControl.png',
  'settings.rules.leakage.fileName': 'leakage.png',
  'settings.rules.overflow.fileName': 'overflow.png',
  'settings.rules.curErr.description':
    'Het systeem bewaakt de datapuntwaarde en genereert een squeal zodra de waarde 1 wordt bereikt.',
  'settings.rules.heartbeat.description':
    'Het systeem controleert de levensstatus van een apparaat aan de hand van zijn heartbeat. Als deze heartbeat gedurende de onderstaande periode ontbreekt, genereert het systeem een squeal.',
  'settings.rules.minVal.description':
    'Het systeem controleert of de waarde niet lager is dan de onderstaande minimumwaarde. Als de waarde tussen de minimumwaarde en de kritische waarde ligt, wordt een lineaire waarde van de squeal berekend. Als de waarde onder de kritische minimumwaarde ligt, bedraagt de gegenereerde squeal 100%.',
  'settings.rules.maxVal.description':
    'Het systeem controleert of de waarde niet hoger is dan de onderstaande maximumwaarde. Als de waarde tussen de maximumwaarde en de kritische waarde ligt, wordt een lineaire waarde van de squeal berekend. Als de waarde boven de kritische maximumwaarde ligt, bedraagt de gegenereerde squeal 100%.',
  'settings.rules.filter.description':
    'Het systeem bewaakt de datapuntwaarde en genereert een squeal als de onderhoudswaarde van het filter wordt bereikt.',
  'settings.rules.fireAlarm.description':
    'Het systeem genereert een squeal zodra een potentiële brandstatuswaarde is ontvangen.',
  'settings.rules.outDoorTempDiff.description':
    'Het systeem controleert het verschil tussen de OpenWeather temperatuur en de gemeten buitentemperatuur van een lokale thermometer. Als het verschil groter is dan 5 graden wordt een alarm gegenereerd.',
  'settings.rules.switchActions.description':
    'Het systeem telt hoe vaak een schakelaar wordt geactiveerd. Zodra het onderstaande maximum aantal is bereikt, wordt een squeal gegenereerd.',
  'settings.rules.co2LevelCheck.description':
    'Het systeem controleert of de CO2 waarde niet hoger is dan de maximumwaarde (900 ppm). Als de waarde tussen de maximumwaarde en de kritische waarde (1200ppm) ligt, wordt een lineaire waarde van de squeal berekend. Als de waarde boven de kritische maximumwaarde ligt, bedraagt de gegenereerde squeal 100%.',
  'settings.rules.ReportingPeriodControlYearly.description': ' ',
  'settings.rules.ReportingPeriodControlQuarterly.description': ' ',
  'settings.rules.ReportingPeriodControlMonthly.description': ' ',
  'settings.rules.ReportingPeriodControlWeekly.description': ' ',
  'settings.rules.ReportingPeriodControlDaily.description': ' ',
  'settings.rules.ReportingPeriodControlHourly.description': ' ',
  'settings.rules.leakage.description':
    'Het systeem controleert voor onderstaande periode tijdens onderstaande controle periode of de waarde niet lager is dan de onderstaande minimumwaarde. Als de waarde tussen de minimumwaarde en de kritische waarde ligt, wordt een lineaire waarde van de squeal berekend. Als de waarde onder de kritische minimumwaarde ligt, bedraagt de gegenereerde squeal 100%.',
  'settings.rules.overflow.description':
    'Het systeem controleert voor onderstaande periode tijdens onderstaande controle periode of de waarde niet hoger is dan de onderstaande maximumwaarde. Als de waarde tussen de maximumwaarde en de kritische waarde ligt, wordt een lineaire waarde van de squeal berekend. Als de waarde boven de kritische maximumwaarde ligt, bedraagt de gegenereerde squeal 100%.',

  // ETL

  'settings.menu.etl.management': 'ETL Management',

  // Organisations

  'settings.menu.organisation': 'Organisatie instellingen',
  'settings.menu.organisation.management': 'Beheer organisatie',
  'settings.menu.organisation.structure': 'Structuur organisatie',
  'settings.menu.organisation.environment': 'Omgeving organisatie',

  // Invoice & Settings
  'settings.menu.invoiceSettings': 'Facturering & Instellingen',
  'settings.menu.invoiceSettings.products': 'Mijn Producten',
  'settings.menu.invoiceSettings.licenses': 'Licenties',
  'settings.menu.invoiceSettings.customer': 'Klantgegevens',
  'settings.menu.invoiceSettings.graphics': 'Afbeeldingen',
  'settings.menu.invoiceSettings.marketplace': 'Marketplace',
  'settings.menu.invoiceSettings.activity': 'Gebruikersactiviteit',

  // Entities
  'settings.entities.title': 'Omgevingsinst.',

  // Entity categories
  'settings.entity.category.assets': 'Middelen',
  'settings.entity.category.persona': 'Persoon',
  'settings.entity.category.vehicles': 'Voertuigen',
  'settings.entity.category.finance': 'Financiën',
  'settings.entity.category.varia': 'varia',
  'settings.entity.category.valueChain': 'Upstream/Downstream Activiteiten',
  'settings.entity.category.degreeDays': 'Equivalente Graaddagen',

  // User overview
  'settings.users.inviteCompany': 'Nodig een bedrijf uit.',
  'settings.users.manageInvites': 'Beheer onverzonden uitnodigingen',
  'settings.users.inviteUser': 'Nodig een gebruiker uit.',
  'settings.users.users': 'Gebruikers',
  'settings.users.companies': 'Bedrijven',
  'settings.users.pending': 'In afwachting',
  'settings.users.active': 'Actief',
  'settings.users.inActive': 'Gedeactiveerd',
  'settings.users.delete': 'Annuleren',
  'settings.users.renew': 'Vernieuwen',
  'settings.users.inviteCancelled': 'Uitnodiging geannuleerd',
  'settings.users.inviteRenewed': 'Uitnodiging vernieuwd',
  'settings.users.inviteFailed': 'Uitnodiging mislukt',
  'settings.users.sendInvite': 'Uitnodiging verzenden',
  'settings.users.editInvite': 'Uitnodiging bewerken',
  'settings.users.sendInviteConfirm': 'Uitnodiging nu verzenden?',
  'settings.users.editInviteConfirm': 'Uitnodiging aanpassen?',

  // User detail
  'settings.userDetail.noRolesFound': 'Geen rollen gevonden',

  // Invite a company modal
  'settings.inviteCompany.success': ' is uitgenodigd!',
  'settings.inviteCompany.failed': ' kon niet worden uitgenodigd',
  'settings.inviteCompany.title': 'Bedrijf uitnodigen',

  // Edit a company modal
  'settings.editCompany.title': 'Toegang bedrijf aanpassen',
  'settings.editCompany.failed': 'Toegang bedrijf kon niet worden aangepast',
  'settings.editCompany.success': 'Toegang bedrijf aangepast!',

  // Invite a user modal
  'settings.inviteUser.title': 'Gebruiker uitnodigen',
  'settings.inviteUser.success': 'Gebruiker is uitgenodigd!',
  'settings.inviteUser.saved': 'Uitnodiging is bewaard.',
  'settings.inviteUser.bulkSuccess': 'De geselecteerde uitnodigingen zijn verzonden!',
  'settings.inviteUser.doubleDashboards':
    'Hetzelfde dashboard werd meerdere keren gekozen met verschillende rechten',
  'settings.inviteUser.failed': 'Gebruiker kon niet worden uitgenodigd',

  // Edit a user modal
  'settings.editUser.title': 'Gebruiker aanpassen',
  'settings.editUser.failed': 'Gebruiker kon niet worden aangepast',
  'settings.editUser.success': 'Gebruiker aangepast!',

  // Edit a customer modal
  'settings.editCustomer.title': 'Klant aanpassen',
  'settings.createCustomer.title': 'Klant aanmaken',
  'settings.editCustomer.failed': 'Klant kon niet worden aangepast',
  'settings.editCustomer.success': 'Klant aangepast!',
  'settings.editCustomer.dealerHasClients':
    'Deze dealer kan niet gedeactiveerd worden aangezien er nog klanten aan verbonden zijn.',
  'settings.editCustomer.confirmChangeDealer':
    'Bent u zeker dat u de dealer wil wijzigen? Alle permissies zullen overgedragen worden!',
  'settings.insertCustomer.success': 'Klant aangemaakt!',
  'settings.insertCustomer.failed': 'Klant kon niet aangemaakt worden.',
  'settings.editCustomer.modules': 'Modules',
  'settings.editCustomer.license': 'License',

  'settings.editCustomer.modules.audit': 'Audit',
  'settings.editCustomer.modules.audit.sdg': 'SDG Audit',
  'settings.editCustomer.modules.audit.dataReadiness': 'Data Readiness',
  'sustainability.menu.configuration': 'Configuratie',
  'sustainability.menu.config.research': 'Onderzoek',
  'sustainability.menu.config.configuration-assistant': 'Configuratie-assistent',
  'sustainability.menu.config.data-survey': 'Data Enquête',
  'sustainability.menu.config.validation': 'Validatie',
  'settings.editCustomer.modules.modules': 'Duurzaamheid',
  'settings.editCustomer.modules.modules.csrd':
    'CSRD (Corporate Sustainability Reporting Directive)',
  'settings.editCustomer.modules.modules.esg': 'ESG (Environment Social Governance)',
  'settings.editCustomer.modules.modules.ghg': 'GHG (Greenhouse Gas Protocol)',
  'settings.editCustomer.modules.modules.gri': 'GRI (Global Reporting Initiative)',
  'settings.editCustomer.modules.modules.sbti': 'SBTi (Science Based Targets initiative)',
  'settings.editCustomer.modules.modules.sdg': 'SDG (Sustainable Development Goals)',

  'settings.editCustomer.modules.dataAccess': 'Data toegang',
  'settings.editCustomer.modules.dataAccess.oData': 'OData',
  'settings.editCustomer.modules.dataAccess.restApi': 'REST API',
  'settings.editCustomer.modules.services.smsModule': 'SMS',
  'settings.editCustomer.modules.services': 'Services',
  'settings.editCustomer.modules.general': 'Varia',
  'settings.editCustomer.modules.general.inviteCompany': 'Bedrijven uitnodigen',

  // Edit Profile
  'settings.updateProfile.success': 'Profiel gewijzigd!',
  'settings.updateProfile.failed': 'Profiel update niet gelukt.',
  'settings.updateProfile.title': 'Wijzig profiel',
  'settings.updateProfile.changePassword': 'WIJZIG WACHTWOORD (OPTIONEEL)',
  'settings.updateProfile.oldPassword': 'Oud wachtwoord',
  'settings.updateProfile.newPassword': 'Nieuw wachtwoord',
  'settings.updateProfile.confirmPassword': 'Bevestig nieuw wachtwoord',
  'settings.upateProfile.UpdatePasswordIncomplete':
    'Gelieve alle wachtwoordvelden in te vullen om uw wachtwoord te wijzigen',
  'settings.updateProfile.advanced.active.success':
    'Uw account bevindt zich nu in de geavanceerde modus',
  'settings.updateProfile.advanced.disabled.success':
    'Uw account bevindt zich nu in de normale modus',
  'settings.updateProfile.advanced.failed': 'Fout het wijzigen van de geavanceerde modus',

  // Fixed permissions
  'settings.fixedpermission.USER_MANAGEMENT': 'Gebruikersbeheer',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT': 'Datapointbeheer',
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT': 'Rollen & permissies beheer',
  'settings.fixedpermission.RULE_MANAGEMENT': 'Rulebeheer',
  'settings.fixedpermission.FLOW_MANAGEMENT': 'Flowbeheer',
  'settings.fixedpermission.PERSONA_MANAGEMENT': 'Personabeheer',
  'settings.fixedpermission.SUSTAINABILITY_VISITOR': 'Gegevensinvoer - Bezoeker',
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR': 'Gegevensinvoer - Bijdrager',
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR': 'Gegevensinvoer - Administrator',
  'settings.fixedpermission.ODATA_USER': 'OData gebruiker',
  'settings.fixedpermission.RESTAPI_USER': 'REST API gebruiker',
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR': 'Organisatie administrator',
  'settings.fixedpermission.SQUEAL_MANAGEMENT': 'Squealbeheer',
  'settings.fixedpermission.IMPORT_VISITOR': 'Import bezoeker',
  'settings.fixedpermission.IMPORT_EXECUTOR': 'Import uitvoerder',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR': 'Import administrator',
  'settings.fixedpermission.DATA_ENTRY_MANAGEMENT': 'Gegevensinvoer beheer',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR': 'Facturering Administrator',
  'settings.fixedpermission.LOG_MANAGEMENT': 'Log beheer',

  // Fixed permission Info

  'settings.fixedpermission.USER_MANAGEMENT_INFO':
    '<strong>Gebruikersbeheer</strong>: Verleent volledige toegang tot Gebruikers & Bedrijven',
  'settings.fixedpermission.DATAPOINT_MANAGEMENT_INFO':
    '<strong>Datapointbeheer</strong>: Verleent volledige toegang tot Datapunten (Activa), Omgevingsinstellingen (Activa) en Catches',
  'settings.fixedpermission.ROLES_PERMISSION_MANAGEMENT_INFO':
    '<strong>Rollen & permissies beheer</strong>: Verleent volledige toegang tot Rollen',
  'settings.fixedpermission.RULE_MANAGEMENT_INFO':
    '<strong>Rulebeheer</strong>: Verleent volledige toegang tot Regels',
  'settings.fixedpermission.FLOW_MANAGEMENT_INFO':
    '<strong>Flowbeheer</strong>: Verleent volledige toegang tot Flows',
  'settings.fixedpermission.PERSONA_MANAGEMENT_INFO':
    '<strong>Personabeheer</strong>: Verleent volledige toegang tot Datapunten (Persona) en Omgevingsinstellingen (Persona). Datapointbeheer permissie is een vereiste',
  'settings.fixedpermission.SUSTAINABILITY_VISITOR_INFO':
    '<strong>Gegevensinvoer - Bezoeker</strong>: Verleent alleen leestoegang tot de beschikbare modules Duurzaamheid, Dashboards voor gegevensinvoer, Datapunten (Voertuigen & Up/Downstream Activiteiten) en Omgevingsinstellingen (Voertuigen & Up/Downstream Activiteiten). Datapointbeheer permissie is een voorwaarde voor dat laatste',
  'settings.fixedpermission.SUSTAINABILITY_CONTRIBUTOR_INFO':
    '<strong>Gegevensinvoer - Bijdrager</strong>: Verleent uitvoeringstoegang tot de beschikbare Duurzaamheid modules, Dashboards voor gegevensinvoer, Datapunten (Voertuigen & Up/Downstream Activiteiten) en Omgevingsinstellingen (Voertuigen & Up/Downstream Activiteiten). Datapointbeheer permissie is een voorwaarde voor dat laatste',
  'settings.fixedpermission.SUSTAINABILITY_ADMINISTRATOR_INFO':
    '<strong>Gegevensinvoer - Beheerder</strong>: Verleent volledige toegang tot de beschikbare Sustainability modules en Dashboards voor gegevensinvoer, Gegevensbeheer, Documentsjablonen, Datapunten (Voertuigen & Up/Downstream Activiteiten) en Omgevingsinstellingen (Voertuigen & Up/Downstream Activiteiten). Datapointbeheer permissie is een voorwaarde voor dat laatste',
  'settings.fixedpermission.ODATA_USER_INFO':
    '<strong>OData gebruiker</strong>: Staat het gebruik van de externe OData interface toe',
  'settings.fixedpermission.RESTAPI_USER_INFO':
    '<strong>REST API gebruiker</strong>: Staat het gebruik van de externe REST API interface toe',
  'settings.fixedpermission.ORGANISATION_ADMINISTRATOR_INFO':
    '<strong>Organisatie administrator</strong>: Verleent volledige toegang tot Organisatie instellingen',
  'settings.fixedpermission.SQUEAL_MANAGEMENT_INFO':
    '<strong>Squealbeheer</strong>: Verleent volledige toegang tot Squeal melding en overzicht',
  'settings.fixedpermission.IMPORT_VISITOR_INFO':
    '<strong>Import bezoeker</strong>: Verleent alleen-lezen toegang tot Importeren (ETL)',
  'settings.fixedpermission.IMPORT_EXECUTOR_INFO':
    '<strong>Import uitvoerder</strong>: Verleent uitvoeringstoegang tot Importeren (ETL)',
  'settings.fixedpermission.IMPORT_ADMINISTRATOR_INFO':
    '<strong>Import administrator</strong>: Verleent volledige toegang tot Importeren (ETL)',
  'settings.fixedpermission.INVOICING_ADMINISTRATOR_INFO':
    '<strong>Facturering administrator</strong>: Verleent volledige toegang tot Facturering & instellingen',
  'settings.fixedpermission.LOG_MANAGEMENT_INFO':
    '<strong>Log beheer</strong>: Geeft toegang tot het overzicht van Gebruikersactiviteit',

  // Rule overview
  'settings.rules.active': 'AAN',
  'settings.rules.inActive': 'UIT',
  'settings.rules.createRule': 'Nieuwe rule aanmaken',
  'settings.rules.ruleOverview': 'Overzicht rules',
  'settings.rules.ruleType': 'Type',
  'settings.rules.ruleName': 'Rule',
  'settings.rules.ruleNumberOfInstances': '# Instanties',
  'settings.rules.ruleStatus': 'Status',

  //Rule instances overview
  'settings.ruleInstances.ruleInstancesOverview': 'Overzicht instanties van rule',
  'settings.ruleInstances.viewInfo': 'INFO',
  'settings.ruleInstances.ruleName': 'Rule',
  'settings.ruleInstances.ruleInstance': 'Instanties van rule',
  'settings.ruleInstances.siteName': 'Site',
  'settings.ruleInstances.buildingName': 'Gebouw',
  'settings.ruleInstances.floorName': 'Verdieping',
  'settings.ruleInstances.roomName': 'Ruimte',
  'settings.ruleInstances.equipmentName': 'Toestel',
  'settings.ruleInstances.datapointName': 'Datapunt',
  'settings.ruleInstances.isActive': 'Status',

  //Flow list
  'settings.flows.flow': 'Flow',
  'settings.flows.flows': 'Flows',
  'settings.flows.active': 'AAN',
  'settings.flows.inActive': 'UIT',
  'settings.flows.description': 'Omschrijving',
  'settings.flows.rule': 'Rule',
  'settings.flows.status': 'Status',

  //Fixed files
  'settings.fixedFiles.title': 'Vaste bestanden',
  'settings.fixedFiles.selectToStart': 'Selecteer een vast bestand',
  'settings.fixedFiles.header.currentFixedFile': 'Vast bestand',

  // Data Import
  'settings.dataImport.title': 'Data Import',

  //Catchs
  'settings.catchs.title': 'Catches',
  'settings.catchs.name': 'Naam',
  'settings.catchs.dealer': 'Dealer',
  'settings.catchs.company': 'Bedrijf',
  'settings.catchs.lastLifeSign': 'Laatste check alive',
  'settings.catchs.version': 'Versie',
  'settings.catchs.load': 'Load',
  'settings.catchs.memory': 'Memory (MB)',
  'settings.catchs.configuration': 'Configuratie',
  'settings.catchs.externalAccess': 'Externe toegang',
  'settings.catchs.status': 'Status',
  'settings.catchs.open': 'Open',
  'settings.catchs.close': 'Gesloten',
  'settings.catchs.basicUI': 'Basis interface',
  'settings.catchs.catchUI': 'Catch interface',
  'settings.catchs.openUI': 'Open',
  'settings.catchs.installationScript': 'Installatie script',
  'settings.catchs.installation': 'Installatie',
  'settings.catchs.generate': 'Aanmaken',
  'settings.catchs.model': 'Model',
  'settings.catchs.firmware': 'Basis Firmware',
  'settings.catchs.catchFirmware': 'Catch Firmware',
  'settings.catchs.load1m': 'Load 1m',
  'settings.catchs.load5m': 'Load 5m',
  'settings.catchs.load15m': 'Load 15m',
  'settings.catchs.processes': 'Processes',
  'settings.catchs.rootPassword': 'Root wachtwoord',
  'settings.catchs.localPassword': 'Lokaal wachtwoord',
  'settings.catchs.requiredVersion': 'Vereiste versie',

  // Data management
  'settings.importDefaultData.confirmImportWarning':
    'Bent u zeker dat u de default gegevens wil importeren? WAARSCHUWING: de huidige gegevens zullen worden gearchiveerd!',
  'settings.importDefaultData.confirmImport':
    'Bent u zeker dat u de default gegevens wil importeren en toevoegen?',

  // Conversion factors
  'settings.conversionFactors.stationaryCombustion': 'Conv: Stationary Combustion',
  'settings.conversionFactors.purchasedEnergy': 'Conv: Purchased Energy',
  'settings.conversionFactors.mobileCombustion': 'Conv: Mobile Combustion',
  'settings.conversionFactors.vehicleFuelEmission': 'Conv: Upstream - Vehicle fuels',
  'settings.conversionFactors.energyGenerationFuelEmission': 'Conv: Upstream - Energy fuels',
  'settings.conversionFactors.upstreamPurchasedEnergy': 'Conv: Upstream - Purchased Energy',
  'settings.conversionFactors.productTransport': 'Conv: Product Transport',
  'settings.conversionFactors.peopleTransport': 'Conv: People Transport',
  'settings.conversionFactors.transportDistributionLoss': 'Transmission & Distribution Losses',

  // Energy contracts
  'settings.energyContracts.energyContracts': 'Energiecontracten',
  'settings.energyContracts.energyType.energySource': 'Energiebron',
  'settings.energyContracts.energyType.renewable': 'Hernieuwbaar',
  'settings.energyContracts.energyType.compositionError':
    'FOUT: totale energiesamenstelling mag niet hoger zijn dan 100%',
  'settings.energyContracts.percentage': 'Percentage',
  'settings.energyContracts.fuelType': 'Brandstoftype',
  'settings.energyContracts.unit': 'Eenheid',
  'settings.energyContracts.name': 'Contract naam',

  // Purchased energy contracts
  'settings.purchasedEnergyContracts.title': 'Purchased Energy contracts',
  'settings.stationaryCombustionContracts.title': 'Stationary Combustion contracts',
  'settings.purchasedEnergyContracts.assignContract': 'Contract toewijzen',
  'settings.purchasedEnergyContracts.model.assign.title':
    'Wijs een contract toe aan {amount} items',
  'settings.purchasedEnergyContracts.model.assign.success':
    'Contract is toegewezen aan {amount} items',

  // Persona
  'settings.persona.location': 'Persoon locatie informatie',
  'settings.persona.personal': 'Persoonlijke informatie',

  // Vehicles
  'settings.vehicles': 'Voertuig instellingen',

  // Dashboards
  'settings.dashboards.rolesAndPermissions': 'Rollen & Permissies',
  'settings.dashboards.info': 'Info',
  'settings.dashboards.dashboards': 'Dashboards',
  'settings.dashboards.dashboards0': 'Dashboards - permissie Bezoeker',
  'settings.dashboards.dashboards1': 'Dashboards - permissie Editor',
  'settings.dashboards.dashboards2': 'Dashboards - permissie Eigenaar',
  'settings.dashboards.convDashboards': 'Conventionele Dashboards',
  'settings.dashboards.successfullyDeleted': 'Dashboard verwijderd.',
  'settings.dashboards.unsuscriptionSuccessful': 'Succesvol uitgeschreven.',
  'settings.dashboards.dashboardName': 'Naam',
  'settings.dashboards.icon': 'Icon',
  'settings.dashboards.creator': 'Auteur',
  'settings.dashboards.saved': 'Wijzigingen werden bewaard.',
  'settings.dashboards.errorSaving': 'Fout bij het bewaren van het dashboard.',
  'settings.dashboards.confirmChangeCreator':
    'Weet u zeker dat u de auteur van deze dashboardgroep wil wijzigen?',
  'settings.dashboards.canNotSetOwner':
    'De nieuwe auteur kan niet toewezen worden. Deze moet de rechten van Eigenaar hebben.',
  'settings.dashboards.noRightsDefined': 'Gelieve rechten toe te kennen aan alle abonnees.',
  'settings.dashboards.useAsHomepage': 'Als startpagina instellen',
  'settings.dashboards.addToFavourites': 'Favoriet',
  'settings.dashboards.masterDashboardGroup': 'Master dashboard groep',
  'settings.dashboards.subscriber': 'Abonnee',
  'settings.dashboards.rights': 'Rechten',
  'settings.dashboards.selectDashboards': 'Gelieve ten minste één dashboard te selecteren.',
  'settings.dashboards.mailAdded': 'E-mailadres is toegevoegd aan de lijst.',
  'settings.dashboards.unknownMail': 'E-mailadres is onbekend.',
  'settings.dashboards.visitor': 'Bezoeker',
  'settings.dashboards.visitorWithShare': 'Bezoeker (Deelpermissie)',
  'settings.dashboards.editor': 'Editor',
  'settings.dashboards.owner': 'Eigenaar',
  'settings.dashboards.conventional': 'Conventioneel',
  'settings.dashboards.master': 'Master',
  'settings.dashboards.pleaseSelect': 'Selecteer',
  'settings.dashboards.addManually': 'Voeg een vrij e-mailadres toe',
  'settings.dashboards.addButton': 'Toevoegen',
  'settings.dashboards.createdAt': 'Aangemaakt',
  'settings.dashboards.updatedAt': 'Laatste wijziging',
  'settings.dashboards.conventionalHint':
    'Deze dashboards zullen niet geüpdated worden nadat ze gedeeld zijn.',
  'settings.dashboards.masterHint':
    'Deze dashboards zullen automatisch upgedated worden nadat ze gedeeld zijn.',
  'settings.dashboards.delete': 'Verwijder',

  // Menu
  'settings.menu.title': `Menu's`,
  'settings.menu.editItems': `Bewerk menu items`,
  'settings.menuItems.title': 'Items',

  // My Products & Licenses
  'settings.myProducts.title': 'Mijn Producten',
  'settings.licenses.title': 'Totalen',
  'settings.dataUsage.title': 'Data gebruik',
  'settings.dataUsage.last12Months': 'Laatste 12 Maanden',
  'settings.dataUsage.historyOfLast': 'Gebruik gegevens van de laatste ',
  'settings.dataUsage.months': ' maanden',
  'settings.licenses.dataposts': 'Dataposts',
  'settings.licenses.totalDataposts': 'Totaal Dataposts',
  'settings.licenses.allowedDataposts': 'Max Dataposts',
  'settings.licenses.totals': 'Totalen',
  'settings.licenses.numberOfUsers': 'Totaal Gebruikers',
  'settings.licenses.numberOfCompanies': 'Totaal Bedrijven',
  'settings.licenses.numberOfOrganisations': 'Totaal Organisaties',
  'settings.licenses.numberOfSites': 'Totaal Sites',
  'settings.licenses.numberOfBuildings': 'Totaal Gebouwen',
  'settings.licenses.numberOfAssets': 'Totaal Activa',
  'settings.licenses.numberOfPersona': 'Totaal Persona',
  'settings.licenses.numberOfVehicles': 'Totaal Voertuigen',
  'settings.licenses.numberOfUpstreamDownstreamActivities': 'Totaal aantal Up/Down Activiteiten',
  'settings.licenses.numberOfDatapoints': 'Totaal Datapoints',
  'settings.licenses.numberOfCatches': 'Totaal Catches',
  'settings.licenses.totalDatapostsPrevious': 'Dataposts M-1',
  'settings.dataUsage.customer': 'Klant',
  'settings.licenses.sms': 'SMS',
  'settings.licenses.totalSms': 'SMS Totaal',
  'settings.licenses.totalSmssPrevious': 'SMS M-1',
  'settings.licenses.allowedSms': 'Max SMS',
  'settings.licenses.sites': 'Sites',
  'settings.licenses.maxSites': 'Max sites',
  'settings.licenses.datapoints': 'Datapunten',
  'settings.licenses.maxDatapoints': 'Max datapunten',
  'settings.licenses.users': 'Gebruikers',
  'settings.licenses.maxUsers': 'Max gebruikers',

  // Virtual
  'settings.virtual.deletedDatapointError': 'Dit datapunt bestaat niet meer.',
}
