const config = {
  displayProps: {},
  editProps: {
    name: 'widget.placeholder',
    category: 'widget.others',
    keywords: ['empty', 'placeholder'],
    img: '/resources/images/widgets/placeholder.png',
    info: {
      'fr-FR': '',
      'en-US': '',
      'nl-NL': '',
    },
    default: {
      type: 'Empty',
    },
    preview: {},
    fields: [],
  },
}

export default config
