import styled from 'styled-components'

import Button from '../../Button'

export const DateRangeSelectorStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
`

export const DateRangeSelectorButton = styled(Button)`
  transition: border 0.2s ease;
  margin: 0 0.4rem 0.4rem 0;
  border: 1px solid var(--color-softStroke);
  line-height: 1;
  padding: 0.2rem 0.7rem;
  border-radius: 1rem;
  &:hover {
    border-color: var(--color-main);
  }
`
