import React from 'react'

import { Tooltip } from 'antd'

import Icon from 'components/Global/Icon'

import { InfoText, Label, WarningText } from './styled'

const FieldLabel = ({
  children,
  isEditMode,
  required,
  tooltip = false,
  infoIcon = false,
  infoText,
  warningText,
}) => {
  const label = (
    <Label className="Label">
      {children}
      {isEditMode && required && <strong> *</strong>}

      {!infoIcon && infoText && <InfoText>{infoText}</InfoText>}
      {infoIcon && infoText && <Icon icon="fas fa-info" />}
      {warningText && <WarningText>{warningText}</WarningText>}
    </Label>
  )

  return tooltip ? <Tooltip title={infoText}>{label}</Tooltip> : label
}

export default FieldLabel
