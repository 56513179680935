import { transparentize } from 'polished'
import styled from 'styled-components'

import Field from '../../components/Field'

export const FlowFieldStyled = styled(Field)`
  position: relative;
  margin: 0 0 2rem;
  padding: 0;
  border: 1px solid var(--color-softStroke);
  border-radius: 6px;

  + label {
    position: absolute;
    left: 2.6rem;
    transform: translate(-50%, -1.76rem);
    background: var(--color-softerStroke);
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-radius: 6px;
  }

  .FlowFieldCondition {
  }
`

export const FlowFieldConditionStyled = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem;

  &:first-child {
    border-left: none;
    border-bottom: 1px solid var(--color-softStroke);
  }

  &:not(:first-child) {
    padding: 1rem;
  }

  > label {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    .FlowFieldCondition__label {
      display: flex;
      position: relative;
      background: var(--color-softerStroke);
      padding: 0.3rem 0.5rem;
      text-transform: uppercase;
      border-radius: 6px;
    }
  }

  .FlowFieldCondition__content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .Field {
      padding: 0;

      &:last-child {
        max-width: 50%;
      }
    }

    > .Icon {
      transition: background 0.2s ease;
      width: 3rem;
      height: 3rem;
      margin-left: auto;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-softerStroke);
      cursor: pointer;

      &:hover {
        background: var(--color-softStroke);
      }
    }
  }
`

export const FlowOperationStyled = styled.span`
  background: ${({ theme }) => transparentize(0.9, theme.color.main)};
  border-radius: 6px;
  padding: 0 1rem;
  margin: auto 0.5rem;
  color: var(--color-main);
  height: 2.5rem;
  display: flex;
  align-items: center;

  &.math {
    font-size: 1.4rem;
  }
`
export const FlowParameter = styled.span`
  border: 1px solid var(--color-softerStroke);
  background: var(--color-softerStroke);
  border-radius: 6px;
  padding: 0.4rem 0.5rem;
`
