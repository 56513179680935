import { css } from 'styled-components'

export const openCloseButton = css`
  transition: box-shadow 0.3s ease;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: var(--color-backgroundFront);
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 100%;
  box-shadow: var(--shadow-shadowElevation3);
  &:hover {
    box-shadow: var(--shadow-shadowElevation5);
  }
  .Button__label {
    transition: transform 0.2s ease;
    position: absolute;
    width: 35%;
    height: 35%;
    transform-style: preserve-3d;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-top: 1px solid var(--color-mainText);
      border-right: 1px solid var(--color-mainText);
      transform: rotate(45deg) translateX(-4px) translateY(4px);
    }
  }
`
