import React from 'react'

import { useQuery } from '@apollo/client'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import { addLocaleData, IntlProvider } from 'react-intl'

import english from '../../../locales/en-US'
import french from '../../../locales/fr-FR'
import dutch from '../../../locales/nl-NL'
import { LANGUAGE_QUERY } from '../../../services/user'

addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(dutch.localeData)

const locales = {
  'en-US': english,
  'fr-FR': french,
  'nl-NL': dutch,
}

const Localization = ({ children, language }) => {
  const { data, error, loading } = useQuery(LANGUAGE_QUERY, { skip: language })

  let languageCode = 'en-US'

  if (language) {
    languageCode = language
  } else {
    if (!error && !loading) {
      languageCode = data.language
    }
  }

  const currentLocale = locales[languageCode]
  // Update the default locale for moment
  dayjs.locale(languageCode.substring(0, 2))

  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
        textComponent={React.Fragment}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default Localization
