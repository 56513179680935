import { css } from 'styled-components'

export const CustomerSelector = css`
  cursor: pointer;
  ${({ showArrow }) =>
    showArrow
      ? css`
          font-size: 1.5rem;
          .DropdownArrow {
            width: 0.5rem;
            height: 0.5rem;
            margin-left: 1rem;
          }
        `
      : css`
          transition: line-height 0.2s ease, padding 0.2s ease, color 0.2s ease;
          overflow: hidden;
          line-height: ${({ isOpen }) => (isOpen ? '1.2' : '0')};
          padding: ${({ isOpen }) => (isOpen ? '.5rem 1rem .5rem 0' : '0 1rem 0 0')};
          color: var(--color-softText);
          &:hover {
            color: var(--color-main);
          }
        `}
`
