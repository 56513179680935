import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Field from 'components/Form/components/Field'
import { Value } from 'components/Form/components/Field/styled'
import IconStyled from 'components/Global/Icon/styled'

export const ImagesFieldStyled = styled(Field)`
  padding: 1rem;

  ${Value} {
    display: flex;
    flex-direction: row !important;
  }
`

export const ImageThumb = styled.div`
  ${props => `width: ${props.width}px; height: ${props.width}px;`}
  position: relative;
  margin: 0.5rem 0.5rem 0.5rem 0;
  cursor: pointer;
  overflow: hidden;

  .ImageThumb__thumb {
    border-radius: 0.2rem;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .Icon {
      color: var(--color-softText);
      font-size: 2rem;
    }

    &.Icon {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:after {
      content: '';
      transition: border 0.2s ease, background 0.2s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid var(--color-softStroke);
      background: transparent;
    }

    &.upload {
      &:after {
        border-style: dashed;
      }
    }
  }

  ${({ deleted }) =>
    deleted &&
    css`
      .ImageThumb__thumb {
        &:after {
          border-color: var(--color-main);
          background: ${({ theme }) => transparentize(0.5, theme.color.main)};
        }

        img {
          opacity: 0.2;
        }
      }

      ${IconStyled} {
        opacity: 1 !important;
        transform: scale(1) !important;
      }
    `}
  &:hover {
    .ImageThumb__thumb {
      &:after {
        border-color: var(--color-main);
        background: ${({ theme }) => transparentize(0.3, theme.color.main)};
      }
    }

    ${IconStyled} {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const ImageThumbButtons = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  font-size: 2rem;

  ${IconStyled} {
    transition: opacity 0.2s ease, transform 0.2s ease;
    color: var(--color-backgroundFront);
    opacity: 0;
    transform: scale(0);
  }
`
