const config = {
  displayProps: {},
  subscribeToSocket: true,
  editProps: {
    name: 'widget.map',
    category: 'widget.others',
    keywords: ['chart', 'map'],
    img: '/resources/images/widgets/map.png',
    info: {
      'fr-FR':
        '<h2>Carte</h2>\n' +
        '<p>Le widget Carte affiche une carte géographique sur laquelle les POI sont affichés.</p>\n' +
        '<h3>Configuration principale</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Titre du widget</li>\n' +
        '<li><strong>Icône </strong>Icône du widget</li>\n' +
        "<li><strong>Couleur </strong>Couleur de l'icône du widget</li>\n" +
        '</ul>\n' +
        '<h4>Points de données</h4>\n' +
        '<ul>\n' +
        '<h5>Entités (LIEN)</h5>\n' +
        '<li><strong>Ajouter des sites </strong>Ajouter un site sur la carte</li>\n' +
        '<li><strong>Ajouter un marqueur personnalisé </strong> Ajouter un espace réservé pour un marqueur personnalisé sur la carte</li>\n' +
        '<li><strong>Ajouter des traceurs </strong> Ajouter un appareil de localisation sur la carte</li>\n' +
        '</ul>\n' +
        '<h4>Valeurs fixes</h4>\n' +
        '<ul>\n' +
        '<li><strong>Zoom automatique </strong> Zoomez automatiquement la carte en fonction des entités et des marqueurs sélectionnés ou non</li>\n' +
        '<li><strong>Niveau de zoom </strong> Spécifiez le niveau de zoom de la carte</li>\n' +
        '<li><strong>Centre de la carte </strong> Définissez le centre de la carte en cliquant sur la carte ou en saisissant les géocoordonnées</li>\n' +
        '</ul>\n' +
        '<h3>Configuration du point de données sélectionné</h3>\n' +
        '<h4>Général</h4>\n' +
        '<ul>\n' +
        '<li><strong>Libellé </strong>Libellé du marqueur/point de données/traceur</li>\n' +
        '<li><strong>Icône </strong>Icône pour le marqueur/datapoint/tracker</li>\n' +
        '<li><strong>Couleur </strong>Couleur du marqueur/point de données/traceur</li>\n' +
        '<li><strong>Afficher les informations de crissement </strong> Afficher les informations de crissement pour le point de données</li>\n' +
        "<li><strong>Géocoordonnées </strong> Définissez l'emplacement du marqueur en cliquant sur la carte ou en saisissant les géocoordonnées</li>\n" +
        "<li><strong>Lien</strong> Définir un tableau de bord, une section ou une URL qui s'ouvrira en cliquant sur le widget</li>\n" +
        '</ul>',
      'en-US':
        '<h2>Map</h2>\n' +
        "<p>The Map widget displays a geographical map on which POI's are displayed.</p>\n" +
        '<h3>Main Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        "<li><strong>Label </strong>Widget's title</li>\n" +
        "<li><strong>Icon </strong>Widget's icon</li>\n" +
        "<li><strong>Color </strong>Widget's icon color</li>\n" +
        '</ul>\n' +
        '<h4>Datapoints</h4>\n' +
        '<ul>\n' +
        '<h5>Entities</h5>\n' +
        '<li><strong>Add Sites </strong>Add a site on the map</li>\n' +
        '<li><strong>Add Custom marker </strong> Add a placeholder for a custom marker on the map</li>\n' +
        '<li><strong>Add Trackers </strong> Add a tracking device on the map</li>\n' +
        '</ul>\n' +
        '<h4>Fixed Values</h4>\n' +
        '<ul>\n' +
        '<li><strong>Auto zoom </strong> Zoom the map automatically based on the selected entities and markers or not</li>\n' +
        '<li><strong>Zoom level </strong> Specify the zoom level of the map</li>\n' +
        '<li><strong>Map Center </strong> Define the map center by clicking on the map or entering geocoordinates</li>\n' +
        '</ul>\n' +
        '<h3>Selected Datapoint Configuration</h3>\n' +
        '<h4>General</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Label for the marker/datapoint/tracker</li>\n' +
        '<li><strong>Icon </strong>Icon for the marker/datapoint/tracker</li>\n' +
        '<li><strong>Color </strong>Color for the marker/datapoint/tracker</li>\n' +
        '<li><strong>Show squeal info </strong> Display squeal info for the datapoint</li>\n' +
        '<li><strong>Geocoordinates </strong> Define the marker location  by clicking on the map or entering geocoordinates</li>\n' +
        '<li><strong>Link</strong> Define a Dashboard, Section or URL that will be opened when clicking on the widget</li>\n' +
        '</ul>',
      'nl-NL':
        '<h2>Kaart</h2>\n' +
        "<p>De kaartwidget toont een geografische kaart waarop POI's worden weergegeven.</p>\n" +
        '<h3>Hoofdconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Titel van widget</li>\n' +
        '<li><strong>Icoon </strong>Widget-icoon</li>\n' +
        '<li><strong>Kleur </strong>Widgetpictogramkleur</li>\n' +
        '</ul>\n' +
        '<h4>Gegevenspunten</h4>\n' +
        '<ul>\n' +
        '<h5>Entiteiten</h5>\n' +
        '<li><strong>Sites toevoegen </strong>Een site op de kaart toevoegen</li>\n' +
        '<li><strong>Aangepaste markering toevoegen </strong> Een tijdelijke aanduiding voor een aangepaste markering op de kaart toevoegen</li>\n' +
        '<li><strong>Voeg Trackers toe </strong> Voeg een volgapparaat toe aan de kaart</li>\n' +
        '</ul>\n' +
        '<h4>Vaste waarden</h4>\n' +
        '<ul>\n' +
        '<li><strong>Auto zoom </strong> Zoom de kaart automatisch in op basis van de geselecteerde entiteiten en markeringen of niet</li>\n' +
        '<li><strong>Zoomniveau </strong> Specificeer het zoomniveau van de kaart</li>\n' +
        '<li><strong>Kaartcentrum </strong> Definieer het kaartcentrum door op de kaart te klikken of geocoördinaten in te voeren</li>\n' +
        '</ul>\n' +
        '<h3>Geselecteerde datapuntconfiguratie</h3>\n' +
        '<h4>Algemeen</h4>\n' +
        '<ul>\n' +
        '<li><strong>Label </strong>Label voor de markering/datapunt/tracker</li>\n' +
        '<li><strong>Icoon </strong>Icoon voor de markering/datapunt/tracker</li>\n' +
        '<li><strong>Kleur </strong>Kleur voor de markering/datapunt/tracker</li>\n' +
        '<li><strong>Toon squeal-info </strong> Toon squeal-info voor het datapunt</li>\n' +
        '<li><strong>Geocoördinaten </strong> Definieer de markeringslocatie door op de kaart te klikken of geocoördinaten in te voeren</li>\n' +
        '<li><strong>Link</strong> Definieer een dashboard, sectie of URL die wordt geopend wanneer op de widget wordt geklikt</li>\n' +
        '</ul>',
    },
    default: {
      type: 'SiteMap',
      title: 'widget.map',
      icon: 'fas fa-info',
      datapoints: [],
      url: null,
      color: null,
      size: 's',
    },
    preview: {
      icon: 'fas fa-map',
      map: {
        sites: [
          {
            type: 'site',
            id: '0052',
            tags: {
              dis: 'Linker Oever',
              geoCoord: '51.279694,4.306193',
              customerRef: 'Lanxess',
            },
            name: 'Site Linker Oever',
            __typename: 'AviaryEntity',
          },
          {
            type: 'site',
            id: '89da4cd2-3ad6-4c40-a9f5-28c427c30772',
            tags: {
              dis: 'Rechter Oever',
              geoCoord: '51.283149,4.331848',
              customerRef: 'Lanxess',
            },
            name: 'Site Rechter Oever ',
            __typename: 'AviaryEntity',
          },
        ],
        markerLinks: {
          '0052': '/dashboard/lanxess_lo',
          '89da4cd2-3ad6-4c40-a9f5-28c427c30772': '/dashboard/lanxess_ro',
        },
        severity: {
          severityCount: {
            critical: 27,
            warning: 3,
            info: 7,
          },
        },
      },
    },
    fields: [
      {
        category: 'widget.general',
        componentName: 'Input',
        label: { formatted: 'widget.label' },
        dataKey: 'title',
      },
      {
        category: 'widget.general',
        componentName: 'IconPicker',
        label: { formatted: 'widget.icon' },
        col: 'col2 first',
        dataKey: 'icon',
        componentProps: {
          bottomDrawer: true,
        },
      },
      {
        category: 'widget.general',
        componentName: 'ColorPicker',
        label: { formatted: 'widget.color' },
        col: 'col2',
        dataKey: 'color',
      },
      {
        category: 'widget.datapoints',
        componentName: 'MapEntitySelector',
        label: { formatted: 'widget.entities' },
        dataKey: 'datapoints',
        componentProps: {
          dataKey: 'datapoints',
          customMarkerOption: true,
          traceableDevicesOption: true,
          section: {
            name: 'datapoints',
            componentName: 'DatapointConfigurator',
            componentProps: {
              dataKey: 'datapoints',
              selectorComponent: 'MapEntitySelector',
              customMarkerOption: true,
              traceableDevicesOption: true,
              fields: [
                {
                  category: 'widget.general',
                  componentName: 'Input',
                  label: { formatted: 'widget.label' },
                  dataKey: 'dis',
                },
                {
                  category: 'widget.general',
                  componentName: 'IconPicker',
                  label: { formatted: 'widget.icon' },
                  col: 'col2 first',
                  dataKey: 'icon',
                  componentProps: {
                    bottomDrawer: true,
                  },
                },
                {
                  category: 'widget.general',
                  componentName: 'ColorPicker',
                  label: { formatted: 'widget.color' },
                  col: 'col2',
                  dataKey: 'color',
                },
                {
                  category: 'widget.general',
                  componentName: 'Switch',
                  label: { formatted: 'widget.showSquealInfo' },
                  dataKey: 'showSquealInfo',
                  col: 'col2 first',
                  dependencies: [
                    {
                      dependentOn: 'selectedDatapointType',
                      dependencyType: 'valueIs',
                      dependencyValue: 'site',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'LocationSearch',
                  label: { formatted: 'widget.geocoordinatesClickOnMap' },
                  dataKey: 'geoCoord',
                  validation: { type: 'required' },
                  dependencies: [
                    {
                      dependentOn: 'selectedDatapointType',
                      dependencyType: 'valueIsNot',
                      dependencyValue: 'equip',
                      dependencyResolver: 'show',
                    },
                  ],
                },
                {
                  category: 'widget.general',
                  componentName: 'LinkPicker',
                  label: { formatted: 'widget.link' },
                  componentProps: {
                    hideSections: true,
                  },
                },
              ],
            },
          },
        },
      },
      {
        category: 'widget.fixedValues',
        componentName: 'MapZoomConfigurator',
      },
    ],
  },
}

export default config
