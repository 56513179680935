import React, { useEffect, useRef, useState } from 'react'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'

import Icon from '../../../../../Global/Icon'
import { ModeStyled } from './styled'

const Mode = ({
  value,
  buttons = {},
  datapoint = {},
  writeDatapoint = {},
  confirmation,
  onConfirmationRequest,
}) => {
  const element = useRef()
  const [mode, setMode] = useState(String(value))
  useEffect(() => {
    setMode(String(value))
  }, [value])

  useEffect(() => {
    if (confirmation.isConfirmed) {
      setMode(confirmation.value)
    }
  }, [confirmation])

  const onClick = (value) => {
    onConfirmationRequest({ value })
  }

  return (
    <ModeStyled className="ModeControl" ref={element}>
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        parentRef={element.current}
        id={writeDatapoint?.id || datapoint.id}
        onConfirmationRequest={onConfirmationRequest}
      >
        <Icon icon={buttons[confirmation.value]?.icon} />
      </ActionConfirmationWithAdjustableValue>
      {Object.entries(buttons).map(([value, button], i) => (
        <Icon
          className={`${value === mode ? 'active' : 'inactive'}`}
          key={`${button.icon}-${i}`}
          icon={button.icon}
          color={button.color}
          onClick={() => onClick(value)}
        />
      ))}
    </ModeStyled>
  )
}

export default Mode
