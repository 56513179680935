import { transparentize } from 'polished'
import styled from 'styled-components'

import Button from 'components/Global/Button'

export const PlusMinusControlStyled = styled.div`
  font-size: 18px;
  cursor: pointer;
`

export const PlusMinusControlButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.5rem;
  transition: background 0.2s ease, color 0.2s ease;

  flex-shrink: 0;
  align-self: flex-end;

  font-size: 11px !important;

  color: var(--color-mainText);
  &:first-child {
    border-bottom: 1px solid var(--color-softStroke);
  }
  &:hover {
    color: var(--color-main);
    background: ${({ theme }) => transparentize(0.9, theme.color.main)};
  }
`
