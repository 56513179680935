import styled from 'styled-components'

const LocationSearchStyled = styled.div`
  > p {
    color: var(--color-softText);
    margin-bottom: 0;
    opacity: 0.6;
  }
  .ant-input-affix-wrapper {
    border-radius: 0.2rem;
    border-color: var(--color-softStroke);
  }
`
export default LocationSearchStyled
