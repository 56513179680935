import styled from 'styled-components'

/*export const FlagIcon = styled(Icon)({
  icon: (props) => props.Img,
})`
    width: 100%;
  `*/

export const TooltipContent = styled.div`
  background: var(--color-backgroundFront);
  max-height: 400px;
  overflow: auto;
  padding: 1rem;
`

export const CustomerFixedPermissionsSelectorStyled = styled.div`
  width: 100%;

  .Tooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .Button {
      flex-direction: row-reverse;
      &:hover,
      &.active {
        .Icon {
          color: var(--color-action);
        }
      }
    }

    .Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      margin: 0.5rem 0 0.5rem 0.5rem;
      color: var(--color-softText);
    }
  }
`
