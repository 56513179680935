export const posthogConfig = {}

export const posthogEnabledDomains = ['demo', 'prod', 'staging', 'local']
export const isPosthogEnabled = posthogEnabledDomains.includes(process.env.REACT_APP_ENV as any)

export class PostHog {
  static identify({ userId, email, name }: { userId: string; email?: string; name?: string }) {
    if (!window.posthog._isIdentified()) {
      window.posthog.identify(userId, {
        email,
        name,
      })
    }
  }
}
