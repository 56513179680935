import dayjs from 'dayjs'

import { CALCULATIONS, TIME_FORMAT_BY_UNIT } from '../../../util/constants'
import { arrow } from '../../Dashboard/utils/common/arrow'

export const getHeatMapRange = ({ comparators, range, difference, offset, data }) => {
  let calculatedRange
  if (!comparators || comparators?.length === 0) {
    calculatedRange = range
  } else if (!difference && typeof comparators[0] === 'number') {
    calculatedRange = [comparators[0] - offset, comparators[0] + offset]
  } else {
    const sum = data?.reduce((a, b) => a + b[2], 0)
    const avg = sum / data?.length || 0
    calculatedRange = [avg - offset, avg + offset]
  }
  return calculatedRange
}

const getDateTime = ({ start, offset, offsetUnit, formatUnit, format }) => {
  return TIME_FORMAT_BY_UNIT(start.add(offset, offsetUnit), formatUnit, true, format)
}

const getDateElement = ({
  xAxisTime,
  heatmapGranularityValue,
  heatmapGranularityUnit,
  granularityUnit,
  yAxisUnit,
  color,
  intervalMethod,
  intl,
}) => {
  const offsetUnit = yAxisUnit // === 'w' ? 'd' : yAxisUnit
  const discardHours = heatmapGranularityValue === 1 && heatmapGranularityUnit === 'd'
  const discardDate = (!discardHours && granularityUnit === 'w') || heatmapGranularityUnit === 'w'

  const date = getDateTime({
    start: xAxisTime,
    offset: 0,
    offsetUnit: heatmapGranularityUnit,
    formatUnit: granularityUnit,
    format: 'DATE_NAME_FORMAT',
  })
  const startHour =
    !discardHours &&
    getDateTime({
      start: xAxisTime,
      offset: 0,
      offsetUnit: yAxisUnit,
      formatUnit: yAxisUnit,
      format: yAxisUnit === 'm' && 'HOUR_FORMAT',
    })
  const endHour =
    !discardHours &&
    getDateTime({
      start: xAxisTime,
      offset: heatmapGranularityValue,
      offsetUnit: offsetUnit,
      formatUnit: yAxisUnit,
      format: yAxisUnit === 'm' && 'HOUR_FORMAT',
    })

  return `<span class="tooltip__date">${discardDate ? '' : `<strong>${date}</strong>`}${
    !discardHours && !discardDate && (startHour || endHour) ? ': ' : ''
  }${!discardHours && startHour ? `<span>${startHour}</span>` : ''}${
    !discardHours && startHour && endHour ? arrow(color) : ''
  }${!discardHours && endHour ? `<span>${endHour}</span>` : ''}${
    intervalMethod
      ? `<span class="tooltip__date-method">${intl.formatMessage({
          id: `widget.${intervalMethod}`,
        })}</span>`
      : ''
  }</span></span>`
}

const getTooltipDate = ({
  granularityValue,
  granularityUnit,
  xAxisTime,
  time,
  intervalMethod = 'mean',
  intl,
  ...input
}) => {
  const elements = []

  for (let i = 0; i < granularityValue; i++) {
    const dateTime = dayjs(time) // || xAxisTime

    elements.push(
      getDateElement({
        ...input,
        intl,
        granularityValue,
        granularityUnit,
        xAxisTime: i ? dateTime.add(i, granularityUnit) : dateTime,
        intervalMethod: granularityValue > 1 && i < granularityValue - 1 && intervalMethod,
      })
    )
  }
  return elements.join('')
}

export const addGroupedByGranularity = ({
  index,
  yAxisMax,
  granularityValue,
  pointValue,
  calculationMethod = 'mean',
  value,
  output,
  time,
}) => {
  const cycle = yAxisMax * granularityValue
  const yAxisIndex = index % yAxisMax
  if (index % cycle === cycle - 1) {
    const xAxisIndex = Math.floor(index / cycle)
    output.comparedSeries.push(
      ...output.pending.map(({ values, time }, index) => {
        return {
          x: xAxisIndex,
          y: index,
          value: CALCULATIONS[calculationMethod]([...values, pointValue]),
          time,
        }
      })
    )

    output.pending = []

    output.absoluteSeries.push(
      ...output.pendingAbsolute.map(({ values, time }) => [
        xAxisIndex,
        CALCULATIONS[calculationMethod]([...values, value]),
        time,
      ])
    )
  } else {
    output.pending[yAxisIndex] = output.pending[yAxisIndex]
      ? {
          ...output.pending[yAxisIndex],
          values: [...output.pending[yAxisIndex].values, pointValue],
        }
      : { time, values: [pointValue] }

    output.pendingAbsolute[yAxisIndex] = output.pendingAbsolute[yAxisIndex]
      ? {
          ...output.pendingAbsolute[yAxisIndex],
          values: [...output.pendingAbsolute[yAxisIndex].values, value],
        }
      : { time, values: [value] }
  }
}

export const addGranularityPlaceholders = ({
  yAxisUnit,
  yAxisMax,
  x,
  time,
  index,
  dayOffset,
  output,
  start,
}) => {
  if (yAxisUnit === 'day' || yAxisUnit === 'd') {
    const timeMoment = dayjs(time)
    const daysInMonth = timeMoment.daysInMonth()
    if (daysInMonth < yAxisMax) {
      const date = timeMoment.date()
      if (date === daysInMonth) {
        const diff = yAxisMax - daysInMonth

        for (let i = 1; i < diff; i++) {
          output.comparedSeries.push([x, date + i, null])
        }
        dayOffset = dayOffset + diff
      }
    }
  }
  if (yAxisUnit === 'week' || yAxisUnit === 'w') {
    if ((index + dayOffset) % 5 === 3) {
      const col = Math.floor((index + dayOffset) / 5) + 1
      const compare = start.add(index + 2, 'weeks').format('M') * 1
      if (col !== compare) {
        output.comparedSeries.push({ x, y: 4, value: null })
        dayOffset = dayOffset + 1
      }
    }
  }
  return dayOffset
}

export const getGroupedDataValue = (datapoints, method = 'sum', index) => {
  return datapoints.reduce(
    (result, { data }, dpIndex) => {
      result.args.push(data[index]?.[1])
      if (dpIndex === datapoints.length - 1) {
        result.result = CALCULATIONS[method](result.args)
      }
      return result
    },
    { args: [], result: 0 }
  ).result
}

export const getTooltipLabel = ({
  yAxisUnit,
  start,
  pointX,
  granularityValue,
  granularityUnit,
  yAxisMax,
  pointY,
  heatmapGranularityValue = 1,
  heatmapGranularityUnit,
  color,
  time,
  intervalMethod,
  intl,
}) => {
  return getTooltipDate({
    xAxisTime: start,
    pointX,
    pointY,
    heatmapGranularityValue,
    heatmapGranularityUnit,
    granularityUnit,
    granularityValue,
    yAxisUnit,
    yAxisMax,
    color,
    time,
    intervalMethod,
    intl,
  })
}
