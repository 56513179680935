import styled, { css } from 'styled-components'

export const StyledAvailableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AvailableItemStyled = styled.div`
  width: 100%;
  margin-bottom: 0.2rem;

  .AvailableItem__caption {
    transition: border 0.2s ease;
    padding: 0.7rem 2rem;
    text-align: left;
    border: 1px solid var(--color-softStroke);
    border-radius: 0.2rem;
    display: block;
    cursor: grab !important;

    &:hover {
      border-color: var(--color-main);
    }
  }
`
export const ReceiverStyled = styled.div`
  width: 100%;
  margin-bottom: 0.2rem;
  padding: 0 0.2rem;
`

export const ReceiverContentStyled = styled.div`
  transition: border 0.2s ease, background 0.2s ease;
  padding: 0.7rem 2rem;
  text-align: left;
  border: 1px solid var(--color-softStroke);
  border-radius: 0.2rem;
  display: flex;
  line-height: 1;
  align-items: center;
  height: 100%;

  ${({ isDraggedOver }) =>
    isDraggedOver &&
    css`
      background: var(--color-softerStroke);
      border: 1px dashed var(--color-softText);

      .Icon {
        color: var(--color-mainText);
      }
    `}
  ${({ isMapped }) =>
    isMapped &&
    css`
      background: none;
      border: 1px solid var(--color-main);

      .ReceiverContent__label {
        color: var(--color-strongText) !important;
      }

      .Icon {
        color: var(--color-mainText);
      }
    `}
  .ReceiverContent__label {
    flex: 1;
  }

  .Icon {
    margin: 0 1rem;
    color: var(--color-softStroke);
  }
`
