import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import Tooltip from '../../Tooltip'

const ColorPickerStyled = styled(Tooltip)`
  display: flex;
  position: relative;
  z-index: ${({ isOpen }) => isOpen && 10};
  &:hover {
    > .Button {
      background: ${({ theme }) => transparentize(0.9, theme.color.main)};
    }
  }

  > .Button {
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 3px;
    border: 1px solid ${({ isOpen, color }) => (isOpen ? color : 'transparent')};

    &.active,
    &:hover {
      &:after {
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
      }
    }
  }
`

export const ColorElement = styled(motion.span)`
  width: 1.8rem;
  height: 1.8rem;
  margin: 0.3rem;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  border: 1px solid ${({ isSelected, value }) => (isSelected ? value : 'transparent')};
  &:after {
    transition: background 0.2s ease, top 0.2s ease, bottom 0.2s ease, left 0.2s ease,
      right 0.2s ease;
    box-shadow: var(--shadow-shadowElevation1);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 3px;
    background: ${({ value }) => value};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      &:after {
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
      }
    `}
`

export default ColorPickerStyled
